import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faChevronDown, faTimes } from "@fortawesome/pro-light-svg-icons";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import CloseButton from "../generic/CloseButton";
import Overview from "../managers/ChangeLogManager/Changelogs/Overview";
import CurrentVersion from "../managers/ChangeLogManager/CurrentVersion";

export interface SettingsDialogContentVersionHistoryProps {
    onClose: () => void;
}

const SettingsDialogContentVersionHistory = ({ onClose }: SettingsDialogContentVersionHistoryProps) => {
    const [expanded, setExpanded] = useState<string>(CurrentVersion.toShortString());

    const renderVersions = () => {
        return Overview.map((changelog) => (
            <Accordion
                expanded={expanded === changelog.version.toShortString()}
                onChange={() => setExpanded(changelog.version.toShortString())}
                key={changelog.version.toShortString()}
            >
                <AccordionSummary expandIcon={<FontAwesomeSvgIcon icon={faChevronDown} />} id={changelog.version.toString()}>
                    <div>
                        <Typography style={{ width: "20%" }}>{changelog.version.toShortString()}</Typography>
                        <Typography variant="caption" component="span">
                            {changelog.date.toLocaleDateString("nl-BE", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>{changelog.content}</AccordionDetails>
            </Accordion>
        ));
    };

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <DialogTitle>Versie Geschiedenis</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            <DialogContent dividers>{renderVersions()}</DialogContent>
            <DialogActions sx={{ m: "5px" }}>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Sluiten
                </Button>
            </DialogActions>
        </>
    );
};

export default SettingsDialogContentVersionHistory;
