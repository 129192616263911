import { useContext } from "react";
import SplitPane from "./SplitPane";
import CommentForm from "../CommentForm";
import Overlays from "../../Overlays";
import LayerProperties from "../LayerProperties";
import ViewsProperties from "../ViewsProperties";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { PanesOrientation } from "../../../context/AppContext/State";
import { SplitPaneContextProvider } from "../../../context/SplitPaneContext/SplitPaneContextProvider";

import styles from "./SplitPane.module.css";
import { Box } from "@mui/material";

const SplitPaneContainer = () => {
    const { state } = useContext(AppContext);

    const renderPanes = () => {
        const { panesState, panesOrientation } = state;
        const orientation = PanesOrientation[panesOrientation].toLowerCase();

        return (
            <div className={styles[orientation]}>
                {panesState.map((pane, index) => (
                    <SplitPaneContextProvider key={index} id={index + 1}>
                        <SplitPane />
                    </SplitPaneContextProvider>
                ))}
            </div>
        );
    };

    const { panesCount } = state;
    return (
        <Box
            sx={{
                flex: 1,
                display: "flex",
                position: "relative",
                height: "100%",
            }}
        >
            <div className={[styles.root, panesCount % 2 === 0 ? styles.even : styles.odd].join(" ")}>{renderPanes()}</div>

            <Overlays />

            <div className={styles.feedback}>
                <CommentForm />
            </div>

            <LayerProperties />
            <ViewsProperties />
        </Box>
    );
};

export default SplitPaneContainer;
