import { configuration } from "../../_configuration/configuration";
import { addProperty, deleteProperty, editProperty, setProperties } from "../../redux/app/actions";
import store from "../../redux/store";

export const fetchProperties = () => {
    const url = `${configuration.api}api/Properties`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((properties) => {
            store.dispatch(setProperties(properties));
        });
};

export const createProperty = (property) => {
    const url = `${configuration.api}api/Properties`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(property),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((property) => {
            store.dispatch(addProperty(property));
        });
};

export const modifyProperty = (property) => {
    const url = `${configuration.api}api/Properties/${property.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(property),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((property) => {
            store.dispatch(editProperty(property));
        });
};

export const removeProperty = (id) => {
    const url = `${configuration.api}api/Properties/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteProperty(id));
    });
};
