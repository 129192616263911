import { configuration } from "../_configuration/configuration";

export const getObjectIndexByName = async (name, cancellation) => {
    const url = `${configuration.api}api/ObjectIndex/Name/Search/${name}`;
    const response = await fetch(url, { signal: cancellation });
    if (!response.ok) {
        throw response;
    }

    return await response.json();
};
