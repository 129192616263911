import { Box, useTheme } from "@mui/material";

const LayersIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.462" height="20" viewBox="0 0 25.462 20">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    // stroke: theme.colors.secondaryDark,
                }}
                d="M12.73,24.361,0,18.065,12.73,10.8l12.731,7.268Zm0,1.018L5.138,21.63,0,24.547,12.73,30.8l12.731-6.3-5.139-2.917Z"
                transform="translate(0.001 -10.797)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.461" height="22" viewBox="0 0 27.461 22">
            <Box
                component={"path"}
                sx={{
                    fill: "none",
                    stroke: theme.colors.placeholderText,
                    strokeLinejoin: "round",
                    strokeWidth: "2px",
                }}
                d="M29.734,3.177,17-3.119l12.73-7.264L42.465-3.115Zm0,1.019L22.142.446,17,3.367l12.731,6.25,12.73-6.3L37.326.4Z"
                transform="translate(-16.004 11.383)"
            />
        </svg>
    );
};

export default LayersIcon;
