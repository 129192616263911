import { useTheme } from "@mui/material";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FavoriteIcon = ({ selected, redBackground }) => {
    const theme = useTheme();

    const defaultStyle = {
        color: theme.colors.disabled,
    };

    const redStyle = {
        color: theme.colors.btnActive,
    };

    const selectedStyle = {
        color: "#FFCE00",
    };

    let style;
    if (selected) style = selectedStyle;
    else if (redBackground) style = redStyle;
    else style = defaultStyle;

    return <FontAwesomeIcon icon={faStar} style={style} />;
};

export default FavoriteIcon;
