import { Box, useTheme } from "@mui/material";

const Collapse = ({ isAlt }) => {
    const theme = useTheme();

    return (
        <Box
            component={"svg"}
            xmlns="http://www.w3.org/2000/svg"
            width="18.002px"
            height="18px"
            viewBox="0 0 18.002 18"
            sx={{
                "& path": {
                    fill: isAlt ? theme.colors.primaryText : theme.colors.inputBackground,
                },
            }}
        >
            <path d="M159.109,33.829h6.469V40.3l-2.149-1.935-3.108,3.11-2.384-2.384,3.107-3.111Z" transform="translate(-157.937 -23.475)" />
            <path d="M172.829,28.967l3.11-3.108-2.381-2.384-3.111,3.107L168.3,24.647v6.469h6.469Z" transform="translate(-157.937 -23.475)" />
            <path d="M160.322,23.475l-2.384,2.384,3.107,3.108-1.938,2.149h6.469V24.647l-2.149,1.935Z" transform="translate(-157.937 -23.475)" />
            <path d="M173.557,41.475l2.381-2.384-3.106-3.115,1.935-2.149H168.3V40.3l2.149-1.935Z" transform="translate(-157.937 -23.475)" />
        </Box>
    );
};

export default Collapse;
