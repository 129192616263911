import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

import { Tip } from "../managers/TipsManager/TipsManager";
import ReactMarkdown from "react-markdown";
import CloseButton from "../generic/CloseButton";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";

export interface SettingsDialogContentUpdateHistoryProps {
    tips: any;
    onClose: () => void;
}

const SettingsDialogContentTipsHistory = ({ tips, onClose }: SettingsDialogContentUpdateHistoryProps) => {
    const [activeTips, setActiveTips] = useState<Array<Tip>>([]);

    useEffect(() => {
        setActiveTips(Tip.fromJson(JSON.stringify(tips)));
    }, [tips]);

    const renderUpdates = () => {
        return orderBy(activeTips, (o) => o.date, "desc").map((tip: Tip) => (
            <Card key={tip.id} style={{ marginBottom: "16px" }}>
                <CardContent>
                    <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                        {tip.date.toLocaleDateString("nl-BE", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </Typography>
                    <ReactMarkdown linkTarget={"_blank"}>{tip.description}</ReactMarkdown>
                </CardContent>
            </Card>
        ));
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>Tips Geschiedenis</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            <DialogContent dividers>{renderUpdates()}</DialogContent>
            <DialogActions sx={{ m: "5px" }}>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Sluiten
                </Button>
            </DialogActions>
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({ tips: appReducer.tips });

export default connect(mapStateToProps)(SettingsDialogContentTipsHistory);
