import { useContext, useMemo } from "react";
import { Box, styled, useTheme } from "@mui/material";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { SplitPaneContext } from "../../../context/SplitPaneContext/SplitPaneContextProvider";
import { togglePaneLegend, togglePaneMeasurementTool, togglePaneSurfaceTool } from "../../../context/AppContext/Reducer";
import StyledTooltip from "../../generic/StyledTooltip";

const Root = styled(Box)(({ theme }) => ({
    pointerEvents: "all",
    backgroundColor: theme.colors.white,
    display: "flex",
    boxShadow: "0 0 2px #D2DDE5",
    userSelect: "none",
}));

const Toggle = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    height: 32,
    width: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "32px",
    maxHeight: "32px",
    padding: "8px",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.textColor,
        color: theme.colors.white,
    },
}));

const ArcgisTools = () => {
    const { state: appState, dispatch: appDispatch } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);
    const paneData = useMemo(
        () => appState.panesState.find((x) => x.id === paneState.id),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [paneState.id]
    );
    const theme = useTheme();

    const buttonGroups = [
        {
            group: "measurement-tools",
            buttons: [
                {
                    name: "distance",
                    className: "esri-icon-measure-line",
                    tooltip: "Afstand meten",
                    callback: () => appDispatch(togglePaneMeasurementTool(paneState.id)),
                    isActive: () => paneData.data?.tools?.measureTool ?? false,
                },
                {
                    name: "surface",
                    className: "esri-icon-measure-area",
                    tooltip: "Oppervlakte meten",
                    callback: () => appDispatch(togglePaneSurfaceTool(paneState.id)),
                    isActive: () => paneData.data?.tools?.surfaceTool ?? false,
                },
            ],
        },
        {
            group: "layer-information",
            buttons: [
                {
                    name: "legend",
                    className: "esri-icon-legend",
                    tooltip: "Legenda",
                    callback: () => appDispatch(togglePaneLegend(paneState.id)),
                    isActive: () => paneData.data?.tools?.legend ?? false,
                },
            ],
        },
    ];

    const renderButtons = (actions) => {
        return actions.map((action, index) => {
            const isSelected = action.isActive();
            let style = {};
            if (isSelected) {
                style = {
                    backgroundColor: theme.colors.textColor,
                    color: theme.colors.white,
                };
            }

            return (
                <StyledTooltip title={action.tooltip} key={index}>
                    <Toggle id={action.name} sx={style} className={action.className} onClick={() => action.callback()} />
                </StyledTooltip>
            );
        });
    };

    return (
        <>
            {buttonGroups.map((group, index) => (
                <Root key={index} id={group.name}>
                    {renderButtons(group.buttons)}
                </Root>
            ))}
        </>
    );
};

export default ArcgisTools;
