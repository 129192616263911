import { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { Box, styled, Typography } from "@mui/material";
import * as forgeConnector from "../connectors/forge";
import * as tokenConnector from "../connectors/token";
import { fetchActivatableResourceGroups } from "../connectors/admin/resources";
import { setSelectedSidebarTabId } from "../redux/app/actions";
import { clearBookmarkState, setExternalDbIdMapping, setExternalIdMapping, setModelViews } from "../redux/forge/actions";
import { setViewerProperties } from "../redux/properties/actions";
import { cancelHighlights, setSelectedObject, setQueuedHighlights } from "../redux/selection/actions";
import ColorFilterExtension from "./forgeComponentExtensions/ColorFilterExtension";
import SectionBoxExtension from "./forgeComponentExtensions/SectionBoxExtension";
import QueryFilterExtension from "./forgeComponentExtensions/QueryFilterExtension/QueryFilterExtension";
import SplitPaneSyncExtension from "./forgeComponentExtensions/SplitPaneSyncExtension";
import ForgeLegend from "./appControls/ForgeLegend";
import { AppContext } from "../context/AppContext/AppContextProvider";
import { beginLoading, finishedLoading, setPaneDocuments, setPaneLegend, setPaneObject } from "../context/AppContext/Reducer";
import { PaneMode } from "../context/SplitPaneContext/State";
import { AutodeskForge } from "../utils/AutodeskForge";
import { getFlatModelsArray } from "../utils/modelFunctions";
import { configuration } from "../_configuration/configuration";

const Container = styled(Box)(() => ({
    position: "relative",
    flex: 1,
    maxWidth: "100%",
}));

const Message = styled(Typography)(() => ({
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

function* flatten(array, depth) {
    if (depth === undefined) depth = 1;

    for (const item of array) {
        if (Array.isArray(item.items) && depth > 0) {
            yield* flatten(item.items, depth - 1);
        } else {
            yield item;
        }
    }
}

const Forge = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const paneData = state.panesState.find((x) => x.id === props.paneId);

    const [fetched, setFetched] = useState(false);
    const [viewerReady, setViewerReady] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [models, setModels] = useState([]);
    const [queuedSelectedObject, setQueuedSelectedObject] = useState(null);
    const [queuedHighlightIds, setQueuedHighlightIds] = useState(null);
    const [hasVisibleModels, setHasVisibleModels] = useState(false);

    const viewerDivRef = useRef();
    const viewerAppRef = useRef();
    const panesStateRef = useRef();
    const bookmarkViewpointRef = useRef();
    const selectOnLoadRef = useRef({});

    const loadedModelsRef = useRef({});
    const loadingModelsRef = useRef({});
    const paneDataRef = useRef({});

    const viewCubeOrientationSet = useRef(false);

    useEffect(() => {
        launchViewer();
        fetchDocuments();
        fetchActivatableResourceGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        paneDataRef.current = paneData;
    }, [paneData]);

    useEffect(() => {
        setInitialized(viewerReady && fetched);
    }, [viewerReady, fetched]);

    useEffect(() => {
        if (initialized && props.selectedObject) handleChangeSelection();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedObject, initialized]);

    useEffect(() => {
        if (initialized && props.highlights && props.highlights.paneId === props.paneId) accentuateHighlightIds(props.highlights.highlightIds);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.highlights, initialized]);

    useEffect(() => {
        if (initialized) updateDocuments();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, paneData.data?.documents]);

    useEffect(() => {
        if (initialized) selectQueuedSelectedObject();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, queuedSelectedObject]);

    useEffect(() => {
        if (initialized) selectQueuedHighlightIds();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized, queuedHighlightIds]);

    useEffect(() => {
        if (!initialized) return;
        const splitPaneSyncExt = paneData.appObject?.loadedExtensions["SplitPaneSyncExtension"];
        const bimViewers = state.panesState.filter((x) => x.mode === PaneMode.BIM && Boolean(x.appObject));
        panesStateRef.current = state.panesState;
        if (splitPaneSyncExt) splitPaneSyncExt.updateStatus(bimViewers.length > 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.panesState, initialized]);

    useEffect(() => {
        const { bookmarkState, bookmarkStatePaneId, paneId, onClearBookmarkState } = props;

        if (bookmarkStatePaneId !== paneId || !initialized) return;

        const { active, viewpoint } = bookmarkState;
        const documents = [...paneData.data.documents];
        const data = getFlatModelsArray(documents);

        for (let i = 0, len = data.length; i < len; i++) {
            const doc = data[i];
            const tar = active.find((x) => x.id === doc.id);
            doc.visible = tar?.visible ?? false;
        }

        dispatch(setPaneDocuments(props.paneId, documents));
        bookmarkViewpointRef.current = viewpoint;
        onClearBookmarkState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bookmarkState, initialized]);

    const selectionChanged = (event) => {
        const { onSetSelectedObject, onSetViewerProperties } = props;

        const selectedObject = event.selections.flat()[0];
        if (selectedObject) {
            const dbId = selectedObject.dbIdArray[0];
            const model = selectedObject.model;
            const modelId = model.myData.urn;

            const externalIdModelMap = props.externalIdMapping[modelId];

            if (externalIdModelMap) {
                const externalId = externalIdModelMap[dbId];
                const selectedId = `${modelId}.${externalId}`;

                onSetSelectedObject(selectedId, configuration.sources.bim, props.paneId);
                if (model) {
                    getProperties(modelId, dbId).then(({ properties }) => {
                        if (properties) onSetViewerProperties(properties, selectedId);
                    });
                }
            } else {
                model.getExternalIdMapping((data) => {
                    const externalIds = Object.keys(data);
                    const mapping = {};
                    for (let i = 0, len = externalIds.length; i < len; i++) {
                        const externalId = externalIds[i];
                        const dbId = data[externalId];
                        mapping[dbId] = externalId.replaceAll("/", "_");
                    }

                    props.onSetExternalIdMapping(modelId, mapping);
                    props.onSetExternalDbIdMapping(modelId, data);

                    const externalId = mapping[dbId];
                    const selectedId = `${modelId}.${externalId}`;

                    onSetSelectedObject(selectedId, configuration.sources.bim, props.paneId);
                    if (model) {
                        getProperties(modelId, dbId).then(({ properties }) => {
                            if (properties) onSetViewerProperties(properties, selectedId);
                        });
                    }
                });
            }
        } else onSetSelectedObject(null, configuration.sources.bim, props.paneId);
    };

    const registerCustomExtensions = () => {
        const extManager = window.Autodesk.Viewing.theExtensionManager;
        const extensions = {
            ColorFilterExtension: ColorFilterExtension,
            SectionBoxExtension: SectionBoxExtension,
            QueryFilterExtension: QueryFilterExtension,
            SplitPaneSyncExtension: SplitPaneSyncExtension,
        };

        for (let i = 0; i < Object.keys(extensions).length; i++) {
            const extName = Object.keys(extensions)[i];
            const ext = extensions[extName];
            if (!extManager.isAvailable(extName)) extManager.registerExtension(extName, ext);
        }
    };

    const loadCustomExtensions = (viewerApp) => {
        const { onSetQueuedHighlights, forgeFilters } = props;

        viewerApp.loadExtension(`ColorFilterExtension`, {
            id: props.paneId,
            data: JSON.parse(JSON.stringify(forgeFilters)),
            callback: (filter) => applyColorFilter(filter),
        });

        viewerApp.loadExtension(`SectionBoxExtension`, { id: props.paneId });

        viewerApp.loadExtension(`QueryFilterExtension`, {
            id: props.paneId,
            onFilter: (ids) => onSetQueuedHighlights("forge", ids, true),
            onReset: () => clearAccentuations(),
        });

        viewerApp.loadExtension(`SplitPaneSyncExtension`, {
            id: props.paneId,
            onSyncCamera: () => onSyncCamera(),
            onSyncSections: () => onSyncSections(),
        });
    };

    const onSyncCamera = () => {
        const viewerState = viewerAppRef.current.getState({ viewport: true });
        const otherBimViewers = panesStateRef.current.filter((x) => x.mode === PaneMode.BIM && x.id !== props.paneId);
        otherBimViewers.forEach((viewer) => {
            if (Boolean(viewer.appObject)) {
                viewer.appObject.restoreState(viewerState);
            }
        });
    };

    const onSyncSections = () => {
        const { cutplanes } = viewerAppRef.current.getState({ cutplanes: true });
        if (cutplanes.length <= 0) return;

        const otherBimViewers = panesStateRef.current.filter((x) => x.mode === PaneMode.BIM && x.id !== props.paneId);
        otherBimViewers.forEach((viewer) => {
            if (Boolean(viewer.appObject)) {
                const sectionBoxExtension = viewer.appObject.loadedExtensions["SectionBoxExtension"];
                if (!Boolean(sectionBoxExtension.sections)) {
                    sectionBoxExtension.sections = viewer.appObject.loadedExtensions["Autodesk.Section"];
                }

                const cutplane = cutplanes[0];
                const plane = new window.THREE.Vector4(cutplane[0], cutplane[1], cutplane[2], cutplane[3]);

                viewer.appObject.restoreState({ cutplanes: [] });
                viewer.appObject.setCutPlanes([plane]);
                sectionBoxExtension.active = true;
                sectionBoxExtension.sections.activate();
                sectionBoxExtension.updateToolbarStatus();
            }
        });
    };

    const launchViewer = () => {
        const { activeProject } = props;
        const bimResources = activeProject.resources.find((x) => x.Name === "Bim360");

        if (!bimResources) return;

        registerCustomExtensions();

        let options = {
            env: "AutodeskProduction",
            api: bimResources.Region === "EU" ? "derivativeV2_EU" : "derivativeV2",
            loaderExtensions: { svf: "Autodesk.MemoryLimited" }, // for allocating more memory: https://forge.autodesk.com/en/docs/viewer/v7/developers_guide/viewer_basics/memory-limit/
            language: "nl",
            getAccessToken: (onSuccess) => {
                tokenConnector
                    .fetchToken(configuration.sources.bim)
                    .then((tokenResult) => onSuccess(tokenResult.result.access_token, tokenResult.result.expires_in));
            },
        };

        const viewerApp = new window.Autodesk.Viewing.GuiViewer3D(viewerDivRef.current, {});
        viewerApp.addEventListener(window.Autodesk.Viewing.MODEL_REMOVED_EVENT, checkHasVisibleModels.bind(this));
        viewerApp.addEventListener(window.Autodesk.Viewing.MODEL_ADDED_EVENT, checkHasVisibleModels.bind(this));

        const callback = () => {
            viewerApp.start();
            viewerApp.prefs.tag("ignore-producer");
            viewerApp.prefs.set("reverseMouseZoomDir", true);
            viewerApp.addEventListener(window.Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, selectionChanged);
            viewerApp.loadExtension("Autodesk.Viewing.MemoryLimitedDebug");
            viewerApp.loadExtension("Autodesk.ViewCubeUi");
            loadCustomExtensions(viewerApp);

            setViewerReady(true);
        };

        AutodeskForge.Instance.initialize(options, callback);

        viewerAppRef.current = viewerApp;
        dispatch(setPaneObject(props.paneId, viewerApp));
    };

    const checkHasVisibleModels = () => {
        const visibleModels = viewerAppRef.current.getVisibleModels();
        setHasVisibleModels(Boolean(visibleModels.length));

        if (!Boolean(visibleModels.length)) dispatch(setPaneLegend(props.paneId, null));
    };

    const fetchDocuments = () => {
        if (!fetched) {
            const documents = forgeConnector.fetchForgeDocuments();
            dispatch(setPaneDocuments(props.paneId, documents));
            setFetched(true);
        }
    };

    const getProperties = (modelId, objId) =>
        new Promise((resolve, reject) => {
            try {
                const model = getVisibleModelById(modelId);
                model.getProperties(objId, (data) => {
                    resolve(data);
                });
            } catch (error) {
                reject(error);
            }
        });

    const getFirstNodeWithOWV_SE_Property = (modelId, dbId, dbIdOriginal = dbId) =>
        new Promise((resolve, reject) => {
            getProperties(modelId, dbId)
                .then(({ properties }) => {
                    //('ForgeComponent - getFirstNodeWithOWV_SE_fysiek dbId: ', dbId)
                    //console.log('ForgeComponent - getFirstNodeWithOWV_SE_fysiek properties: ', properties)
                    const hasOWV_SE_property = properties.some(({ displayName }) => displayName === "OWV_SE_fysiek" || displayName === "OWV_SE_logisch");
                    const model = getVisibleModelById(modelId);
                    const instanceTree = model.getData().instanceTree;
                    if (hasOWV_SE_property) {
                        resolve(dbId);
                    } else {
                        const parentId = instanceTree.getNodeParentId(parseInt(dbId));
                        if (!parentId) {
                            // there are no more parents
                            resolve(dbIdOriginal);
                        } else {
                            resolve(getFirstNodeWithOWV_SE_Property(modelId, parentId, dbIdOriginal));
                        }
                    }
                })
                .catch(reject);
        });

    const setModelVisibility = (modelId, visible) => {
        const { documents } = paneData.data;
        const docs = [...documents];
        const data = getFlatModelsArray(docs);
        const entry = data.find((x) => x.id === modelId);
        entry.visible = visible;

        dispatch(setPaneDocuments(props.paneId, docs));
    };

    const handleChangeSelection = () => {
        const { documents } = paneData.data;
        const { selectedObject } = props;

        if (selectedObject) {
            const { source, isPhysicalObject, objectId, highlightIds, paneId } = selectedObject;

            if (!Boolean(objectId) || source !== "forge") return;

            if (isPhysicalObject) {
                viewerAppRef.current.impl.selector.clearSelection();
            } else {
                let [derivativeId, externalId] = objectId.split(".");
                externalId = externalId.replaceAll("_", "/");

                const flatDocuments = [...flatten(documents, Infinity)];
                const { id: modelId, visible, folderId } = flatDocuments.find(({ relationships }) => relationships.derivatives.data.id === derivativeId);

                if (paneId === props.paneId) {
                    if (folderId && !visible) {
                        setModelVisibility(modelId, true);
                    }

                    const model = getVisibleModelById(derivativeId);
                    if (!model || !model.loader) {
                        setSelectionOnLoad(derivativeId, externalId, highlightIds);
                        return;
                    }

                    const loading = model.loader.loading;
                    if (loading) {
                        setSelectionOnLoad(derivativeId, externalId, highlightIds);
                        return;
                    }
                }

                if (modelId && visible) {
                    const dbId = parseInt(props.externalDbIdMapping[derivativeId][externalId]);
                    setSelection(derivativeId, dbId);
                }
            }

            selectHighlightIds(highlightIds);
        }
    };

    const setSelectionOnLoad = (derivativeId, externalId, highlightIds) => {
        selectOnLoadRef.current[derivativeId] = { externalId, highlightIds };
    };

    const setSelection = (derivativeId, dbId) => {
        getFirstNodeWithOWV_SE_Property(derivativeId, dbId)
            .then((dbIdWithOWV_SE_fysiek) => {
                // console.log("ForgeComponent - handleChangeSelection ", dbId, dbIdWithOWV_SE_fysiek);

                if (dbId !== dbIdWithOWV_SE_fysiek) {
                    setQueuedSelectedObject({
                        dbId: parseInt(dbIdWithOWV_SE_fysiek),
                        derivativeId,
                    });

                    return;
                } else {
                    props.onSetSelectedSidebarTabId("selection");

                    const queuedSelectedObject = {
                        dbId: parseInt(dbId),
                        derivativeId,
                    };

                    const aggregateSelection = viewerAppRef.current.impl.selector.getAggregateSelection();
                    const selectionByModel = aggregateSelection.find(({ model }) => model.myData.urn === derivativeId);
                    if (selectionByModel) {
                        const isSelected = selectionByModel.selection.some((selectedDbId) => selectedDbId === parseInt(dbId));
                        if (!isSelected) {
                            setQueuedSelectedObject(queuedSelectedObject);
                        }
                    } else {
                        setQueuedSelectedObject(queuedSelectedObject);
                    }
                }
            })
            .catch((error) => {
                // console.error("ForgeComponent - handleChangeSelection error: ", error);
            });
    };

    const selectQueuedSelectedObject = () => {
        if (queuedSelectedObject) {
            const { dbId, derivativeId } = queuedSelectedObject;
            const model = getVisibleModelById(derivativeId);

            if (model) {
                viewerAppRef.current.clearSelection();
                viewerAppRef.current.select([dbId], model);
                setQueuedSelectedObject(null);
            }
        }
    };

    const selectQueuedHighlightIds = () => {
        //console.log('ForgeComponent selectQueuedHighlightIds - queuedHighlightIds: ', queuedHighlightIds)

        if (Array.isArray(queuedHighlightIds)) {
            const updatedQueuedHighlightIds = [];
            const queuedHighlightIdsByModel = {};

            queuedHighlightIds.forEach((highlightId) => {
                const [derivativeId, dbId] = highlightId.split(".");
                const model = getVisibleModelById(derivativeId);
                //console.log('ForgeComponent selectQueuedHighlightIds: ', dbId, derivativeId)

                if (model) {
                    queuedHighlightIdsByModel[derivativeId] = queuedHighlightIdsByModel[derivativeId] || { model, dbIds: [] };
                    queuedHighlightIdsByModel[derivativeId].dbIds.push(parseInt(dbId));
                } else {
                    updatedQueuedHighlightIds.push(highlightId);
                }
            });

            setTimeout(() => {
                Object.keys(queuedHighlightIdsByModel).forEach((derivativeId) => {
                    const { model, dbIds } = queuedHighlightIdsByModel[derivativeId];
                    //console.log('ForgeComponent selectQueuedHighlightIds: ', queuedHighlightIdsByModel)
                    //console.log('ForgeComponent selectQueuedHighlightIds: ', derivativeId)
                    //console.log('ForgeComponent selectQueuedHighlightIds: ', queuedHighlightIdsByModel[derivativeId])
                    //console.log('ForgeComponent selectQueuedHighlightIds: ', this.viewerApp, model, dbIds)
                    viewerAppRef.current.select(dbIds, model);
                });

                if (queuedHighlightIds !== null) {
                    setQueuedHighlightIds(updatedQueuedHighlightIds.length ? updatedQueuedHighlightIds : null);
                }
            }, 1000);
        }
    };

    const selectHighlightIds = (highlightIds) => {
        // console.log("selectHighlightIds");
        const { documents } = paneData.data;

        if (Array.isArray(highlightIds)) {
            // console.log("ForgeComponent selectHighlightIds - highlightIds: ", highlightIds);
            highlightIds.forEach((highlightId) => {
                const [derivativeId] = highlightId.split(".");
                const flatDocuments = [...flatten(documents, Infinity)];
                const { id: modelId, visible, folderId } = flatDocuments.find(({ relationships }) => relationships.derivatives.data.id === derivativeId);

                if (folderId && !visible) {
                    setModelVisibility(modelId, true);
                }
            });

            setQueuedHighlightIds(highlightIds);
        }
    };

    const accentuateHighlightIds = (highlightIds) => {
        const { keepVisibleContainersVisible } = props;
        const { documents } = paneData.data;

        // clear all accentuations and hide all current visible models
        clearAccentuations();
        props.onCancelHighlights();

        if (Array.isArray(highlightIds)) {
            (keepVisibleContainersVisible ? new Promise((resolve, reject) => resolve(true)) : hideAllVisibleModelsAsync()).then(() => {
                const promises = highlightIds.map(
                    (highlightId) =>
                        new Promise((resolve, reject) => {
                            const [derivativeId, externalId] = highlightId.split(".");
                            const flatDocuments = [...flatten(documents, Infinity)];
                            const {
                                id: modelId,
                                visible,
                                folderId,
                            } = flatDocuments.find(({ relationships }) => relationships.derivatives.data.id === derivativeId);

                            if (folderId && !visible) {
                                setModelVisibility(modelId, true);
                            }

                            getVisibleModelByIdAsync(derivativeId).then((model) => {
                                model.getExternalIdMapping((externalIdMap) => {
                                    const dbId = externalIdMap[externalId.replaceAll("_", "/")];
                                    resolve({
                                        derivativeId,
                                        model,
                                        dbId,
                                        folderName: folderId,
                                    });
                                });
                            });
                        })
                );

                Promise.all(promises).then((objectsArray) => {
                    const objects = objectsArray.reduce((objects, object) => {
                        const { derivativeId, model, dbId } = object;

                        // viewerAppRef.current.setThemingColor(dbId, highlightColor, model, true);
                        objects[derivativeId] = objects[derivativeId] || {
                            model: model,
                            dbIds: [],
                        };
                        objects[derivativeId].dbIds.push(dbId);
                        return objects;
                    }, {});

                    for (const key in objects) {
                        const { model, dbIds } = objects[key];
                        viewerAppRef.current.isolate(dbIds, model);
                    }

                    viewerAppRef.current.utilities.fitToView();
                });
            });
        }
    };

    const clearAccentuations = () => {
        try {
            viewerAppRef.current.showAll();
        } catch (error) {
            // console.log('clearAccentuations showAll error: ', error)
        }

        const models = viewerAppRef.current.getVisibleModels();
        models.forEach((model) => {
            try {
                viewerAppRef.current.clearThemingColors(model);
            } catch (error) {
                console.warning("clearAccentuations clearThemingColors error: ", error);
            }
        });

        try {
            viewerAppRef.current.utilities.fitToView();
        } catch {}
    };

    const getVisibleModelById = (modelId) => {
        const visibleModels = viewerAppRef.current.getVisibleModels();
        const model = visibleModels.find((model) => {
            return model.myData.urn === modelId;
        });

        return model;
    };

    const getVisibleModelByIdAsync = (modelId, milliseconds = 200) => {
        const createPromiseChain = () =>
            new Promise((resolve) => {
                const model = getVisibleModelById(modelId);
                if (model && typeof model.isLoadDone === "function" && model.isLoadDone()) {
                    resolve(model);
                } else {
                    window.setTimeout(() => createPromiseChain().then(resolve), milliseconds);
                }
            });

        return createPromiseChain();
    };

    const hideAllVisibleModelsAsync = (milliseconds = 200) => {
        const { documents } = paneDataRef.current.data;
        const visibleModels = viewerAppRef.current.getVisibleModels();

        visibleModels.forEach((model) => {
            const flatDocuments = [...flatten(documents, Infinity)];
            const { id: itemId } = flatDocuments.find(({ relationships }) => relationships.derivatives.data.id === model.myData.urn);

            if (itemId) {
                setModelVisibility(itemId, false);
            }
        });

        const createPromiseChain = () =>
            new Promise((resolve) => {
                const visibleModels = viewerAppRef.current.getVisibleModels();
                if (Array.isArray(visibleModels) && visibleModels.length) {
                    window.setTimeout(() => createPromiseChain().then(resolve), milliseconds);
                } else resolve();
            });

        return createPromiseChain();
    };

    const updateDocuments = () => {
        const { documents } = paneData.data;
        const alreadyRequestedModels = {
            ...loadingModelsRef.current,
            ...loadedModelsRef.current,
        };

        const activeDocumentIds = getFlatModelsArray(documents)
            .filter((x) => x.visible)
            .map((x) => x.relationships.derivatives.data.id);

        const toRequestModels = activeDocumentIds.filter((activeDocumentId) => {
            let notLoaded = !alreadyRequestedModels[activeDocumentId];
            return Boolean(notLoaded);
        });

        let promiseChain = null;
        toRequestModels.forEach((urn) => {
            loadingModelsRef.current[urn] = true;
            dispatch(beginLoading(props.paneId, urn));

            if (!promiseChain) {
                promiseChain = new Promise((resolve, reject) => {
                    window.Autodesk.Viewing.Document.load(
                        `urn:${urn}`,
                        (doc) => onDocumentLoaded(doc, urn, resolve, reject),
                        (error) => reject(error)
                    );
                });
            } else {
                promiseChain.then(() => {
                    window.Autodesk.Viewing.Document.load(
                        `urn:${urn}`,
                        (doc) => onDocumentLoaded(doc, urn),
                        (err) => onDocumentError(urn, err)
                    );
                });
            }
        });

        Object.keys(alreadyRequestedModels).forEach((x) => {
            const visible = activeDocumentIds.some((activeDocumentId) => activeDocumentId === x);
            const modelId = loadedModelsRef.current[x];

            try {
                let model = viewerAppRef.current.getVisibleModels().find((model) => model.id === modelId);
                if (modelId) {
                    if (visible) {
                        if (bookmarkViewpointRef.current) {
                            viewerAppRef.current.restoreState(bookmarkViewpointRef.current);
                            bookmarkViewpointRef.current = null;
                        }

                        if (model) return;
                        viewerAppRef.current.showModel(modelId);
                        model = viewerAppRef.current.getVisibleModels().find((model) => model.id === modelId);
                        trySetColorFilter(model);
                        trySetTopOrientation();
                    } else {
                        viewerAppRef.current.hideModel(modelId);
                        viewCubeOrientationSet.current = viewerAppRef.current.getVisibleModels().length > 0;
                    }
                }
            } catch {}
        });
    };

    const onDocumentLoaded = (doc, id, resolve, reject) => {
        // A document contains references to 3D and 2D geometries.
        const geometries = doc.getRoot().search({ type: "geometry" });
        if (geometries.length === 0) {
            // console.error("Document contains no geometry.");
            return;
        }

        // Choose any of the available geometries
        const initGeom = geometries.find((x) => x.data.role === "3d") || geometries[0];
        const loadOptions = {
            globalOffset: { x: 150000, y: 215000, z: 0 },
            applyRefPoint: true,
            applyScaling: "m",
        };

        // Load the chosen geometry
        let svfUrl = doc.getViewablePath(initGeom);
        viewerAppRef.current.loadModel(
            svfUrl,
            loadOptions,
            (model) => onModelLoaded(model, id, geometries, resolve, reject),
            (error) => reject(error)
        );
    };

    const onDocumentError = (urn, error) => {
        console.warn("Error occured while loading geometry");
        console.warn("\tModel urn:", urn);
        console.warn("\tError:", error);
    };

    const onModelLoaded = (model, id, geometries, resolve) => {
        let modelId = model.getModelId();
        loadingModelsRef.current[id] = false;
        loadedModelsRef.current[id] = modelId;
        setModels([...models, model]);

        if (bookmarkViewpointRef.current) {
            viewerAppRef.current.restoreState(bookmarkViewpointRef.current);
            bookmarkViewpointRef.current = null;
        }

        const views = getViewpoints(geometries);
        props.onSetViews(model.myData.urn, views);

        const derivativeId = model.myData.urn;
        const { externalId, highlightIds } = selectOnLoadRef.current[derivativeId] ?? {};
        if (!props.externalIdMapping[derivativeId])
            model.getExternalIdMapping((data) => {
                const externalIds = Object.keys(data);
                const mapping = {};
                for (let i = 0, len = externalIds.length; i < len; i++) {
                    const externalId = externalIds[i];
                    const dbId = data[externalId];
                    mapping[dbId] = externalId.replaceAll("/", "_");
                }

                props.onSetExternalIdMapping(derivativeId, mapping);
                props.onSetExternalDbIdMapping(derivativeId, data);

                if (externalId) {
                    const dbId = parseInt(data[externalId]);
                    setSelection(derivativeId, dbId);
                    selectHighlightIds(highlightIds);
                    selectOnLoadRef.current[derivativeId] = null;
                }
            });
        else {
            if (externalId) {
                const dbId = parseInt(props.externalDbIdMapping[model.myData.urn][externalId]);
                setSelection(derivativeId, dbId);
                selectHighlightIds(highlightIds);
                selectOnLoadRef.current[derivativeId] = null;
            }
        }

        trySetColorFilter(model);
        trySetTopOrientation();
        dispatch(finishedLoading(props.paneId, derivativeId));

        if (resolve) {
            resolve(model);
        }
    };

    const trySetTopOrientation = () => {
        if (!viewCubeOrientationSet.current) {
            const cubeExtension = viewerAppRef.current.getExtension("Autodesk.ViewCubeUi");
            cubeExtension.setViewCube("top");
            viewCubeOrientationSet.current = true;
        }
    };

    const getViewpoints = (geoms, folder = { name: "root", children: [], viewpoints: [] }) => {
        let parent = folder;

        for (let i = 0, len = geoms.length; i < len; i++) {
            const geometry = geoms[i];
            if (geometry.data.type === "view") {
                const camera = geometry.data.camera;
                const nwVPName = geometry.data.name;
                const placementWithOffset = viewerAppRef.current.model.getData().placementWithOffset;
                const pos = new window.THREE.Vector3(camera[0], camera[1], camera[2]);
                const target = new window.THREE.Vector3(camera[3], camera[4], camera[5]);
                const up = new window.THREE.Vector3(camera[6], camera[7], camera[8]);
                const aspect = camera[9];
                const fov = camera[10] < 1 ? (camera[10] / Math.PI) * 180 : camera[10];
                const orthoScale = camera[11];
                const isPerspective = camera[12];
                const offsetPos = pos.applyMatrix4(placementWithOffset);
                const offsetTarget = target.applyMatrix4(placementWithOffset);

                parent.viewpoints.push({
                    aspect: aspect,
                    isPerspective: isPerspective,
                    fov: fov,
                    position: offsetPos,
                    target: offsetTarget,
                    up: up,
                    orthoScale: orthoScale,
                    name: nwVPName,
                });
            } else if (geometry.data.type === "folder") {
                const newFolder = {
                    name: geometry.data.name,
                    children: [],
                    viewpoints: [],
                };
                parent.children.push(newFolder);
                if (geometry.children && geometry.children.length > 0) getViewpoints(geometry.children, newFolder);
            } else if (geometry.children && geometry.children.length > 0) getViewpoints(geometry.children, parent);
        }

        return folder;
    };

    const trySetColorFilter = (model) => {
        if (updateColors(model)) return;
        setTimeout(() => trySetColorFilter(model), 500);
    };

    const updateColors = (model) => {
        const data = model.getData();
        if (!data) return false;
        if (!data.instanceTree) return false;
        if (!data.instanceTree.nodeAccess) return false;
        if (!data.instanceTree.nodeAccess.dbIdToIndex) return false;

        const { forgeFilters } = props;
        const activeFilter = forgeFilters
            .map((group) => group.filters)
            .reduce((acc, filter) => acc.concat(filter), [])
            .find((filter) => filter.isActive);

        applyModelColorFilter(model, activeFilter);

        return true;
    };

    const applyColorFilter = (filter) => {
        const loadedModels = viewerAppRef.current.getVisibleModels();

        for (let i = 0; i < loadedModels.length; i++) {
            const model = loadedModels[i];
            viewerAppRef.current.clearThemingColors(model);
            applyModelColorFilter(model, filter);
        }
    };

    const applyModelColorFilter = (model, filter) => {
        viewerAppRef.current.clearThemingColors(model);

        if (!filter) {
            dispatch(setPaneLegend(props.paneId, null));
            return;
        }

        const dbIds = Object.keys(model.getData().instanceTree.nodeAccess.dbIdToIndex).map((x) => parseInt(x));
        model.getBulkProperties2(dbIds, { propFilter: filter.parameters }, (elements) => {
            const dbIdsByParameterValue = {};
            for (let i = 0; i < elements.length; i++) {
                const value = elements[i].properties[0].displayValue;
                if (!dbIdsByParameterValue[value]) dbIdsByParameterValue[value] = [];
                dbIdsByParameterValue[value].push(elements[i].dbId);
            }

            // Debug output:
            // console.log("Filtering for model:", model.getModelKey());
            // console.log(`Settings for ${filter.parameters}:`, filter.options);
            // console.log(`Different values for ${filter.parameters} in model:`, dbIdsByParameterValue);

            dispatch(setPaneLegend(props.paneId, filter.options));

            const options = filter.options.map((x) => ({ ...x, parameterValue: x.parameterStringValue || x.parameterBoolValue }));
            const missingParameterColor = options.find((x) => x.parameterValue === undefined);
            if (missingParameterColor) {
                viewerAppRef.current.setColorMaterial(
                    model,
                    dbIds,
                    new window.THREE.Vector4(
                        missingParameterColor.color[0],
                        missingParameterColor.color[1],
                        missingParameterColor.color[2],
                        missingParameterColor.color[3]
                    )
                );
            }

            for (let i = 0; i < Object.keys(dbIdsByParameterValue).length; i++) {
                const filterOptions = options.find((x) => x.parameterValue === Object.keys(dbIdsByParameterValue)[i]);

                if (filterOptions)
                    viewerAppRef.current.setColorMaterial(
                        model,
                        Object.values(dbIdsByParameterValue[Object.keys(dbIdsByParameterValue)[i]]),
                        new window.THREE.Vector4(filterOptions.color[0], filterOptions.color[1], filterOptions.color[2], filterOptions.color[3])
                    );
            }
        });
    };

    const adjustViewerSize = () => {
        if (viewerAppRef.current && typeof viewerAppRef.current.resize === "function") {
            viewerAppRef.current.resize();
        }
    };

    const { activeProject } = props;
    const resourceAccess = activeProject.resources.find((x) => x.Name === "Bim360");
    const forgeViewerClassNames = ["forge-viewer"];

    return (
        <Container>
            <ReactResizeDetector handleHeight handleWidth onResize={adjustViewerSize} />
            <div className={forgeViewerClassNames.join(" ")} ref={viewerDivRef} />
            {Boolean(!hasVisibleModels) && (
                <Message component={"div"}>
                    {Boolean(viewerReady) ? "Selecteer één of meerdere 3D modellen" : Boolean(resourceAccess) && "Bezig met laden..."}
                </Message>
            )}
            <ForgeLegend />
        </Container>
    );
};

const mapStateToProps = ({ tokenReducer, forgeReducer, selectionReducer, appReducer, knownObjectIdsReducer, projectReducer }) => ({
    tokens: tokenReducer.tokenBySource,
    activeProject: projectReducer.activeProject,
    forgeFilters: projectReducer.forgeFilters,
    bookmarkState: forgeReducer.bookmarkState,
    bookmarkStatePaneId: forgeReducer.bookmarkStatePaneId,
    selectedObject: selectionReducer.selectedObject,
    highlights: selectionReducer.highlights,
    knownObjectIds: knownObjectIdsReducer.knownObjectIds,
    keepVisibleContainersVisible: selectionReducer.keepVisibleContainersVisible,
    externalIdMapping: forgeReducer.externalIdMapping,
    externalDbIdMapping: forgeReducer.externalDbIdMapping,
});

const mapDispatchToProps = (dispatch) => ({
    onClearBookmarkState: () => dispatch(clearBookmarkState()),
    onSetSelectedObject: (objectId, source, pane) => dispatch(setSelectedObject(objectId, source, pane)),
    onSetViewerProperties: (viewerProperties, objectId) => dispatch(setViewerProperties(viewerProperties, objectId, configuration.sources.bim)),
    onSetQueuedHighlights: (source, highlightIds, keepVisibleContainersVisible) =>
        dispatch(setQueuedHighlights(source, highlightIds, keepVisibleContainersVisible)),
    onSetSelectedSidebarTabId: (selectedSidebarTabId) => dispatch(setSelectedSidebarTabId(selectedSidebarTabId)),
    onCancelHighlights: () => dispatch(cancelHighlights()),
    onSetExternalIdMapping: (modelId, mapping) => dispatch(setExternalIdMapping(modelId, mapping)),
    onSetExternalDbIdMapping: (modelId, mapping) => dispatch(setExternalDbIdMapping(modelId, mapping)),
    onSetViews: (modelId, views) => dispatch(setModelViews(modelId, views)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forge);
