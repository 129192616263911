import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setShouldRevealSelectedRelation } from "../../../../../redux/app/actions";

const Root = styled(Box)(() => ({
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    pointerEvents: "none",
    userSelect: "none",
}));

const Inner = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.inputBorder}`,
    boxShadow: `0 3px 6px ${theme.colors.black}29`,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "12px",
    color: theme.colors.textColor,
    padding: "8px 14px",
    marginBottom: "6px",
    pointerEvents: "auto",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.secondarySelectionHover,
    },
}));

class RevealSelectedRelation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClick = () => {
        const { setShouldRevealSelectedRelation } = this.props;

        setShouldRevealSelectedRelation(true);
    };

    render() {
        return (
            <Root>
                <Inner onClick={this.handleClick}>Toon gerelateerd object</Inner>
            </Root>
        );
    }
}

const mapStateToProps = ({ appReducer }) => ({
    shouldRevealSelectedRelation: appReducer.shouldRevealSelectedRelation,
});

const mapDispatchToProps = (dispatch) => ({
    setShouldRevealSelectedRelation: (shouldRevealSelectedRelation) => dispatch(setShouldRevealSelectedRelation(shouldRevealSelectedRelation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevealSelectedRelation);
