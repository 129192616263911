import { Component, createRef } from "react";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import { Box, Button, ButtonGroup, Chip, CircularProgress, TextField, styled } from "@mui/material";
import { faFileImport, faPlus, faPencil, faCloudDownload, faTrashAlt, faSave, faBinoculars } from "@fortawesome/pro-light-svg-icons";
import { getFlatLayersArray } from "../../../utils/layerFunctions";
import { getFlatModelsArray } from "../../../utils/modelFunctions";
import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../../context/SplitPaneContext/State";
import { determineTargetData, setPaneDetails } from "../../../context/AppContext/Reducer";
import { setBookmarkState as setForgeBookmarkState } from "../../../redux/forge/actions";
import { setBookmarkState as SetArcgisBookmarkState } from "../../../redux/webMaps/actions";
import { addOrUpdateBookmark, removeBookmark, renameBookmark, uploadBookmarkScreenshot } from "../../../connectors/userState";
import CreatebookmarkModal from "./bookmarks/CreateBookmarkModal";
import DeleteBookmarkConfirmationModal from "./bookmarks/DeleteBookmarkConfirmationModal";
import ImportBookmarkModal from "./bookmarks/ImportBookmarkModal";
import RenameBookmarkModal from "./bookmarks/RenameBookmarkModal";
import UpdateBookmarkConfirmationModal from "./bookmarks/UpdateBookmarkConfirmationModal";
import StyledTooltip from "../../generic/StyledTooltip";
import { generateUUID } from "../../../utils/guidFunctions";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    "& h2": {
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const ProgressContainer = styled(Box)(() => ({
    position: "absolute",
    display: "flex",
    backgroundColor: "#FFFFFFCC",
    width: "100%",
    height: "100%",
    zIndex: 1,
}));

const Container = styled(Box)(() => ({
    flex: 1,
    position: "relative",
}));

const Inner = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
}));

const SearchContainer = styled(Box)(() => ({
    margin: 8,
}));

const ButtonContainer = styled(Box)(() => ({
    marginTop: "auto",
    padding: "15px",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
    display: "flex",
    height: "100%",
}));

const BookmarkContainer = styled(Box)(() => ({
    overflow: "auto",
    flex: "1",
}));

const Bookmark = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: 8,
    flex: 1,
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "12px",
    whiteSpace: "normal",
    lineHeight: "22px",
    borderBottom: "1px solid #E6E6E6",
    "&>img": {
        minWidth: "100px",
        minHeight: "100px",
        maxWidth: "100px",
        maxHeight: "100px",
        objectFit: "cover",
        cursor: "pointer",
    },
    "&>img:hover + div > div": {
        color: "#499C85",
    },
    "&>div": {
        width: "100%",
        display: "flex",
        marginLeft: "8px",
        flexDirection: "column",
    },
    "&:first-of-type": {
        marginTop: "7px",
    },
    "&:last-of-type": {
        borderBottom: "none",
        paddingBottom: "0",
    },
}));

const Header = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const Title = styled("span")(() => ({
    fontWeight: "600",
    marginRight: 10,
    cursor: "pointer",
    "&:hover": {
        color: "#499C85",
    },
}));

const BookmarkActionsContainer = styled(Box)(() => ({
    marginTop: "auto",
}));

const BookmarkAction = styled(Button)(() => ({
    color: "#707070",
    fontSize: 14,
    "&:hover": {
        color: "#499C85",
    },
}));

const CssTextField = styled(TextField)({
    // "& label.Mui-focused": {
    //     color: "green",
    // },
    // "& .MuiInput-underline:after": {
    //     borderBottomColor: "green",
    // },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            color: "rgba(0, 0, 0, 0.87) !important",
        },
        // "&:hover fieldset": {
        //     borderColor: "yellow",
        // },
        // "&.Mui-focused fieldset": {
        //     borderColor: "green",
        // },
    },
});

class Bookmarks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createBookmark: false,
            importBookmark: false,
            deleteBookmark: false,
            renameBookmark: false,
            updateBookmark: false,
            bookmarkName: "",
            searchVerb: "",
            bookmarkFile: null,
            isLoading: false,
        };

        this.canvasRef = createRef();
        this.fileInputRef = createRef();
        this.shareCodeInputRef = createRef();
    }

    createBookmark = () => {
        this.setState({ createBookmark: true, bookmarkName: "" });
    };

    closeCreateBookmark = () => {
        this.setState({ createBookmark: false, bookmarkName: "" });
    };

    importBookmark = () => {
        this.fileInputRef.current.click();
    };

    handleImportFile = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = this.handleFileData;
            reader.readAsText(e.target.files[0]);
        }
        this.setState({ importBookmark: true, bookmarkName: "" });
    };

    handleFileData = (e) => {
        const bookmark = JSON.parse(e.target.result);
        this.setState({ importBookmark: true, bookmarkName: bookmark.name, bookmarkFile: bookmark });
        this.fileInputRef.current.value = "";
    };

    closeImportBookmark = () => {
        this.setState({ importBookmark: false, bookmarkName: "" });
    };

    onSaveBookmark = (name) => {
        this.setState({ isLoading: true, createBookmark: false, bookmarkName: "" });
        this.saveBookmark(generateUUID(), name);
    };

    saveBookmark = (id, name) => {
        const { activePane, panesState } = this.context.state;
        const pane = panesState[activePane - 1];
        const { data: paneData, mode } = pane;

        let bookmark = {
            id,
            name,
            active: [],
            screenshot: null,
            viewpoint: null,
        };

        switch (mode) {
            case PaneMode.GIS: {
                const gis = pane.appObject;
                gis.takeScreenshot({
                    format: "jpg",
                    quality: 70,
                }).then((screenshot) => {
                    uploadBookmarkScreenshot(bookmark.id, screenshot.dataUrl).then((data) => {
                        const layers = getFlatLayersArray(paneData.themes).map((x) => ({
                            id: x.id,
                            visible: x.visible,
                        }));

                        bookmark.active = layers;
                        bookmark.basemapId = paneData.basemapId;
                        bookmark.screenshot = data.url;
                        bookmark.viewpoint = gis.viewpoint.toJSON();
                        bookmark.type = "arcgis";

                        addOrUpdateBookmark(bookmark).then((x) => this.setState({ isLoading: false }));
                    });
                });
                break;
            }

            case PaneMode.BIM: {
                const bim = pane.appObject;
                uploadBookmarkScreenshot(bookmark.id, bim.canvas.toDataURL("image/png")).then((data) => {
                    const models = getFlatModelsArray(paneData.documents).map((x) => ({ id: x.id, visible: x.visible }));

                    bookmark.active = models;
                    bookmark.screenshot = data.url;
                    bookmark.viewpoint = bim.getState();
                    bookmark.type = "forge";

                    addOrUpdateBookmark(bookmark).then((x) => this.setState({ isLoading: false }));
                });
                break;
            }

            default:
                throw new Error("Invalid mode specified:", mode);
        }
    };

    onSaveImportedBookmark = (name) => {
        this.setState({ isLoading: true });

        const id = generateUUID();
        const bookmark = { ...this.state.bookmarkFile, name, id };

        this.convertToBase64(bookmark.screenshot).then((screenshot) => {
            uploadBookmarkScreenshot(bookmark.id, screenshot).then((data) => {
                bookmark.screenshot = data.url;
                addOrUpdateBookmark(bookmark);

                this.setState({ importBookmark: false, bookmarkName: "", isLoading: false });
            });
        });
    };

    onLoadBookmark = (uuid) => {
        const { activeProject, state, onSetBimBookmarkState, onSetGisBookmarkState } = this.props;
        const project = state.find((x) => x.projectId === activeProject.name);
        const bookmark = project.bookmarks.find((x) => x.id === uuid);
        const targetPaneId = this.getValidTarget(bookmark.type);

        if (bookmark) {
            switch (bookmark.type) {
                case "arcgis": {
                    onSetGisBookmarkState(targetPaneId, bookmark);
                    break;
                }

                case "forge": {
                    onSetBimBookmarkState(targetPaneId, bookmark);
                    break;
                }

                default:
                    throw new Error("Invalid bookmark type specified:", bookmark.type);
            }
        }
    };

    deleteBookmarkConfirmation = (bookmark) => this.setState({ deleteBookmark: bookmark });
    closeDeleteBookmarkConfirmation = () => this.setState({ deleteBookmark: false });
    onDeleteBookmark = ({ id }) => {
        this.setState({ isLoading: true, deleteBookmark: false });
        removeBookmark(id).then((x) => this.setState({ isLoading: false }));
    };

    updateBookmarkConfirmation = (bookmark) => this.setState({ updateBookmark: bookmark });
    closeUpdateBookmarkConfirmation = () => this.setState({ updateBookmark: false });
    onUpdateBookmark = ({ id, name }) => {
        this.setState({ isLoading: true, updateBookmark: false });
        this.saveBookmark(id, name);
    };

    showRenameBookmark = (bookmark) => this.setState({ renameBookmark: bookmark });
    closeRenameBookmark = () => this.setState({ renameBookmark: false });
    onRenameBookmark = (name) => {
        const { id } = this.state.renameBookmark;
        this.setState({ isLoading: true, renameBookmark: false });
        renameBookmark(id, name).then((x) => this.setState({ isLoading: false }));
    };

    onExportBookmark = (bookmark) => {
        const exportedBookmark = JSON.parse(JSON.stringify(bookmark));
        this.convertToBase64(exportedBookmark.screenshot).then((screenshot) => {
            exportedBookmark.screenshot = screenshot;
            FileSaver.saveAs(
                new Blob([JSON.stringify(exportedBookmark, null, 4)], {
                    type: "application/json",
                }),
                `${exportedBookmark.name}.lantis-bookmark`
            );
        });
    };

    convertToBase64 = async (url) => {
        return new Promise((resolve, reject) => {
            const canvas = this.canvasRef.current;
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                const context = canvas.getContext("2d");
                canvas.height = img.height;
                canvas.width = img.width;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0);

                resolve(canvas.toDataURL("image/png"));
            };
            img.onerror = (e) => reject("Failed to load image.");
            img.src = url;
        });
    };

    renderBookmarks = () => {
        const { activeProject, state } = this.props;
        const project = state.find((x) => x.projectId === activeProject.name);
        let bookmarks = project.bookmarks;

        if (this.state.searchVerb.length > 0) {
            bookmarks = bookmarks.filter((x) => x.name.toLowerCase().indexOf(this.state.searchVerb.toLocaleLowerCase()) > -1);
        }

        return (
            <BookmarkContainer>
                {bookmarks &&
                    bookmarks.length > 0 &&
                    bookmarks.map((bookmark, index) => (
                        <Bookmark key={index}>
                            <img src={`${bookmark.screenshot}?ts=${Date.now()}`} onClick={() => this.onLoadBookmark(bookmark.id)} alt="Bookmark screenshot" />
                            <div>
                                <Header>
                                    <Title onClick={() => this.onLoadBookmark(bookmark.id)}>{bookmark.name} </Title>
                                    <Chip label={bookmark.type === "arcgis" ? "GIS" : "BIM"} size="small" style={{ marginLeft: "auto", userSelect: "none" }} />
                                </Header>
                                <BookmarkActionsContainer>
                                    <ButtonGroup variant="text">
                                        <StyledTooltip title={"Bookmark inladen..."}>
                                            <BookmarkAction onClick={() => this.onLoadBookmark(bookmark.id)}>
                                                <FontAwesomeSvgIcon icon={faBinoculars} />
                                            </BookmarkAction>
                                        </StyledTooltip>

                                        <StyledTooltip title={"Bookmark hernoemen..."}>
                                            <BookmarkAction onClick={() => this.setState({ renameBookmark: bookmark })}>
                                                <FontAwesomeSvgIcon icon={faPencil} />
                                            </BookmarkAction>
                                        </StyledTooltip>

                                        <StyledTooltip title={"Bookmark updaten..."}>
                                            <BookmarkAction onClick={() => this.setState({ updateBookmark: bookmark })}>
                                                <FontAwesomeSvgIcon icon={faSave} />
                                            </BookmarkAction>
                                        </StyledTooltip>

                                        <StyledTooltip title={"Bookmark delen..."}>
                                            <BookmarkAction onClick={() => this.onExportBookmark(bookmark)}>
                                                <FontAwesomeSvgIcon icon={faCloudDownload} />
                                            </BookmarkAction>
                                        </StyledTooltip>

                                        <StyledTooltip title={"Bookmark verwijderen..."}>
                                            <BookmarkAction onClick={() => this.deleteBookmarkConfirmation(bookmark)}>
                                                <FontAwesomeSvgIcon icon={faTrashAlt} />
                                            </BookmarkAction>
                                        </StyledTooltip>
                                    </ButtonGroup>
                                </BookmarkActionsContainer>
                            </div>
                        </Bookmark>
                    ))}

                {(!bookmarks || bookmarks.length === 0) && (
                    <MessageContainer>
                        <span>Geen bookmarks gevonden.</span>
                    </MessageContainer>
                )}
            </BookmarkContainer>
        );
    };

    getValidTarget = (type) => {
        const { activePane, panesCount, panesState } = this.context.state;
        const mode = type === "arcgis" ? PaneMode.GIS : PaneMode.BIM;
        if (panesCount === 1) {
            if (panesState[0].mode !== mode) this.changePaneMode(1, mode);
            return 1;
        }

        const matchingActivePane = panesState.find((x) => x.mode === mode && x.id === activePane);
        if (matchingActivePane) return matchingActivePane.id;

        const matchingPane = panesState.find((x) => x.mode === mode && x.id !== activePane);
        if (matchingPane) return matchingPane.id;

        const firstNoneModePane = panesState.find((x) => x.mode === PaneMode.NONE);
        if (firstNoneModePane) {
            this.changePaneMode(firstNoneModePane.id, mode);
            return firstNoneModePane.id;
        }

        const firstInactivePane = panesState.find((x) => x.id !== activePane);
        if (firstInactivePane) {
            this.changePaneMode(firstInactivePane.id, mode);
            return firstInactivePane.id;
        }
    };

    changePaneMode = (paneId, mode) => {
        const data = determineTargetData(mode);
        this.context.dispatch(setPaneDetails(paneId, { id: paneId, mode, data, appObject: undefined }));
    };

    render() {
        const { title } = this.props;
        const { activePane, panesState } = this.context.state;
        const mode = panesState[activePane - 1].mode;
        const canCreateBookmark = mode !== PaneMode.NONE;

        return (
            <>
                <input type="file" style={{ display: "none" }} ref={this.fileInputRef} onChange={(e) => this.handleImportFile(e)} accept=".lantis-bookmark" />
                <canvas style={{ display: "none" }} ref={this.canvasRef} />
                <Root>
                    <h2>{title}</h2>
                    <Container>
                        {this.state.isLoading && (
                            <ProgressContainer>
                                <CircularProgress
                                    sx={{
                                        position: "relative",
                                        top: "calc(50% - 20px)",
                                        left: "calc(50% - 20px)",
                                    }}
                                />
                            </ProgressContainer>
                        )}
                        {mode === PaneMode.DOCUMENT ? (
                            <Inner>
                                <MessageContainer>
                                    <span>Documenten worden niet ondersteund.</span>
                                </MessageContainer>
                            </Inner>
                        ) : (
                            <Inner>
                                <SearchContainer>
                                    <CssTextField
                                        id="name"
                                        label="Zoeken"
                                        placeholder="Bookmark zoeken..."
                                        type="text"
                                        fullWidth
                                        autoComplete="off"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={this.state.searchVerb}
                                        onChange={(e) => this.setState({ searchVerb: e.target.value })}
                                        variant={"standard"}
                                    />
                                </SearchContainer>
                                {this.renderBookmarks()}
                                <ButtonContainer>
                                    <ButtonGroup fullWidth>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            disableElevation
                                            onClick={() => this.createBookmark()}
                                            startIcon={<FontAwesomeSvgIcon icon={faPlus} />}
                                            className={canCreateBookmark ? "" : "disabled"}
                                        >
                                            Nieuw
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            disableElevation
                                            onClick={() => this.importBookmark()}
                                            startIcon={<FontAwesomeSvgIcon icon={faFileImport} />}
                                        >
                                            Importeren
                                        </Button>
                                    </ButtonGroup>
                                </ButtonContainer>
                            </Inner>
                        )}
                    </Container>
                </Root>

                <CreatebookmarkModal
                    open={this.state.createBookmark}
                    mode={mode}
                    onClose={() => this.closeCreateBookmark()}
                    onSave={(name) => this.onSaveBookmark(name)}
                />

                <ImportBookmarkModal
                    open={this.state.importBookmark}
                    data={this.state.bookmarkFile}
                    initialName={this.state.bookmarkName}
                    onClose={() => this.closeImportBookmark()}
                    onImport={(name) => this.onSaveImportedBookmark(name)}
                />

                <DeleteBookmarkConfirmationModal
                    bookmark={this.state.deleteBookmark}
                    open={this.state.deleteBookmark}
                    onClose={() => this.closeDeleteBookmarkConfirmation()}
                    onConfirm={() => this.onDeleteBookmark(this.state.deleteBookmark)}
                />

                <UpdateBookmarkConfirmationModal
                    bookmark={this.state.updateBookmark}
                    open={this.state.updateBookmark}
                    onClose={() => this.closeUpdateBookmarkConfirmation()}
                    onConfirm={() => this.onUpdateBookmark(this.state.updateBookmark)}
                />

                <RenameBookmarkModal
                    bookmark={this.state.renameBookmark}
                    open={this.state.renameBookmark}
                    onClose={() => this.closeRenameBookmark()}
                    onRename={(name) => this.onRenameBookmark(name)}
                />
            </>
        );
    }
}

Bookmarks.contextType = AppContext;

const mapStateToProps = ({ accountReducer, projectReducer }) => ({
    state: accountReducer.state,
    activeProject: projectReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => ({
    onSetBimBookmarkState: (paneId, state) => dispatch(setForgeBookmarkState(paneId, state)),
    onSetGisBookmarkState: (paneId, state) => dispatch(SetArcgisBookmarkState(paneId, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks);
