import { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { subDays } from "date-fns";
import { addViewedChangelog } from "../../../connectors/userState";
import { Changelog } from "./Changelog";
import ChangelogModal from "./ChangelogModal";
import changelogs from "./Changelogs/Overview";

import currentVersion from "./CurrentVersion";

export interface ChangelogManagerProps {
    userState: any;
    activeProject: any;
}

const ChangelogManager = ({ userState, activeProject }: ChangelogManagerProps) => {
    const [shouldShow, setShouldShow] = useState(false);
    const [changelog, setChangelog] = useState<Changelog>();

    useLayoutEffect(() => {
        const changelog = changelogs.find((x) => x.version.isEqual(currentVersion) && x.date > subDays(new Date(), 14));
        setChangelog(changelog);
    }, []);

    useLayoutEffect(() => {
        if (!changelog || !userState) return;

        const state = userState.find((x: any) => x.projectId === activeProject.name);
        if (!state || !state.changelogs) {
            setShouldShow(true);
            return;
        }

        setShouldShow(!state.changelogs.some((x: string) => x === changelog?.version.toShortString()));
    }, [userState, activeProject, changelog]);

    const onConfirm = () => {
        addViewedChangelog(changelog?.version.toShortString());
        setShouldShow(false);
    };

    return changelog ? <ChangelogModal open={shouldShow} changelog={changelog} onClose={onConfirm} /> : null;
};

const mapStateToProps = ({ accountReducer, projectReducer }: any) => ({
    userState: accountReducer.state,
    activeProject: projectReducer.activeProject,
});

export default connect(mapStateToProps)(ChangelogManager);
