const defaultState = {
    objects: {},
    names: {},
    status: "",
};

const objectNamesReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "REQUEST_OBJECT_NAME":
            newState.objects = Object.assign({}, newState.objects);
            newState.objects[action.objectId] = newState.objects[action.objectId] || {};
            newState.objects[action.objectId].status = "fetching";
            return newState;

        case "RECEIVE_OBJECT_NAME":
            newState.objects = Object.assign({}, newState.objects);
            newState.objects[action.objectId] = newState.objects[action.objectId] || {};
            newState.objects[action.objectId].status = "fetched";
            newState.objects[action.objectId].name = action.name;
            if (action.error) {
                newState.objects[action.objectId].error = action.error;
            }
            return newState;

        case "CANCEL_OBJECT_NAME":
            newState.objects = Object.assign({}, newState.objects);
            delete newState.objects[action.objectId];
            return newState;

        case "REQUEST_OBJECT_NAMES":
            newState.objects = Object.assign({}, newState.objects);
            action.objectIds.forEach((objectId) => {
                newState.objects[objectId] = newState.objects[objectId] || {};
                newState.objects[objectId].status = "fetching";
            });
            return newState;

        case "RECEIVE_OBJECT_NAMES":
            newState.objects = Object.assign({}, newState.objects);

            for (const objectId in action.names) {
                if (action.error) {
                    newState.objects[objectId].error = action.error;
                } else {
                    const name = action.names[objectId];
                    newState.objects[objectId] = newState.objects[objectId] || {};
                    newState.objects[objectId].status = "fetched";
                    newState.objects[objectId].name = name;
                }
            }
            return newState;

        case "REQUEST_OBJECT_NAMES_LIST": {
            return { ...newState, status: "fetching" };
        }

        case "RECEIVE_OBJECT_NAMES_LIST": {
            return { ...newState, status: "fetched", names: action.names };
        }

        default:
            return state;
    }
};

export default objectNamesReducer;
