import { Component, isValidElement } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setPropertiesOverlayOpen } from "../../../../../redux/app/actions";
import ObjectName from "../../../../generic/ObjectName";
import Expand from "../../../../svg/appControls/Expand";

import sortBy from "lodash/sortBy";

import isURL from "validator/lib/isURL";
import { stringTruncate } from "../../../../../utils/stringFunctions";
import { configuration } from "../../../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    minHeight: "54px",
    height: "50vh",
}));

const RootLinkedObject = styled(Root)(({ theme }) => ({
    border: `1px solid ${theme.colors.textColor}`,
}));

const Title = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.colors.primaryText,
    padding: "16px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    fontSize: "15px",
    color: theme.colors.white,
    position: "relative",
}));

const TitleLinkedObject = styled(Title)(({ theme }) => ({
    backgroundColor: theme.colors.textColor,
}));

const TitleDragHandle = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "-4px",
    left: 0,
    right: 0,
    height: "8px",
    cursor: "ns-resize",
}));

const PropertiesContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: "auto",
}));

const PropertyContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "8px",
}));

const PropertyName = styled(Box)(({ theme }) => ({
    flex: 0.5,
    paddingRight: "24px",
    wordBreak: "break-all",
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
}));

const PropertyValue = styled(Box)(({ theme }) => ({
    flex: 0.7,
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
    overflowWrap: "anywhere",
}));

class Properties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            height: null,
            whitelistedProperties: null,
        };
    }

    componentDidMount() {
        window.addEventListener("mousemove", this.drag);
        window.addEventListener("mouseup", this.dragStop);
        this.setProperties();
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.drag);
        window.removeEventListener("mouseup", this.dragStop);
    }

    componentDidUpdate(prevProps, prevState) {
        const propertiesVariables = ["selectedObject", "selectedLinkedId", "selectedLinkedSource", "viewerProperties", "objectProperties", "objectRelations"];
        const propertiesVariablesHaveChanged = propertiesVariables.reduce((propertiesVariablesHaveChanged, key) => {
            return propertiesVariablesHaveChanged || this.props[key] !== prevProps[key];
        }, false);
        if (propertiesVariablesHaveChanged) {
            this.setProperties();
        }
    }

    setProperties = () => {
        const whitelistedProperties = this.renderWhitelistedProperties();
        this.setState({ whitelistedProperties });
    };

    showPropertiesOverlay = () => {
        this.props.onSetPropertiesOverlayOpen(true);
    };

    parseProperties = (properties = {}) => {
        const parsedProperties = [];

        for (const source in properties) {
            // debugger
            const sourceProperties = properties[source];
            for (const category in sourceProperties.PropertyCategories) {
                // debugger
                const categoryProperties = sourceProperties.PropertyCategories[category].Properties;
                // debugger
                for (const property in categoryProperties) {
                    // debugger
                    const parsedProperty = {
                        source,
                        category,
                        ...categoryProperties[property],
                    };
                    parsedProperties.push(parsedProperty);
                }
                // debugger
            }
        }

        return parsedProperties;
    };

    getPhysicalAndLogicalProperties = () => {
        const { selectedObject, objectProperties, objectRelations } = this.props;

        const { status, relations } = objectRelations[selectedObject.objectId] || {};

        if (status !== "fetched") {
            return {
                physicalObjectProperties: undefined,
                logicalObjectProperties: undefined,
            };
        }

        const { OutgoingRelations: outgoingRelations } = relations;

        const linkedPhysicalObjectId = (
            outgoingRelations.find(({ Name }) => {
                return (
                    Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                    Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT
                );
            }) || {}
        ).OtherObjectId;
        const physicalObjectProperties = (objectProperties[linkedPhysicalObjectId] || {}).properties;

        const linkedLogicalObjectId = (
            outgoingRelations.find(({ Name }) => {
                return (
                    Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT ||
                    Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT
                );
            }) || {}
        ).OtherObjectId;
        const logicalObjectProperties = (objectProperties[linkedLogicalObjectId] || {}).properties;

        return {
            physicalObjectProperties,
            logicalObjectProperties,
        };
    };

    renderProperty = (name, value, anchorText = null, key) => {
        const { relatics, whitelist: globalWhitelist } = this.props;

        // Filter out link to relatics if the user doesn't have access
        if (name === "Link" && !relatics) return null;

        // check if it is an url
        if (typeof value == "string") {
            let url = value;
            if (url.startsWith("www.")) {
                url = `https://${url}`;
            }

            if (
                url.startsWith("https://bamnv.relaticsonline.com/dca244c5-5a84-e511-80c7-000af764f30b/ShowObject.aspx") &&
                url.indexOf("PortalID=8f51a51a-2eb5-ea11-a2f6-00155d642301") === -1
            ) {
                url = `${url}&PortalID=8f51a51a-2eb5-ea11-a2f6-00155d642301`;
            }

            if (isURL(url, { require_protocol: true })) {
                // check if we start with "www"
                value = (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {stringTruncate(url, 25)}
                    </a>
                );
            }
        } else if (typeof value === "object" && !isValidElement(value)) {
            value = JSON.stringify(value);
        }

        const dataTypeInfo = globalWhitelist.find((x) => x.name?.toLowerCase() === name.toLowerCase());
        if (dataTypeInfo && dataTypeInfo.dataType) {
            switch (dataTypeInfo.dataType) {
                case "Date":
                    value = new Date(value).toLocaleString("nl-BE", { year: "numeric", month: "long", day: "numeric" });
                    break;
                case "Bool":
                    value = value === 1 ? "Waar" : value === 0 ? "Onwaar" : value;
                    break;
                default:
                    // Data is handled as a string so we don't perform any actions.
                    break;
            }
        }

        return (
            <PropertyContainer key={key}>
                <PropertyName>{name}</PropertyName>
                <PropertyValue>{value}</PropertyValue>
            </PropertyContainer>
        );
    };

    renderWhitelistedProperties = () => {
        const { selectedObject, viewerProperties, whitelist: globalWhitelist } = this.props;

        if (!viewerProperties.source && !selectedObject.source) return;

        const properties = [];
        this.parseObjectProperties(properties);
        this.parseViewerProperties(properties);
        this.parseOtherProperties(properties);

        const source = (viewerProperties.source ?? selectedObject.source) === "forge" ? "bim360" : viewerProperties.source ?? selectedObject.source;
        const whitelist = globalWhitelist.filter((x) => x.type?.toLowerCase() === source.toLowerCase());

        const filtered = properties.filter((property) => {
            const whitelistItem = whitelist.find(
                (item) =>
                    ((item.name &&
                        (item.name.toLowerCase() === property.property.toLowerCase() ||
                            (item.name.endsWith("*") &&
                                property.property.toLowerCase().startsWith(item.name.toLowerCase().substring(0, item.name.length - 1))))) ||
                        !item.name) &&
                    item.category?.toLowerCase() === property.category?.toLowerCase() &&
                    item.whiteList &&
                    !item.blackList
            );

            if (whitelistItem) {
                property.order = whitelistItem.priority;
                return property;
            } else {
                return null;
            }
        });

        return sortBy(filtered, ["order", "name"]).map((property, index) => this.renderProperty(property.property, property.value, property.anchor, index));
    };

    parseObjectProperties = (properties) => {
        const { selectedObject, selectedLinkedId, selectedLinkedSource, viewerProperties, relatics } = this.props;

        const hasSelectedLinkedId = Boolean(selectedLinkedId);
        const objectId = selectedLinkedId || (selectedObject ? selectedObject.objectId : null);
        const objectSource = selectedLinkedSource || (selectedObject ? selectedObject.source : null);

        properties.push({
            category: "Informatie",
            property: "Naam",
            value: <ObjectName objectId={objectId} shouldGetObjectName={!hasSelectedLinkedId} shouldCheckLinkedObject={!hasSelectedLinkedId} />,
        });

        properties.push({
            category: "Informatie",
            property: "Object id",
            value: objectId,
        });

        if (objectSource === configuration.sources.gis) {
            properties.push({
                category: "Informatie",
                property: "Soort object",
                value: "gis object",
            });

            if (viewerProperties && viewerProperties.properties) {
                for (let i = 0, len = Object.keys(viewerProperties.properties).length; i < len; i++) {
                    const key = Object.keys(viewerProperties.properties)[i];
                    const value = viewerProperties.properties[key];

                    properties.push({
                        category: "Kaartlaag",
                        property: key,
                        value: value,
                    });
                }
            }
        } else if (objectSource === configuration.sources.bim) {
            properties.push({
                category: "Informatie",
                property: "Soort object",
                value: "bim object",
            });
        }

        if (!relatics) return;

        const { physicalObjectProperties, logicalObjectProperties } = this.getPhysicalAndLogicalProperties();
        const linksInfoConfig = [
            {
                name: "physical",
                objectProperties: physicalObjectProperties,
            },
            {
                name: "logical",
                objectProperties: logicalObjectProperties,
            },
        ];

        linksInfoConfig.forEach(({ name, objectProperties }) => {
            if (objectProperties) {
                const links = this.parseProperties(objectProperties).filter(({ Name }) => Name === "Link");
                links.forEach((link) => {
                    properties.push({
                        category: "Informatie",
                        property: `Link ${name === "physical" ? "Fysiek" : "Logisch"}`,
                        value: link.Value,
                        anchorText: name,
                    });
                });
            }
        });
    };

    parseViewerProperties = (properties) => {
        const { viewerProperties, selectedObject, selectedLinkedId } = this.props;

        if (selectedLinkedId) return;

        const viewerPropertiesBelongToSelectedObject =
            viewerProperties.objectId === selectedObject.objectId && viewerProperties.source === selectedObject.source;

        if (viewerPropertiesBelongToSelectedObject) {
            switch (viewerProperties.source) {
                case configuration.sources.gis: {
                    for (const key in viewerProperties.properties) {
                        const value = viewerProperties.properties[key];
                        const exists = properties.find((x) => x.category === "Kaartlaag" && x.property === key);
                        if (!exists)
                            properties.push({
                                category: "Kaartlaag",
                                property: key,
                                value: value,
                            });
                        else exists.value = value;
                    }

                    break;
                }

                case configuration.sources.bim: {
                    viewerProperties.properties.forEach((property) => {
                        const { displayCategory, displayName, displayValue } = property;
                        const exists = properties.find((x) => x.category === displayCategory && x.property === displayName);
                        if (!exists)
                            properties.push({
                                category: displayCategory,
                                property: displayName,
                                value: displayValue,
                            });
                        else exists.value = displayValue;
                    }, {});

                    break;
                }

                default:
                    throw new Error("Invalid source supplied:", viewerProperties.source);
            }
        }
    };

    parseOtherProperties = (properties) => {
        const { selectedObject, selectedLinkedId, objectProperties, objectRelations } = this.props;

        if (selectedLinkedId) {
            const { props } = objectProperties[selectedLinkedId] || {};

            this.parseProperties(props).forEach(({ Name, Value, source, category }, index) => {
                properties.push({
                    category: "Properties",
                    property: Name,
                    value: Value,
                });
            });
        }

        const { status } = objectRelations[selectedObject.objectId] || {};

        const propertiesList = [];
        if (status === "fetched") {
            const { physicalObjectProperties, logicalObjectProperties } = this.getPhysicalAndLogicalProperties();

            if (physicalObjectProperties) {
                const propertiesObject = {
                    name: "Fysiek",
                    properties: [...this.parseProperties(physicalObjectProperties)],
                };

                propertiesList.push(propertiesObject);
            }

            if (logicalObjectProperties) {
                const propertiesObject = {
                    name: "Logisch",
                    properties: [...this.parseProperties(logicalObjectProperties)],
                };

                propertiesList.push(propertiesObject);
            }

            if (propertiesList.length > 0)
                propertiesList.forEach(({ name, properties: props }, index) => {
                    props.forEach(({ Name, Value }, index) => {
                        properties.push({
                            category: name,
                            property: Name,
                            value: Value,
                        });
                    });
                });
        }
    };

    dragStart = (event) => {
        event.preventDefault();
        if (!this.state.dragging) {
            this.setState({ dragging: true });
        }
    };

    drag = (event) => {
        const { dragging, height } = this.state;

        if (this.container && dragging) {
            event.preventDefault();
            const boundingClientRect = this.container.getBoundingClientRect();
            const heightDifference = boundingClientRect.y - event.clientY;
            const newHeight = Math.max(boundingClientRect.height + heightDifference, 0);
            if (height !== newHeight) {
                this.setState({ height: newHeight });
            }
        }
    };

    dragStop = () => {
        if (this.state.dragging) {
            this.setState({ dragging: false });
        }
    };

    render() {
        const { selectedLinkedId } = this.props;
        const { height, whitelistedProperties } = this.state;
        let RootComponent = Root;
        let TitleComponent = Title;

        if (selectedLinkedId) {
            RootComponent = RootLinkedObject;
            TitleComponent = TitleLinkedObject;
        }

        const containerStyles = {};
        if (typeof height === "number") {
            containerStyles.height = `${height}px`;
        }

        return (
            <RootComponent ref={(ref) => (this.container = ref)} style={containerStyles}>
                <TitleComponent>
                    <TitleDragHandle onMouseDown={this.dragStart} />
                    <span>Eigenschappen</span>
                    <div onClick={this.showPropertiesOverlay} style={{ cursor: "pointer" }}>
                        <Expand />
                    </div>
                </TitleComponent>
                <PropertiesContainer>{whitelistedProperties}</PropertiesContainer>
            </RootComponent>
        );
    }
}

const mapStateToProps = ({ selectionReducer, objectRelationsReducer, objectPropertiesReducer, appDynamicSettingsReducer, projectReducer }) => ({
    selectedObject: selectionReducer.selectedObject,
    selectedLinkedId: selectionReducer.selectedLinkedId,
    selectedLinkedSource: selectionReducer.selectedLinkedSource,
    filteredRelations: objectRelationsReducer.filteredRelations,
    objectRelations: objectRelationsReducer.objects,
    objectProperties: objectPropertiesReducer.objects,
    viewerProperties: objectPropertiesReducer.viewerProperties,
    appSettings: appDynamicSettingsReducer,
    relatics: projectReducer.resources.Relatics,
    whitelist: projectReducer.whitelist,
});

const mapDispatchToProps = (dispatch) => ({
    onSetPropertiesOverlayOpen: (propertiesOverlayOpen) => dispatch(setPropertiesOverlayOpen(propertiesOverlayOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
