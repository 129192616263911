import { Component } from "react";
import { Box, styled } from "@mui/material";
import { stringToCharCodes } from "../../../../../utils/stringFunctions";

const CategoryContainer = styled(Box)(({ theme }) => ({
    "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.colors.inputBorder}`,
    },
}));

const CategoryTitle = styled(Box)(({ theme }) => ({
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
    color: theme.colors.textColor,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.colors.inputBorder,
}));

const CategoryContent = styled(Box)(({ theme }) => ({
    paddingLeft: "20px",
}));

class PropertyCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, title, subcategoryContainer = false, subcategory = false, children } = this.props;

        let ContentComponent = Box;
        if (!subcategoryContainer) {
            ContentComponent = CategoryContent;
        }

        return (
            <CategoryContainer
                id={stringToCharCodes(id)}
                data-container-type="categoryContainer"
                data-title={title}
                data-subcategory={subcategory ? true : undefined}
                data-subcategory-container={subcategoryContainer ? true : undefined}
            >
                <CategoryTitle style={subcategory ? { pl: 40 } : {}}>{title}</CategoryTitle>
                <ContentComponent style={subcategory ? { pl: 40 } : {}}>{children}</ContentComponent>
            </CategoryContainer>
        );
    }
}

export default PropertyCategory;
