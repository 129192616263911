import { PaneMode } from "./State";

export enum ActionType {
    SetMode,
}

export interface SetMode {
    type: ActionType.SetMode;
    payload: { mode: PaneMode };
}

export type SplitPaneAction = SetMode;
