import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setSelectedSidebarTabId, setSidebarOpen } from "../../redux/app/actions";
import { faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import StyledTooltip from "../generic/StyledTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    backgroundColor: theme.colors.white,
}));

const Tab = styled(Box)(({ theme }) => ({
    flex: 1,
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.inputBackground,
    },
}));

const Collapse = styled(Tab)(({ theme }) => ({
    color: theme.colors.placeholderText,
    "&:hover": {
        backgroundColor: "#F8F8F8",
        color: "#499C85",
    },
}));

const SelectedTab = styled(Tab)(({ theme }) => ({
    backgroundColor: theme.colors.inputBackground,
}));

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredTabId: null,
        };
    }

    render() {
        const { tabs, selectedSidebarTabId, onSetSelectedSidebarTabId, onSetSidebarOpen } = this.props;
        const { activePane, panesState } = this.context.state;
        const activeMode = panesState[activePane - 1].mode;
        const filteredTabs = tabs.filter((x) => x.isVisible(activeMode));

        if (!selectedSidebarTabId) {
            const firstTab = filteredTabs[0];
            if (firstTab) onSetSelectedSidebarTabId(firstTab.id);

            return null;
        }

        const { hoveredTabId } = this.state;

        return (
            <Root>
                <StyledTooltip title={"Zijbalk inklappen"}>
                    <Collapse id={"collapse"} onClick={() => onSetSidebarOpen(false)} style={{ maxWidth: 66 }}>
                        <FontAwesomeIcon icon={faChevronDoubleRight} />
                    </Collapse>
                </StyledTooltip>

                {filteredTabs.map((tab, index) => {
                    const isSelected = tab.id === selectedSidebarTabId;
                    const isHovered = tab.id === hoveredTabId;

                    let Component;
                    if (isSelected) Component = SelectedTab;
                    else Component = Tab;

                    const Icon = tab.icon;
                    return (
                        <StyledTooltip title={tab.tooltip || tab.title} key={index}>
                            <Component id={tab.id} onClick={() => onSetSelectedSidebarTabId(tab.id)} style={{ maxWidth: 66 }}>
                                <Icon selected={isSelected || isHovered} />
                            </Component>
                        </StyledTooltip>
                    );
                })}
            </Root>
        );
    }
}

Tabs.contextType = AppContext;

const mapStateToProps = ({ appReducer }) => ({
    selectedSidebarTabId: appReducer.selectedSidebarTabId,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedSidebarTabId: (selectedSidebarTabId) => dispatch(setSelectedSidebarTabId(selectedSidebarTabId)),
    onSetSidebarOpen: (sidebarOpen) => dispatch(setSidebarOpen(sidebarOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
