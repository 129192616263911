import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 2, 0),
    date: new Date(2022, 7, 5),
    content: (
        <>
            <ChangelogItem
                content="Implementatie WFS Lagen (ArcGIS)"
                type={ChangelogItemType.New}
                description="De ArcGIS viewer werd aangepast zodat deze nu de nieuwe WFS lagen kan gebruiken."
            />
        </>
    ),
};

export default changelog;
