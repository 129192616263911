import { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, TextField, styled } from "@mui/material";
import {
    countTotalModels,
    countTotalModelsFiltered,
    countVisibleModels,
    getFlatModelsArray,
    getTreeIndexString,
    isModelFiltered,
} from "../../../utils/modelFunctions";
import ViewsPropertiesButton from "./ViewsPropertiesButton";
import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { setPaneDocuments } from "../../../context/AppContext/Reducer";
import LoadingSwitch from "../../generic/LoadingSwitch";
import TagManager from "react-gtm-module";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { setModelsSearch } from "../../../redux/app/actions";
import InputButton from "../../admin/InputButton";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons";
import StyledTooltip from "../../generic/StyledTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    display: "flex",
    flexDirection: "column",
}));

const Header = styled(Box)(({ theme }) => ({
    display: "flex",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    backgroundColor: theme.colors.inputBackground,
    borderColor: theme.colors.inputBorder,
    alignItems: "center",
    padding: "16px 18px",
    "& h2": {
        fontSize: "17px",
        marginTop: 0,
        marginBottom: "0",
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
    },
    "& h3": {
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        marginTop: 5,
        marginBottom: "0",
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: 500,
    },
}));

const InputContainer = styled(Box)(() => ({
    flex: 1,
}));

const Container = styled(Box)(() => ({
    flex: 1,
    position: "relative",
}));

const Inner = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: "auto",
}));

const ItemContainer = styled(Box)(() => ({
    userSelect: "none",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
    display: "flex",
    padding: "9px 14px 8px 16px",
    placeItems: "center",
    "&:hover": {
        backgroundColor: "#f8f8f8",
    },
}));

const ModelContainer = styled(TitleContainer)(() => ({
    borderBottom: "none",
}));

const Title = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "12px",
    paddingLeft: "16px",
    whiteSpace: "normal",
    lineHeight: "22px",
    verticalAlign: "middle",
}));

const GroupLayerCounter = styled("span")(({ theme }) => ({
    color: theme.colors.placeholderText,
    fontSize: "10px",
    fontWeight: theme.fonts.openSans.regular,
    display: "flex",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
}));

const Models = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const [expandedIndices, setExpandedIndices] = useState({});
    const [filterInputActive, setFilterInputActive] = useState(false);
    const [filter, setFilter] = useState("");
    const [, forceRender] = useState(0);

    useEffect(() => {
        forceRender((x) => x + 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.panesState[state.activePane - 1].data?.documents, state.panesState[state.activePane - 1].data?.loaded]);

    const toggleExpandedIndex = (index, isExpanded) => {
        const indices = JSON.parse(JSON.stringify(expandedIndices || {}));
        if (isExpanded) {
            delete indices[index];
            Object.keys(indices).forEach((expandedIndex) => {
                if (expandedIndex.startsWith(index)) {
                    delete indices[expandedIndex];
                }
            });

            const getParentIndex = (index) => index.split("-").slice(0, -1).join("-");
            let parentIndex = getParentIndex(index);
            while (parentIndex) {
                indices[parentIndex] = true;
                parentIndex = getParentIndex(parentIndex);
            }
        } else {
            indices[index] = true;
        }

        setExpandedIndices(indices);
    };

    const renderDocuments = () => {
        const { activePane, panesState } = state;
        const documents = [...panesState[activePane - 1].data.documents];
        const loaded = panesState[activePane - 1].data.loaded ?? [];

        const setModelVisibility = (modelId, visible) => {
            const docs = [...documents];
            const data = getFlatModelsArray(docs);
            const entry = data.find((x) => x.id === modelId);
            entry.visible = visible;

            if (visible)
                TagManager.dataLayer({
                    dataLayer: {
                        event: "select_model",
                        model_id: modelId,
                        model_name: entry.attributes.displayName,
                    },
                });

            dispatch(setPaneDocuments(activePane, docs));
        };

        const setFolderVisibility = (folderName, visible) => {
            const findFolder =
                (found = false) =>
                (document, index) => {
                    if (!document.folder) return document;

                    let foundFolder = found;
                    if (document.folder.id === folderName) {
                        foundFolder = true;
                    }

                    if (foundFolder) {
                        document.items = document.items.map((item) => {
                            if (item.folder) return item;
                            item.visible = visible;

                            if (visible)
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: "select_model",
                                        model_id: item.relationships.derivatives.data.id,
                                        model_name: item.attributes.displayName,
                                    },
                                });

                            return item;
                        });
                    }

                    document.items = document.items.map(findFolder(foundFolder));

                    return document;
                };

            const docs = documents.map(findFolder());
            dispatch(setPaneDocuments(activePane, docs));
        };

        const isItemLoading = (item) => {
            if (!!item.folder) {
                return item.items.some((x) => isItemLoading(x));
            } else {
                if (!item.visible) return false;
                return loaded.findIndex((x) => x === item.relationships.derivatives.data.id) === -1;
            }
        };

        const renderGroup =
            (level = 0, prevTreeIndex = []) =>
            (item, index) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `Layers__renderDocuments__renderGroup__${treeIndexString}`;
                const isGroup = !!item.folder;
                const style = {
                    paddingLeft: 16 * (level + 1),
                };
                const isLoading = isItemLoading(item);

                const renderFolder = ({ folder, items }) => {
                    const folderId = folder.id;
                    const folderName = folder.attributes.displayName;
                    const fullPath = folder.attributes.fullPath;
                    const visibleModels = countVisibleModels(item);
                    const filteredModels = countTotalModelsFiltered(item, props.searchQuery);
                    const totalModels = countTotalModels(item);
                    const folderVisible = Boolean(visibleModels);
                    const isExpanded = item.expanded === true;
                    const canActivateGroup = props.activatableResourceGroups.some((x) => x.resource === "BIM360" && x.item === fullPath);

                    if (Boolean(props.searchQuery) && filteredModels === 0) return null;

                    return folderName === "generated-root-folder" ? (
                        items.map(renderGroup(level, treeIndex))
                    ) : (
                        <Fragment>
                            <TitleContainer
                                onClick={() => {
                                    toggleExpandedIndex(treeIndexString, isExpanded);
                                    item.expanded = !item.expanded;
                                }}
                                style={{ ...style, cursor: "pointer" }}
                            >
                                {canActivateGroup ? (
                                    <LoadingSwitch active={folderVisible} loading={isLoading} onChange={() => setFolderVisibility(folderId, !folderVisible)} />
                                ) : (
                                    <div style={{ display: "flex", width: 16 }}>
                                        <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} style={{ height: "0.7em", cursor: "pointer" }} />
                                    </div>
                                )}
                                <Title>
                                    <span>{folderName}</span>
                                    <GroupLayerCounter>
                                        {Boolean(props.searchQuery) ? `${visibleModels}/${filteredModels}` : `${visibleModels}/${totalModels}`}
                                    </GroupLayerCounter>
                                </Title>
                            </TitleContainer>
                            {isExpanded && items.map(renderGroup(level + 1, treeIndex))}
                        </Fragment>
                    );
                };

                const renderModel = (item) => {
                    const { attributes, id, visible } = item;

                    if (Boolean(props.searchQuery) && !isModelFiltered(item, props.searchQuery)) return null;

                    let tooltip = `Laatst bijgewerkt: ${new Date(attributes.modificationDate).toLocaleDateString("nl-BE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })}`;

                    if (attributes.description)
                        tooltip = (
                            <Fragment>
                                {attributes.displayName}
                                <br />
                                {tooltip}
                            </Fragment>
                        );

                    return (
                        <StyledTooltip title={tooltip}>
                            <ModelContainer style={style}>
                                <LoadingSwitch active={visible} loading={isLoading} onChange={() => setModelVisibility(id, !visible)} />
                                <Title component={"label"} htmlFor={"inputId"}>
                                    {attributes.description ?? attributes.displayName}
                                </Title>
                                <ViewsPropertiesButton item={item} isVisible={visible} />
                            </ModelContainer>
                        </StyledTooltip>
                    );
                };

                return <ItemContainer key={key}>{Boolean(isGroup) ? renderFolder(item) : renderModel(item)}</ItemContainer>;
            };

        return documents.map(renderGroup());
    };

    const { title, activeProject } = props;
    const resourceAccess = activeProject.resources.find((x) => x.Name === "Bim360");

    return Boolean(resourceAccess) ? (
        <Root>
            <Header>
                {filterInputActive ? (
                    <InputContainer>
                        <TextField
                            id="name"
                            label={title}
                            placeholder={"Filter"}
                            type="text"
                            fullWidth
                            autoComplete="off"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                props.onSetFilter(filter);
                                                setFilterInputActive(false);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faSearch} style={{ fontSize: "1rem" }} />
                                        </InputButton>

                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                setFilterInputActive(false);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                        </InputButton>
                                    </>
                                ),
                            }}
                        />
                    </InputContainer>
                ) : (
                    <>
                        <div>
                            <h2>{title}</h2>
                            {Boolean(props.searchQuery) && (
                                <h3>
                                    Actieve filter: {props.searchQuery}{" "}
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            fontSize: "1rem",
                                        }}
                                        onClick={() => props.onSetFilter("")}
                                    />
                                </h3>
                            )}
                        </div>
                        <FontAwesomeIcon
                            style={{
                                marginLeft: "auto",
                                cursor: "pointer",
                            }}
                            icon={farSearch}
                            onClick={() => setFilterInputActive(true)}
                        />
                    </>
                )}
            </Header>
            <Container>
                <Inner>{renderDocuments()}</Inner>
            </Container>
        </Root>
    ) : (
        <MessageContainer>U heeft geen toegang tot BIM modellen in dit project.</MessageContainer>
    );
};

const mapStateToProps = ({ appReducer, projectReducer }) => ({
    activatableResourceGroups: appReducer.activatableResourceGroups,
    searchQuery: appReducer.modelsSearchQuery,
    activeProject: projectReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => ({
    onSetFilter: (query) => dispatch(setModelsSearch(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Models);
