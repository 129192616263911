import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import CloseButton from "../../generic/CloseButton";

export interface TipsModalProps {
    message: string;
    open: boolean;
    onClose: () => void;
}

const TipsModal = ({ open, onClose, message }: TipsModalProps) => {
    const renderContent = () => {
        return (
            <Box sx={{ marginBottom: 16 }}>
                <ReactMarkdown linkTarget={"_blank"}>{message}</ReactMarkdown>
            </Box>
        );
    };

    return (
        <Dialog open={open} keepMounted aria-labelledby="wist-je-datje-dialog-slide-title" fullWidth={true}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "60vh",
                    "& a": {
                        color: "#8dd9c8",
                        textDecoration: "none",
                    },
                    "& a:hover": {
                        textDecoration: "underline",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <DialogTitle id="wist-je-datje-dialog-slide-title">Wist je dat?</DialogTitle>

                    <CloseButton aria-label="Sluit wist-je-datje" size="small" onClick={() => onClose()}>
                        <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                    </CloseButton>
                </Box>
                <DialogContent
                    dividers
                    sx={{
                        flex: 1,
                        overflow: "auto",
                    }}
                >
                    {renderContent()}
                </DialogContent>
                <DialogActions sx={{ m: "5px" }}>
                    <Button color="primary" variant="contained" onClick={() => onClose()}>
                        Doorgaan
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default TipsModal;
