import store from "../../redux/store";
import { structureAvailableFiles } from "../forge";
import {
    addActivatableResourceGroup,
    addDefaultActiveResource,
    deleteActivatableResourceGroup,
    deleteDefaultActiveResource,
    setActivatableResourceGroups,
    setBIMResources,
    setDefaultActiveResources,
    setGISResources,
} from "../../redux/app/actions";
import { groupLayers } from "../../utils/layerFunctions";
import { configuration } from "../../_configuration/configuration";

export const fetchBIMResources = async () => {
    const availableFiles = store.getState().projectReducer.resources.Bim360 ?? [];
    const documents = structureAvailableFiles(availableFiles);

    store.dispatch(setBIMResources(documents));
};

export const fetchGISToken = (source) => {
    const url = `${configuration.api}api/Tokens`;
    const options = {
        method: "GET",
    };

    return fetch(`${url}?tokenServiceName=arcgis&referer=${window.location.host}`, options).then((response) => {
        return response.json();
    });
};

export const fetchGISResources = (webMaps, token) => {
    const promises = [];
    for (let i = 0, len = webMaps.length; i < len; i++) {
        promises.push(getOperationalLayers(webMaps[i], token));
    }

    return Promise.all(promises).then((operationalLayersArray) => {
        const operationalLayers = operationalLayersArray.flat();
        const flat = flattenAuthorizedLayers(operationalLayers);
        const groups = groupLayers(flat);

        store.dispatch(setGISResources(groups));
        return JSON.parse(JSON.stringify(groups));
    });
};

export const fetchActivatableResourceGroups = () => {
    const url = `${configuration.api}api/ActivatableResourceGroups`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((resourceGroups) => {
            store.dispatch(setActivatableResourceGroups(resourceGroups));
        });
};

export const createActivatableResourceGroup = (resource) => {
    const url = `${configuration.api}api/ActivatableResourceGroups`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resource),
    };

    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((resource) => {
            store.dispatch(addActivatableResourceGroup(resource));
        });
};

export const removeActivatableResourceGroup = (id) => {
    const url = `${configuration.api}api/ActivatableResourceGroups/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteActivatableResourceGroup(id));
    });
};

export const fetchDefaultActiveResources = () => {
    const url = `${configuration.api}api/DefaultActiveResources`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((resources) => {
            store.dispatch(setDefaultActiveResources(resources));
        });
};

export const createDefaultActiveResource = (resource) => {
    const url = `${configuration.api}api/DefaultActiveResources`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resource),
    };

    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((resource) => {
            store.dispatch(addDefaultActiveResource(resource));
        });
};

export const removeDefaultActiveResource = (id) => {
    const url = `${configuration.api}api/DefaultActiveResources/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteDefaultActiveResource(id));
    });
};

const getOperationalLayers = (webMap, token) => {
    return fetch(`https://www.arcgis.com/sharing/rest/content/items/${webMap.id}/data?token=${token}`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((webMapData) =>
            webMapData.operationalLayers
                .reverse()
                .flat()
                .map((x) => {
                    x.title = `${webMap.title} - ${x.title}`;
                    return x;
                })
        );
};

const flattenAuthorizedLayers = (operationalLayers) => {
    const authorizedResources = store.getState().projectReducer.resources.ArcGis;
    const flatResources = authorizedResources.reduce((acc, currValue) => acc.concat(currValue.Items), []);
    const resourcesArray = flatResources.map((x) => x.Uri);
    const deniedArray = authorizedResources.filter((x) => x.Denied).map((x) => x.Uri);
    const flattened = [];

    for (let i = 0, len = operationalLayers.length; i < len; i++) {
        const layerOrGroup = operationalLayers[i];

        if (layerOrGroup.layerType === "GroupLayer") {
            layerOrGroup.layers.reverse();
            for (let j = 0, groupLen = layerOrGroup.layers.length; j < groupLen; j++) {
                const layer = layerOrGroup.layers[j];
                if (layerOrGroup.title) layer.title = `${layerOrGroup.title} - ${layer.title}`;

                const path = layer.title.split(" - ").join("/");
                if (deniedArray.some((y) => path.startsWith(y))) continue;
                if (resourcesArray.some((y) => path.startsWith(y))) flattened.push(layer);
            }
        } else {
            const path = layerOrGroup.title.split(" - ").join("/");
            if (deniedArray.some((y) => path.startsWith(y))) continue;
            if (resourcesArray.some((y) => path.startsWith(y))) flattened.push(layerOrGroup);
        }
    }

    // store.dispatch(setDefaultThemesOrder(flattened.map((x) => ({ id: x.id, visible: x.visible }))));

    return flattened;
};
