import { Box, useTheme } from "@mui/material";

const BookmarksIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 384 512">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    stroke: "rgba(0,0,0,0)",
                }}
                d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.8" height="21" viewBox="0 0 384 512">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.placeholderText,
                }}
                d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
            />
        </svg>
    );
};

export default BookmarksIcon;
