import { Box, useTheme } from "@mui/material";

const BackIcon = ({ selected }) => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13.048" viewBox="0 0 8 13.048">
            <Box
                component={"path"}
                sx={{
                    fill: selected ? "#000000" : theme.colors.disabled,
                    fillRule: "evenodd",
                }}
                d="M20.66,8.758l6.532-6.516L28.66,3.726,23.611,8.758l5.049,5.048L27.192,15.29Z"
                transform="translate(-20.66 -2.242)"
            />
        </svg>
    );
};

export default BackIcon;
