import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 3, 1),
    date: new Date(2022, 9, 20),
    content: (
        <>
            <ChangelogItem
                content="Gerelateerde modellen inladen"
                type={ChangelogItemType.Bugfix}
                description="Het probleem met inladen van meerdere gerelateerde modellen werd verholpen."
            />
        </>
    ),
};

export default changelog;
