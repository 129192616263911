import { Component } from "react";
import { connect } from "react-redux";
import * as objectDataConnector from "../../connectors/objectData";
import * as objectNamesConnector from "../../connectors/objectNames";
import { setFilteredRelations, setLinkedPhysicalAndLogicalObjectIds } from "../../redux/relations/actions";
import { configuration } from "../../_configuration/configuration";

class ObjectDataManager extends Component {
    componentDidMount() {
        this.loadObjectData();
    }

    componentDidUpdate(prevProps, prevState) {
        const selectionHasChanged = this.props.selectedObject !== prevProps.selectedObject;
        const selectedLinkedIdHasChanged = this.props.selectedLinkedId !== prevProps.selectedLinkedId;
        if (selectionHasChanged || selectedLinkedIdHasChanged) {
            this.loadObjectData();
        }
        if (selectionHasChanged) {
            this.loadPhysicalObjectTree();
        }

        const physicalObjectTreesHaveChanged = this.props.physicalObjectTrees !== prevProps.physicalObjectTrees;
        if (physicalObjectTreesHaveChanged) {
            this.loadPhysicalObjectTreesNames();
        }

        const objectRelationsHaveChanged = this.props.objectRelations !== prevProps.objectRelations;
        if (selectionHasChanged || objectRelationsHaveChanged) {
            this.loadObjectRelationsData();
            this.filterSelectedObjectLinkedRelations();
        }

        const filteredRelationsHaveChanged = this.props.filteredRelations !== prevProps.filteredRelations;
        if (filteredRelationsHaveChanged) {
            this.loadRelatedObjectNames();
        }
    }

    loadObjectData = () => {
        const { selectedObject, selectedLinkedId, objectProperties, objectRelations, onSetFilteredRelations } = this.props;
        const id = selectedLinkedId || (selectedObject && selectedObject.objectId);
        if (id) {
            //fetch properties
            {
                const { status } = objectProperties[id] || {};
                switch (status) {
                    case "fetching":
                    case "fetched":
                        break;
                    default:
                        onSetFilteredRelations({});
                        objectDataConnector.loadObjectProperties(id);
                }
            }
            //fetch relations
            {
                const { status } = objectRelations[id] || {};
                if (!status) {
                    onSetFilteredRelations({});
                    objectDataConnector.loadObjectRelations(id);
                }
            }
        }
    };

    loadPhysicalObjectTree = () => {
        const { selectedObject, physicalObjectTrees } = this.props;

        if (!selectedObject) {
            return;
        }

        const { objectId, isPhysicalObject } = selectedObject;

        const { physicalObjectTree = {} } = physicalObjectTrees[objectId] || {};

        if (!physicalObjectTree || !physicalObjectTree.status) {
            objectDataConnector.loadPhysicalObjectTree(objectId, isPhysicalObject);
        }
    };

    loadPhysicalObjectTreesNames = () => {
        const { physicalObjectTrees, objectNames } = this.props;
        const objectIdsSet = new Set();
        for (const objectId in physicalObjectTrees) {
            const { status, physicalObjectTree } = physicalObjectTrees[objectId];
            if (status === "fetched") {
                const { linkedPhysicalObjectId, children = [], parents = [] } = physicalObjectTree || {};
                const unknownObjectIds = [linkedPhysicalObjectId, ...children, ...parents].filter((objectId) => !objectNames[objectId]);
                unknownObjectIds.forEach((objectId) => objectIdsSet.add(objectId));
            }
        }
        const objectIds = Array.from(objectIdsSet);
        if (objectIds.length) {
            objectNamesConnector.loadObjectNamesBatch(objectIds);
        }
    };

    loadObjectRelationsData = () => {
        const { selectedObject, objectProperties, objectRelations } = this.props;
        const id = selectedObject && selectedObject.objectId;
        if (id) {
            const { status, relations } = objectRelations[id] || {};
            if (status === "fetched") {
                const { OutgoingRelations: outgoingRelations = [] } = relations;

                const linkedPhysicalObject = outgoingRelations.find(({ Name }) => {
                    return (
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT
                    );
                });

                const linkedLogicalObject = outgoingRelations.find(({ Name }) => {
                    return (
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT
                    );
                });

                const { isPhysicalObject } = selectedObject;

                const linkedObjects = isPhysicalObject ? [selectedObject] : [linkedPhysicalObject, linkedLogicalObject];

                linkedObjects
                    .filter((linkedObject) => Boolean(linkedObject))
                    .forEach(({ OtherObjectId, objectId }) => {
                        objectId = OtherObjectId || objectId;
                        const otherObjectProperties = objectProperties[objectId] || {};
                        if (!otherObjectProperties.status) {
                            objectDataConnector.loadObjectProperties(objectId);
                        }

                        const otherObjectRelations = objectRelations[objectId] || {};
                        if (!otherObjectRelations.status) {
                            objectDataConnector.loadObjectRelations(objectId);
                        }
                    });
            }
        }
    };

    filterSelectedObjectLinkedRelations = () => {
        const { selectedObject, objectRelations, onSetFilteredRelations, onSetLinkedPhysicalAndLogicalObjectIds } = this.props;

        if (selectedObject) {
            const { status, relations = {} } = objectRelations[selectedObject.objectId] || {};

            const { OutgoingRelations: outgoingRelations = [] } = relations;

            if (status === "fetched") {
                const physicalObject = outgoingRelations.find(
                    ({ Name }) =>
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT
                );
                const physicalObjectId = selectedObject.isPhysicalObject ? selectedObject.objectId : Boolean(physicalObject) && physicalObject.OtherObjectId;
                const physicalObjectRelations = Boolean(physicalObjectId) && objectRelations[physicalObjectId];
                const { IncomingRelations: physicalIncomingRelations = [], OutgoingRelations: physicalOutgoingRelations = [] } =
                    (physicalObjectRelations || {}).relations || {};

                const logicalObject = outgoingRelations.find(
                    ({ Name }) =>
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT
                );
                const logicalObjectId = Boolean(logicalObject) && logicalObject.OtherObjectId;
                const logicalObjectRelations = Boolean(logicalObjectId) && objectRelations[logicalObjectId];
                const { IncomingRelations: logicalIncomingRelations = [], OutgoingRelations: logicalOutgoingRelations = [] } =
                    (logicalObjectRelations || {}).relations || {};

                const availableDerivatives = this.props.resources.Bim360?.map((x) => x.derivative) ?? [];
                const relaticsAccess = this.props.resources.Relatics !== undefined;
                const availabilityFilter = (item) => {
                    // No GIS layer ids available, skip removing these from the relations for now.
                    // If a relation would be opened it will not show the layer so this does not cause a security issue, just a minor inconvenience for now.
                    if (item.Name.startsWith("GIS")) return true;

                    // Handle access to Relatics related things
                    if (item.Name === "HEEFT_SYSTEEMEIS") return relaticsAccess;

                    // Ignore all other things like tree relations (same file)
                    if (!item.Name.startsWith("BIM")) return true;

                    const fileParts = item.OtherObjectId.split(".");
                    if (fileParts.length > 2) console.error("MULTIPLE DOTS IN FILENAME, NEEDS FIX");

                    const derivativeId = fileParts[0];
                    if (availableDerivatives.some((x) => x === derivativeId)) return true;

                    return false;
                };

                const allRelations = {
                    physicalIncomingRelations: physicalIncomingRelations.filter(availabilityFilter),
                    physicalOutgoingRelations: physicalOutgoingRelations.filter(availabilityFilter),
                    logicalIncomingRelations: logicalIncomingRelations.filter(availabilityFilter),
                    logicalOutgoingRelations: logicalOutgoingRelations.filter(availabilityFilter),
                };

                const gisRelations = this.filterRelations(allRelations, "gis");
                const bimRelations = this.filterRelations(allRelations, "bim");
                const pdfRelations = this.filterRelations(allRelations, "pdf");

                const requirementRelations = this.filterRelations(allRelations, "requirement");

                const filteredRelations = {
                    gisRelations,
                    bimRelations,
                    pdfRelations,
                    requirementRelations,
                };

                onSetFilteredRelations(filteredRelations);
                onSetLinkedPhysicalAndLogicalObjectIds(physicalObjectId, logicalObjectId);
            }
        }
    };

    filterRelations = (relations, filterName) => {
        const { selectedObject } = this.props;
        const { objectId: selectedObjectId } = selectedObject || {};
        const { physicalIncomingRelations, physicalOutgoingRelations, logicalIncomingRelations, logicalOutgoingRelations } = relations;
        const filters = {
            gis: (relations) =>
                relations.filter((relation) => {
                    return (
                        relation.Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        relation.Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT
                    );
                }),
            bim: (relations) =>
                relations.filter((relation) => {
                    return (
                        relation.Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        relation.Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT
                    );
                }),
            pdf: (relations) =>
                relations.filter((relation) => {
                    return (
                        relation.Name === configuration.relationTypes.PDF_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        relation.Name === configuration.relationTypes.PDF_IS_GELINKT_AAN_LOGISCH_OBJECT
                    );
                }),
            requirement: (relations) =>
                relations.filter((relation) => {
                    return relation.Name === configuration.relationTypes.HEEFT_BESTEKSEIS || relation.Name === configuration.relationTypes.HEEFT_SYSTEEMEIS;
                }),
        };

        const filterSelfRelations = (relation) => {
            return relation.OtherObjectId !== selectedObjectId;
        };

        const filter = filters[filterName];

        if (filter) {
            return {
                physicalIncomingRelations: filter(physicalIncomingRelations).filter(filterSelfRelations),
                physicalOutgoingRelations: filter(physicalOutgoingRelations).filter(filterSelfRelations),
                logicalIncomingRelations: filter(logicalIncomingRelations).filter(filterSelfRelations),
                logicalOutgoingRelations: filter(logicalOutgoingRelations).filter(filterSelfRelations),
            };
        } else {
            throw new Error("Relations - filterRelations invalidFilterNameError");
        }
    };

    loadRelatedObjectNames = () => {
        const { filteredRelations = {}, objectNames } = this.props;

        const allRelations = Object.values(filteredRelations);
        const allRelatedObjectIds = allRelations
            .reduce((allRelatedIds, relations) => {
                const { physicalIncomingRelations, physicalOutgoingRelations, logicalIncomingRelations, logicalOutgoingRelations } = relations;
                const objectIds = [...physicalIncomingRelations, ...physicalOutgoingRelations, ...logicalIncomingRelations, ...logicalOutgoingRelations].map(
                    ({ OtherObjectId }) => OtherObjectId
                );

                // When objectIds contains more than 100k elements we can't use the spread operator
                // because this will trigger the following error: `RangeError: Maximum call stack size exceeded`.
                // To avoid running in the issue we have to push the elements into the target array one at a time.
                if (objectIds.length > 100000) {
                    for (let i = 0; i < objectIds.length; i++) {
                        allRelatedIds.push(objectIds[i]);
                    }
                } else {
                    allRelatedIds.push(...objectIds);
                }

                return allRelatedIds;
            }, [])
            .filter((objectId) => !objectNames[objectId]);

        if (allRelatedObjectIds && allRelatedObjectIds.length) {
            const objectIds = Array.from(new Set(allRelatedObjectIds));
            if (objectIds.length) {
                objectNamesConnector.loadObjectNamesBatch(objectIds);
            }
        }
    };

    render() {
        return null;
    }
}

const mapStateToProps = ({ selectionReducer, objectPropertiesReducer, objectRelationsReducer, favoritesReducer, objectNamesReducer, projectReducer }) => ({
    selectedObject: selectionReducer.selectedObject,
    selectedLinkedId: selectionReducer.selectedLinkedId,
    objectProperties: objectPropertiesReducer.objects,
    objectRelations: objectRelationsReducer.objects,
    filteredRelations: objectRelationsReducer.filteredRelations,
    physicalObjectTrees: objectRelationsReducer.physicalObjectTrees,
    favoriteObjects: favoritesReducer.favoriteObjects,
    objectNames: objectNamesReducer.objects,
    resources: projectReducer.resources,
});

const mapDispatchToProps = (dispatch) => ({
    onSetFilteredRelations: (filteredRelations) => dispatch(setFilteredRelations(filteredRelations)),
    onSetLinkedPhysicalAndLogicalObjectIds: (physicalObjectId, logicalObjectId) =>
        dispatch(setLinkedPhysicalAndLogicalObjectIds(physicalObjectId, logicalObjectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDataManager);
