export const stringToCharCodes = (string) => {
    if (!string || typeof string !== "string" || !string.length) {
        throw new Error("stringToCharCodes requires a string argument of length > 0");
    }
    return `charCodes_${string
        .split("")
        .map((char, index) => {
            return string.charCodeAt(index);
        })
        .join("")}`;
};

export const stringTruncate = (string, n) => {
    if (!string || typeof string !== "string" || !string.length) {
        throw new Error("stringTruncate requires a string argument of length > 0");
    }
    return string.length > n ? string.substr(0, n - 1) + "..." : string;
};
