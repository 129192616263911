import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    TextField,
    lighten,
    SxProps,
    Theme,
    useTheme,
} from "@mui/material";
import { faCheck, faPencil, faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import DeleteButton from "../DeleteButton";
import InputButton from "../InputButton";
import { createRole, fetchRoles, modifyRole, removeRole } from "../../../connectors/admin/users";
import StyledTooltip from "../../generic/StyledTooltip";

export interface AdminDialogContentUsersRolesProps {
    roles: any;
    onClose: () => void;
}

const AdminDialogContentUsersRoles = ({ roles, onClose }: AdminDialogContentUsersRolesProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fetched, setFetched] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [userRoles, setUserRoles] = useState<any[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [addNewRole, setAddNewRole] = useState<boolean>(false);
    const [newRole, setNewRole] = useState<any>({
        project: "OWV - Lantis",
        role: "",
        identity: "",
        groupGuid: "",
    });
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);
    const [editRole, setEditRole] = useState<any>();

    useEffect(() => {
        fetchRoles().then(() => setFetched(true));
    }, []);

    useEffect(() => {
        if (!fetched) return;

        setData(roles);
        setUserRoles(roles.sort((a: any, b: any) => a.role.localeCompare(b.role)).map((x: any) => ({ ...x })));
        setIsLoading(false);
    }, [roles, fetched]);

    const onSave = () => {
        setIsLoading(true);
        const promises = [];
        for (let i = 0; i < userRoles.length; i++) {
            const role = userRoles[i];
            const originalRole = data.find((x) => x.id === role.id);
            if (originalRole.role !== role.role || originalRole.groupGuid !== role.groupGuid || originalRole.identity !== role.identity)
                promises.push(modifyRole(role));
        }

        Promise.all(promises);
    };

    const onReset = () => {
        setUserRoles(roles.sort((a: any, b: any) => a.role.localeCompare(b.role)).map((x: any) => ({ ...x })));
    };

    const hasChanges = (): boolean => {
        const originalRoles = data;
        if (originalRoles.length !== userRoles.length) return true;

        for (let i = 0; i < userRoles.length; i++) {
            const originalRole = originalRoles[i];
            const role = userRoles[i];
            if (originalRole.role !== role.role || originalRole.groupGuid !== role.groupGuid || originalRole.identity !== role.identity) return true;
        }

        return false;
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const editEntry = () => {
        const data = [...userRoles];
        const entry = data.find((x) => x.id === editRole.id);
        const fields = Object.keys(entry);
        for (let i = 0; i < fields.length; i++) {
            entry[fields[i]] = editRole[fields[i]];
        }

        setUserRoles(data);
    };

    const onAddEntry = () => {
        setIsLoading(true);
        const role = { ...newRole };
        if (role.groupGuid.length === 0) role.groupGuid = null;

        createRole(role).then(() => {
            setAddNewRole(false);
            setNewRole({
                project: "OWV - Lantis",
                role: "",
                identity: "",
                groupGuid: "",
            });
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);
        setShouldDelete(false);
        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            promises.push(removeRole(selected[i]));
        }

        Promise.all(promises).then((_) => {
            setSelected([]);
            setIsLoading(false);
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>BIM</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setAddNewRole(true)} onDelete={() => onDeleteEntry()} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"></TableCell>
                                        <TableCell>Rol</TableCell>
                                        <TableCell>Identiteit</TableCell>
                                        <TableCell>AD Groep ID</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userRoles.map((property, index) => {
                                        const isItemSelected = isSelected(property.id);
                                        const isEditting = editRole?.id === property.id;

                                        return isEditting ? (
                                            <TableRow hover key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editRole.role}
                                                        onChange={(e) => setEditRole({ ...editRole, role: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editRole.identity}
                                                        onChange={(e) => setEditRole({ ...editRole, identity: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editRole.groupGuid}
                                                        onChange={(e) => setEditRole({ ...editRole, groupGuid: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            editEntry();
                                                            setEditRole(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                    </InputButton>

                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditRole(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow hover onClick={() => handleClick(property.id)} key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>{property.role}</TableCell>
                                                <TableCell>{property.identity}</TableCell>
                                                <TableCell>{property.groupGuid}</TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditRole({ ...property });
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={addNewRole} onClose={() => setAddNewRole(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                        <DialogTitle id="form-dialog-title">Nieuwe rol toevoegen</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField label="Rol" value={newRole.role} onChange={(e) => setNewRole({ ...newRole, role: e.currentTarget.value })} />
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField
                                        label="Identiteit"
                                        value={newRole.identity}
                                        onChange={(e) => setNewRole({ ...newRole, identity: e.currentTarget.value })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField
                                        label="AD Groep ID"
                                        value={newRole.groupGuid}
                                        onChange={(e) => setNewRole({ ...newRole, groupGuid: e.currentTarget.value })}
                                    />
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddNewRole(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button onClick={() => onAddEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={Boolean(shouldDelete)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Rol(len) verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>U staat op het punt de geselecteerde rol(len) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    roles: appReducer.roles,
});

export default connect(mapStateToProps)(AdminDialogContentUsersRoles);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={style}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Rollen
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde rol(len) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe rol toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
