import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { setQueuedSelectedObjectResource, setQueuedSelectedObject } from "../../redux/selection/actions";
import { setSelectedSidebarTabId, setSelectedRelationsTabId } from "../../redux/app/actions";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { determineTargetData, setPaneDetails } from "../../context/AppContext/Reducer";
import { PaneMode } from "../../context/SplitPaneContext/State";
import { configuration } from "../../_configuration/configuration";

const QueryParameterManager = (props) => {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const urlParams = getUrlParameters();
        const urlPassedId = urlParams.get("id");
        const urlPassedResource = urlParams.get("resource");
        const urlPassedMode = urlParams.get("mode");
        const realMode = configuration.sources[urlPassedMode];
        const mode = realMode ? realMode : configuration.sources.gis;

        let actualMode;
        switch (mode) {
            case configuration.sources.bim:
                actualMode = PaneMode.BIM;
                break;
            case configuration.sources.gis:
                actualMode = PaneMode.GIS;
                break;
            case configuration.sources.pdf:
                actualMode = PaneMode.DOCUMENT;
                break;
            default:
                actualMode = PaneMode.GIS;
        }

        if (urlPassedId) {
            //console.log("will set queued selected object", urlPassedId, mode);
            props.onSetQueuedSelectedObject(urlPassedId, mode, true, []);
            props.onSetQueuedSelectedResource(urlPassedResource);
            props.onSetSelectedSidebarTabId("selection");
            props.onSetSelectedRelationsTabId(
                mode === configuration.sources.gis ? "maps" : mode === configuration.sources.bim ? "models" : "physicalObjectTree"
            );

            removeUrlParameter("id");
            removeUrlParameter("resource");
        }

        if (actualMode) {
            const { panesState } = state;
            const currentMode = panesState[0]?.mode;
            if (actualMode === currentMode) return;

            const data = determineTargetData(actualMode);
            dispatch(setPaneDetails(1, { id: 1, mode: actualMode, data, appObject: undefined }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.panesState.length > 1) {
            removeUrlParameter("mode");
            return;
        }

        const { panesState } = state;
        const mode = panesState[0]?.mode;
        let urlMode;
        switch (mode) {
            case PaneMode.BIM:
                urlMode = "bim";
                break;
            case PaneMode.GIS:
                urlMode = "gis";
                break;
            case PaneMode.DOCUMENT:
                urlMode = "pdf";
                break;
            default:
                urlMode = "gis";
        }

        updateUrlParameter("mode", urlMode);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.panesState]);

    const updateUrlParameter = (key, value) => {
        const query = getUrlParameters();
        if (query.get(key) !== value) {
            //console.log('should updateUrlParameter: ', key, value)
            query.set(key, value);
            if (window.history.replaceState) {
                window.history.replaceState("", "", `/?${query}`);
            }
        }
    };

    const removeUrlParameter = (key) => {
        const query = getUrlParameters();
        query.delete(key);
        if (window.history.replaceState) {
            window.history.replaceState("", "", `/?${query}`);
        }
    };

    const getUrlParameters = () => new URLSearchParams(window.location.search);

    return null;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    onSetQueuedSelectedObject: (a, b, c, d) => dispatch(setQueuedSelectedObject(a, b, c, d)),
    onSetQueuedSelectedResource: (a) => dispatch(setQueuedSelectedObjectResource(a)),
    onSetSelectedSidebarTabId: (a) => dispatch(setSelectedSidebarTabId(a)),
    onSetSelectedRelationsTabId: (id) => dispatch(setSelectedRelationsTabId(id, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryParameterManager);
