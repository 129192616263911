import { useContext, useMemo } from "react";
import JsonTable from "ts-react-json-table";

import { connect } from "react-redux";

import { Box } from "@mui/material";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { SplitPaneContext } from "../../context/SplitPaneContext/SplitPaneContextProvider";

const ForgeLegend = (props) => {
    const { state: appState } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);
    const paneData = useMemo(() => appState.panesState.find((x) => x.id === paneState.id), [appState.panesState, paneState.id]);

    const columns = [
        {
            key: "parameterValue",
            label: "Waarde",
            cell: (row) => {
                if (typeof row.parameterValue === "boolean") {
                    return row.parameterValue ? "Waar" : "Onwaar";
                }

                return row.parameterValue || "<Leeg>";
            },
        },
        {
            key: "description",
            label: "Omschrijving",
            cell: (row) => {
                if (typeof row.parameterValue === "boolean") {
                    return row.parameterValue ? "Voldoet" : "Voldoet niet";
                }

                if (!Object.keys(row).includes("parameterValue")) return "";

                return row.description;
            },
        },
        {
            key: "color",
            label: "Kleur",
            cell: (row) => (
                <div
                    style={{
                        background: `rgba(${row.color[0] * 255}, ${row.color[1] * 255}, ${row.color[2] * 255}, ${row.color[3]})`,
                        display: "block",
                        width: "25px",
                        height: "25px",
                        margin: "0 auto",
                        border: "1px solid #CCC",
                    }}
                ></div>
            ),
        },
    ];

    const legendActive = paneData.data.tools?.legend ?? false;
    const legend = paneData.data.legend?.map((x) => ({ ...x, parameterValue: x.parameterStringValue || x.parameterBoolValue }));

    return legendActive && !props.propertiesOverlayOpen && !props.relationsOverlayOpen ? (
        <Box
            sx={{
                position: "absolute",
                bottom: "100px",
                left: "30px",
                zIndex: "999",
                width: "500px",
                maxHeight: "40%",
                maxWidth: "50%",
                overflow: "auto",
                background: "#ffffff",
                boxShadow: "0 1px 2px rgb(0 0 0 / 30%)",
                "&>div": {
                    width: "100%",
                },
                "&>span": {
                    margin: ".5em 1em",
                },
                "& table": {
                    margin: ".5em 1em",
                    width: "calc(100% - 2em)",
                },
            }}
            className={"esri-component esri-widget esri-ui-corner"}
        >
            {legend ? <JsonTable rows={legend} columns={columns} /> : <span>Geen legenda</span>}
        </Box>
    ) : null;
};

const mapStateToProps = ({ appReducer }) => ({
    propertiesOverlayOpen: appReducer.propertiesOverlayOpen,
    relationsOverlayOpen: appReducer.relationsOverlayOpen,
});

export default connect(mapStateToProps)(ForgeLegend);
