import { Fragment, useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    useTheme,
} from "@mui/material";
import { faCheck, faFolderOpen, faPencil, faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import InputButton from "../InputButton";
import {
    createActivatableResourceGroup,
    createDefaultActiveResource,
    fetchActivatableResourceGroups,
    fetchDefaultActiveResources,
    fetchGISResources,
    fetchGISToken,
    removeActivatableResourceGroup,
    removeDefaultActiveResource,
} from "../../../connectors/admin/resources";
import { getTreeIndexString } from "../../../utils/modelFunctions";
import differenceBy from "lodash/differenceBy";
import { loadModules } from "esri-loader";
import { fetchResources } from "../../../connectors/admin/resourcesConfig";

export interface AdminDialogContentGISLayersProps {
    resources: any;
    layers: any;
    activatableResourceGroups: any;
    defaultActiveResources: any;
    onClose: () => void;
}

const RESOURCE_KEY = "ArcGis";

const AdminDialogContentGISLayers = ({ resources, layers, activatableResourceGroups, defaultActiveResources, onClose }: AdminDialogContentGISLayersProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fetched, setFetched] = useState(false);
    const [gisLayers, setGisLayers] = useState<any[]>([]);
    const [groupData, setGroupData] = useState<any[]>([]);
    const [resourceGroups, setResourceGroups] = useState<any[]>([]);
    const [activeData, setActiveData] = useState<any[]>([]);
    const [defaultActive, setDefaultActive] = useState<any[]>([]);
    const [editEntry, setEditEntry] = useState<any>();
    const [editGroupToggle, setEditGroupToggle] = useState<boolean>(false);
    const [editDefaultActive, setEditDefaultActive] = useState<boolean>(false);
    const [token, setToken] = useState<any>();

    useEffect(() => {
        fetchResources();
        fetchGISToken("arcgis").then((token) => {
            loadModules(["esri/identity/IdentityManager"]).then(([identityManager]) => {
                identityManager.registerToken({
                    token: token.result.token,
                    server: "https://www.arcgis.com/sharing",
                });
                setToken(token.result.token);
            });
        });
    }, []);

    useEffect(() => {
        if (!token || !resources) return;

        loadModules(["esri/portal/Portal"])
            .then(([Portal]) => {
                const portal = new Portal();
                portal.authMode = "immediate";

                return portal.load();
            })
            .then((portal) => {
                const { value: arcgisOwnerName } = resources.find((x: any) => x.resource === "ArcGis" && x.key === "Owner");
                const queryParams = {
                    query: `owner: ${arcgisOwnerName} AND type: Web Map`,
                    sortField: "numViews",
                    sortOrder: "desc",
                    num: 100,
                };

                return portal.queryItems(queryParams);
            })
            .then((queryResult) => queryResult.results)
            .then((webMaps) => {
                const availableWebmaps = resources.filter((x: any) => x.resource === "ArcGis" && x.key === "WebMap");
                const foundWebMaps = webMaps
                    .filter((x: any) =>
                        availableWebmaps
                            .map((x: any) => ({ title: x.value, order: x.number }))
                            .some((y: any) => {
                                if (y.title === x.title) {
                                    x.order = y.order;
                                    return true;
                                }

                                return false;
                            })
                    )
                    .sort((a: any, b: any) => a.order - b.order);

                fetchGISResources(
                    foundWebMaps.map((x: any) => ({ id: x.id, title: x.title })),
                    token
                ).then((x) => {});
            });
    }, [resources, token]);

    useEffect(() => {
        if (!layers || layers.length === 0) return;
        Promise.all([fetchActivatableResourceGroups(), fetchDefaultActiveResources()]).then(() => setFetched(true));
    }, [layers]);

    useEffect(() => {
        if (!fetched) return;
        setGisLayers(layers.map((x: any) => ({ ...x })));

        const groupData = activatableResourceGroups.filter((x: any) => x.resource === RESOURCE_KEY);
        setGroupData(groupData);
        setResourceGroups(groupData.map((x: any) => ({ ...x })));

        const activeData = defaultActiveResources.filter((x: any) => x.resource === RESOURCE_KEY);
        setActiveData(activeData);
        setDefaultActive(activeData.map((x: any) => ({ ...x })));

        setIsLoading(false);
    }, [layers, activatableResourceGroups, defaultActiveResources, fetched]);

    const onSave = () => {
        setIsLoading(true);
        const promises: Promise<void>[] = [];

        saveGroupData(promises);
        saveDefaultActiveData(promises);

        Promise.all(promises);
    };

    const saveGroupData = (promises: Promise<void>[]) => {
        const toAdd = differenceBy(resourceGroups, groupData, "item");
        const toDelete = differenceBy(groupData, resourceGroups, "item");
        for (let i = 0; i < toAdd.length; i++) {
            const entry = toAdd[i];
            promises.push(createActivatableResourceGroup(entry));
        }

        for (let i = 0; i < toDelete.length; i++) {
            const entry = toDelete[i];
            promises.push(removeActivatableResourceGroup(entry.id));
        }
    };

    const saveDefaultActiveData = (promises: Promise<void>[]) => {
        const toAdd = differenceBy(defaultActive, activeData, "item");
        const toDelete = differenceBy(activeData, defaultActive, "item");
        for (let i = 0; i < toAdd.length; i++) {
            const entry = toAdd[i];
            promises.push(createDefaultActiveResource(entry));
        }

        for (let i = 0; i < toDelete.length; i++) {
            const entry = toDelete[i];
            promises.push(removeDefaultActiveResource(entry.id));
        }
    };

    const onReset = () => {
        setResourceGroups(groupData.map((x: any) => ({ ...x })));
        setDefaultActive(activeData.map((x: any) => ({ ...x })));
    };

    const hasChanges = (): boolean => {
        if (groupData.length !== resourceGroups.length) return true;
        if (activeData.length !== defaultActive.length) return true;

        for (let i = 0; i < groupData.length; i++) {
            const originalEntry = groupData[i];
            const stillExists = resourceGroups.find((x) => x.item === originalEntry.item && x.resource === RESOURCE_KEY);

            if (!stillExists) return true;
        }

        for (let i = 0; i < activeData.length; i++) {
            const originalEntry = activeData[i];
            const stillExists = defaultActive.find((x) => x.item === originalEntry.item && x.resource === RESOURCE_KEY);

            if (!stillExists) return true;
        }

        return false;
    };

    const renderBool = (value: boolean): string => {
        if (value) return "Ja";
        return "Neen";
    };

    const handleCheckboxChange = (key: string, canToggle: boolean, isActive: boolean) => {
        let groupData;
        let defaultData;
        if (canToggle) {
            groupData = [...resourceGroups];
            groupData.push({
                project: "OWV - Lantis", // TODO: Replace??
                resource: RESOURCE_KEY,
                item: key,
            });
        } else {
            groupData = resourceGroups.filter((x) => x.item !== key);
        }

        if (isActive) {
            defaultData = [...defaultActive];
            defaultData.push({
                project: "OWV - Lantis", // TODO: Replace??
                resource: RESOURCE_KEY,
                item: key,
            });
        } else {
            defaultData = defaultActive.filter((x) => x.item !== key);
        }

        setResourceGroups(groupData);
        setDefaultActive(defaultData);
    };

    const renderThemes = () => {
        const renderGroup =
            (level = 0, prevTreeIndex: any[] = [], parentId = 0) =>
            (item: any, index: any) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `Layers__renderThemes__renderGroup__${treeIndexString}`;
                const isGroup = item.type === "group";
                const style = {
                    paddingLeft: 16 * (level + (isGroup ? 0 : 1)),
                    display: "flex",
                };

                const isDefaultActive = defaultActive.find((x: any) => x.item === item.id) ? true : false;
                const isGroupActivatable = resourceGroups.find((x) => x.item === item.id) ? true : false;
                const isEditting = item === editEntry;

                return (
                    <Fragment key={key}>
                        <TableRow>
                            <TableCell>
                                <span style={style}>
                                    {isGroup && <FontAwesomeSvgIcon icon={faFolderOpen} style={{ height: "0.7em", marginRight: 10 }} />}
                                    <span>{item.title}</span>
                                </span>
                            </TableCell>
                            {isEditting ? (
                                <>
                                    {isGroup ? (
                                        <>
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={editGroupToggle} onChange={(e) => setEditGroupToggle(e.target.checked)} />
                                            </TableCell>
                                            <TableCell>-</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>-</TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={editDefaultActive} onChange={(e) => setEditDefaultActive(e.target.checked)} />
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                handleCheckboxChange(item.id, editGroupToggle, editDefaultActive);
                                                setEditEntry(undefined);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                        </InputButton>

                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                setEditEntry(undefined);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                        </InputButton>
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    {isGroup ? (
                                        <>
                                            <TableCell>{renderBool(isGroupActivatable)}</TableCell>
                                            <TableCell>-</TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>-</TableCell>
                                            <TableCell>{renderBool(isDefaultActive)}</TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                setEditGroupToggle(isGroupActivatable);
                                                setEditDefaultActive(isDefaultActive);
                                                setEditEntry(item);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                        </InputButton>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                        {isGroup && item.children.map(renderGroup(level + 1, treeIndex, item.identifier))}
                    </Fragment>
                );
            };

        return gisLayers.map(renderGroup());
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>GIS</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>Groep activeerbaar</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>Laag geactiveerd</TableCell>
                                        <TableCell style={{ minWidth: 85 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderThemes()}</TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    resources: appReducer.resources,
    layers: appReducer.layers,
    activatableResourceGroups: appReducer.activatableResourceGroups,
    defaultActiveResources: appReducer.defaultActiveResources,
});

export default connect(mapStateToProps)(AdminDialogContentGISLayers);

const TableToolbar = () => {
    const theme = useTheme();

    return (
        <Toolbar
            sx={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Typography
                sx={{
                    flex: "1 1 100%",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Lagen
            </Typography>
        </Toolbar>
    );
};
