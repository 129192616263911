import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Box, TextField, styled, useTheme } from "@mui/material";
import {
    getTreeIndexString,
    countVisibleLayers,
    countTotalLayers,
    getFlatLayersArray,
    groupLayers,
    countTotalLayersFiltered,
} from "../../../utils/layerFunctions";
import LayerPropertiesButton from "./LayerPropertiesButton";

import { faGripLines, faEye, faEyeSlash, faChevronRight, faChevronDown } from "@fortawesome/pro-solid-svg-icons";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { setPaneThemes, setPaneBasemap } from "../../../context/AppContext/Reducer";

import LoadingSwitch from "../../generic/LoadingSwitch";
import TagManager from "react-gtm-module";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons";
import InputButton from "../../admin/InputButton";
import { setLayersSearch } from "../../../redux/app/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { configuration } from "../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    display: "flex",
    flexDirection: "column",
}));

const Header = styled(Box)(({ theme }) => ({
    display: "flex",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    backgroundColor: theme.colors.inputBackground,
    borderColor: theme.colors.inputBorder,
    alignItems: "center",
    padding: "16px 18px",
    "& h2": {
        fontSize: "17px",
        marginTop: 0,
        marginBottom: "0",
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
    },
    "& h3": {
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        marginTop: 5,
        marginBottom: "0",
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: 500,
    },
}));

const InputContainer = styled(Box)(() => ({
    flex: 1,
}));

const Container = styled(Box)(() => ({
    flex: 1,
    position: "relative",
}));

const Inner = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
}));

const ButtonContainer = styled(Box)(() => ({
    marginTop: "auto",
    padding: "15px",
}));

const ItemContainer = styled(Box)(({ theme }) => ({
    userSelect: "none",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
    display: "flex",
    padding: "9px 14px 8px 16px",
    placeItems: "center",
    "&:hover": {
        backgroundColor: "#f8f8f8",
    },
}));

const Layer = styled(TitleContainer)(({ theme }) => ({
    borderBottom: "none",
}));

const BaseLayerTitleContainer = styled(TitleContainer)(({ theme }) => ({
    paddingLeft: "32px",
}));

const Title = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    justifyContent: "space-between",
    flex: 1,
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "12px",
    paddingLeft: "16px",
    whiteSpace: "normal",
    lineHeight: "22px",
    verticalAlign: "middle",
}));

const GroupLayerCounter = styled("span")(({ theme }) => ({
    color: theme.colors.placeholderText,
    fontSize: "10px",
    fontWeight: theme.fonts.openSans.regular,
    display: "flex",
    marginLeft: "10px",
}));

const LayerActions = styled(Box)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: theme.fonts.openSans.regular,
    display: "flex",
}));

const DragHandle = styled(Box)(({ theme }) => ({
    marginLeft: "10px",
    fontSize: "16px",
    color: theme.colors.placeholderText,
    "&:hover": {
        color: "#000000",
    },
}));

const MessageContainer = styled(Root)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
}));

const Layers = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const [expandedTreeIndices, setExpandedTreeIndices] = useState({});
    const [filterInputActive, setFilterInputActive] = useState(false);
    const [filter, setFilter] = useState("");
    const [, forceRender] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        forceRender((x) => x + 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.panesState[state.activePane - 1].data?.themes, state.panesState[state.activePane - 1].data?.loaded]);

    const toggleExpandedIndex = (index, isExpanded) => {
        const indices = JSON.parse(JSON.stringify(expandedTreeIndices || {}));
        if (isExpanded) {
            delete indices[index];
            Object.keys(indices).forEach((expandedTreeIndex) => {
                if (expandedTreeIndex.startsWith(index)) {
                    delete indices[expandedTreeIndex];
                }
            });
            const getParentIndex = (index) => index.split("-").slice(0, -1).join("-");
            let parentIndex = getParentIndex(index);
            while (parentIndex) {
                indices[parentIndex] = true;
                parentIndex = getParentIndex(parentIndex);
            }
        } else {
            indices[index] = true;
        }

        setExpandedTreeIndices(indices);
    };

    const isSelectedObjectInGroup = (children, selectedObject) => {
        return children.some((item) => {
            if (item.type === "group") {
                return isSelectedObjectInGroup(item.children, selectedObject);
            } else {
                //if (item.type === "layer") {
                return isSelectedObjectInLayer(item, selectedObject);
            }
        });
    };

    const isSelectedObjectInLayer = (layer, selectedObject) => {
        return selectedObject.objectId && layer.id === selectedObject.objectId.split(".")[0];
    };

    const isWithinZoomLevel = (item) => {
        if (!item.layer || !item.layer.visible) return null;

        const { activePane, panesState } = state;
        const { scale } = panesState[activePane - 1].data;

        if (item.layer.minScale === 0 && item.layer.maxScale === 0) {
            if (!item.layer.sourceJSON) return true;

            if (item.layer.sourceJSON.minScale > 0 && item.layer.sourceJSON.maxScale > 0)
                return scale <= item.layer.sourceJSON.minScale && scale >= item.layer.sourceJSON.maxScale;

            if (item.layer.sourceJSON.minScale === 0 && item.layer.sourceJSON.maxScale > 0) return scale >= item.layer.sourceJSON.maxScale;

            if (item.layer.sourceJSON.minScale > 0 && item.layer.sourceJSON.maxScale === 0) return scale <= item.layer.sourceJSON.minScale;
        } else {
            if (item.layer.minScale > 0 && item.layer.maxScale > 0) return scale <= item.layer.minScale && scale >= item.layer.maxScale;

            if (item.layer.minScale === 0 && item.layer.maxScale > 0) return scale >= item.layer.maxScale;

            if (item.layer.minScale > 0 && item.layer.maxScale === 0) return scale <= item.layer.minScale;
        }

        return true;
    };

    const renderThemes = () => {
        const { activePane, panesState } = state;
        const themes = [...panesState[activePane - 1].data.themes];
        const loaded = panesState[activePane - 1].data.loaded ?? [];
        const { selectedObject } = props;

        const setLayerVisibility = (layerId, visible) => {
            let changed = false;
            const setLayerVisible = (itemOriginal) => {
                const item = { ...itemOriginal };
                if (item.type === "group") {
                    item.children = item.children.map((item) => {
                        return setLayerVisible(item);
                    });
                } else if (item.id === layerId) {
                    if (item.visible !== visible) {
                        item.visible = visible;
                        changed = true;
                        if (visible)
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "select_layer",
                                    layer_id: item.id,
                                    layer_name: item.title,
                                },
                            });
                    }
                }
                return item;
            };

            const themes = [...panesState[activePane - 1].data.themes].map(setLayerVisible);
            if (changed) dispatch(setPaneThemes(activePane, themes));
        };

        const setThemeVisibility = (treeIndex, visible) => {
            const setThemeVisible = (treeIndexOriginal) => (itemOriginal, index) => {
                const treeIndex = [...treeIndexOriginal];
                const item = { ...itemOriginal };
                if (!treeIndex.length) {
                    if (item.type === "layer") {
                        if (visible)
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "select_layer",
                                    layer_id: item.id,
                                    layer_name: item.title,
                                },
                            });

                        return {
                            ...item,
                            visible: visible,
                        };
                    } else {
                        item.children = item.children.map(setThemeVisible(treeIndex));
                    }
                    return item;
                }
                const treeIndexFirst = treeIndex.shift();
                if (index === treeIndexFirst) {
                    item.children = item.children.map(setThemeVisible(treeIndex));
                    return item;
                } else {
                    return itemOriginal;
                }
            };

            const themes = [...panesState[activePane - 1].data.themes].map(setThemeVisible(treeIndex));
            dispatch(setPaneThemes(activePane, themes));
        };

        const isItemLoading = (item) => {
            if (item.type === "group") {
                return item.children.some((x) => isItemLoading(x));
            } else {
                if (!item.visible) return false;
                return loaded.findIndex((x) => x === item.id) === -1;
            }
        };

        const hasArcgisSelectedObject = selectedObject && selectedObject.source === configuration.sources.gis && selectedObject.paneId === activePane;
        const renderGroup =
            (level = 0, prevTreeIndex = [], parentId = 0) =>
            (item, index) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `Layers__renderThemes__renderGroup__${treeIndexString}`;
                const totalLayers = countTotalLayers(item);
                const filteredLayers = countTotalLayersFiltered(item, props.searchQuery);
                const visibleLayers = countVisibleLayers(item);
                const isVisible = visibleLayers > 0;
                const isGroup = item.type === "group";
                const isExpanded = item.expanded === true;
                const style = {
                    paddingLeft: `${16 * (level + 1)}px`,
                };
                const selectedObjectInGroup = Boolean(
                    hasArcgisSelectedObject && isGroup && !isExpanded && isSelectedObjectInGroup(item.children, selectedObject)
                );
                const selectedObjectInLayer = Boolean(hasArcgisSelectedObject && !isGroup && isSelectedObjectInLayer(item, selectedObject));
                if (selectedObjectInGroup) {
                    style.backgroundColor = "rgba(224, 44, 22, 0.1)";
                }
                if (selectedObjectInLayer) {
                    style.backgroundColor = "rgba(224, 44, 22, 0.2)";
                }
                const canActivateGroup = props.activatableResourceGroups.some((x) => x.resource === "ArcGis" && x.item === item.id);
                const withinZoom = isWithinZoomLevel(item);
                const isLoading = isItemLoading(item);

                if (Boolean(props.searchQuery) && filteredLayers === 0) return null;

                let dragHandleStyle = {};
                if (Boolean(props.searchQuery))
                    dragHandleStyle = {
                        color: theme.colors.disabled,
                        pointerEvents: "none",
                        cursor: "unset !important",
                    };

                return (
                    <Draggable key={item.identifier} draggableId={`${item.identifier}`} index={index}>
                        {(provided, snapshot) => (
                            <ItemContainer key={key} ref={provided.innerRef} {...provided.draggableProps}>
                                {Boolean(isGroup) ? (
                                    <div>
                                        <TitleContainer
                                            onClick={() => {
                                                toggleExpandedIndex(treeIndexString, isExpanded);
                                                item.expanded = !item.expanded;
                                            }}
                                            sx={{ ...style, cursor: "pointer" }}
                                        >
                                            {canActivateGroup ? (
                                                <LoadingSwitch
                                                    active={isVisible}
                                                    loading={isLoading}
                                                    onChange={() => setThemeVisibility(treeIndex, !isVisible)}
                                                />
                                            ) : (
                                                <div style={{ display: "flex", width: 16 }}>
                                                    <FontAwesomeIcon
                                                        icon={isExpanded ? faChevronDown : faChevronRight}
                                                        style={{ height: "0.7em", cursor: "pointer" }}
                                                    />
                                                </div>
                                            )}
                                            <Title>
                                                <span>{item.title}</span>
                                                <GroupLayerCounter onClick={(e) => e.stopPropagation()}>
                                                    {Boolean(props.searchQuery) ? `${visibleLayers}/${filteredLayers}` : `${visibleLayers}/${totalLayers}`}
                                                    <DragHandle sx={dragHandleStyle} {...provided.dragHandleProps}>
                                                        <FontAwesomeIcon icon={faGripLines} />
                                                    </DragHandle>
                                                </GroupLayerCounter>
                                            </Title>
                                        </TitleContainer>

                                        <Droppable droppableId={treeIndexString} type={`group-${item.identifier}`}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {isExpanded && item.children.map(renderGroup(level + 1, treeIndex, item.identifier))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                ) : (
                                    <Layer sx={style}>
                                        <LoadingSwitch active={isVisible} loading={isLoading} onChange={() => setLayerVisibility(item.id, !isVisible)} />
                                        <Title component={"label"} htmlFor={"inputId"}>
                                            {item.title}
                                        </Title>
                                        <LayerActions component={"span"} onClick={(e) => e.stopPropagation()}>
                                            {Boolean(item.layer) && item.layer.visible && (
                                                <div style={{ marginRight: "10px" }}>
                                                    {isVisible && (withinZoom ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />)}
                                                </div>
                                            )}
                                            <LayerPropertiesButton item={item} isVisible={isVisible} />
                                            <DragHandle sx={dragHandleStyle} {...provided.dragHandleProps}>
                                                <FontAwesomeIcon icon={faGripLines} />
                                            </DragHandle>
                                        </LayerActions>
                                    </Layer>
                                )}
                            </ItemContainer>
                        )}
                    </Draggable>
                );
            };

        return themes.map(renderGroup());
    };

    const handleDragEnd = (result) => {
        const { activePane, panesState } = state;
        const themes = [...panesState[activePane - 1].data.themes];
        if (!result.destination) return;

        if (result.type !== "DEFAULT") {
            if (result.source.droppableId !== result.destination.droppableId) return;

            const levels = result.source.droppableId.split("-").map((accessor) => Number(accessor.replace("[", "").replace("]", "")));
            let subList = themes;

            for (let i = 0; i < levels.length; i++) {
                subList = subList[levels[i]].children;
            }

            moveArrayElement(subList, result.source.index, result.destination.index);
        } else {
            moveArrayElement(themes, result.source.index, result.destination.index);
        }

        dispatch(setPaneThemes(activePane, themes));
    };

    const moveArrayElement = (arr, old_index, new_index) => {
        while (old_index < 0) old_index += arr.length;
        while (new_index < 0) new_index += arr.length;

        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) arr.push(undefined);
        }

        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    const renderBasemaps = () => {
        const { basemapOptions } = props;
        const { activePane, panesState } = state;
        const { basemapId } = panesState[activePane - 1].data;

        const treeIndex = ["x"];
        const treeIndexString = getTreeIndexString(treeIndex);
        const isExpanded = expandedTreeIndices && expandedTreeIndices[treeIndexString];

        // const basemap = basemapOptions.find((x) => {
        //     return x.id === basemapId;
        // });

        return (
            <ItemContainer>
                <TitleContainer onClick={() => toggleExpandedIndex(treeIndexString, isExpanded)} style={{ cursor: "pointer" }}>
                    <div style={{ display: "flex", width: 16 }}>
                        <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} style={{ height: "0.7em", cursor: "pointer" }} />
                    </div>
                    <Title>
                        <span>Onderleggers</span>
                        <GroupLayerCounter>
                            {Boolean(basemapId) ? 1 : 0}/{basemapOptions.length}
                        </GroupLayerCounter>
                    </Title>
                </TitleContainer>

                {isExpanded && (
                    <ItemContainer>
                        {basemapOptions.map(({ id, name }) => {
                            const inputId = `checkbox_${id}`;

                            return (
                                <BaseLayerTitleContainer key={`${id}`}>
                                    <LoadingSwitch
                                        active={basemapId === id}
                                        onChange={() =>
                                            basemapId === id ? dispatch(setPaneBasemap(activePane, null)) : dispatch(setPaneBasemap(activePane, id))
                                        }
                                    />
                                    <Title component={"label"} htmlFor={inputId}>
                                        {name}
                                    </Title>
                                </BaseLayerTitleContainer>
                            );
                        })}
                    </ItemContainer>
                )}
            </ItemContainer>
        );
    };

    const restoreDefaultOrder = () => {
        const { activePane, panesState } = state;
        const order = props.defaultOrder;
        const themes = [...panesState[activePane - 1].data.themes];
        const flattened = getFlatLayersArray(themes);
        const sortedLayers = [];

        for (let i = 0, len = flattened.length; i < len; i++) {
            const layer = flattened[i];
            const index = order.findIndex((x) => x.id === layer.id);
            const visible = order.find((x) => x.id === layer.id)?.visible ?? false;

            if (index >= 0) {
                sortedLayers[index] = layer;
                sortedLayers[index].title = layer.sourceJson.title;
                sortedLayers[index].visible = visible;
            }
        }

        const groups = groupLayers(sortedLayers);
        dispatch(setPaneThemes(activePane, groups));
        dispatch(setPaneBasemap(activePane, "topo-vector"));
    };

    const { title, activeProject } = props;
    const resourceAccess = activeProject.resources.find((x) => x.Name === "ArcGis");

    return Boolean(resourceAccess) ? (
        <Root>
            <Header>
                {filterInputActive ? (
                    <InputContainer>
                        <TextField
                            id="name"
                            label={title}
                            placeholder={"Filter"}
                            type="text"
                            fullWidth
                            variant={"standard"}
                            autoComplete="off"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                props.onSetFilter(filter);
                                                setFilterInputActive(false);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faSearch} style={{ fontSize: "1rem" }} />
                                        </InputButton>

                                        <InputButton
                                            size="small"
                                            onClick={(e) => {
                                                setFilterInputActive(false);
                                                e.stopPropagation();
                                            }}
                                        >
                                            <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                        </InputButton>
                                    </>
                                ),
                            }}
                        />
                    </InputContainer>
                ) : (
                    <>
                        <div>
                            <h2>{title}</h2>
                            {Boolean(props.searchQuery) && (
                                <h3>
                                    Actieve filter: {props.searchQuery}{" "}
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            fontSize: "1rem",
                                        }}
                                        onClick={() => props.onSetFilter("")}
                                    />
                                </h3>
                            )}
                        </div>
                        <FontAwesomeIcon
                            style={{
                                marginLeft: "auto",
                                cursor: "pointer",
                            }}
                            icon={farSearch}
                            onClick={() => setFilterInputActive(true)}
                        />
                    </>
                )}
            </Header>

            <Container>
                <Inner>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="group">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {renderThemes()}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {renderBasemaps()}

                    <ButtonContainer>
                        <Button variant="contained" color="primary" size="large" fullWidth disableElevation onClick={() => restoreDefaultOrder()}>
                            Standaardvolgorde herstellen
                        </Button>
                    </ButtonContainer>
                </Inner>
            </Container>
        </Root>
    ) : (
        <MessageContainer>U heeft geen toegang tot GIS lagen in dit project.</MessageContainer>
    );
};

const mapStateToProps = ({ appReducer, webMapsReducer, selectionReducer, projectReducer }) => ({
    activatableResourceGroups: appReducer.activatableResourceGroups,
    searchQuery: appReducer.layersSearchQuery,
    basemapOptions: webMapsReducer.basemapOptions,
    defaultOrder: webMapsReducer.defaultOrder,
    selectedObject: selectionReducer.selectedObject,
    activeProject: projectReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => ({
    onSetFilter: (query) => dispatch(setLayersSearch(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layers);
