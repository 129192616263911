import { Box, Chip, Typography, styled } from "@mui/material";

const BaseChip = styled(Chip)(() => ({
    width: "100%",
    borderRadius: 6,
    fontWeight: 500,
    textTransform: "uppercase",
}));

const NewChip = styled(BaseChip)(() => ({
    backgroundColor: "#8dd9c8",
}));

const UpdateChip = styled(BaseChip)(() => ({
    backgroundColor: "#7fb1eb",
}));

const CorrectionChip = styled(BaseChip)(() => ({
    backgroundColor: "#d9d3d3",
}));

export enum ContentUpdateType {
    New,
    Update,
    Correction,
    Unknown,
}

export interface ContentUpdateProps {
    content: string;
    type: ContentUpdateType;
    description?: string;
}

const ContentUpdate = ({ content: title, description, type }: ContentUpdateProps) => {
    const renderChip = () => {
        switch (type) {
            case ContentUpdateType.New:
                return <NewChip label="Nieuw" size="small" />;
            case ContentUpdateType.Update:
                return <UpdateChip label="Update" size="small" />;
            case ContentUpdateType.Correction:
                return <CorrectionChip label="Correctie" size="small" />;
            default:
                console.error(`Ongeldig update type voor inhoudsupdate '${title}'`);
                return <UpdateChip label="Update" size="small" />;
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Box
                sx={{
                    margin: "5px",
                    width: 100,
                }}
            >
                {renderChip()}
            </Box>
            <Box
                sx={{
                    margin: "5px",
                    flex: 1,
                }}
            >
                <Typography>{title}</Typography>
                {description && (
                    <Typography variant="caption" component="span">
                        {description}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ContentUpdate;
