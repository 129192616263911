import { useContext, useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { PanesOrientation } from "../../../context/AppContext/State";
import { setPanes } from "../../../context/AppContext/Reducer";
import { SplitPaneContext } from "../../../context/SplitPaneContext/SplitPaneContextProvider";

import TwoHorizontalIcon from "../../svg/splitscreen/TwoHorizontalIcon";
import TwoVerticalIcon from "../../svg/splitscreen/TwoVerticalIcon";
import ThreeHorizontalIcon from "../../svg/splitscreen/ThreeHorizontalIcon";
import ThreeVerticalIcon from "../../svg/splitscreen/ThreeVerticalIcon";
import ThreeMixedIcon from "../../svg/splitscreen/ThreeMixedIcon";
import FourDefaultIcon from "../../svg/splitscreen/FourDefaultIcon";
import OneDefaultIcon from "../../svg/splitscreen/OneDefaultIcon";
import StyledTooltip from "../../generic/StyledTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const quickToggles = [
    {
        id: "1-horizontal",
        icon: OneDefaultIcon,
        count: 1,
        orientation: PanesOrientation.HORIZONTAL,
        tooltip: "1 Venster",
    },
    {
        id: "2-horizontal",
        icon: TwoHorizontalIcon,
        count: 2,
        orientation: PanesOrientation.HORIZONTAL,
        tooltip: "2 Vensters - Horizontaal",
    },
    {
        id: "2-vertical",
        icon: TwoVerticalIcon,
        count: 2,
        orientation: PanesOrientation.VERTICAL,
        tooltip: "2 Vensters - Verticaal",
    },
    {
        id: "3-horizontal",
        icon: ThreeHorizontalIcon,
        count: 3,
        orientation: PanesOrientation.HORIZONTAL,
        tooltip: "3 Vensters - Horizontaal",
    },
    {
        id: "3-vertical",
        icon: ThreeVerticalIcon,
        count: 3,
        orientation: PanesOrientation.VERTICAL,
        tooltip: "3 Vensters - Verticaal",
    },
    {
        id: "3-mixed",
        icon: ThreeMixedIcon,
        count: 3,
        orientation: PanesOrientation.MIXED,
        tooltip: "3 Vensters - Mixed",
    },
    {
        id: "4-default",
        icon: FourDefaultIcon,
        count: 4,
        orientation: PanesOrientation.HORIZONTAL,
        tooltip: "4 Vensters",
    },
];

const SplitScreenQuickToggle = () => {
    const { state: appState, dispatch } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);
    const [showOptions, setShowOptions] = useState(false);

    const renderOptions = () => {
        const { panesCount, panesOrientation } = appState;

        return quickToggles.map(({ count, orientation, icon: Icon, tooltip }, index) => {
            const isSelected = count === panesCount && orientation === panesOrientation;

            if (isSelected) return null;

            return (
                <StyledTooltip key={index} placement={"right"} title={tooltip}>
                    <Box
                        sx={{
                            boxSizing: "border-box",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 32,
                            width: 32,
                            maxWidth: "32px",
                            maxHeight: "32px",
                            padding: "8px",
                            cursor: "pointer",
                            "&:hover": {
                                color: "white",
                                backgroundColor: "#1F293C",
                            },
                            "&.disabled": {
                                backgroundColor: "#F8F8F8",
                                pointerEvents: "none",
                            },
                        }}
                        onClick={() => {
                            dispatch(setPanes(count, orientation));
                            setShowOptions(false);
                        }}
                    >
                        <Icon className={""} style={{ width: 16, height: 16 }} />
                    </Box>
                </StyledTooltip>
            );
        });
    };

    const renderActiveOption = () => {
        const { panesCount, panesOrientation } = appState;
        const activeOption = quickToggles.find((x) => x.count === panesCount && x.orientation === panesOrientation)!;
        const Icon = activeOption.icon;

        return (
            <StyledTooltip title={activeOption.tooltip}>
                <Box
                    sx={{
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 32,
                        width: 32,
                        maxWidth: "32px",
                        maxHeight: "32px",
                        padding: "8px",
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#1F293C",
                        "&:hover": {
                            color: "white",
                            backgroundColor: "#1F293C",
                        },
                        "&.disabled": {
                            backgroundColor: "#F8F8F8",
                            pointerEvents: "none",
                        },
                    }}
                >
                    <Icon className={""} style={{ width: 16, height: 16 }} />
                </Box>
            </StyledTooltip>
        );
    };

    const renderToolbar = () => {
        const quickToggle = Boolean(JSON.parse(localStorage.getItem("split-screen-quick-toggle") ?? "true"));
        if (!quickToggle || paneState.id !== 1) return null;

        return (
            <ClickAwayListener onClickAway={() => setShowOptions(false)}>
                <Box
                    sx={{
                        pointerEvents: "all",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        boxShadow: "0 0 2px #D2DDE5",
                        userSelect: "none",
                        position: "relative",
                    }}
                >
                    {renderActiveOption()}

                    <StyledTooltip title={"Layout wijzigen..."} placement={"right"}>
                        <Box
                            sx={{
                                boxSizing: "border-box",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 32,
                                width: 32,
                                maxWidth: "32px",
                                maxHeight: "32px",
                                padding: "8px",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#1F293C",
                                },
                                "&.disabled": {
                                    backgroundColor: "#F8F8F8",
                                    pointerEvents: "none",
                                },
                            }}
                            onClick={() => setShowOptions((x) => !x)}
                        >
                            {showOptions ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        </Box>
                    </StyledTooltip>

                    {showOptions && (
                        <Box
                            sx={{
                                pointerEvents: "all",
                                backgroundColor: "#FFFFFF",
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: "0 0 2px #D2DDE5",
                                userSelect: "none",
                                position: "absolute",
                                top: 32,
                                right: 0,
                            }}
                        >
                            {renderOptions()}
                        </Box>
                    )}
                </Box>
            </ClickAwayListener>
        );
    };

    return renderToolbar();
};

export default SplitScreenQuickToggle;
