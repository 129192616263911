import { Component } from "react";
import { Box, Autocomplete, Button, FormGroup, TextField, styled } from "@mui/material";

const Container = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(34,34,34,.9)",
    padding: "8px",
    width: "100%",
    color: "#FFFFFF",
    "& label.Mui-focused": {
        color: "white",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
        },
        "&:hover fieldset": {
            borderColor: "white",
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
        },
    },
}));

const Row = styled(Box)(() => ({
    marginBottom: "20px",
    "&:last-child": { marginTop: "auto", marginBottom: 0 },
}));

const preserveFunctionName = (fn) => {
    if (process.env.NODE_ENV === "development") return fn;

    const fnString = fn.toString();
    const fnName = fn.name;
    const newFnString = fnString.replace(/^function/, fnName);
    // eslint-disable-next-line no-new-func
    const fnGenerator = new Function(`return function ${newFnString}`);
    return fnGenerator();
};

// Written as a class component instead of a functional hook component due to having stale state in hook components when using events.
class QueryFilterExtensionPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            rawAttributes: [],
            attribute: null,
            attributeInputValue: "",
            values: [],
            value: [],
            inputValue: "",
            elements: {},
            elementCount: 0,
        };

        // https://forge.autodesk.com/en/docs/viewer/v6/tutorials/propdb-queries/
        // The query function must be named userFunction! Considering we need multiple of these we use container objects that define different versions of this function.
        this.getAttributeNamesContainer = {
            userFunction(pdb, data) {
                const foundAttributeIdsByKey = {};
                const { modelId } = data;

                pdb.enumAttributes((id, attrDef, attrRaw) => {
                    const name = attrDef.displayName;
                    foundAttributeIdsByKey[name] = foundAttributeIdsByKey[name] || [];
                    foundAttributeIdsByKey[name].push({
                        modelId: modelId,
                        id: id,
                        name: name,
                    });
                });

                return foundAttributeIdsByKey;
            },
        };

        this.getAttributeValuesContainer = {
            userFunction(pdb, data) {
                const propertyValues = new Set();
                const { properties, modelId } = data;

                pdb.enumObjects((dbId) => {
                    pdb.enumObjectProperties(dbId, (attrId, valId) => {
                        const property = properties.find((p) => p.id === attrId && p.modelId === modelId);
                        if (!property) {
                            return;
                        }

                        const thisValue = pdb.getAttrValue(attrId, valId).toString();
                        propertyValues.add(thisValue);
                    });
                });

                return Array.from(propertyValues).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
            },
        };

        this.getElementsContainer = {
            userFunction(pdb, data) {
                const foundObjectsByModelUrn = {};
                const { values, properties, modelId, modelUrn } = data;
                pdb.enumObjects((dbId) => {
                    pdb.enumObjectProperties(dbId, (attrId, valId) => {
                        const property = properties.find((p) => p.id === attrId && p.modelId === modelId);
                        if (!property) {
                            return false;
                        }

                        const thisValue = pdb.getAttrValue(attrId, valId).toString();
                        let pass = true;
                        if (values.length > 0) {
                            pass = false;

                            for (let i = 0, len = values.length; i < len; i++) {
                                if (values[i] === thisValue) {
                                    pass = true;
                                    break;
                                }
                            }
                        }

                        if (pass) {
                            foundObjectsByModelUrn[modelUrn] = foundObjectsByModelUrn[modelUrn] || [];
                            foundObjectsByModelUrn[modelUrn].push(dbId);
                        }
                    });
                });

                return foundObjectsByModelUrn;
            },
        };

        this.getAttributeNamesContainer.userFunction = preserveFunctionName(this.getAttributeNamesContainer.userFunction);
        this.getAttributeValuesContainer.userFunction = preserveFunctionName(this.getAttributeValuesContainer.userFunction);
        this.getElementsContainer.userFunction = preserveFunctionName(this.getElementsContainer.userFunction);
    }

    componentDidMount() {
        const { viewer } = this.props;

        viewer.addEventListener(window.Autodesk.Viewing.MODEL_ADDED_EVENT, this.getAttributes.bind(this));
        viewer.addEventListener(window.Autodesk.Viewing.MODEL_REMOVED_EVENT, this.getAttributes.bind(this));
        viewer.addEventListener(window.Autodesk.Viewing.MODEL_ADDED_EVENT, this.checkHasVisibleModels.bind(this));
        viewer.addEventListener(window.Autodesk.Viewing.MODEL_REMOVED_EVENT, this.checkHasVisibleModels.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        const attributeSelectionHasChanged = this.state.attribute !== prevState.attribute;
        const attributesHasChanged = this.state.attributes !== prevState.attributes;
        if (attributeSelectionHasChanged || attributesHasChanged) this.getPossibleValues();
        if (attributesHasChanged) this.getElements();

        const possibleValuesHasChanged = this.state.values !== prevState.values;
        if (possibleValuesHasChanged) this.getElements();

        const valueTextHasChanged = this.state.value !== prevState.value;
        if (valueTextHasChanged) this.getElements();
    }

    checkHasVisibleModels = () => {
        const { setVisible, viewer } = this.props;
        const visibleModels = viewer.getVisibleModels();
        const shouldBeVisible = Boolean(Array.isArray(visibleModels) && visibleModels.length);
        setVisible(shouldBeVisible);
    };

    getAttributes = () => {
        const { viewer } = this.props;
        const models = viewer.getVisibleModels();
        const promises = models.map((model) => {
            const data = {
                modelId: model.id,
            };

            return model.getPropertyDb().executeUserFunction(this.getAttributeNamesContainer.userFunction, data);
        });

        Promise.all(promises).then((attributes) => {
            const attributeSuggestionsSet = new Set();
            const rawAttributes = new Set();
            attributes.forEach((attribute) => {
                Object.keys(attribute).forEach((key) => {
                    if (!attribute) return;
                    attributeSuggestionsSet.add(key);
                    rawAttributes.add(attribute[key]);
                });
            });
            const attributeSuggestions = Array.from(attributeSuggestionsSet).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

            this.setState({ attributes: attributeSuggestions, rawAttributes: Array.from(rawAttributes).flat() });
        });
    };

    getPossibleValues = () => {
        if (!this.state.attribute) return;

        const { viewer } = this.props;
        const models = viewer.getVisibleModels();
        const promises = models.map((model) => {
            const data = {
                properties: this.state.rawAttributes.filter((x) => x.name === this.state.attribute),
                modelId: model.id,
            };

            return model.getPropertyDb().executeUserFunction(this.getAttributeValuesContainer.userFunction, data);
        });

        Promise.all(promises).then((values) => {
            const uniqueValues = values
                .flat()
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
            this.setState({ values: uniqueValues });
        });
    };

    getElements = () => {
        if (!this.state.attribute) {
            this.setState({ elementCount: 0 });
            return;
        }

        const { viewer } = this.props;
        const models = viewer.getVisibleModels();
        const promises = models.map((model) => {
            const data = {
                properties: this.state.rawAttributes.filter((x) => x.name === this.state.attribute),
                values: this.state.value,
                modelId: model.id,
                modelUrn: model.getData().urn,
            };

            return model.getPropertyDb().executeUserFunction(this.getElementsContainer.userFunction, data);
        });

        Promise.all(promises).then((values) => {
            const elementsPerModelUrn = values.flat();
            let count = 0;

            values.forEach((modelUrn) => {
                Object.keys(modelUrn).forEach((key) => {
                    count += modelUrn[key]?.length || 0;
                });
            });

            this.setState({ elements: elementsPerModelUrn, elementCount: count });
        });
    };

    filterElements = () => {
        const { onFilter } = this.props;
        const { elements } = this.state;
        const highlightIdsKeys = {};

        elements.forEach((modelElement) =>
            Object.keys(modelElement).forEach((modelUrn) => {
                const dbIds = modelElement[modelUrn];
                dbIds.forEach((dbId) => {
                    const highlightId = `${modelUrn}.${dbId}`;
                    highlightIdsKeys[highlightId] = true;
                });
            })
        );

        onFilter(Object.keys(highlightIdsKeys));
    };

    reset = () => {
        const { onReset } = this.props;
        onReset();
    };

    render = () => {
        const { attribute, attributeInputValue, attributes, value, inputValue, values, elementCount } = this.state;

        return (
            <Container>
                <Row>
                    <Autocomplete
                        id="attribute"
                        value={attribute}
                        size="small"
                        onChange={(event, newValue) => {
                            this.setState({ attribute: newValue });
                        }}
                        inputValue={attributeInputValue}
                        onInputChange={(event, newInputValue) => {
                            this.setState({ attributeInputValue: newInputValue });
                        }}
                        options={attributes}
                        // getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Op welke attribuut wenst u te filteren?" variant="outlined" />}
                        noOptionsText={"Geen opties beschikbaar"}
                    />
                </Row>

                <Row>
                    <Autocomplete
                        multiple
                        id="value"
                        size="small"
                        value={value}
                        onChange={(event, newValue) => {
                            this.setState({ value: newValue });
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            this.setState({ inputValue: newInputValue });
                        }}
                        options={values}
                        // getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Op welke waarden wenst u te filteren?" variant="outlined" />}
                        noOptionsText={"Geen opties beschikbaar"}
                    />
                </Row>

                <Row>
                    <FormGroup row style={{ justifyContent: "space-between" }}>
                        <Button variant="contained" color="primary" size="small" onClick={() => this.filterElements()}>
                            Filter {elementCount} {elementCount === 1 ? "element" : "elementen"}
                        </Button>
                        <Button variant="outlined" size="small" onClick={() => this.reset()}>
                            Reset
                        </Button>
                    </FormGroup>
                </Row>
            </Container>
        );
    };
}

export default QueryFilterExtensionPanel;
