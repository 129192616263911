import { Component } from "react";
import { Box, Button, styled } from "@mui/material";
import CommentIcon from "../svg/appControls/CommentIcon";
import * as feedbackConnector from "../../connectors/feedback";
import CheckIcon from "../svg/appControls/CheckIcon";
import CrossIcon from "../svg/appControls/CrossIcon";

const Root = styled(Box)(({ theme }) => ({
    position: "relative",
    pointerEvents: "all",
    cursor: "pointer",
    backgroundColor: theme.colors.white,
    borderRadius: "50%",
    display: "flex",
    width: "36px",
    height: "36px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `0 6px 18px ${theme.colors.black}70`,
}));

const PopupContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: "100%",
    bottom: "100%",
    boxShadow: `0 6px 18px ${theme.colors.black}70`,
    color: theme.colors.textColor,
    cursor: "initial",
    width: "472px",
}));

const PopupTitle = styled(Box)(({ theme }) => ({
    padding: "16px 18px",
    fontSize: "17px",
    marginTop: 0,
    marginBottom: 0,
    fontFamily: theme.fonts.raleway.fontFamily,
    fontWeight: theme.fonts.raleway.bold,
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
    backgroundColor: theme.colors.inputBackground,
}));

const PopupContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    padding: "16px",
}));

const FormRow = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    marginBottom: "13px",
}));

const FormCategory = styled("label")(() => ({
    flex: 1,
    "&>input": {
        marginRight: "8px",
    },
}));

const Divider = styled("hr")(({ theme }) => ({
    height: "1px",
    backgroundColor: theme.colors.inputBorder,
    border: "none",
}));

const Textarea = styled("textarea")(({ theme }) => ({
    resize: "none",
    width: "424px",
    height: "88px",
    padding: "4px 8px",
    backgroundColor: theme.colors.inputBackground,
    border: `1px solid ${theme.colors.inputBorder}`,
    marginTop: "8px",
    color: theme.fonts.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
}));

const ActionsContainer = styled(Box)(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
}));

const StatusContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "320px",
}));

const StatusIconContainer = styled(Box)(() => ({
    marginBottom: "31px",
}));

const SuccessMessage = styled(Box)(({ theme }) => ({
    color: theme.colors.success,

    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    fontSize: "16px",
    "& > div": {
        marginBottom: "4px",
        textAlign: "center",
    },
}));

const FailedMessage = styled(Box)(({ theme }) => ({
    color: theme.colors.error,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    fontSize: "16px",
    "& > div": {
        marginBottom: "4px",
        textAlign: "center",
    },
}));

const statuses = {
    hidden: "hidden",
    ready: "ready",
    sending: "sending",
    success: "success",
    failed: "failed",
};

class CommentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: statuses.hidden,
            category: "",
            message: "",
        };
    }

    toggleVisibility = () => {
        const status = Boolean(this.state.status === statuses.hidden) ? statuses.ready : statuses.hidden;
        this.setState({ status });
    };

    stopPropagation = (event) => {
        event.stopPropagation();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log('CommentForm - handleSubmit state: ', this.state)

        this.setState({ status: statuses.sending });

        const { status, category, message } = this.state;

        if (status !== statuses.ready) {
            return;
        }

        feedbackConnector
            .submitFeedback(category, message)
            .then(() => {
                this.setState({
                    status: statuses.success,
                });
            })
            .catch((error) => {
                this.setState({
                    status: statuses.failed,
                });
                // console.error("CommentForm - handleSubmit error: ", error);
            })
            .finally(() => {
                const hidePopup = () => {
                    this.setState({
                        status: statuses.hidden,
                    });
                };
                setTimeout(hidePopup, 2500);
            });
    };

    render() {
        const { status, category, message } = this.state;

        const isVisible = Boolean(status !== statuses.hidden);
        const isReady = Boolean(status === statuses.ready);
        const isSending = Boolean(status === statuses.sending);
        const isDisabled = Boolean(status === statuses.sending);
        const isSuccess = Boolean(status === statuses.success);
        const isFailed = Boolean(status === statuses.failed);

        return (
            <Root style={isVisible ? { borderTopRightRadius: "0" } : null} onClick={this.toggleVisibility}>
                <CommentIcon selected={isVisible} />
                {Boolean(isVisible) &&
                    (Boolean(isReady || isSending) ? (
                        <PopupContainer>
                            <form onClick={this.stopPropagation}>
                                <PopupTitle>Feedback</PopupTitle>
                                <PopupContent>
                                    <FormRow>Waarover gaat jouw feedback?</FormRow>
                                    <FormRow>
                                        <FormCategory>
                                            <input
                                                type="radio"
                                                name="category"
                                                value="app"
                                                checked={category === "app"}
                                                onChange={(e) => this.setState({ category: e.target.value })}
                                                required
                                            />
                                            <span>De werking of interface</span>
                                        </FormCategory>
                                        <FormCategory>
                                            <input
                                                type="radio"
                                                name="category"
                                                value="data"
                                                checked={category === "data"}
                                                onChange={(e) => this.setState({ category: e.target.value })}
                                                required
                                            />
                                            <span>Een model of document</span>
                                        </FormCategory>
                                    </FormRow>
                                    <Divider />
                                    <FormRow>
                                        <label>
                                            <div>Feedback</div>
                                            <Textarea
                                                placeholder="Typ hier uw feedback"
                                                name="message"
                                                value={message}
                                                onChange={(e) => this.setState({ message: e.target.value })}
                                                required
                                            />
                                        </label>
                                    </FormRow>
                                    <ActionsContainer>
                                        <Button color="primary" variant="contained" disabled={isDisabled} onClick={this.handleSubmit}>
                                            Verzenden
                                        </Button>
                                    </ActionsContainer>
                                </PopupContent>
                            </form>
                        </PopupContainer>
                    ) : Boolean(isSuccess) ? (
                        <PopupContainer>
                            <PopupTitle>Feedback</PopupTitle>
                            <PopupContent>
                                <StatusContainer>
                                    <StatusIconContainer>
                                        <CheckIcon />
                                    </StatusIconContainer>
                                    <SuccessMessage>
                                        <div>Jouw feedback werd verzonden.</div>
                                        <div>Bedankt!</div>
                                    </SuccessMessage>
                                </StatusContainer>
                            </PopupContent>
                        </PopupContainer>
                    ) : (
                        Boolean(isFailed) && (
                            <PopupContainer>
                                <PopupTitle>Feedback</PopupTitle>
                                <PopupContent>
                                    <StatusContainer>
                                        <StatusIconContainer>
                                            <CrossIcon />
                                        </StatusIconContainer>
                                        <FailedMessage>
                                            <div>Oeps er ging iets mis.</div>
                                            <div>Probeer het later nog eens.</div>
                                        </FailedMessage>
                                    </StatusContainer>
                                </PopupContent>
                            </PopupContainer>
                        )
                    ))}
            </Root>
        );
    }
}

export default CommentForm;
