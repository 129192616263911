import { Fragment, useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    useTheme,
} from "@mui/material";
import { faCheck, faFolderOpen, faPencil, faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import InputButton from "../InputButton";
import {
    createActivatableResourceGroup,
    fetchActivatableResourceGroups,
    fetchBIMResources,
    removeActivatableResourceGroup,
} from "../../../connectors/admin/resources";
import { getTreeIndexString } from "../../../utils/modelFunctions";
import differenceBy from "lodash/differenceBy";

export interface AdminDialogContentBIMModelsProps {
    activatableResourceGroups: any;
    models: any;
    onClose: () => void;
}

const RESOURCE_KEY = "BIM360";

const AdminDialogContentBIMModels = ({ activatableResourceGroups, models, onClose }: AdminDialogContentBIMModelsProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fetched, setFetched] = useState(false);
    const [bimModels, setBimModels] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [resourceGroups, setResourceGroups] = useState<any[]>([]);
    const [expandedIndices, setExpandedIndices] = useState({});
    const [editEntry, setEditEntry] = useState<any>();
    const [editGroupToggle, setEditGroupToggle] = useState<boolean>(false);

    useEffect(() => {
        Promise.all([fetchBIMResources(), fetchActivatableResourceGroups()]).then(() => setFetched(true));
    }, []);

    useEffect(() => {
        if (!fetched) return;
        setBimModels(models.map((x: any) => ({ ...x })));

        const data = activatableResourceGroups.filter((x: any) => x.resource === RESOURCE_KEY);
        setData(data);
        setResourceGroups(data.map((x: any) => ({ ...x })));
        setIsLoading(false);
    }, [models, activatableResourceGroups, fetched]);

    const toggleExpandedIndex = (index: any, isExpanded: any) => {
        const indices = JSON.parse(JSON.stringify(expandedIndices || {}));
        if (isExpanded) {
            delete indices[index];
            Object.keys(indices).forEach((expandedIndex) => {
                if (expandedIndex.startsWith(index)) {
                    delete indices[expandedIndex];
                }
            });

            const getParentIndex = (index: any) => index.split("-").slice(0, -1).join("-");
            let parentIndex = getParentIndex(index);
            while (parentIndex) {
                indices[parentIndex] = true;
                parentIndex = getParentIndex(parentIndex);
            }
        } else {
            indices[index] = true;
        }

        setExpandedIndices(indices);
    };

    const onSave = () => {
        setIsLoading(true);
        const promises = [];
        const toAdd = differenceBy(resourceGroups, data, "item");
        const toDelete = differenceBy(data, resourceGroups, "item");

        for (let i = 0; i < toAdd.length; i++) {
            const entry = toAdd[i];
            promises.push(createActivatableResourceGroup(entry));
        }

        for (let i = 0; i < toDelete.length; i++) {
            const entry = toDelete[i];
            promises.push(removeActivatableResourceGroup(entry.id));
        }

        Promise.all(promises);
    };

    const onReset = () => {
        setResourceGroups(data.map((x: any) => ({ ...x })));
    };

    const hasChanges = (): boolean => {
        if (data.length !== resourceGroups.length) return true;
        for (let i = 0; i < data.length; i++) {
            const originalEntry = data[i];
            const stillExists = resourceGroups.find((x) => x.item === originalEntry.item && x.resource === RESOURCE_KEY);

            if (!stillExists) return true;
        }

        return false;
    };

    const renderBool = (key: string): string => {
        const value = resourceGroups.find((x) => x.item === key);
        if (value) return "Ja";
        return "Neen";
    };

    const handleGroupToggleChange = (key: string, canToggle: boolean) => {
        let data;
        if (canToggle) {
            data = [...resourceGroups];
            data.push({
                project: "OWV - Lantis", // TODO: Replace??
                resource: RESOURCE_KEY,
                item: key,
            });
        } else {
            data = resourceGroups.filter((x) => x.item !== key);
        }

        setResourceGroups(data);
    };

    const renderDocuments = () => {
        const documents = bimModels;

        const renderGroup =
            (level = 0, prevTreeIndex: any[] = []) =>
            (item: any, index: any) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `Layers__renderDocuments__renderGroup__${treeIndexString}`;
                const isGroup = !!item.folder;
                const style = {
                    paddingLeft: 16 * level,
                    display: "flex",
                };

                const renderFolder = ({ folder, items }: any) => {
                    const folderName = folder.attributes.displayName;
                    const path = folder.attributes.fullPath;
                    const countModels = items.filter((x: any) => !Boolean(x.folder)).length;
                    const isEditting = editEntry === folder.id;
                    const isExpanded = item.expanded === true;
                    const isItemSelected = resourceGroups.find((x) => x.item === path) ? true : false;

                    return folderName === "generated-root-folder" ? (
                        items.map(renderGroup(level, treeIndex))
                    ) : (
                        <Fragment>
                            <TableRow>
                                <TableCell>
                                    <span style={style}>
                                        <FontAwesomeSvgIcon icon={faFolderOpen} style={{ height: "0.7em", marginRight: 10 }} />
                                        <span>{folderName}</span>
                                    </span>
                                </TableCell>
                                <TableCell>
                                    {countModels > 0 && (
                                        <Button
                                            startIcon={<FontAwesomeSvgIcon icon={faSearch} />}
                                            style={{ margin: "-10px 0 -10px auto" }}
                                            onClick={() => {
                                                toggleExpandedIndex(treeIndexString, isExpanded);
                                                item.expanded = !item.expanded;
                                            }}
                                        >
                                            {isExpanded ? "Verberg modellen" : "Toon modellen"} ({countModels})
                                        </Button>
                                    )}
                                </TableCell>
                                {isEditting ? (
                                    <>
                                        <TableCell padding="checkbox">
                                            <Checkbox checked={editGroupToggle} onChange={(e) => setEditGroupToggle(e.target.checked)} />
                                        </TableCell>
                                        <TableCell>
                                            <InputButton
                                                size="small"
                                                onClick={(e) => {
                                                    handleGroupToggleChange(path, editGroupToggle);
                                                    setEditEntry(undefined);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                            </InputButton>

                                            <InputButton
                                                size="small"
                                                onClick={(e) => {
                                                    setEditEntry(undefined);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                            </InputButton>
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell>{renderBool(item.folder.attributes.fullPath)}</TableCell>
                                        <TableCell>
                                            <InputButton
                                                size="small"
                                                onClick={(e) => {
                                                    setEditGroupToggle(isItemSelected);
                                                    setEditEntry(folder.id);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                            </InputButton>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                            {isExpanded && renderModels(items, level)}
                            {items.map(renderGroup(level + 1, treeIndex))}
                        </Fragment>
                    );
                };

                const renderModels = (items: any, level: number) => {
                    const padding = { paddingLeft: 16 * level + 20 };
                    return (
                        <TableRow>
                            <TableCell style={padding} colSpan={2}>
                                <Box
                                    component={"ul"}
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
                                        margin: 0,
                                        "&>li": {
                                            listStyle: "none",
                                        },
                                    }}
                                >
                                    {items
                                        .filter((x: any) => !Boolean(x.folder))
                                        .map((model: any, index: number) => {
                                            const { attributes } = model;

                                            return <li key={index}>{attributes.description ?? attributes.displayName}</li>;
                                        })}
                                </Box>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    );
                };

                return <Fragment key={key}>{Boolean(isGroup) && renderFolder(item)}</Fragment>;
            };

        return documents.map(renderGroup());
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>BIM</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>Groep activeerbaar</TableCell>
                                        <TableCell style={{ minWidth: 85 }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderDocuments()}</TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    models: appReducer.models,
    activatableResourceGroups: appReducer.activatableResourceGroups,
});

export default connect(mapStateToProps)(AdminDialogContentBIMModels);

const TableToolbar = () => {
    const theme = useTheme();

    return (
        <Toolbar
            sx={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Typography
                sx={{
                    flex: "1 1 100%",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Modellen
            </Typography>
        </Toolbar>
    );
};
