import store from "../redux/store";
import { groupLayers } from "../utils/layerFunctions";
import { setActiveByDefault, setDefaultThemesOrder, setThemes } from "../redux/webMaps/actions";
import { configuration } from "../_configuration/configuration";

export const fetchWebMapsLayers = async (webMaps) => {
    let token;
    try {
        token = store.getState().tokenReducer.tokenBySource[configuration.sources.gis].token.result.token;
    } catch (error) {}

    const promises = [];
    for (let i = 0, len = webMaps.length; i < len; i++) {
        promises.push(getOperationalLayers(webMaps[i], token));
    }

    const themes = store.getState().webMapsReducer.themes;
    if (themes && themes.length > 0) {
        return JSON.parse(JSON.stringify(themes));
    }

    return await Promise.all(promises).then((operationalLayersArray) => {
        const operationalLayers = operationalLayersArray.flat();
        const flat = flattenAuthorizedLayers(operationalLayers);
        const groups = groupLayers(flat);

        store.dispatch(setThemes(groups));
        return JSON.parse(JSON.stringify(groups));
    });
};

const getOperationalLayers = (webMap, token) => {
    return fetch(`https://www.arcgis.com/sharing/rest/content/items/${webMap.id}/data?token=${token}`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((webMapData) =>
            webMapData.operationalLayers
                .reverse()
                .flat()
                .map((x) => {
                    x.title = `${webMap.title} - ${x.title}`;
                    return x;
                })
        );
};

export const fetchActiveByDefault = () => {
    const project = store.getState().projectReducer.activeProject;
    const url = `${configuration.api}api/Projects/${project.id}/Resources/arcgis/Items/ActiveByDefault`;
    const options = {
        method: "GET",
    };

    fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((items) => {
            store.dispatch(setActiveByDefault(items));
        })
        .catch((error) => {
            // console.error("connectors/projects - fetchDefaultState error: ", error);
        });
};

const flattenAuthorizedLayers = (operationalLayers) => {
    const activeByDefault = store.getState().webMapsReducer.activeByDefault.map((x) => x.replaceAll(" - ", "/"));
    const authorizedResources = store.getState().projectReducer.resources.ArcGis;
    const flatResources = authorizedResources.reduce((acc, currValue) => acc.concat(currValue.Items), []);
    const resourcesArray = flatResources.map((x) => x.Uri);
    const deniedArray = authorizedResources.filter((x) => x.Denied).map((x) => x.Uri);
    const flattened = [];

    for (let i = 0, len = operationalLayers.length; i < len; i++) {
        const layerOrGroup = operationalLayers[i];

        if (layerOrGroup.layerType === "GroupLayer") {
            layerOrGroup.layers.reverse();
            for (let j = 0, groupLen = layerOrGroup.layers.length; j < groupLen; j++) {
                const layer = layerOrGroup.layers[j];
                if (layerOrGroup.title) layer.title = `${layerOrGroup.title} - ${layer.title}`;

                const path = layer.title.split(" - ").join("/");
                if (deniedArray.some((y) => path.startsWith(y))) continue;
                if (resourcesArray.some((y) => path.startsWith(y))) flattened.push(layer);

                if (activeByDefault.length > 0) {
                    layer.visible = activeByDefault.some((x) => layer.id === x);
                }
            }
        } else {
            const path = layerOrGroup.title.split(" - ").join("/");
            if (deniedArray.some((y) => path.startsWith(y))) continue;
            if (resourcesArray.some((y) => path.startsWith(y))) flattened.push(layerOrGroup);

            if (activeByDefault.length > 0) {
                layerOrGroup.visible = activeByDefault.some((x) => layerOrGroup.id === x);
            }
        }
    }

    store.dispatch(setDefaultThemesOrder(flattened.map((x) => ({ id: x.id, visible: x.visible }))));

    return flattened;
};
