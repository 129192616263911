export const receiveForgeDocuments = (documents) => ({
    type: "RECEIVE_FORGE_DOCUMENTS",
    documents,
});

export const setExternalIdMapping = (modelId, mapping) => ({
    type: "SET_EXTERNAL_ID_MAPPING",
    modelId,
    mapping,
});

export const setExternalDbIdMapping = (modelId, mapping) => ({
    type: "SET_EXTERNAL_DBID_MAPPING",
    modelId,
    mapping,
});

export const setBookmarkState = (paneId, state) => ({
    type: "SET_STATE_TO_RESTORE",
    paneId,
    state,
});

export const clearBookmarkState = () => ({
    type: "CLEAR_STATE_TO_RESTORE",
});

export const setModelViews = (modelId, views) => ({
    type: "SET_MODEL_VIEWS",
    modelId,
    views,
});

export const showModelViews = (modelId) => ({
    type: "SHOW_MODEL_VIEWS",
    modelId,
});
