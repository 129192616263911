import { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { loadModules } from "esri-loader";
import { Box, CircularProgress, styled } from "@mui/material";

import ArcgisTooltip from "./appControls/ArcgisTooltip";
import FeaturePicker from "./appControls/FeaturePicker";

import { setSelectedObject, cancelHighlights } from "../redux/selection/actions";
import { setSelectedSidebarTabId } from "../redux/app/actions";
import { setViewerProperties } from "../redux/properties/actions";
import { clearBookmarkState } from "../redux/webMaps/actions";

import * as tokenConnector from "../connectors/token";
import { fetchWebMapsLayers } from "../connectors/arcgis";
import { fetchActivatableResourceGroups } from "../connectors/admin/resources";

import { AppContext } from "../context/AppContext/AppContextProvider";
import { beginLoading, finishedLoading, setPaneBasemap, setPaneObject, setPaneScale, setPaneThemes } from "../context/AppContext/Reducer";

import { getFlatLayersArray, groupLayers } from "../utils/layerFunctions";

import { sortBy } from "lodash";
import { configuration } from "../_configuration/configuration";

const Container = styled(Box)(() => ({
    flex: 1,
    maxWidth: "100%",
    "@global": {
        ".esri-ui-bottom-right": {},
    },
}));

const ProgressIndicator = styled(CircularProgress)(() => ({
    position: "relative",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
}));

const ArcGis = (props) => {
    const [token, setToken] = useState();
    const [initialized, setInitialized] = useState(false);
    const [viewerReady, setViewerReady] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [keepView, setKeepView] = useState(false);
    const [featurePickerOptions, setFeaturePickerOptions] = useState(null);
    const [featureHoverOptions, setFeatureHoverOptions] = useState(null);

    const modules = useRef();

    const mapViewRef = useRef();
    const mapViewContainerRef = useRef();
    const webMapRef = useRef();
    const loading = useRef([]);

    const [legendWidget, setLegendWidget] = useState();
    const [measurementWidget, setMeasurementWidget] = useState();
    const [surfaceWidget, setSurfaceWidget] = useState();

    const highlightIds = useRef(null);
    const selectedObjectRef = useRef(null);
    const selectOnLoadRef = useRef({});
    const highlightOnLoadRef = useRef({});
    const hoverTimeoutRef = useRef();
    const featurePickerWorking = useRef(false);

    const { state, dispatch } = useContext(AppContext);
    const paneData = state.panesState.find((x) => x.id === props.paneId);

    const tokenRefreshTimerRef = useRef();
    const initialZoomExecuted = useRef(false);

    const containerRef = useRef();

    useEffect(() => {
        const { tokens } = props;
        const tokenResult = tokens[configuration.sources.gis];
        if (!tokenResult) tokenConnector.fetchToken(configuration.sources.gis);

        return () => {
            clearTimeout(tokenRefreshTimerRef.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadModules([
            "esri/layers/FeatureLayer",
            "esri/layers/TileLayer",
            "esri/layers/WebTileLayer",
            "esri/layers/WFSLayer",
            "esri/renderers/support/jsonUtils",
            "esri/layers/ogc/wfsUtils",
            "esri/layers/support/LabelClass",
            "esri/PopupTemplate",
            "esri/geometry/Extent",
            "esri/layers/support/TileInfo",
            "esri/Viewpoint",
        ]).then(([FeatureLayer, TileLayer, WebTileLayer, WFSLayer, rendererJsonUtils, wfsUtils, LabelClass, PopupTemplate, Extent, TileInfo, Viewpoint]) => {
            modules.current = {
                FeatureLayer,
                TileLayer,
                WebTileLayer,
                WFSLayer,
                rendererJsonUtils,
                wfsUtils,
                LabelClass,
                PopupTemplate,
                Extent,
                TileInfo,
                Viewpoint,
            };
        });
    }, []);

    useEffect(() => {
        loadModules(["esri/views/MapView", "esri/Map"]).then(([MapView, Map]) => {
            webMapRef.current = new Map({
                basemap: paneData?.data?.basemapId ?? "topo-vector",
            });

            mapViewRef.current = new MapView({
                map: webMapRef.current,
                container: mapViewContainerRef.current,
                center: [4.3868346520269315, 51.230367906237454],
                zoom: 13,
                ui: {
                    components: ["attribution", "compass", "zoom"],
                },
                popup: {
                    dockEnabled: true,
                    dockOptions: {
                        buttonEnabled: false,
                        breakpoint: false,
                        position: "top-right",
                    },
                },
            });

            mapViewRef.current.watch("zoom", (e) => {
                dispatch(setPaneScale(props.paneId, mapViewRef.current.scale));
            });

            mapViewRef.current.on("layerview-create", (e) => {
                onLayerLoaded(e.layer);
                dispatch(finishedLoading(props.paneId, e.layer.id));
                dispatch(setPaneScale(props.paneId, mapViewRef.current.scale));

                if (e.layer.title === "Algemeen - Tracekaart" && !initialZoomExecuted.current) {
                    initialZoomExecuted.current = true;
                    e.layer.when(() => mapViewRef.current.goTo(e.layer.fullExtent));
                }
            });

            dispatch(setPaneObject(props.paneId, mapViewRef.current));

            setViewClickEventListener();
            setViewHoverEventListener();
            setViewerReady(true);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInitialized(viewerReady && fetched);
    }, [viewerReady, fetched]);

    useEffect(() => {
        const tokenResult = props.tokens[configuration.sources.gis];
        if (!tokenResult || tokenResult.isFetching) return;

        const timeout = tokenResult.token.result.expires - new Date() - 5 * 60 * 1000; // todo: debug
        if (timeout > 0) {
            tokenRefreshTimerRef.current = setTimeout(() => tokenConnector.fetchToken(configuration.sources.gis), timeout);
            setToken(tokenResult.token.result);
        } else tokenConnector.fetchToken(configuration.sources.gis);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tokens[configuration.sources.gis]]);

    useEffect(() => {
        loadModules(["esri/identity/IdentityManager"]).then(([identityManager]) =>
            identityManager.registerToken({
                ...token,
                server: "https://www.arcgis.com/sharing",
            })
        );
    }, [token]);

    useEffect(() => {
        toggleLegend();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paneData.data?.tools?.legend]);

    useEffect(() => {
        if (!webMapRef.current || webMapRef.current.basemap?.id === paneData.data.basemapId) return;
        webMapRef.current.basemap = paneData.data.basemapId;
    }, [paneData.data.basemapId]);

    useEffect(() => {
        if (initialized) handleChangeSelection();
        selectedObjectRef.current = props.selectedObject;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedObject, initialized]);

    useEffect(() => {
        if (initialized && props.highlights && props.highlights.paneId === props.paneId) setHighlightIds(props.highlights.highlightIds);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.highlights, initialized]);

    useEffect(
        () => toggleMeasureTool(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [paneData.data?.tools?.measureTool]
    );

    useEffect(
        () => toggleSurfaceTool(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [paneData.data?.tools?.surfaceTool]
    );

    useEffect(() => {
        if (initialized) return;

        const resourceAccess = props.activeProject.resources.find((x) => x.Name === "ArcGis");
        if (!resourceAccess) return;
        if (!token) return;

        fetchActivatableResourceGroups();

        loadModules(["esri/portal/Portal"])
            .then(([Portal]) => {
                const portal = new Portal();
                portal.authMode = "immediate";

                return portal.load();
            })
            .then((portal) => {
                const { Owner: arcgisOwnerName } = props.activeProject.resources.find((x) => x.Name === "ArcGis");
                const queryParams = {
                    query: `owner: ${arcgisOwnerName} AND type: Web Map`,
                    sortField: "numViews",
                    sortOrder: "desc",
                    num: 100,
                };

                return portal.queryItems(queryParams);
            })
            .then((queryResult) => queryResult.results)
            .then((webMaps) => {
                const { Webmaps: webMapNames } = props.activeProject.resources.find((x) => x.Name === "ArcGis");
                const foundWebMaps = webMaps
                    .filter((x) =>
                        webMapNames
                            .map((x, i) => ({ title: x, order: i }))
                            .some((y) => {
                                if (y.title === x.title) {
                                    x.order = y.order;
                                    return true;
                                }

                                return false;
                            })
                    )
                    .sort((a, b) => a.order - b.order);

                if (!paneData.data.themes || paneData.data.themes.length === 0) {
                    fetchWebMapsLayers(foundWebMaps.map((x) => ({ id: x.id, title: x.title }))).then((x) => {
                        dispatch(setPaneThemes(props.paneId, x));
                        setFetched(true);
                    });
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (!initialized) return;
        toggleLayerVisibility();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paneData.data.themes, initialized]);

    useEffect(() => {
        const { bookmarkState, bookmarkStatePaneId, paneId, onClearBookmarkState } = props;

        if (bookmarkStatePaneId !== paneId || !initialized) return;

        const { active, basemapId, viewpoint } = bookmarkState;
        const flattened = getFlatLayersArray(paneData.data.themes);
        const sortedLayers = [];

        for (let i = 0, len = flattened.length; i < len; i++) {
            const layer = flattened[i];
            const index = active.findIndex((x) => x.id === layer.id);
            const visible = active.find((x) => x.id === layer.id)?.visible ?? false;

            if (index >= 0) {
                sortedLayers[index] = layer;
                sortedLayers[index].title = layer.sourceJson.title;
                sortedLayers[index].visible = visible;
            }
        }

        const groups = groupLayers(sortedLayers);
        dispatch(setPaneThemes(props.paneId, groups));
        dispatch(setPaneBasemap(props.paneId, basemapId));
        paneData.appObject.goTo(modules.current.Viewpoint.fromJSON(viewpoint));
        onClearBookmarkState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bookmarkState, initialized]);

    const toggleLayerVisibility = async () => {
        let visibleCount = 1;
        const layers = getFlatLayersArray(paneData.data.themes).reverse();

        for (let i = 0, len = layers.length; i < len; i++) {
            const { id, visible, sourceJson } = layers[i];
            const layer = webMapRef.current.allLayers.items.find((layer) => layer.id === id);
            const isLoading = loading.current.find((x) => x === id);

            if (layer) {
                layer.visible = Boolean(visible);
                webMapRef.current.reorder(layer, visibleCount++);

                if (!visible && props.selectedObject?.objectId) {
                    const selectedObjectLayer = props.selectedObject.objectId.split(".")[0];
                    if (id === selectedObjectLayer) {
                        removeHighlightIds();
                        props.onSetSelectedObject(null, configuration.sources.gis, props.paneId);
                    }
                }
            } else {
                if (visible && !Boolean(isLoading)) {
                    loading.current.push(id);
                    dispatch(beginLoading(props.paneId, id));
                    const visOrder = visibleCount++;

                    await createLayer(sourceJson)
                        .then((layer) => {
                            layers[i].layer = layer;
                            webMapRef.current.layers.add(layers[i].layer);
                            webMapRef.current.reorder(layers[i].layer, visOrder);
                        })
                        .catch((err) => console.error(`Failed to load layer '${layers[i].title}' (id: ${layers[i].id}), ${err}`));
                }
            }
        }
    };

    const createLayer = (layer) => {
        if (layer.layerType === "WebTiledLayer" || layer.layerType === "WFS") {
            return new Promise((resolve) => resolve(handleLayerCreation(layer)));
        }

        return fetch(`https://www.arcgis.com/sharing/rest/content/items/${layer.itemId}/data?token=${token.token}`)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }

                throw new Error();
            })
            .then((layerInfo) => {
                return handleLayerCreation(layer, layerInfo);
            });
    };

    const handleLayerCreation = async (layer, layerInfo = null) => {
        const { WebTileLayer, Extent, TileInfo, rendererJsonUtils, LabelClass, FeatureLayer, TileLayer, WFSLayer, wfsUtils } = modules.current;

        if (layerInfo) layerInfo = JSON.parse(layerInfo);

        let representationInfo;
        if (layer.url && layer.layerType !== "WFS") {
            representationInfo = await fetch(`${layer.url}?f=json&token=${token.token}`).then((r) => r.json());
        }

        switch (layer.layerType) {
            case "WFS":
                const capabilities = await wfsUtils.getCapabilities(layer.url);
                const wfsLayerInfo = await wfsUtils.getWFSLayerInfo(capabilities, layer.wfsInfo.name);
                const wfsLayer = WFSLayer.fromWFSLayerInfo(wfsLayerInfo);
                wfsLayer.id = layer.id;
                wfsLayer.outFields = ["*"];
                wfsLayer.minScale = layer.minScale;

                return wfsLayer;

            case "WebTiledLayer":
                return new WebTileLayer({
                    id: layer.id,
                    title: layer.title,
                    urlTemplate: layer.templateUrl,
                    copyright: layer.copyright,
                    minScale: layer.minScale ?? representationInfo?.minScale ?? 0,
                    maxScale: layer.maxScale ?? representationInfo?.maxScale ?? 0,
                    opacity: layer.opacity ?? 1,
                    blendMode: layer.blendMode ?? "normal",
                    fullExtent: Extent.fromJSON(layer.fullExtent),
                    wmtsInfo: layer.wmtsInfo,
                    tileInfo: TileInfo.fromJSON(layer.tileInfo),
                });

            case "ArcGISFeatureLayer":
                if (layerInfo) {
                    const layerId = layer.url.split("/").pop();
                    if (Number(layerId) && Number(layerId) < layerInfo.layers.length) {
                        const layerDetails = layerInfo.layers[layerId];

                        layer.layerDefinition = layerDetails.layerDefinition ?? layer.layerDefinition;
                        layer.popupInfo = layerDetails.popupInfo ?? layer.popupInfo;
                    }
                }

                const options = {
                    id: layer.id,
                    title: layer.title,
                    url: layer.url,
                    outFields: layer.outFields,
                    minScale: layer.layerDefinition?.minScale ?? representationInfo?.minScale ?? 0,
                    maxScale: layer.layerDefinition?.maxScale ?? representationInfo?.maxScale ?? 0,
                };

                if (layer.layerDefinition?.drawingInfo?.renderer) options.renderer = rendererJsonUtils.fromJSON(layer.layerDefinition.drawingInfo.renderer);

                if (layer.layerDefinition?.drawingInfo?.labelingInfo) {
                    options.labelingInfo = [];
                    for (let k = 0; k < layer.layerDefinition.drawingInfo.labelingInfo.length; k++) {
                        options.labelingInfo.push(LabelClass.fromJSON(layer.layerDefinition.drawingInfo.labelingInfo[k]));
                    }
                }

                // Don't set the PopupTemplate as it is currently not used by the frontend.
                // if (layer.popupInfo) {
                //     options.popupTemplate = PopupTemplate.fromJSON(layer.popupInfo);
                // }

                return new FeatureLayer(options);

            case "ArcGISTiledMapServiceLayer":
                return new TileLayer({
                    id: layer.id,
                    title: layer.title,
                    url: layer.url,
                    minScale: layer.minScale ?? representationInfo?.minScale ?? 0,
                    maxScale: layer.maxScale ?? representationInfo?.maxScale ?? 0,
                    opacity: layer.opacity ?? 1,
                    blendMode: layer.blendMode ?? "normal",
                });

            default:
                throw new Error(`Unhandled layer type: ${layer.layerType}`);
        }
    };

    const toggleLegend = () => {
        const legendActive = paneData.data?.tools?.legend ?? false;
        if (legendActive) {
            if (!legendWidget) {
                loadModules(["esri/widgets/Legend"]).then(([Legend]) => {
                    const legendWidget = new Legend({
                        view: mapViewRef.current,
                    });
                    mapViewRef.current.ui.add(legendWidget, "bottom-right");
                    setLegendWidget(legendWidget);
                });
            }
        } else {
            if (legendWidget) {
                legendWidget.destroy();
                setLegendWidget(null);
            }
        }
    };

    const toggleMeasureTool = () => {
        const measureToolActive = paneData.data?.tools?.measureTool ?? false;
        if (measureToolActive) {
            disableSurfaceTool();

            if (!measurementWidget) {
                loadModules(["esri/widgets/DistanceMeasurement2D"]).then(([DistanceMeasurement2D]) => {
                    const measurementWidget = new DistanceMeasurement2D({
                        viewModel: {
                            view: mapViewRef.current,
                        },
                    });
                    mapViewRef.current.ui.add(measurementWidget, "top-right");
                    setMeasurementWidget(measurementWidget);
                    measurementWidget.viewModel.clearMeasurement();
                    measurementWidget.viewModel.newMeasurement();
                });
            }
        } else {
            disableMeasureTool();
        }
    };

    const disableMeasureTool = () => {
        if (measurementWidget) {
            measurementWidget.destroy();
            setMeasurementWidget(null);
        }
    };

    const toggleSurfaceTool = () => {
        const surfaceToolActive = paneData.data?.tools?.surfaceTool ?? false;
        if (surfaceToolActive) {
            disableMeasureTool();

            if (!surfaceWidget) {
                loadModules(["esri/widgets/AreaMeasurement2D"]).then(([AreaMeasurement2D]) => {
                    const surfaceWidget = new AreaMeasurement2D({
                        viewModel: {
                            view: mapViewRef.current,
                        },
                    });
                    mapViewRef.current.ui.add(surfaceWidget, "top-right");
                    setSurfaceWidget(surfaceWidget);
                    surfaceWidget.viewModel.clearMeasurement();
                    surfaceWidget.viewModel.newMeasurement();
                });
            }
        } else {
            disableSurfaceTool();
        }
    };

    const disableSurfaceTool = () => {
        if (surfaceWidget) {
            surfaceWidget.destroy();
            setSurfaceWidget(null);
        }
    };

    const isBaseLayer = (id) => webMapRef.current.basemap.baseLayers.items.some((baseLayer) => baseLayer.id === id);

    const setViewClickEventListener = () => {
        mapViewRef.current.on("click", (event) => {
            featurePickerWorking.current = true;

            removeHighlightIds();
            setFeaturePickerOptions(null);

            mapViewRef.current.hitTest(event).then(({ results }) => {
                const features = [...new Set(results.filter(({ layer }) => !isBaseLayer(layer.id)).map((result) => result.graphic))];
                const promises = features.map((feature) => {
                    return new Promise((resolve, reject) => {
                        const layer = feature.layer;
                        const query = layer.createQuery();
                        query.where = `${layer.objectIdField} = '${feature.attributes[layer.objectIdField]}'`;
                        query.outFields = [layer.objectIdField, layer.displayField || "Label"];

                        layer
                            .queryFeatures(query)
                            .then((result) => {
                                if (!result.features || !result.features.length) {
                                    result.features = [feature.graphic];
                                }
                                resolve(result.features.map((graphic) => ({ graphic })));
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                });

                Promise.all(promises)
                    .then((featureSets) => {
                        const scope = featureSets
                            .reduce((features, featureSet) => [...features, ...featureSet], [])
                            .map((feature) => {
                                const objectIdField = feature.graphic.layer.objectIdField;
                                const displayField = feature.graphic.layer.displayField;

                                return {
                                    objectId: `${feature.graphic.layer.id}.${feature.graphic.attributes[objectIdField]}`,
                                    objectIdField,
                                    label: feature.graphic.attributes[displayField || "Label"] || "",
                                    layer: feature.graphic.layer,
                                };
                            })
                            .filter((value, index, self) => self.findIndex((m) => m.objectId === value.objectId) === index);

                        if (scope.length) {
                            if (scope.length === 1) {
                                const objectId = scope[0].objectId;

                                setKeepView(false);

                                if (objectId === selectedObjectRef.current?.objectId) {
                                    removeHighlightIds();
                                    props.onSetSelectedObject(null, configuration.sources.gis, props.paneId);
                                } else {
                                    props.onSetSelectedObject(objectId, configuration.sources.gis, props.paneId);
                                    props.onSetSelectedSidebarTabId("selection");
                                }
                            } else {
                                removeHighlightIds();
                                mapViewRef.current.popup.clear();
                                const features = scope.map((x) => {
                                    return {
                                        objectId: x.objectId,
                                        label: x.label,
                                        title: x.layer.title,
                                    };
                                });

                                setFeaturePickerOptions({
                                    event,
                                    features: sortBy(features, ["title", "label"]),
                                });
                            }
                        } else {
                            removeHighlightIds();
                            props.onSetSelectedObject(null, configuration.sources.gis, props.paneId);
                        }

                        featurePickerWorking.current = false;
                    })
                    .catch((error) => {
                        // console.error("setViewClickEventListener error: ", error);
                    });
            });
        });
    };

    const setViewHoverEventListener = () => {
        mapViewRef.current.on("pointer-move", (event) => {
            setFeatureHoverOptions(null);

            clearTimeout(hoverTimeoutRef.current);

            hoverTimeoutRef.current = setTimeout(() => {
                mapViewRef.current.hitTest(event).then(({ results }) => {
                    const features = [...new Set(results.filter(({ layer }) => !isBaseLayer(layer.id)).map((result) => result.graphic))];
                    const promises = features.map((feature) => {
                        return new Promise((resolve, reject) => {
                            const layer = feature.layer;
                            const query = layer.createQuery();
                            query.where = `OBJECTID = '${feature.attributes[layer.objectIdField]}'`;
                            query.outFields = [layer.objectIdField, layer.displayField || "Label"];

                            layer
                                .queryFeatures(query)
                                .then((result) => {
                                    if (!result.features || !result.features.length) {
                                        result.features = [feature.graphic];
                                    }
                                    resolve(result.features.map((graphic) => ({ graphic })));
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                    });

                    Promise.all(promises)
                        .then((featureSets) => {
                            const scope = featureSets
                                .reduce((features, featureSet) => [...features, ...featureSet], [])
                                .map((feature) => {
                                    const objectIdField = feature.graphic.layer.objectIdField;
                                    const displayField = feature.graphic.layer.displayField;

                                    return {
                                        objectId: `${feature.graphic.layer.id}.${feature.graphic.attributes[objectIdField]}`,
                                        objectIdField,
                                        label: feature.graphic.attributes[displayField || "Label"] || "",
                                        layer: feature.graphic.layer,
                                    };
                                })
                                .filter((value, index, self) => self.findIndex((m) => m.objectId === value.objectId) === index);

                            if (scope.length) {
                                removeHighlightIds();
                                mapViewRef.current.popup.clear();
                                const features = scope.map((x) => {
                                    return {
                                        objectId: x.objectId,
                                        label: x.label,
                                        title: x.layer.title,
                                    };
                                });

                                setFeatureHoverOptions({
                                    event,
                                    features: sortBy(features, ["title", "label"]),
                                });
                            }
                        })
                        .catch((error) => {
                            // console.error("setViewClickEventListener error: ", error);
                        });
                });
            }, 400);
        });

        mapViewRef.current.on("pointer-leave", (event) => {
            clearTimeout(hoverTimeoutRef.current);
        });
    };

    const setThemeVisible = (layerId, visible = true) => {
        const { panesState } = state;
        let changed = false;

        const setLayerVisible = (itemOriginal) => {
            const item = { ...itemOriginal };
            if (item.type === "group") {
                item.children = item.children.map((item) => {
                    return setLayerVisible(item);
                });
            } else if (item.id === layerId) {
                if (item.visible !== visible) {
                    item.visible = visible;
                    changed = true;
                }
            }
            return item;
        };

        const themes = [...panesState[props.paneId - 1].data.themes].map(setLayerVisible);
        if (changed) dispatch(setPaneThemes(props.paneId, themes));
    };

    const handleChangeSelection = () => {
        const { selectedObject } = props;
        if (selectedObject && selectedObject.objectId) {
            removeHighlightIds();

            if (selectedObject.source !== "arcgis") return;
            if (!selectedObject.isPhysicalObject) {
                let objectId = selectedObject.objectId;
                if (objectId.objectId) objectId = objectId.objectId;

                const [layerId, featureId, wfsFeatureId] = objectId.split(".");
                if (!layerId || !featureId) return;
                if (selectedObject.paneId === props.paneId) setThemeVisible(layerId);

                const layer = webMapRef.current.allLayers.items.find((layer) => layer.id === layerId);
                if (layer?.loaded) queryLayerSelect(layer, wfsFeatureId ? `${featureId}.${wfsFeatureId}` : featureId);
                else if (selectedObject.paneId === props.paneId) selectOnLoadRef.current[layerId] = featureId;
            }

            if ((selectedObject.highlightIds || []).length) {
                if (selectedObject.paneId === props.paneId) setHighlightIds(selectedObject.highlightIds);
            }
        }
    };

    const onLayerLoaded = (layer) => {
        const selectOnLoad = selectOnLoadRef.current[layer.id];
        if (selectOnLoad) {
            queryLayerSelect(layer, selectOnLoad);
            selectOnLoadRef.current[layer.id] = null;
        }

        const highlightOnLoad = highlightOnLoadRef.current[layer.id];
        if (highlightOnLoad) {
            queryLayerHighlight(layer, highlightOnLoad.highlights, highlightOnLoad.zoom);
            highlightOnLoadRef.current[layer.id] = null;
        }
    };

    const queryLayerSelect = (layer, featureId) => {
        const { onSetViewerProperties } = props;
        const selectedObject = props.selectedObject ?? selectedObjectRef.current;
        if (!selectedObject) return;

        const objectIdField = layer.objectIdField;
        const query = layer.createQuery();
        query.where = `${objectIdField} = '${featureId}'`;
        query.outSpatialReference = mapViewRef.current.spatialReference;

        layer
            .queryFeatures(query)
            .then(({ features }) => {
                const feature = features[0];
                if (!keepView && feature) {
                    onSetViewerProperties(feature.attributes, selectedObject.objectId);
                    setHighlightIds([selectedObject.objectId], false);
                }

                setKeepView(false);
            })
            .catch((error) => {
                // console.error("handleChangeSelection - query error: ", error);
            });
    };

    const queryLayerHighlight = (layer, highlightIdsByLayer, zoom) => {
        const { onSetViewerProperties } = props;
        const selectedObject = props.selectedObject ?? selectedObjectRef.current;

        mapViewRef.current.whenLayerView(layer).then((layerView) => {
            const featureIdsCommaSeparated = highlightIdsByLayer[layer.id].map((featureId) => `'${featureId}'`).join(", ");
            const objectIdField = layer.objectIdField;
            const query = layer.createQuery();
            query.where = `${objectIdField} IN (${featureIdsCommaSeparated})`;
            query.outSpatialReference = mapViewRef.current.spatialReference;

            layer
                .queryFeatures(query)
                .then(({ features }) => {
                    if (Array.isArray(features) && features.length) {
                        const feature = features[0];

                        removeHighlightIds();
                        highlightIds.current = layerView.highlight(features);
                        if (!keepView && feature) {
                            onSetViewerProperties(feature.attributes, selectedObject.objectId);
                        }

                        setKeepView(false);

                        if (zoom) {
                            let extents = null;
                            for (let i = 0, len = features.length; i < len; i++) {
                                if (extents) extents.union(features[i].geometry.extent);
                                else extents = features[i].geometry.extent;
                            }

                            if (!featurePickerOptions || featurePickerOptions.features.length === 0) mapViewRef.current.goTo(extents.expand(2));
                        }
                    }
                })
                .catch((error) => {
                    // console.error("handleChangeSelection - query error: ", error);
                });
        });
    };

    const setHighlightIds = (highlights, zoom = true) => {
        removeHighlightIds();

        const highlightIdsByLayer = highlights.reduce((highlightIdsByLayer, highlightId) => {
            const [layerId, featureId, wfsFeatureId] = highlightId.split(".");
            highlightIdsByLayer[layerId] = highlightIdsByLayer[layerId] || [];
            highlightIdsByLayer[layerId].push(wfsFeatureId ? `${featureId}.${wfsFeatureId}` : featureId);
            return highlightIdsByLayer;
        }, {});

        Object.keys(highlightIdsByLayer).forEach((layerId) => {
            setThemeVisible(layerId);

            const layer = webMapRef.current.allLayers.items.find((layer) => layer.id === layerId);
            if (layer?.loaded) queryLayerHighlight(layer, highlightIdsByLayer, zoom);
            else highlightOnLoadRef.current[layerId] = { highlights: highlightIdsByLayer, zoom };
        });
    };

    const setFeaturePickerHighlightIds = (highlightId) => {
        const [layerId, featureId] = highlightId.split(".");
        const layer = webMapRef.current.allLayers.items.find((layer) => layer.id === layerId);

        mapViewRef.current.whenLayerView(layer).then((layerView) => {
            const objectIdField = layer.objectIdField;
            const query = layer.createQuery();
            query.where = `${objectIdField} = '${featureId}'`;
            query.outSpatialReference = mapViewRef.current.spatialReference;

            layer
                .queryFeatures(query)
                .then(({ features }) => {
                    if (Array.isArray(features) && features.length) {
                        removeHighlightIds();
                        highlightIds.current = layerView.highlight(features);
                    }
                })
                .catch((error) => {
                    console.error("setFeaturePickerHighlightIds - query error: ", error);
                });
        });
    };

    const removeHighlightIds = () => {
        if (highlightIds.current) {
            highlightIds.current.remove();
            props.onCancelHighlights();
        }
    };

    const handleFeaturePicker = (feature) => {
        const { objectId } = feature;
        setHighlightIds([objectId]);
        props.onSetSelectedObject(objectId, configuration.sources.gis, props.paneId);
        props.onSetSelectedSidebarTabId("selection");
        setKeepView(false);
        setFeaturePickerOptions(null);
        setFeatureHoverOptions(null);
    };

    return (
        <Container ref={containerRef}>
            {!initialized && <ProgressIndicator />}
            <div style={{ width: "100%", height: "100%" }} className="webmap" ref={mapViewContainerRef} />
            {Boolean(featurePickerOptions) && (
                <FeaturePicker
                    options={featurePickerOptions}
                    callback={(feature) => handleFeaturePicker(feature)}
                    setHighlightIds={setFeaturePickerHighlightIds}
                    removeHighlightIds={removeHighlightIds}
                    container={containerRef}
                    paneId={props.paneId}
                />
            )}
            {!Boolean(featurePickerOptions) && !featurePickerWorking.current && Boolean(featureHoverOptions) && (
                <FeaturePicker
                    options={featureHoverOptions}
                    callback={(feature) => handleFeaturePicker(feature)}
                    setHighlightIds={setFeaturePickerHighlightIds}
                    removeHighlightIds={removeHighlightIds}
                    container={containerRef}
                    paneId={props.paneId}
                />
            )}
            <ArcgisTooltip />
        </Container>
    );
};

const mapStateToProps = ({ tokenReducer, webMapsReducer, selectionReducer, projectReducer }) => ({
    tokens: tokenReducer.tokenBySource,
    selectedObject: selectionReducer.selectedObject,
    highlights: selectionReducer.highlights,
    activeProject: projectReducer.activeProject,
    resources: projectReducer.resources.ArcGis,
    bookmarkState: webMapsReducer.bookmarkState,
    bookmarkStatePaneId: webMapsReducer.bookmarkStatePaneId,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedObject: (objectId, source, pane) => dispatch(setSelectedObject(objectId, source, pane)),
    onCancelHighlights: () => dispatch(cancelHighlights()),
    onSetSelectedSidebarTabId: (selectedSidebarTabId) => dispatch(setSelectedSidebarTabId(selectedSidebarTabId)),
    onSetViewerProperties: (viewerProperties, objectId) => dispatch(setViewerProperties(viewerProperties, objectId, configuration.sources.gis)),
    onClearBookmarkState: () => dispatch(clearBookmarkState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArcGis);
