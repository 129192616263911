import { IconButton, styled } from "@mui/material";

const InputButton = styled(IconButton)(() => ({
    backgroundColor: "unset",
    padding: 0,
    marginLeft: 10,
    "&:hover": {
        color: "#000000",
        backgroundColor: "unset",
    },
}));

export default InputButton;
