export const requestObjectName = (objectId) => ({
    type: "REQUEST_OBJECT_NAME",
    objectId,
});

export const receiveObjectName = (objectId, name, error) => ({
    type: "RECEIVE_OBJECT_NAME",
    objectId,
    name,
    error,
});

export const cancelObjectName = (objectId) => ({
    type: "CANCEL_OBJECT_NAME",
    objectId,
});

export const requestObjectNames = (objectIds) => ({
    type: "REQUEST_OBJECT_NAMES",
    objectIds,
});

export const receiveObjectNames = (objectIds, names, error) => ({
    type: "RECEIVE_OBJECT_NAMES",
    objectIds,
    names,
    error,
});

export const requestObjectNamesList = () => ({
    type: "REQUEST_OBJECT_NAMES_LIST",
});

export const receiveObjectNamesList = (names) => ({
    type: "RECEIVE_OBJECT_NAMES_LIST",
    names,
});
