import { Component, Fragment, isValidElement } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setPropertiesOverlayOpen } from "../../../../../redux/app/actions";
import ObjectName from "../../../../generic/ObjectName";
import Collapse from "../../../../svg/appControls/Collapse";
import PropertyCategory from "./PropertyCategory";

import isURL from "validator/lib/isURL";
import { generateUUID } from "../../../../../utils/guidFunctions";
import { configuration } from "../../../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    height: "calc(100% - 2px)",
    maxHeight: "calc(100% - 2px)",
}));

const RootLinkedObject = styled(Root)(({ theme }) => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.textColor,
}));

const Title = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.colors.primaryText,
    padding: "21px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    fontSize: "15px",
    color: theme.colors.white,
    position: "relative",
}));

const TitleLinkedObject = styled(Title)(({ theme }) => ({
    backgroundColor: theme.colors.textColor,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    maxHeight: "calc(100% - 54px)",
    minHeight: "calc(100% - 54px)",
}));

const IndexContainer = styled(Box)(({ theme }) => ({
    overflowY: "auto",
    backgroundColor: theme.colors.inputBackground,
    userSelect: "none",
    cursor: "pointer",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
}));

const CategoryIndex = styled(Box)(({ theme }) => ({
    padding: "10px 15px",
    fontWeight: theme.fonts.openSans.semiBold,
    "&:hover": {
        backgroundColor: theme.colors.inputBorder,
    },
}));

const SelectedCategoryIndex = styled(CategoryIndex)(({ theme }) => ({
    backgroundColor: theme.colors.btnHover,
    color: theme.colors.primaryText,
}));

const SelectedCategoryIndexLinkedObject = styled(CategoryIndex)(({ theme }) => ({
    backgroundColor: theme.colors.secondarySelectionHover,
    color: theme.colors.textColor,
}));

const PropertiesContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: "auto",
}));

const PropertyContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "8px",
}));

const PropertyName = styled(Box)(({ theme }) => ({
    flex: 0.3,
    paddingRight: "24px",
    wordBreak: "break-all",
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
}));

const PropertyValue = styled(Box)(({ theme }) => ({
    flex: 0.7,
    whiteSpace: "normal",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
}));

class PropertiesExpanded extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indices: null,
            selectedIndex: null,
            objectInfo: null,
            viewerProperties: null,
            otherProperties: null,
        };
    }

    componentDidMount() {
        this.setProperties();
        this.setIndices();
    }

    componentDidUpdate(prevProps, prevState) {
        const propertiesVariables = ["selectedObject", "selectedLinkedId", "selectedLinkedSource", "viewerProperties", "objectProperties", "objectRelations"];
        const propertiesVariablesHaveChanged = propertiesVariables.reduce((propertiesVariablesHaveChanged, key) => {
            return propertiesVariablesHaveChanged || this.props[key] !== prevProps[key];
        }, false);
        if (propertiesVariablesHaveChanged) {
            this.setProperties();
        }

        this.setIndices();
        this.setSelectedIndex();
    }

    setProperties = () => {
        const objectInfo = this.renderObjectInfo();
        const viewerProperties = this.renderViewerProperties();
        const otherProperties = this.renderOtherProperties();
        this.setState({
            objectInfo,
            viewerProperties,
            otherProperties,
        });
    };

    setIndices = () => {
        if (!this.propertiesContainer) {
            return;
        }
        const indices = Array.prototype.slice.call(this.propertiesContainer.querySelectorAll('div[data-container-type="categoryContainer"]')).map((child) => {
            return {
                id: child.id,
                title: child.dataset["title"],
                isSubcategory: Boolean(child.dataset["subcategory"]),
            };
        });

        const indicesHasChanged = JSON.stringify(indices) !== JSON.stringify(this.state.indices);
        if (indicesHasChanged) {
            this.setState({ indices });
        }
    };

    renderIndices = () => {
        const { selectedLinkedId } = this.props;

        const { indices, selectedIndex } = this.state;

        const indicesIsArray = Array.isArray(indices);
        if (!indicesIsArray) {
            return null;
        }

        const containerHasScroll = this.propertiesContainer.scrollHeight > this.propertiesContainer.offsetHeight;
        return indices.map(({ id, title, isSubcategory }, index) => {
            let CategoryIndexComponent = CategoryIndex;
            let style = {};

            if (isSubcategory) {
                style = { ...style, paddingLeft: "34px" };
            }

            const isSelected = selectedIndex === id;
            if (isSelected && containerHasScroll) {
                CategoryIndexComponent = SelectedCategoryIndex;

                if (selectedLinkedId) {
                    CategoryIndexComponent = SelectedCategoryIndexLinkedObject;
                }
            }

            return (
                <CategoryIndexComponent key={index} style={style} onClick={() => this.scrollToCategory(id)} data-id={id}>
                    {title}
                </CategoryIndexComponent>
            );
        });
    };

    scrollToCategory = (id) => {
        const containerHasScroll = this.propertiesContainer.scrollHeight > this.propertiesContainer.offsetHeight;
        if (!this.propertiesContainer) {
            return;
        }

        if (!id && containerHasScroll) {
            this.propertiesContainer.scrollBy(0, 1);
            this.propertiesContainer.scrollBy(0, -1);
            return;
        }

        const query = `div#${id}[data-container-type="categoryContainer"]`;
        const element = this.propertiesContainer.querySelector(query);
        if (element) {
            if (containerHasScroll) element.scrollIntoView();
        }
    };

    setSelectedIndex = () => {
        if (!this.propertiesContainer) return;

        const selectedIndex =
            (
                Array.prototype.slice
                    .call(this.propertiesContainer.querySelectorAll('div[data-container-type="categoryContainer"]:not([data-subcategory-container])'))
                    .find((element) => {
                        const { top } = this.propertiesContainer.getBoundingClientRect();
                        const { bottom } = element.getBoundingClientRect();
                        return bottom - top > 0;
                    }) || {}
            ).id || null;
        if (selectedIndex !== this.state.selectedIndex) {
            this.setState({ selectedIndex });
        }
    };

    hidePropertiesOverlay = () => {
        this.props.onSetPropertiesOverlayOpen(false);
    };

    parseProperties = (properties = {}) => {
        const parsedProperties = [];

        for (const source in properties) {
            const sourceProperties = properties[source];
            for (const category in sourceProperties.PropertyCategories) {
                const categoryProperties = sourceProperties.PropertyCategories[category].Properties;
                for (const property in categoryProperties) {
                    const parsedProperty = {
                        source,
                        category,
                        ...categoryProperties[property],
                    };
                    parsedProperties.push(parsedProperty);
                }
            }
        }

        return parsedProperties;
    };

    getPhysicalAndLogicalProperties = () => {
        const { selectedObject, objectProperties, objectRelations } = this.props;

        const { status, relations } = objectRelations[selectedObject.objectId] || {};

        if (status !== "fetched") {
            return {
                physicalObjectProperties: undefined,
                logicalObjectProperties: undefined,
            };
        }

        const { OutgoingRelations: outgoingRelations } = relations;

        const linkedPhysicalObjectId = (
            outgoingRelations.find(({ Name }) => {
                return (
                    Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                    Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT
                );
            }) || {}
        ).OtherObjectId;
        const physicalObjectProperties = (objectProperties[linkedPhysicalObjectId] || {}).properties;

        const linkedLogicalObjectId = (
            outgoingRelations.find(({ Name }) => {
                return (
                    Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT ||
                    Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT
                );
            }) || {}
        ).OtherObjectId;
        const logicalObjectProperties = (objectProperties[linkedLogicalObjectId] || {}).properties;

        return {
            physicalObjectProperties,
            logicalObjectProperties,
        };
    };

    renderOtherProperties = () => {
        const { selectedObject, selectedLinkedId, objectProperties, objectRelations } = this.props;

        if (selectedLinkedId) {
            const { properties } = objectProperties[selectedLinkedId] || {};

            return (
                <PropertyCategory title="Properties" id="Properties">
                    {this.parseProperties(properties).map(({ Name, Value, source, category }, index) => {
                        return (
                            <Fragment key={`${Name}_${index}`}>
                                {/*<dt>{Name} <sup>{source} ({category})</sup></dt>*/}
                                {/*<dd>{Value}</dd>*/}
                                {this.renderProperty(Name, Value)}
                            </Fragment>
                        );
                    })}
                </PropertyCategory>
            );
        }

        const { status } = objectRelations[selectedObject.objectId] || {};

        const propertiesList = [];

        switch (status) {
            case "fetching": {
                return <div style={{ margin: 20 }}>Bezig met laden...</div>;
            }

            case "fetched": {
                const { physicalObjectProperties, logicalObjectProperties } = this.getPhysicalAndLogicalProperties();

                if (physicalObjectProperties) {
                    const propertiesObject = {
                        name: "Fysiek",
                        properties: [
                            // {Name: 'Physical objectId', Value: linkedPhysicalObjectId},
                            ...this.parseProperties(physicalObjectProperties),
                        ],
                    };
                    propertiesList.push(propertiesObject);
                }

                if (logicalObjectProperties) {
                    const propertiesObject = {
                        name: "Logisch",
                        properties: [
                            // {Name: 'Logical objectId', Value: linkedLogicalObjectId},
                            ...this.parseProperties(logicalObjectProperties),
                        ],
                    };
                    propertiesList.push(propertiesObject);
                }

                return propertiesList.length ? (
                    <Fragment>
                        {propertiesList.map(({ name, properties }, index) => {
                            const key = `${name}_${index}`;
                            return (
                                <PropertyCategory key={key} id={key} title={name}>
                                    {properties.map(({ Name, Value }, index) => (
                                        <Fragment key={`${Name}_${index}`}>{this.renderProperty(Name, Value)}</Fragment>
                                    ))}
                                </PropertyCategory>
                            );
                        })}
                    </Fragment>
                ) : null;
            }

            default: {
                return null;
            }
        }
    };

    renderProperty = (name, value, anchorText = null) => {
        const { relatics, whitelist: globalWhitelist } = this.props;

        // Filter out link to relatics if the user doesn't have access
        if (name === "Link" && !relatics) return null;

        // check if it is an url
        if (typeof value == "string") {
            let url = value;
            if (url.startsWith("www.")) {
                url = `https://${url}`;
            }

            if (
                url.startsWith("https://bamnv.relaticsonline.com/dca244c5-5a84-e511-80c7-000af764f30b/ShowObject.aspx") &&
                url.indexOf("PortalID=8f51a51a-2eb5-ea11-a2f6-00155d642301") === -1
            ) {
                url = `${url}&PortalID=8f51a51a-2eb5-ea11-a2f6-00155d642301`;
            }

            if (isURL(url, { require_protocol: true })) {
                // check if we start with "www"
                value = (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {url}
                    </a>
                );
            }
        } else if (typeof value === "object" && !isValidElement(value)) {
            value = JSON.stringify(value);
        }

        const dataTypeInfo = globalWhitelist.find((x) => x.name?.toLowerCase() === name.toLowerCase());
        if (dataTypeInfo && dataTypeInfo.dataType) {
            switch (dataTypeInfo.dataType) {
                case "Date":
                    value = new Date(value).toLocaleString("nl-BE", { year: "numeric", month: "long", day: "numeric" });
                    break;
                case "Bool":
                    value = Boolean(value) ? "Waar" : "Onwaar";
                    break;
                default:
                    // Data is handled as a string so we don't perform any actions.
                    break;
            }
        }

        return (
            <PropertyContainer key={generateUUID()}>
                <PropertyName>{name}</PropertyName>
                <PropertyValue>{value}</PropertyValue>
            </PropertyContainer>
        );
    };

    renderViewerProperties = () => {
        const { viewerProperties, selectedObject, selectedLinkedId, whitelist: globalWhitelist } = this.props;

        if (selectedLinkedId) {
            return null;
        }

        const source = (viewerProperties.source ?? selectedObject.source) === "forge" ? "bim360" : viewerProperties.source ?? selectedObject.source;
        const blacklist = globalWhitelist.filter((x) => x.type?.toLowerCase() === source.toLowerCase() && x.blackList);

        const viewerPropertiesBelongToSelectedObject =
            viewerProperties.objectId === selectedObject.objectId && viewerProperties.source === selectedObject.source;
        if (viewerPropertiesBelongToSelectedObject) {
            switch (viewerProperties.source) {
                case configuration.sources.gis: {
                    const properties = [];

                    for (const key in viewerProperties.properties) {
                        const value = viewerProperties.properties[key];
                        if (
                            blacklist.some(
                                (item) =>
                                    (item.name &&
                                        (item.name.toLowerCase() === key.toLowerCase() ||
                                            (item.name.endsWith("*") &&
                                                key.toLowerCase().startsWith(item.name.toLowerCase().substring(0, item.name.length - 1))))) ||
                                    !item.name
                            )
                        )
                            continue;

                        properties.push(this.renderProperty(key, value));
                    }

                    return (
                        <PropertyCategory title="Kaartlaag" id="Kaartlaag">
                            {properties.map((property, index) => (
                                <Fragment key={index}>{property}</Fragment>
                            ))}
                        </PropertyCategory>
                    );
                }

                case configuration.sources.bim: {
                    const propertyCategories = viewerProperties.properties.reduce((propertyCategories, property) => {
                        const { displayCategory, displayName, displayValue } = property;

                        if (
                            blacklist.some(
                                (item) =>
                                    ((item.name &&
                                        (item.name.toLowerCase() === displayName.toLowerCase() ||
                                            (item.name.endsWith("*") &&
                                                displayName.toLowerCase().startsWith(item.name.toLowerCase().substring(0, item.name.length - 1))))) ||
                                        !item.name) &&
                                    item.category?.toLowerCase() === displayCategory.toLowerCase()
                            )
                        )
                            return propertyCategories;

                        propertyCategories[displayCategory] = propertyCategories[displayCategory] || [];
                        propertyCategories[displayCategory].push(this.renderProperty(displayName, displayValue));

                        return propertyCategories;
                    }, {});

                    const properties = [];

                    for (const category in propertyCategories) {
                        const categoryProperties = propertyCategories[category];
                        properties.push(
                            <PropertyCategory title={category} id={`Viewer_${category}`} subcategory>
                                {categoryProperties}
                            </PropertyCategory>
                        );
                    }

                    return (
                        <PropertyCategory title="Viewer" id="Viewer" subcategoryContainer>
                            {properties.map((property, index) => (
                                <Fragment key={index}>{property}</Fragment>
                            ))}
                        </PropertyCategory>
                    );
                }

                default: {
                    return null;
                }
            }
        } else {
            return null;
        }
    };

    renderObjectInfo = () => {
        const { selectedObject, selectedLinkedId, selectedLinkedSource } = this.props;

        const hasSelectedLinkedId = Boolean(selectedLinkedId);

        const objectId = selectedLinkedId || (selectedObject ? selectedObject.objectId : null);
        const objectSource = selectedLinkedSource || (selectedObject ? selectedObject.source : null);

        const properties = [];

        properties.push(
            this.renderProperty(
                "Naam",
                <ObjectName objectId={objectId} shouldGetObjectName={!hasSelectedLinkedId} shouldCheckLinkedObject={!hasSelectedLinkedId} />
            )
        );

        properties.push(this.renderProperty("objectId", objectId));

        const objectNature = (() => {
            switch (objectSource) {
                case configuration.sources.gis: {
                    return "kaart";
                }

                case configuration.sources.bim: {
                    return "3D model";
                }

                default: {
                    return "";
                }
            }
        })();
        properties.push(this.renderProperty("Soort object", objectNature));

        const { physicalObjectProperties, logicalObjectProperties } = this.getPhysicalAndLogicalProperties();
        const linksInfoConfig = [
            {
                name: "physical",
                objectProperties: physicalObjectProperties,
            },
            {
                name: "logical",
                objectProperties: logicalObjectProperties,
            },
        ];

        linksInfoConfig.forEach(({ name, objectProperties }) => {
            if (objectProperties) {
                const links = this.parseProperties(objectProperties).filter(({ Name }) => Name === "Link");
                links.forEach((link) => {
                    properties.push(this.renderProperty(`Link ${name === "physical" ? "Fysiek" : "Logisch"}`, link.Value, name));
                });
            }
        });

        return (
            <PropertyCategory title="Informatie" id="Informatie">
                {properties.map((property, index) => (
                    <Fragment key={index}>{property}</Fragment>
                ))}
            </PropertyCategory>
        );
    };

    render() {
        const { selectedLinkedId } = this.props;

        const { objectInfo, viewerProperties, otherProperties } = this.state;

        let RootComponent = Root;
        let TitleComponent = Title;

        if (selectedLinkedId) {
            RootComponent = RootLinkedObject;
            TitleComponent = TitleLinkedObject;
        }

        return (
            <RootComponent>
                <TitleComponent>
                    <span>Eigenschappen</span>
                    <div onClick={this.hidePropertiesOverlay} style={{ cursor: "pointer" }}>
                        <Collapse />
                    </div>
                </TitleComponent>
                <ContentContainer>
                    <IndexContainer>{this.renderIndices()}</IndexContainer>
                    <PropertiesContainer ref={(propertiesContainer) => (this.propertiesContainer = propertiesContainer)} onScroll={this.setSelectedIndex}>
                        {objectInfo}
                        {viewerProperties}
                        {otherProperties}
                    </PropertiesContainer>
                </ContentContainer>
            </RootComponent>
        );
    }
}

const mapStateToProps = ({ selectionReducer, objectRelationsReducer, objectPropertiesReducer, projectReducer }) => ({
    selectedObject: selectionReducer.selectedObject,
    selectedLinkedId: selectionReducer.selectedLinkedId,
    selectedLinkedSource: selectionReducer.selectedLinkedSource,
    filteredRelations: objectRelationsReducer.filteredRelations,
    objectRelations: objectRelationsReducer.objects,
    objectProperties: objectPropertiesReducer.objects,
    viewerProperties: objectPropertiesReducer.viewerProperties,
    relatics: projectReducer.resources.Relatics,
    whitelist: projectReducer.whitelist,
});

const mapDispatchToProps = (dispatch) => ({
    onSetPropertiesOverlayOpen: (propertiesOverlayOpen) => dispatch(setPropertiesOverlayOpen(propertiesOverlayOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesExpanded);
