import { configuration } from "../_configuration/configuration";
import store from "../redux/store";
import { receiveToken, requestToken } from "../redux/token/actions";

export const fetchToken = (source) => {
    store.dispatch(requestToken(source));

    const url = `${configuration.api}api/Tokens`;
    const options = {
        method: "GET",
    };

    return new Promise((resolve, reject) =>
        fetch(`${url}?tokenServiceName=${source}&referer=${window.location.host}`, options)
            .then((response) => {
                return response.json();
            })
            .then((token) => {
                store.dispatch(receiveToken(source, token));
                resolve(token);
            })
            .catch((error) => {
                // console.error("connectors/token - fetchToken error: ", error);
            })
    );
};
