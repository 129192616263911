import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    TextField,
    lighten,
    useTheme,
    Theme,
    SxProps,
    SelectChangeEvent,
} from "@mui/material";
import { faCheck, faPencil, faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import DeleteButton from "../DeleteButton";
import InputButton from "../InputButton";
import { createResourceItem, fetchResourceItems, fetchRoles, modifyResourceItem, removeResourceItem } from "../../../connectors/admin/users";
import StyledTooltip from "../../generic/StyledTooltip";

export interface AdminDialogContentUsersAccessProps {
    resourceItems: any;
    roles: any;
    onClose: () => void;
}

const RESOURCE_TYPES = [
    {
        key: "Relatics",
        value: "Relatics",
    },
    {
        key: "Bim360",
        value: "BIM Modellen",
    },
    {
        key: "ArcGis",
        value: "GIS Lagen",
    },
    {
        key: "Bim360Pdf",
        value: "BIM Documenten",
    },
];

const AdminDialogContentUsersAccess = ({ resourceItems, roles, onClose }: AdminDialogContentUsersAccessProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fetched, setFetched] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [itemsAccess, setItemsAccess] = useState<any[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [addNewAccessItem, setAddNewAccessItem] = useState<boolean>(false);
    const [newAccessItem, setNewAccessItem] = useState<any>({
        deny: false,
        item: "",
        project: "OWV - Lantis",
        resource: RESOURCE_TYPES[1].key,
        role: "",
    });
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);
    const [editAccessItem, setEditAccessItem] = useState<any>();

    useEffect(() => {
        Promise.all([fetchResourceItems(), fetchRoles()]).then(() => setFetched(true));
    }, []);

    useEffect(() => {
        if (!fetched) return;

        setData(resourceItems);
        setItemsAccess(
            resourceItems
                .sort((a: any, b: any) => a.resource.localeCompare(b.resource) || a.role.localeCompare(b.role) || a.item.localeCompare(b.item))
                .map((x: any) => ({ ...x }))
        );
        setIsLoading(false);
    }, [resourceItems, fetched]);

    const onSave = () => {
        setIsLoading(true);
        const promises = [];
        for (let i = 0; i < itemsAccess.length; i++) {
            const accessItem = itemsAccess[i];
            const originalAccessItem = data.find((x) => x.id === accessItem.id);

            if (
                originalAccessItem.deny !== accessItem.deny ||
                originalAccessItem.role !== accessItem.role ||
                originalAccessItem.item !== accessItem.item ||
                originalAccessItem.resource !== accessItem.resource
            )
                promises.push(modifyResourceItem(accessItem));
        }
        Promise.all(promises);
    };

    const onReset = () => {
        setItemsAccess(
            resourceItems
                .sort((a: any, b: any) => a.resource.localeCompare(b.resource) || a.role.localeCompare(b.role) || a.item.localeCompare(b.item))
                .map((x: any) => ({ ...x }))
        );
    };

    const hasChanges = (): boolean => {
        const originalAccessItems = data;
        if (originalAccessItems.length !== itemsAccess.length) return true;

        for (let i = 0; i < itemsAccess.length; i++) {
            const originalRole = originalAccessItems[i];
            const accessItem = itemsAccess[i];
            if (
                originalRole.deny !== accessItem.deny ||
                originalRole.role !== accessItem.role ||
                originalRole.item !== accessItem.item ||
                originalRole.resource !== accessItem.resource
            )
                return true;
        }

        return false;
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const editEntry = () => {
        const data = [...itemsAccess];
        const entry = data.find((x) => x.id === editAccessItem.id);
        const fields = Object.keys(entry);
        for (let i = 0; i < fields.length; i++) {
            entry[fields[i]] = editAccessItem[fields[i]];
        }

        setItemsAccess(data);
    };

    const onAddEntry = () => {
        setIsLoading(true);

        createResourceItem(newAccessItem).then(() => {
            setAddNewAccessItem(false);
            setNewAccessItem({
                deny: false,
                item: "",
                project: "OWV - Lantis",
                resource: RESOURCE_TYPES[1].key,
                role: "",
            });
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);
        setShouldDelete(false);
        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            promises.push(removeResourceItem(selected[i]));
        }

        Promise.all(promises).then((_) => {
            setSelected([]);
            setIsLoading(false);
        });
    };

    const renderResource = (key: string) => {
        const resourceType = RESOURCE_TYPES.find((x) => x.key === key);
        return resourceType?.value ?? key;
    };

    const renderBool = (value: boolean) => {
        if (value) return "Ja";
        return "Neen";
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>BIM</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setAddNewAccessItem(true)} onDelete={() => onDeleteEntry()} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"></TableCell>
                                        <TableCell>Categorie</TableCell>
                                        <TableCell>Resource</TableCell>
                                        <TableCell>Rol</TableCell>
                                        <TableCell>Toegang weigeren</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {itemsAccess.map((property, index) => {
                                        const isItemSelected = isSelected(property.id);
                                        const isEditting = editAccessItem?.id === property.id;

                                        return isEditting ? (
                                            <TableRow hover key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={editAccessItem.resource}
                                                        onChange={(e: SelectChangeEvent) => setEditAccessItem({ ...editAccessItem, resource: e.target.value })}
                                                    >
                                                        {RESOURCE_TYPES.map((type, index) => (
                                                            <MenuItem key={index} value={type.key}>
                                                                {type.value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editAccessItem.item}
                                                        onChange={(e) => setEditAccessItem({ ...editAccessItem, item: e.currentTarget.value })}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={editAccessItem.role}
                                                        onChange={(e) => setEditAccessItem({ ...editAccessItem, role: e.target.value })}
                                                    >
                                                        {roles.map((role: any, index: number) => (
                                                            <MenuItem key={index} value={role.role}>
                                                                {role.role}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={editAccessItem.deny}
                                                        onChange={(e) => setEditAccessItem({ ...editAccessItem, deny: e.target.checked })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            editEntry();
                                                            setEditAccessItem(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                    </InputButton>

                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditAccessItem(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow hover onClick={() => handleClick(property.id)} key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>{renderResource(property.resource)}</TableCell>
                                                <TableCell>
                                                    {property.resource === "Relatics" ? "-" : property.item}
                                                </TableCell>
                                                <TableCell>{property.role}</TableCell>
                                                <TableCell>{renderBool(property.deny)}</TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditAccessItem({ ...property });
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={addNewAccessItem} onClose={() => setAddNewAccessItem(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                        <DialogTitle id="form-dialog-title">Nieuwe toegangsregel toevoegen</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newAccessItem.resource}
                                        onChange={(e) => {
                                            const accessItem = { ...newAccessItem, resource: e.target.value };
                                            if (e.target.value === "Relatics") accessItem.item = "Dummy";
                                            setNewAccessItem(accessItem);
                                        }}
                                    >
                                        {RESOURCE_TYPES.map((type, index) => (
                                            <MenuItem key={index} value={type.key}>
                                                {type.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    {newAccessItem.resource === "Relatics" ? (
                                        <TextField label="Resource" value="-" disabled />
                                    ) : (
                                        <TextField
                                            label="Resource"
                                            value={newAccessItem.item}
                                            onChange={(e) => setNewAccessItem({ ...newAccessItem, item: e.currentTarget.value })}
                                        />
                                    )}
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newAccessItem.role}
                                        onChange={(e) => setNewAccessItem({ ...newAccessItem, role: e.target.value })}
                                    >
                                        {roles.map((role: any, index: number) => (
                                            <MenuItem key={index} value={role.role}>
                                                {role.role}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={newAccessItem.deny}
                                                onChange={(e) => setNewAccessItem({ ...newAccessItem, deny: e.target.checked })}
                                            />
                                        }
                                        label="Toegang weigeren?"
                                    />
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddNewAccessItem(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button onClick={() => onAddEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={Boolean(shouldDelete)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Toegangsregel(s)verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>
                                U staat op het punt de geselecteerde toegangsregel(s) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    roles: appReducer.roles,
    resourceItems: appReducer.resourceItems,
});

export default connect(mapStateToProps)(AdminDialogContentUsersAccess);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={style}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Toegangsregels
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde toegangsregel(s) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe toegangsregel toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
