const defaultState = ({
  objects: {},
  filteredRelations: {
    gisRelations: null,
    bimRelations: null,
    pdfRelations: null,
    requirementRelations: null,
  },
  physicalObjectTrees: {},
  physicalObjectId: null,
  logicalObjectId: null,
})

const reducer = (state = defaultState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'REQUEST_OBJECT_RELATIONS': {
      newState.objects = Object.assign({}, newState.objects)
      newState.objects[action.objectId] = newState.objects[action.objectId] || {}
      newState.objects[action.objectId].status = 'fetching'
      return newState
    }

    case 'RECEIVE_OBJECT_RELATIONS': {
      newState.objects = Object.assign({}, newState.objects)
      newState.objects[action.objectId] = newState.objects[action.objectId] || {}
      newState.objects[action.objectId].status = 'fetched'
      newState.objects[action.objectId].relations = action.relations || {IncomingRelations: [], OutgoingRelations: []}
      if (action.error) {
        newState.objects[action.objectId].error = action.error
      }
      return newState
    }

    case 'SET_FILTERED_RELATIONS': {
      newState.filteredRelations = action.filteredRelations
      return newState
    }

    case 'REQUEST_PHYSICAL_OBJECT_TREE': {
      newState.physicalObjectTrees = Object.assign({}, state.physicalObjectTrees)
      newState.physicalObjectTrees[action.objectId] = newState.physicalObjectTrees[action.objectId] || {}
      newState.physicalObjectTrees[action.objectId].status = 'fetching'
      return newState
    }

    case 'RECEIVE_PHYSICAL_OBJECT_TREE': {
      newState.physicalObjectTrees = Object.assign({}, state.physicalObjectTrees)
      newState.physicalObjectTrees[action.objectId] = newState.physicalObjectTrees[action.objectId] || {}
      newState.physicalObjectTrees[action.objectId].status = 'fetched'
      newState.physicalObjectTrees[action.objectId].physicalObjectTree = action.physicalObjectTree
      if (action.error) {
        newState.physicalObjectTrees[action.objectId].error = action.error
      }
      return newState
    }

    case 'SET_LINKED_PHYSICAL_AND_LOGICAL_OBJECT_IDS': {
      newState.physicalObjectId = action.physicalObjectId
      newState.logicalObjectId = action.logicalObjectId
      return newState
    }

    default: {
      return state
    }
  }
}

export default reducer