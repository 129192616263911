import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";

import App from "./App";
import store from "./redux/store";
import defaultTheme from "./themes/default";
import { AppContextProvider } from "./context/AppContext/AppContextProvider";
import AuthenticationHandler from "./authentication/AuthenticationHandler";

import { configuration } from "./_configuration/configuration";

import "./index.css";

LicenseInfo.setLicenseKey(configuration.muiLicenseKey);

if (configuration.sentry) {
    Sentry.init({
        dsn: configuration.sentry.dsn,
        environment: configuration.sentry.env,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: configuration.sentry.tracesSampleRate,
        ignoreErrors: ["BrowserAuthError", "InteractionRequiredAuthError", "ClientAuthError"],
    });
}

if (configuration.gtm && configuration.gtm.id) {
    TagManager.initialize({
        gtmId: configuration.gtm.id,
    });
}

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: configuration.authentication.appId,
        redirectUri: window.location.hostname === "localhost" ? "http://localhost:3000" : `https://${window.location.hostname}`,
        authority: `https://login.microsoft.com/${configuration.authentication.tenant}`,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
});
msalInstance.enableAccountStorageEvents();

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    const account = accounts[0];
    msalInstance.setActiveAccount(account);
    Sentry.configureScope((scope) => {
        scope.setUser({ id: account.localAccountId, username: account.name, email: account.username });
    });
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === "msal:loginSuccess" && event.payload) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        Sentry.configureScope((scope) => {
            scope.setUser({ id: account.localAccountId, username: account.name, email: account.username });
        });
    }
});

const root = createRoot(document.getElementById("root"));

root.render(
    <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />

            <Provider store={store}>
                <AppContextProvider>
                    <AuthenticationHandler>
                        <App />
                    </AuthenticationHandler>
                </AppContextProvider>
            </Provider>
        </ThemeProvider>
    </MsalProvider>
);
