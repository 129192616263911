import { useContext } from "react";
import Toolbar from "../Toolbar/Toolbar";
import ArcgisComponent from "../../ArcgisComponent";
import ForgeComponent from "../../ForgeComponent";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { setActivePane } from "../../../context/AppContext/Reducer";
import { SplitPaneContext } from "../../../context/SplitPaneContext/SplitPaneContextProvider";
import { PaneMode } from "../../../context/SplitPaneContext/State";

import styles from "./SplitPane.module.css";
import ForgePdfComponent from "../../ForgePdfComponent";

const SplitPane = () => {
    const { state: appState, dispatch: appDispatch } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);

    const setActive = () => {
        if (appState.activePane === paneState.id) return;
        appDispatch(setActivePane(paneState.id));
    };

    const renderComponent = () => {
        const { panesState } = appState;
        const paneDetails = panesState[paneState.id - 1];

        switch (paneDetails.mode) {
            case PaneMode.BIM:
                return <ForgeComponent paneId={paneState.id} />;
            case PaneMode.GIS:
                return <ArcgisComponent paneId={paneState.id} />;
            case PaneMode.DOCUMENT:
                return <ForgePdfComponent paneId={paneState.id} />;
            default:
                return <div className={styles.message}>Gelieve een modus te selecteren bovenaan dit venster...</div>;
        }
    };

    const { id } = paneState;
    const { activePane, panesCount } = appState;
    return (
        <div className={styles.pane} onClick={() => setActive()}>
            {panesCount > 1 && <div data-content={id} className={[styles.border, activePane === id ? styles.active : ""].join(" ")}></div>}
            <Toolbar />
            {renderComponent()}
        </div>
    );
};

export default SplitPane;
