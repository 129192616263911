export default class FallbackNamesCache {
    private static instance: FallbackNamesCache;
    protected cacheMap: Map<string, string>;

    private constructor() {
        this.cacheMap = new Map();
    }

    static getInstance(): FallbackNamesCache {
        if (!this.instance) {
            this.instance = new FallbackNamesCache();
        }

        return this.instance;
    }

    get(key: string): string | undefined {
        return this.cacheMap.get(key);
    }

    has(key: string): boolean {
        return this.cacheMap.has(key);
    }

    set(key: string, value: string): void {
        this.cacheMap.set(key, value);
    }

    delete(key: string, value: string): void {
        this.cacheMap.delete(key);
    }

    clear(): void {
        this.cacheMap.clear();
    }
}
