import { Component } from "react";
import { Box, styled } from "@mui/material";
import ObjectName from "../../../../generic/ObjectName";
import { connect } from "react-redux";
import { setSelectedLinkedId } from "../../../../../redux/selection/actions";
import ArrowUp from "../../../../svg/selectionTabs/relationsTab/ArrowUp";
import ArrowDown from "../../../../svg/selectionTabs/relationsTab/ArrowDown";
import SelectObject from "./SelectObject";
import FavoriteToggle from "../../../../generic/FavoriteToggle";
import comparePropsAndState from "../../../../../utils/comparePropsAndState";
import theme from "../../../../../themes/default";

const Root = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 18px",
    paddingLeft: "37px",
    "&>div": {
        display: "flex",
        alignItems: "center",
    },
}));

const IconContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const Left = styled(Box)(() => ({
    overflow: "hidden",
}));

const Right = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const ObjectNameContainer = styled(Box)(() => ({
    marginLeft: 12,
    wordBreak: "break-all",
}));

const FavoriteIconContainer = styled(Box)(() => ({
    marginLeft: 12,
}));

class Relation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    handleMouseEnter = () => {
        this.setState({ isHovering: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovering: false });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return comparePropsAndState(this.props, nextProps, this.state, nextState);
    }

    render() {
        const { objectId, icon: Icon, direction, selectedLinkedId, onSetSelectedLinkedId, source, noSelect, depth = 0 } = this.props;
        const { isHovering } = this.state;

        const Arrow = direction === "outgoing" ? ArrowUp : ArrowDown;
        const isSelected = selectedLinkedId === objectId;
        const id = Boolean(isSelected) ? "secondarySelectionNode" : undefined;

        let rootStyle = { "&:hover": { backgroundColor: theme.colors.secondarySelectionHover } };
        const indentStyle = { paddingLeft: `calc(${depth} * 24px)` };
        if (isSelected) rootStyle = { border: `1px solid ${theme.colors.textColor}`, color: theme.colors.textColor };

        return (
            <Root
                id={id}
                sx={rootStyle}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={() => onSetSelectedLinkedId(objectId, source)}
            >
                <Left sx={indentStyle}>
                    <IconContainer>
                        <Icon selected={isSelected} />
                        <Arrow selected={isSelected} />
                    </IconContainer>
                    <ObjectNameContainer>
                        <ObjectName objectId={objectId} />
                    </ObjectNameContainer>
                </Left>
                {Boolean(!noSelect) && (
                    <Right>
                        {Boolean(isHovering) && <SelectObject objectId={objectId} source={source} />}
                        <FavoriteIconContainer>
                            <FavoriteToggle objectId={objectId} source={source} isHidden={!isHovering} />
                        </FavoriteIconContainer>
                    </Right>
                )}
            </Root>
        );
    }
}

const mapStateToProps = ({ selectionReducer, favoritesReducer }) => ({
    selectedLinkedId: selectionReducer.selectedLinkedId,
    favoriteObjects: favoritesReducer.favoriteObjects,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedLinkedId: (selectedLinkedId, source) => dispatch(setSelectedLinkedId(selectedLinkedId, source)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Relation);
