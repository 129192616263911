import { Box, useTheme } from "@mui/material";

const CheckIcon = () => {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="52.952" viewBox="0 0 64 52.952">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.success,
                }}
                d="M55.893,7.031,51.7,11.476,22.5,42.46,9.416,29.38.782,38.015,22.75,59.983l4.317-4.571,37.714-40Z"
                transform="translate(-0.782 -7.031)"
            />
        </svg>
    );
};

export default CheckIcon;
