const defaultState = {
    tokenBySource: {},
};

const tokenReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "REQUEST_TOKEN": {
            newState.tokenBySource = Object.assign({}, state.tokenBySource);
            newState.tokenBySource[action.source] = Object.assign({}, state.tokenBySource[action.source] || {}, {
                isFetching: true,
            });
            return newState;
        }

        case "RECEIVE_TOKEN": {
            newState.tokenBySource = Object.assign({}, state.tokenBySource);
            newState.tokenBySource[action.source] = Object.assign({}, state.tokenBySource[action.source] || {}, {
                isFetching: false,
                token: action.token,
            });
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default tokenReducer;
