import { EnvConfiguration } from "./AppConfiguration";

export const localhost: EnvConfiguration = {
    api: "https://lantis-dashboard-gateway-v1.azurewebsites.net/",
    assets: "https://lantisdashboard.blob.core.windows.net/assets/",
    authentication: {
        appId: "12075abd-cac9-4f38-a572-1fb3500d4160",
        tenant: "c941e798-f7a6-4b14-9441-a0bbd8ef42be",
        scopes: ["api://lantis-gateway-test/Dashboard.Access"],
        adminGroup: "4d06f4ca-1bca-4453-9005-e6652ca3c20b",
    },
};
