import { PanesOrientation, PaneState } from "./State";

export enum ActionType {
    SetActivePane,
    SetPaneDetails,
    SetPanes,
    SetPaneObject,

    // BIM, DOCUMENT
    SetPaneDocuments,
    SetPaneLegend,

    // GIS
    SetPaneBasemap,
    SetPaneScale,
    SetPaneThemes,

    // Data
    BeginLoading,
    FinishedLoading,

    // Toolbars
    TogglePaneSurfaceTool,
    TogglePaneMeasurementTool,
    TogglePaneLegend,
}

export interface SetActivePane {
    type: ActionType.SetActivePane;
    payload: { pane: number };
}

export interface SetPaneDetails {
    type: ActionType.SetPaneDetails;
    payload: { pane: number; state: PaneState };
}

export interface SetPanes {
    type: ActionType.SetPanes;
    payload: { count: number; orientation: PanesOrientation };
}

export interface SetPaneDocuments {
    type: ActionType.SetPaneDocuments;
    payload: { pane: number; documents: any[] };
}

export interface SetPaneBasemap {
    type: ActionType.SetPaneBasemap;
    payload: { pane: number; basemapId: string };
}

export interface SetPaneScale {
    type: ActionType.SetPaneScale;
    payload: { pane: number; scale: number };
}

export interface SetPaneThemes {
    type: ActionType.SetPaneThemes;
    payload: { pane: number; themes: any[] };
}

export interface BeginLoading {
    type: ActionType.BeginLoading;
    payload: { pane: number; id: string };
}

export interface FinishedLoading {
    type: ActionType.FinishedLoading;
    payload: { pane: number; id: string };
}

export interface SetPaneObject {
    type: ActionType.SetPaneObject;
    payload: { pane: number; obj: object };
}

export interface SetPaneLegend {
    type: ActionType.SetPaneLegend;
    payload: { pane: number; legend?: any };
}

export interface TogglePaneLegend {
    type: ActionType.TogglePaneLegend;
    payload: { pane: number };
}

export interface TogglePaneMeasurementTool {
    type: ActionType.TogglePaneMeasurementTool;
    payload: { pane: number };
}

export interface TogglePaneSurfaceTool {
    type: ActionType.TogglePaneSurfaceTool;
    payload: { pane: number };
}

export type AppAction =
    | SetActivePane
    | SetPaneDetails
    | SetPanes
    | SetPaneObject
    | SetPaneDocuments
    | SetPaneBasemap
    | SetPaneScale
    | SetPaneThemes
    | BeginLoading
    | FinishedLoading
    | SetPaneLegend
    | TogglePaneLegend
    | TogglePaneMeasurementTool
    | TogglePaneSurfaceTool;
