import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "@redux-devtools/extension";

import accountReducer from "./account/reducer";
import tokenReducer from "./token/reducer";
import selectionReducer from "./selection/reducer";
import objectPropertiesReducer from "./properties/reducer";
import objectRelationsReducer from "./relations/reducer";
import webMapsReducer from "./webMaps/reducer";
import appReducer from "./app/reducer";
import favoritesReducer from "./favorites/reducer";
import forgeReducer from "./forge/reducer";
import objectNamesReducer from "./objectNames/reducer";
import knownObjectIdsReducer from "./knownObjectIds/reducer";
import projectReducer from "./projects/reducer";
import pdfReducer from "./pdf/reducer";

export default createStore(
    combineReducers({
        accountReducer,
        tokenReducer,
        selectionReducer,
        objectPropertiesReducer,
        objectRelationsReducer,
        webMapsReducer,
        appReducer,
        favoritesReducer,
        forgeReducer,
        objectNamesReducer,
        knownObjectIdsReducer,
        projectReducer,
        pdfReducer,
    }),
    devToolsEnhancer()
);
