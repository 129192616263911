import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 4, 1),
    date: new Date(2023, 2, 1),
    content: (
        <>
            <ChangelogItem
                content="Element Selectie"
                type={ChangelogItemType.Bugfix}
                description="Het probleem bij het inladen van gerelateerde lagen, modellen, documenten en andere, bij het selecteren van een element met meer dan 130000 relaties werd verholpen."
            />
        </>
    ),
};

export default changelog;
