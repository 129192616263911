import { Component, createRef } from "react";
import { Box, CircularProgress, InputBase, Typography, alpha, styled } from "@mui/material";
import * as objectIndexConnector from "../../connectors/objectIndex";
import { connect } from "react-redux";
import { setQueuedSelectedObject } from "../../redux/selection/actions";
import { setSelectedRelationsTabId, setSelectedSidebarTabId } from "../../redux/app/actions";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../context/SplitPaneContext/State";
import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import StyledTooltip from "../generic/StyledTooltip";
import SearchIcon from "@mui/icons-material/Search";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { configuration } from "../../_configuration/configuration";

const Root = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
}));

const Inner = styled(Box)(({ theme }) => ({
    position: "relative",
    flex: 1,
    maxWidth: "650px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    color: theme.colors.textColor,
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(0, 2),
    cursor: "pointer",
    color: "#499C85",
    "&:hover": {
        color: "#327668",
    },
}));

const SearchResultsContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.white}`,
    boxShadow: `0 6px 18px ${theme.colors.black}29`,
    userSelect: "none",
    cursor: "pointer",
    maxHeight: "240px",
    overflowY: "auto",
    borderRadius: "0 0 4px 4px",
}));

const SearchResultItem = styled(Box)(({ theme }) => ({
    padding: "10px 16px",
    "&:hover": {
        backgroundColor: theme.colors.btnHover,
    },
}));

const Search = styled(Box)(({ theme }) => ({
    display: "flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "100%",
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignSelf: "center",
    color: "#499C85",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    flex: "1",
    "& .MuiInputBase-input": {
        height: 28,
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            searchVerb: "",
            objectNames: [],
            hideSearchResults: true,
            loading: false,
        };
        this.timer = null;
        this.innerRef = createRef();
    }

    componentDidMount() {
        document.addEventListener("click", this.hideSearchResultsOnClick);
        document.addEventListener("keyup", this.hideSearchResultsOnEscape);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hideSearchResultsOnClick);
        document.removeEventListener("keyup", this.hideSearchResultsOnEscape);
    }

    componentDidUpdate(prevProps, prevState) {
        const searchVerbHasChanged = this.state.searchVerb !== prevState.searchVerb;
        if (searchVerbHasChanged) {
            this.search();
        }
    }

    hideSearchResultsOnClick = (event) => {
        const isInner = this.innerRef.current === event.target;
        const isContainedInInner = this.innerRef.current.contains(event.target);

        if (isInner || isContainedInInner) {
            return;
        }

        this.hideSearchResults();
    };

    hideSearchResultsOnEscape = (event) => {
        const isEscape = event.key === "Escape";
        if (isEscape) {
            this.hideSearchResults();
        }
    };

    hideSearchResults = () => {
        this.setState((state) => ({
            hideSearchResults: true,
        }));
    };

    showSearchResults = () => {
        if (this.state.hideSearchResults) {
            this.setState((state) => ({
                hideSearchResults: false,
            }));
        }
    };

    toggleIsExpanded = () => {
        this.setState((state) => ({
            isExpanded: !state.isExpanded,
            searchVerb: "",
            objectNames: [],
        }));
    };

    search = () => {
        const { searchVerb } = this.state;
        if (this.timer) window.clearTimeout(this.timer);
        if (this.controller) this.controller.abort();

        this.setState({ loading: true });

        if (searchVerb && searchVerb.length) {
            this.controller = new AbortController();
            this.timer = window.setTimeout(() => {
                this.setState({
                    objectNames: [],
                    hideSearchResults: false,
                });

                objectIndexConnector.getObjectIndexByName(searchVerb, this.controller.signal).then((objectNames) => {
                    const objectNamesFiltered = objectNames.filter((objectName = "") => {
                        return objectName.indexOf(":") >= 0;
                    });
                    this.setState({
                        objectNames: objectNamesFiltered,
                        loading: false,
                    });
                });
                this.timer = null;
            }, configuration.settings.searchDelay);
        }
    };

    selectResult = (objectName = "") => {
        const { activePane, panesState } = this.context.state;
        const mode = panesState[activePane - 1]?.mode;
        const { setQueuedSelectedObject, setSelectedSidebarTabId, onSetSelectedRelationsTabId } = this.props;
        const objectId = objectName.split(":")[0];
        if (objectId) {
            setQueuedSelectedObject(objectId, mode === PaneMode.GIS ? configuration.sources.gis : configuration.sources.bim, true);
            setSelectedSidebarTabId("selection");
            onSetSelectedRelationsTabId(mode === PaneMode.GIS ? "maps" : mode === PaneMode.BIM ? "models" : "physicalObjectTree");
            this.hideSearchResults();
        }
    };

    render() {
        const { searchVerb, isExpanded, objectNames, hideSearchResults } = this.state;
        const hasSearchResults = Boolean(objectNames.length);

        return (
            <Root>
                <Inner ref={this.innerRef}>
                    {Boolean(isExpanded) && (
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                name="searchVerb"
                                placeholder="Typ hier de naam van een fysiek object"
                                onChange={(e) => this.setState({ searchVerb: e.target.value })}
                                onFocus={this.showSearchResults}
                                value={searchVerb}
                                autoFocus={true}
                                autoComplete="off"
                                inputProps={{ "aria-label": "search" }}
                            />
                        </Search>
                    )}
                    {!hideSearchResults &&
                        (Boolean(hasSearchResults) ? (
                            <SearchResultsContainer>
                                {objectNames.map((objectName) => {
                                    return (
                                        <SearchResultItem key={objectName} onClick={() => this.selectResult(objectName)}>
                                            <Typography>{objectName}</Typography>
                                        </SearchResultItem>
                                    );
                                })}
                            </SearchResultsContainer>
                        ) : (
                            <SearchResultsContainer>
                                {this.timer || this.state.loading ? (
                                    <CircularProgress
                                        sx={{
                                            position: "relative",
                                            top: "calc(50% - 20px)",
                                            left: "calc(50% - 20px)",
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    />
                                ) : (
                                    <SearchResultItem>
                                        <Typography>Geen zoekresultaten voor de ingevoerde zoekterm.</Typography>
                                    </SearchResultItem>
                                )}
                            </SearchResultsContainer>
                        ))}
                </Inner>

                <IconContainer onClick={this.toggleIsExpanded}>
                    {Boolean(isExpanded) ? (
                        <StyledTooltip title={"Zoeken sluiten..."}>
                            <FontAwesomeSvgIcon icon={faTimes} sx={{ maxHeight: 20 }} />
                        </StyledTooltip>
                    ) : (
                        <StyledTooltip title={"Zoeken..."}>
                            <FontAwesomeSvgIcon icon={faSearch} sx={{ maxHeight: 20 }} />
                        </StyledTooltip>
                    )}
                </IconContainer>
            </Root>
        );
    }
}

SearchBar.contextType = AppContext;

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    setQueuedSelectedObject: (objectId, source, isPhysicalObject) => dispatch(setQueuedSelectedObject(objectId, source, isPhysicalObject)),
    setSelectedSidebarTabId: (tabId) => dispatch(setSelectedSidebarTabId(tabId)),
    onSetSelectedRelationsTabId: (id) => dispatch(setSelectedRelationsTabId(id, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
