import { Box, useTheme } from "@mui/material";

const HistoryIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    stroke: "rgba(0,0,0,0)",
                }}
                d="M10.5,21A10.5,10.5,0,1,1,21,10.5,10.512,10.512,0,0,1,10.5,21ZM5.713,9.628V12.31H12.42V4.262H9.74V9.628Z"
                transform="translate(0.5 0.5)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.8" height="21" viewBox="0 0 22.8 21">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.placeholderText,
                }}
                d="M24.3,15a10.523,10.523,0,0,0-10.284,8.4H12L15,27l3-3.6H16.481a8.091,8.091,0,1,1,.834,6.206l-1.978,1.359A10.5,10.5,0,1,0,24.3,15Zm-.6,5.1v4.8H20.1v2.4h6V20.1Z"
                transform="translate(-12 -15)"
            />
        </svg>
    );
};

export default HistoryIcon;
