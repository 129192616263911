import { ContentUpdateType } from "./ContentUpdateItem";

export class ContentUpdate {
    id: string;
    date: Date;
    updates: Array<ContentEntry>;

    constructor(id: string, date: string, updates: Array<any>) {
        this.id = id;
        this.date = new Date(date);
        this.updates = ContentEntry.fromJson(updates);
    }

    static fromJson = (json: string) => {
        const parsed = JSON.parse(json);
        const result = new Array<ContentUpdate>();

        parsed.forEach((element: any) => {
            result.push(new ContentUpdate(element.id, element.datum, element.updates));
        });

        return result;
    };
}

export class ContentEntry {
    type: ContentUpdateType;
    title: string;
    description: string;

    constructor(type: string, title: string, description: string) {
        this.title = title;
        this.description = description;
        this.type = this.determineType(type);
    }

    determineType = (type: string): ContentUpdateType => {
        switch (type.toLowerCase()) {
            case "nieuw":
                return ContentUpdateType.New;
            case "update":
                return ContentUpdateType.Update;
            case "correctie":
                return ContentUpdateType.Correction;
            default:
                return ContentUpdateType.Unknown;
        }
    };

    static fromJson = (parsed: any) => {
        const result = new Array<ContentEntry>();

        parsed.forEach((element: any) => {
            result.push(new ContentEntry(element.type, element.titel, element.omschrijving));
        });

        return result;
    };
}
