import { Component } from "react";
import { Box } from "@mui/material";
import SelectPointer from "../../../../svg/selectionTabs/relationsTab/SelectPointer";
import { connect } from "react-redux";
import { setQueuedSelectedObject } from "../../../../../redux/selection/actions";
import StyledTooltip from "../../../../generic/StyledTooltip";

class SelectObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    toggleHovering = (isHovering) => {
        this.setState({ isHovering });
    };

    handleMouseEnter = (event) => {
        this.toggleHovering(true);
    };

    handleMouseLeave = () => {
        this.toggleHovering(false);
    };

    handleClick = (event) => {
        event.stopPropagation();
        const { onSelect, objectId, highlightIds, source, isPhysicalObject, onSetQueuedSelectedObject } = this.props;

        if (typeof onSelect === "function") {
            onSelect();
        } else {
            onSetQueuedSelectedObject(objectId, source, isPhysicalObject, highlightIds);
        }
    };

    render() {
        const { highlightIds = [], amount } = this.props;
        const { isHovering } = this.state;
        const multiSelect = Boolean(highlightIds.length);
        const tooltipText = multiSelect ? `Selecteer ${highlightIds.length} ${highlightIds.length === 1 ? "object" : "objecten"}` : "Selecteer";

        return (
            <StyledTooltip title={tooltipText}>
                <Box
                    sx={{
                        position: "relative",
                        marginLeft: "8px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleClick}
                >
                    <SelectPointer isSelected={isHovering} />
                    {Boolean(amount) && <span>({amount})</span>}
                </Box>
            </StyledTooltip>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    onSetQueuedSelectedObject: (objectId, source, isPhysicalObject, highlightIds) =>
        dispatch(setQueuedSelectedObject(objectId, source, isPhysicalObject, highlightIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectObject);
