import { PaneMode } from "../SplitPaneContext/State";
import {
    ActionType,
    AppAction,
    BeginLoading,
    FinishedLoading,
    SetActivePane,
    SetPaneBasemap,
    SetPaneDetails,
    SetPaneDocuments,
    SetPaneLegend,
    SetPaneObject,
    SetPanes,
    SetPaneScale,
    SetPaneThemes,
    TogglePaneLegend,
    TogglePaneMeasurementTool,
    TogglePaneSurfaceTool,
} from "./Actions";
import { AppState, BIMData, GISData, GISTools, PanesOrientation, PaneState } from "./State";

import store from "../../redux/store";
import { setSelectedSidebarTabId } from "../../redux/app/actions";

const verifySelectedTab = (activeMode: PaneMode) => {
    const activeTab = store.getState().appReducer.selectedSidebarTabId;
    switch (activeMode) {
        case PaneMode.NONE:
            if (["models", "layers", "documents"].some((x) => x === activeTab)) store.dispatch(setSelectedSidebarTabId("empty"));
            break;
        case PaneMode.BIM:
            if (["empty", "layers", "documents"].some((x) => x === activeTab)) store.dispatch(setSelectedSidebarTabId("models"));
            break;
        case PaneMode.GIS:
            if (["models", "empty", "documents"].some((x) => x === activeTab)) store.dispatch(setSelectedSidebarTabId("layers"));
            break;
        case PaneMode.DOCUMENT:
            if (["models", "layers", "empty"].some((x) => x === activeTab)) store.dispatch(setSelectedSidebarTabId("documents"));
            break;
    }
};

const determineTargetMode = (index: number): PaneMode => {
    switch (index) {
        case 0:
            return PaneMode.GIS;
        case 1:
            return PaneMode.BIM;
        case 2:
            return PaneMode.DOCUMENT;
        default:
            return PaneMode.NONE;
    }
};

export const determineTargetData = (mode: PaneMode): BIMData | GISData | undefined => {
    switch (mode) {
        case PaneMode.BIM:
        case PaneMode.DOCUMENT:
            return { type: "BIM", documents: [], loaded: [] };
        case PaneMode.GIS:
            return { type: "GIS", basemapId: "topo-vector", themes: [], loaded: [] };
    }

    return undefined;
};

export const appReducer = (state: AppState, action: AppAction) => {
    switch (action.type) {
        case ActionType.SetPanes: {
            const data = state.panesState.slice(0, action.payload.count);
            for (let i = 0; i < action.payload.count; i++) {
                if (!data[i]) {
                    const targetMode: PaneMode = determineTargetMode(i);
                    const paneData = determineTargetData(targetMode);
                    data[i] = { id: i + 1, mode: targetMode, appObject: undefined, data: paneData };
                }
            }

            let activePane = state.activePane;
            if (state.activePane > action.payload.count) activePane = 1;
            verifySelectedTab(data[activePane - 1].mode);

            return {
                ...state,
                activePane,
                panesCount: action.payload.count,
                panesOrientation: action.payload.orientation,
                panesState: data,
            };
        }

        case ActionType.SetActivePane: {
            verifySelectedTab(state.panesState[action.payload.pane - 1].mode);
            return { ...state, activePane: action.payload.pane };
        }

        case ActionType.SetPaneDetails: {
            const data = [...state.panesState];
            data[action.payload.pane - 1] = action.payload.state;
            verifySelectedTab(action.payload.state.mode);

            return { ...state, panesState: data };
        }

        case ActionType.SetPaneObject: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (pane) pane.appObject = action.payload.obj;

            return { ...state, panesState };
        }

        case ActionType.SetPaneDocuments: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (pane) {
                if (!pane.data) pane.data = determineTargetData(PaneMode.BIM);
                if (pane.data?.type === "BIM") pane.data.documents = action.payload.documents;
            }

            return { ...state, panesState };
        }

        case ActionType.SetPaneBasemap: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (pane) {
                if (!pane.data) pane.data = determineTargetData(PaneMode.GIS);
                if (pane.data?.type === "GIS") pane.data.basemapId = action.payload.basemapId;
            }

            return { ...state, panesState };
        }

        case ActionType.SetPaneScale: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (pane) {
                if (!pane.data) pane.data = determineTargetData(PaneMode.GIS);
                if (pane.data?.type === "GIS") pane.data.scale = action.payload.scale;
            }

            return { ...state, panesState };
        }

        case ActionType.SetPaneThemes: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (pane) {
                if (!pane.data) pane.data = determineTargetData(PaneMode.GIS);
                if (pane.data?.type === "GIS") pane.data.themes = action.payload.themes;
            }

            return { ...state, panesState };
        }

        case ActionType.BeginLoading: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (!pane) return state;

            if (pane.data && (pane.data.type === "BIM" || pane.data.type === "GIS")) {
                if (pane.data.loaded) {
                    pane.data.loaded = pane.data.loaded.filter((x) => x !== action.payload.id);
                }
            }

            return { ...state, panesState };
        }

        case ActionType.FinishedLoading: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);
            if (!pane) return state;

            if (pane.data && (pane.data.type === "BIM" || pane.data.type === "GIS")) {
                if (!pane.data.loaded) pane.data.loaded = [];

                const loaded = [...pane.data.loaded];
                loaded.push(action.payload.id);
                pane.data.loaded = loaded;
            }

            return { ...state, panesState };
        }

        case ActionType.SetPaneLegend: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);

            if (!pane || !pane.data) return state;

            if (pane.data.type === "BIM") {
                pane.data.legend = action.payload.legend;
            }

            return { ...state, panesState };
        }

        case ActionType.TogglePaneLegend: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);

            if (!pane || !pane.data) return state;

            if (pane.data.type === "BIM" || pane.data.type === "GIS") {
                if (!pane.data.tools) {
                    if (pane.mode === PaneMode.BIM) pane.data.tools = { legend: false };
                    else if (pane.mode === PaneMode.GIS) pane.data.tools = { legend: false, measureTool: false, surfaceTool: false };
                }

                const tools = pane.data.tools!;
                tools.legend = !tools.legend;
            }

            return { ...state, panesState };
        }

        case ActionType.TogglePaneMeasurementTool: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);

            if (!pane || !pane.data) return state;

            if (pane.data.type === "GIS") {
                if (pane.mode !== PaneMode.GIS) return state;

                if (!pane.data.tools) {
                    if (pane.mode === PaneMode.GIS) pane.data.tools = { legend: false, measureTool: false, surfaceTool: false };
                }

                const tools = pane.data.tools as GISTools;
                tools.measureTool = !tools.measureTool;
                tools.surfaceTool = false;
            }

            return { ...state, panesState };
        }

        case ActionType.TogglePaneSurfaceTool: {
            const panesState = [...state.panesState];
            const pane = panesState.find((x) => x.id === action.payload.pane);

            if (!pane || !pane.data) return state;

            if (pane.data.type === "GIS") {
                if (pane.mode !== PaneMode.GIS) return state;

                if (!pane.data.tools) {
                    if (pane.mode === PaneMode.GIS) pane.data.tools = { legend: false, measureTool: false, surfaceTool: false };
                }

                const tools = pane.data.tools as GISTools;
                tools.measureTool = false;
                tools.surfaceTool = !tools.surfaceTool;
            }

            return { ...state, panesState };
        }

        default:
            return state;
    }
};

export const setPanes = (count: number, orientation: PanesOrientation): SetPanes => ({
    type: ActionType.SetPanes,
    payload: { count, orientation },
});

export const setActivePane = (pane: number): SetActivePane => ({
    type: ActionType.SetActivePane,
    payload: { pane },
});

export const setPaneDetails = (pane: number, state: PaneState): SetPaneDetails => ({
    type: ActionType.SetPaneDetails,
    payload: { pane, state },
});

export const setPaneObject = (pane: number, obj: object): SetPaneObject => ({
    type: ActionType.SetPaneObject,
    payload: { pane, obj },
});

export const setPaneDocuments = (pane: number, documents: any[]): SetPaneDocuments => ({
    type: ActionType.SetPaneDocuments,
    payload: { pane, documents },
});

export const setPaneBasemap = (pane: number, basemapId: string): SetPaneBasemap => ({
    type: ActionType.SetPaneBasemap,
    payload: { pane, basemapId },
});

export const setPaneScale = (pane: number, scale: number): SetPaneScale => ({
    type: ActionType.SetPaneScale,
    payload: { pane, scale },
});

export const setPaneThemes = (pane: number, themes: any[]): SetPaneThemes => ({
    type: ActionType.SetPaneThemes,
    payload: { pane, themes },
});

export const beginLoading = (pane: number, id: string): BeginLoading => ({
    type: ActionType.BeginLoading,
    payload: { pane, id },
});

export const finishedLoading = (pane: number, id: string): FinishedLoading => ({
    type: ActionType.FinishedLoading,
    payload: { pane, id },
});

export const setPaneLegend = (pane: number, legend: any): SetPaneLegend => ({
    type: ActionType.SetPaneLegend,
    payload: { pane, legend },
});

export const togglePaneLegend = (pane: number): TogglePaneLegend => ({
    type: ActionType.TogglePaneLegend,
    payload: { pane },
});

export const togglePaneMeasurementTool = (pane: number): TogglePaneMeasurementTool => ({
    type: ActionType.TogglePaneMeasurementTool,
    payload: { pane },
});

export const togglePaneSurfaceTool = (pane: number): TogglePaneSurfaceTool => ({
    type: ActionType.TogglePaneSurfaceTool,
    payload: { pane },
});
