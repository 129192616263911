const SplitScreenFourDefaultIcon = ({ className, style }) => {
    return (
        <svg
            version="1.1"
            id="split-screen-4-default"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={style}
        >
            <path
                style={{ fill: "currentcolor" }}
                d="M464,32H48C21.5,32,0,53.5,0,80v352c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V80C512,53.5,490.5,32,464,32z M240,448
                    H47.2c-8.4,0-15.2-6.8-15.2-15.2V288h208V448z M240,255.7H32v-160h208V255.7z M480,432.8c0,8.4-6.8,15.2-15.2,15.2H272V288h208
                    V432.8z M480,255.7H272v-160h208V255.7z"
            />
        </svg>
    );
};

export default SplitScreenFourDefaultIcon;
