import { AppConfiguration, EnvConfiguration } from "./AppConfiguration";
import { localhost } from "./localhost";
import { development } from "./development";
import { production } from "./production";

const getEnvConfiguration = (env?: string): EnvConfiguration => {
    switch (env) {
        case "development":
            return development;
        case "production":
            return production;
        default:
            return localhost;
    }
};

export const configuration: AppConfiguration = {
    muiLicenseKey: "7f0559479f20532440d7caf0d8c9cdadTz02MzQ2OSxFPTE3MTIxMjk1Njk5NTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    settings: {
        // Application searchbar search delay in ms
        searchDelay: 400,
    },
    relationTypes: {
        GIS_IS_GELINKT_AAN_FYSIEK_OBJECT: "GIS_IS_GELINKT_AAN_FYSIEK_OBJECT",
        GIS_IS_GELINKT_AAN_LOGISCH_OBJECT: "GIS_IS_GELINKT_AAN_LOGISCH_OBJECT",
        BIM_IS_GELINKT_AAN_FYSIEK_OBJECT: "BIM_IS_GELINKT_AAN_FYSIEK_OBJECT",
        BIM_IS_GELINKT_AAN_LOGISCH_OBJECT: "BIM_IS_GELINKT_AAN_LOGISCH_OBJECT",
        PDF_IS_GELINKT_AAN_FYSIEK_OBJECT: "PDF_IS_GELINKT_AAN_FYSIEK_OBJECT",
        PDF_IS_GELINKT_AAN_LOGISCH_OBJECT: "PDF_IS_GELINKT_AAN_LOGISCH_OBJECT",
        HEEFT_BESTEKSEIS: "HEEFT_BESTEKSEIS",
        HEEFT_SYSTEEMEIS: "HEEFT_SYSTEEMEIS",
        HEEFT_BOVENLIGGEND_FYSIEK_OBJECT: "HEEFT_BOVENLIGGEND_FYSIEK_OBJECT",
        HEEFT_BOVENLIGGEND_LOGISCH_OBJECT: "HEEFT_BOVENLIGGEND_LOGISCH_OBJECT",
    },
    sources: {
        bim: "forge",
        gis: "arcgis",
        pdf: "document",
    },
    ...getEnvConfiguration(process.env.REACT_APP_STAGE),
};
