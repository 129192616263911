import React from "react";
import { Box, useTheme } from "@mui/material";

const FavoritesIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.904" height="21" viewBox="0 0 21.904 21">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    //stroke: theme.colors.secondaryDark,
                }}
                d="M104.8,23.156l4.735.683a1.637,1.637,0,0,1,.91,2.791l-3.421,3.342a1.633,1.633,0,0,0-.469,1.45l.814,4.714A1.637,1.637,0,0,1,105,37.864l-4.237-2.221a1.638,1.638,0,0,0-1.524,0l-4.232,2.231a1.637,1.637,0,0,1-2.377-1.721l.8-4.715a1.642,1.642,0,0,0-.473-1.448l-3.429-3.34a1.637,1.637,0,0,1,.9-2.792l4.733-.693a1.639,1.639,0,0,0,1.232-.9l2.112-4.293a1.637,1.637,0,0,1,2.936,0l2.123,4.293a1.634,1.634,0,0,0,1.232.893Z"
                transform="translate(-89.034 -17.062)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.408" height="23.639" viewBox="0 0 24.408 23.639">
            <Box
                component={"path"}
                sx={{
                    fill: "none",
                    stroke: theme.colors.placeholderText,
                    strokeMiterlimit: 10,
                    strokeWidth: "2.5px",
                }}
                d="M104.8-4.891l4.735.683a1.637,1.637,0,0,1,1.386,1.854,1.646,1.646,0,0,1-.476.937l-3.421,3.342a1.633,1.633,0,0,0-.469,1.45l.814,4.714A1.637,1.637,0,0,1,105,9.817L100.761,7.6a1.638,1.638,0,0,0-1.524,0L95.005,9.827a1.637,1.637,0,0,1-2.377-1.721l.8-4.715a1.644,1.644,0,0,0-.473-1.448L89.529-1.4a1.635,1.635,0,0,1-.03-2.315,1.634,1.634,0,0,1,.934-.477l4.733-.693a1.639,1.639,0,0,0,1.232-.9l2.112-4.293a1.636,1.636,0,0,1,2.192-.742,1.633,1.633,0,0,1,.744.742l2.123,4.293a1.634,1.634,0,0,0,1.232.893Z"
                transform="translate(-87.784 12.243)"
            />
        </svg>
    );
};

export default FavoritesIcon;
