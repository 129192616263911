import { faMap } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectionNameMapsIcon = ({ selected }) => {
    const theme = useTheme();

    const defaultStyle = {
        color: theme.colors.textColor,
    };

    const selectedStyle = {
        color: theme.colors.primaryText,
    };

    let style;
    if (selected) style = selectedStyle;
    else style = defaultStyle;

    return <FontAwesomeIcon icon={faMap} style={style} />;
};

export default SelectionNameMapsIcon;
