import { subDays } from "date-fns";
import { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { ContentUpdate } from "./ContentUpdate";
import ContentUpdateModal from "./ContentUpdateModal";
import sortBy from "lodash/sortBy";
import { addViewedUpdate } from "../../../connectors/userState";

export interface ContentUpdateManagerProps {
    activeProject: any;
    suppressedNotifications: boolean;
    updates: any;
    userState: any;
}

const ContentUpdateManager = ({ suppressedNotifications, userState, activeProject, updates }: ContentUpdateManagerProps) => {
    const [shouldShow, setShouldShow] = useState(false);
    const [contentUpdate, setContentUpdate] = useState<ContentUpdate>();

    useLayoutEffect(() => {
        const data = ContentUpdate.fromJson(JSON.stringify(updates));
        const filtered = data.filter((x) => x.date > subDays(new Date(), 14));
        const sorted = sortBy(filtered, (x) => x.date).reverse();

        if (sorted.length >= 1) {
            setContentUpdate(sorted[0]);
        }
    }, [updates]);

    useLayoutEffect(() => {
        if (!contentUpdate || !userState || suppressedNotifications) return;

        const state = userState.find((x: any) => x.projectId === activeProject.name);
        if (!state || !state.contentUpdates) {
            setShouldShow(true);
            return;
        }

        setShouldShow(!state.contentUpdates.some((x: string) => x === contentUpdate.id));
    }, [userState, activeProject, suppressedNotifications, contentUpdate]);

    const onConfirm = () => {
        if (!contentUpdate) return;

        addViewedUpdate(contentUpdate.id);
        setShouldShow(false);
    };

    return contentUpdate ? <ContentUpdateModal open={shouldShow} update={contentUpdate} onClose={onConfirm} /> : null;
};

const mapStateToProps = ({ accountReducer, appReducer, projectReducer }: any) => ({
    activeProject: projectReducer.activeProject,
    suppressedNotifications: appReducer.suppressedNotifications,
    updates: appReducer.updates,
    userState: accountReducer.state,
});

export default connect(mapStateToProps)(ContentUpdateManager);
