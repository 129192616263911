import { configuration } from "../../_configuration/configuration";

const Autodesk = window.Autodesk;

class SplitPaneSyncExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;
        this.options = options;

        this.actions = [
            {
                id: "sync-sections-button",
                icon: "adsk-icon-plane-x",
                desc: "Sectie synchroniseren",
                action: options.onSyncSections,
            },
            {
                id: "sync-camera-button",
                icon: "adsk-icon-camera",
                desc: "Camera synchroniseren",
                action: options.onSyncCamera,
            },
            {
                id: "sync-button",
                icon: "sync-alt",
                desc: "Synchronisatie",
                action: () => null,
            },
        ];
    }

    load = () => {
        this.setupCss();

        return true;
    };

    setupCss = () => {
        let styleElement = document.getElementById("split-pane-sync-extension-styles");

        if (styleElement) return;

        const { actions } = this;
        let css = "";
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            action.className = action.icon;

            if (action.icon.startsWith("adsk-icon")) continue;

            action.className = `spse-button-${i}`;

            css += `
.${action.className} {
    background-color: #ffffff;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url(${configuration.assets}fa/light/${action.icon}.svg);
    mask-image: url(${configuration.assets}fa/light/${action.icon}.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.active > .${action.className},
.inactive:hover > .${action.className} {
    background-color: #00bfff;
}
`;
        }

        styleElement = document.createElement("style");
        styleElement.id = "fake-sync-extension-styles";
        styleElement.type = "text/css";
        styleElement.innerHTML = css;

        document.getElementsByTagName("head")[0].appendChild(styleElement);
    };

    unload = () => {
        return true;
    };

    onToolbarCreated = (toolbar) => {
        const fseToolbar = new Autodesk.Viewing.UI.RadioButtonGroup("spse-toolbar");

        this.groupButton = new Autodesk.Viewing.UI.ComboButton(this.actions[this.actions.length - 1].id);
        this.groupButton.setToolTip(this.actions[this.actions.length - 1].desc);
        this.groupButton.setIcon(this.actions[this.actions.length - 1].className);
        this.groupButton.addClass("disabled");

        for (let i = 0, len = this.actions.length - 1; i < len; i++) {
            const button = this.actions[i];

            const actionButton = new Autodesk.Viewing.UI.Button(`spse-button-${i}`);
            actionButton.setToolTip(button.desc);
            actionButton.setIcon(button.className);
            actionButton.onClick = button.action;

            this.groupButton.addControl(actionButton);
        }

        fseToolbar.addControl(this.groupButton);
        toolbar.addControl(fseToolbar);
    };

    updateStatus = (enabled) => {
        if (!this.groupButton) return;

        if (enabled) this.groupButton.removeClass("disabled");
        else this.groupButton.addClass("disabled");
    };
}

export default SplitPaneSyncExtension;
