import axios from "axios";
import { configuration } from "../_configuration/configuration";

const instance = axios.create({
    baseURL: configuration.api,
    validateStatus: (status: number) => {
        return (status >= 200 && status < 300) || status === 404;
    },
});

export default instance;
