import { Box, useTheme } from "@mui/material";

const SelectPointer = ({ isSelected = false }) => {
    const theme = useTheme();
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.771" height="14" viewBox="0 0 10.771 14">
            <Box
                component={"path"}
                sx={{
                    fill: isSelected ? theme.colors.secondarySelection : `${theme.colors.secondarySelection}4D`,
                }}
                d="M23.9,15.7v14l4.7-4.632,6.066.1Z"
                transform="translate(-23.898 -15.699)"
            />
        </svg>
    );
};

export default SelectPointer;
