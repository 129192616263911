import { Box, useTheme } from "@mui/material";

const ArrowDown = ({ selected }) => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7.486" height="10" viewBox="0 0 7.486 10">
            <Box
                component={"path"}
                sx={{
                    fill: selected ? theme.colors.textColor : theme.colors.placeholderText,
                }}
                d="M29.569,16.661l-3.743-3.743-3.743,3.743.782.782,2.4-2.4v7.877h1.118V15.041l2.4,2.4Z"
                transform="translate(29.568 22.918) rotate(180)"
            />
        </svg>
    );
};

export default ArrowDown;
