import { useState } from "react";
import { connect } from "react-redux";
import { AppBar, Box, Button, Toolbar, useTheme } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

import SearchBar from "./titlebar/SearchBar";
import HelpBar from "./titlebar/HelpBar";
import OptionsBar from "./titlebar/OptionsBar";
import ObjectViewModal from "./generic/ObjectViewModal";

import { removeAuthentication } from "../redux/account/actions";

import logo from "../assets/logo.svg";

const NOT_LOGGED_IN_USERNAME = "LOGIN";

const Header = ({ isAuthenticated, account, authButtonMethod }) => {
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const theme = useTheme();

    const handleAccountInfoClick = () => {
        if (accountInfoOpen) {
            setAccountInfoOpen(false);
            return;
        }

        if (isAuthenticated) {
            setAccountInfoOpen(true);
            return;
        }

        authButtonMethod();
    };

    const logout = () => {
        setAccountInfoOpen(false);
        authButtonMethod();
    };

    return (
        <Box sx={{ zIndex: 1000 }}>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: theme.palette.primary,
                }}
            >
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        component={"img"}
                        src={logo}
                        alt="Logo"
                        sx={{
                            display: "none",
                            [theme.breakpoints.up("sm")]: {
                                display: "block",
                                height: "3rem",
                            },
                        }}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                        }}
                    >
                        {isAuthenticated && <SearchBar />}
                        {isAuthenticated && <OptionsBar />}
                        <HelpBar />
                    </Box>

                    <Button variant="contained" color="secondary" onClick={handleAccountInfoClick}>
                        <AccountCircle sx={{ mr: theme.spacing(1) }} />
                        {isAuthenticated ? account?.displayName : NOT_LOGGED_IN_USERNAME}
                    </Button>
                </Toolbar>
            </AppBar>

            <ObjectViewModal
                title={"Gebruiker"}
                object={account}
                open={accountInfoOpen}
                handleClose={handleAccountInfoClick}
                keys={[
                    {
                        key: "displayName",
                        label: "Naam",
                    },
                    {
                        key: "email",
                        label: "E-mailadres",
                    },
                ]}
                actions={[
                    {
                        title: "Uitloggen",
                        method: () => {
                            const yes = window.confirm("Bent u zeker dat u wenst uit te loggen? De applicatie kan dan niet meer worden gebruikt.");
                            if (yes) logout();
                        },
                    },
                ]}
            />
        </Box>
    );
};

const mapStateToProps = ({ accountReducer }) => ({
    account: accountReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
    onSetLogout: () => dispatch(removeAuthentication()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
