import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setQueuedSelectedObject } from "../../../redux/selection/actions";
import { setSelectedSidebarTabId } from "../../../redux/app/actions";
import ObjectName from "../../generic/ObjectName";
import Icon from "../../svg/selectionTabs/Icon";
import { faMap, faCube } from "@fortawesome/pro-solid-svg-icons";
import FavoriteToggle from "../../generic/FavoriteToggle";
import { configuration } from "../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    "& h2": {
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const Container = styled(Box)(() => ({
    flex: 1,
    overflowY: "auto",
}));

const HistoryItem = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    userSelect: "none",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.secondarySelectionHover,
    },
}));

const NameContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const NameValue = styled(Box)(({ theme }) => ({
    marginLeft: "12px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
    color: theme.colors.textColor,
    wordBreak: "break-word",
}));

const TimestampContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const TimestampValue = styled(Box)(({ theme }) => ({
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "12px",
    color: theme.colors.placeholderText,
    marginLeft: "10px",
    marginRight: "10px",
}));

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveringIndex: null,
        };
    }

    openHistoryItem = (objectId, source) => {
        this.props.onSetQueuedSelectedObject(objectId, source);
        this.props.onSetSelectedSidebarTabId("selection");
    };

    setHoveringIndex = (hoveringIndex) => {
        this.setState({ hoveringIndex });
    };

    renderIcon = (source) => {
        switch (source) {
            case configuration.sources.gis: {
                return <Icon icon={faMap} />;
            }

            case configuration.sources.bim: {
                return <Icon icon={faCube} />;
            }

            default: {
                return null;
            }
        }
    };

    renderHistory = () => {
        const { history } = this.props;

        return history.map(({ objectId, source, timestamp }, index) => {
            return (
                <HistoryItem key={index} onClick={() => this.openHistoryItem(objectId, source)}>
                    <NameContainer>
                        {this.renderIcon(source)}
                        <NameValue>
                            <ObjectName objectId={objectId} shouldGetObjectName shouldCheckLinkedObject />
                        </NameValue>
                    </NameContainer>
                    <TimestampContainer>
                        <TimestampValue>{timestamp}</TimestampValue>
                        <div>
                            <FavoriteToggle objectId={objectId} source={source} />
                        </div>
                    </TimestampContainer>
                </HistoryItem>
            );
        });
    };

    render() {
        const { title } = this.props;

        return (
            <Root>
                <h2>{title}</h2>
                <Container>{this.renderHistory()}</Container>
            </Root>
        );
    }
}

const mapStateToProps = ({ selectionReducer }) => ({
    history: selectionReducer.fullHistory,
});

const mapDispatchToProps = (dispatch) => ({
    onSetQueuedSelectedObject: (objectId, source) => dispatch(setQueuedSelectedObject(objectId, source)),
    onSetSelectedSidebarTabId: (selectedSidebarTabId) => dispatch(setSelectedSidebarTabId(selectedSidebarTabId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(History);
