const getFavoriteObjects = () => {
    let favoriteObjects = [];
    try {
        const storedFavoriteObjectIds = JSON.parse(localStorage.getItem("favoriteObjects"));
        if (Array.isArray(storedFavoriteObjectIds)) {
            favoriteObjects = storedFavoriteObjectIds;
        }
    } catch (error) {
        // console.error('favorites/reducer - getFavoriteObjectsFromLocalStorage error: ', error)
    }
    return favoriteObjects;
}

export default getFavoriteObjects;