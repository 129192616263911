import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/pro-light-svg-icons";
import { faMap as faMapSolid } from "@fortawesome/pro-solid-svg-icons";

const MapsIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <FontAwesomeIcon icon={faMapSolid} style={{ color: theme.colors.white }} />
    ) : (
        <FontAwesomeIcon icon={faMap} style={{ color: theme.colors.black }} />
    );
};

export default MapsIcon;
