import changelog_v2_0_0 from "./v2_0_0";
import changelog_v2_1_0 from "./v2_1_0";
import changelog_v2_1_1 from "./v2_1_1";
import changelog_v2_2_0 from "./v2_2_0";
import changelog_v2_3_0 from "./v2_3_0";
import changelog_v2_3_1 from "./v2_3_1";
import changelog_v2_4_0 from "./v2_4_0";
import changelog_v2_4_1 from "./v2_4_1";
import changelog_v2_5_0 from "./v2_5_0";

const changelog = [
    changelog_v2_5_0,
    changelog_v2_4_1,
    changelog_v2_4_0,
    changelog_v2_3_1,
    changelog_v2_3_0,
    changelog_v2_2_0,
    changelog_v2_1_1,
    changelog_v2_1_0,
    changelog_v2_0_0,
];

export default changelog;
