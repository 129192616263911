import { useTheme } from "@mui/material";
import { faCube } from "@fortawesome/pro-regular-svg-icons";
import { faCube as faCubeSolid } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

const ModelsIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <FontAwesomeSvgIcon
            icon={faCubeSolid}
            sx={{
                width: "22px!important",
                height: "22px!important",
                color: theme.colors.secondaryDark,
            }}
        />
    ) : (
        <FontAwesomeSvgIcon
            icon={faCube}
            sx={{
                width: "22px!important",
                height: "22px!important",
                color: theme.colors.placeholderText,
            }}
        />
    );
};

export default ModelsIcon;
