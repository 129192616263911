import { connect } from "react-redux";
import { Box, styled } from "@mui/material";

import PropertiesOverlay from "./sidebar/PropertiesOverlay";
import RelationsOverlay from "./sidebar/RelationsOverlay";

const Container = styled(Box)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    pointerEvents: "none",
}));

const Overlays = ({ relationsOverlayOpen, propertiesOverlayOpen }) => {
    return (
        <Container>
            {Boolean(propertiesOverlayOpen) && <PropertiesOverlay />}
            {Boolean(relationsOverlayOpen) && <RelationsOverlay />}
        </Container>
    );
};

const mapStateToProps = (state) => ({
    relationsOverlayOpen: state.appReducer.relationsOverlayOpen,
    propertiesOverlayOpen: state.appReducer.propertiesOverlayOpen,
});

export default connect(mapStateToProps)(Overlays);
