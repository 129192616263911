import { useEffect, useState } from "react";
import { Box, Button, Paper, Typography, styled } from "@mui/material";

const Root = styled(Box)(() => ({
    zIndex: 20000,
    backgroundColor: "#000000CE",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
}));

const Container = styled(Paper)(() => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: 150,
    padding: 30,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
}));

const Content = styled(Box)(() => ({
    alignSelf: "center",
    "& :last-of-type": {
        marginLeft: 30,
    },
}));

const CookieConsent = () => {
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem("cookie-consent");
        if (consent) return;
        setShouldShow(true);
    }, []);

    const onConfirm = () => {
        localStorage.setItem("cookie-consent", "1");
        setShouldShow(false);
    };

    return shouldShow ? (
        <Root>
            <Container elevation={4} square>
                <Content>
                    <Typography>
                        Onze applicatie, BIM Dashboard, maakt gebruik van cookies en daarmee vergelijkbare technieken. We gebruiken analytische cookies om het
                        gedrag van gebruikers na te gaan en de applicatie aan de hand van deze gegevens te verbeteren. Daarnaast gebruikt de applicatie ook
                        cookies voor de authenticatie.
                    </Typography>
                </Content>
                <Content>
                    <Button color="primary" variant="contained" onClick={() => onConfirm()}>
                        Doorgaan
                    </Button>
                </Content>
            </Container>
        </Root>
    ) : null;
};

export default CookieConsent;
