import { useContext, useEffect, useRef, useState } from "react";
import { AlphaPicker } from "react-color";
import { connect } from "react-redux";
import { loadModules } from "esri-loader";

import { getFlatLayersArray } from "../../utils/layerFunctions";
import { showLayerProperties } from "../../redux/webMaps/actions";

import { Box, Button, styled } from "@mui/material";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

const Root = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "64px",
    right: 0,
    zIndex: 1000,
    minHeight: "100%",
    maxHeight: "100%",
    borderLeft: `1px solid ${theme.colors.inputBorder}`,
    width: "420px",
    display: "flex",
    flexDirection: "column",
    transition: "margin-right 0.2s ease-in",
    backgroundColor: "#FFFFFF",
    userSelect: "none",
    "& h2": {
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const CloseButton = styled("span")(() => ({
    float: "right",
    fontSize: "14px",
    lineHeight: "20px",
    verticalAlign: "middle",
    cursor: "pointer",
}));

const ContentGroup = styled(Box)(({ theme }) => ({
    paddingTop: "18px",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderColor: theme.colors.inputBorder,
    color: "#1F293C",
    fontSize: "12px",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 600,
    whiteSpace: "normal",
    "& h3": {
        marginTop: 0,
        marginBottom: "0",
        padding: "12px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "14px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const RangeSlider = styled(Box)(() => ({
    pointerEvents: "none",
}));

const ContentGroupData = styled(Box)(() => ({
    padding: "18px",
}));

const ActionsContainer = styled(Box)(() => ({
    display: "flex",
    flexFlow: "column",
    padding: "18px",
}));

const LayerProperties = (props) => {
    const { state } = useContext(AppContext);

    const [visible, setVisible] = useState(false);
    const [layerInfo, setLayerInfo] = useState();
    const [scaleRangeSliderWidget, setScaleRangeSliderWidget] = useState();

    const [opacityColor, setOpacityColor] = useState();
    const [opacity, setOpacity] = useState();

    const scaleRangeSliderRef = useRef();

    useEffect(() => {
        if (!props.layerId) {
            setVisible(false);
            return;
        }

        const { activePane, panesState } = state;
        const { data, appObject } = panesState[activePane - 1];
        if (!data) {
            setVisible(false);
            return;
        }

        const { themes } = data;

        const info = getFlatLayersArray(themes).find((layer) => layer.id === props.layerId);
        if (!info) {
            setVisible(false);
            return;
        }

        setLayerInfo(info);
        setVisible(true);

        setOpacityColor({ h: 0, s: 0, l: 0, a: info.layer.opacity });
        setOpacity(info.layer.opacity);

        if (!scaleRangeSliderWidget) {
            loadModules(["esri/widgets/ScaleRangeSlider"]).then(([ScaleRangeSlider]) => {
                if (!appObject) return;

                setScaleRangeSliderWidget(
                    new ScaleRangeSlider({
                        view: appObject,
                        layer: info.layer,
                        region: "BE",
                        container: scaleRangeSliderRef.current,
                    })
                );
            });
        } else {
            scaleRangeSliderWidget.minScale = info.layerDefinition ? info.layerDefinition.minScale : info.layer.minScale;
            scaleRangeSliderWidget.maxScale = info.layerDefinition ? info.layerDefinition.maxScale : info.layer.maxScale;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.layerId, state.activePane]);

    useEffect(() => {
        if (!visible) return;
        if (!props.layerId) return;

        const { activePane, panesState } = state;
        const { themes } = panesState[activePane - 1].data;

        const info = getFlatLayersArray(themes).find((layer) => layer.id === props.layerId);
        if (!info) return;

        if (!info.visible) setVisible(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.panesState]);

    useEffect(() => {
        if (!visible && scaleRangeSliderWidget) {
            scaleRangeSliderWidget.destroy();
            setScaleRangeSliderWidget(null);
        }
    }, [visible, scaleRangeSliderWidget]);

    const handleChangeComplete = (data) => {
        if (data.hsl.a !== opacity) {
            setOpacityColor(data.hsl);
            setOpacity(data.hsl.a);
            layerInfo.layer.opacity = data.hsl.a;
        }
    };

    const zoomToLayer = () => {
        if (!layerInfo) return;

        const { activePane, panesState } = state;
        const { appObject } = panesState[activePane - 1];

        const layer = layerInfo.layer;

        const query = layer.createQuery();
        query.outSpatialReference = appObject.spatialReference;

        layer.queryExtent(query).then((response) => {
            appObject.goTo(response.extent);
        });
    };

    return visible ? (
        <Root>
            <h2>
                <Box sx={{ display: "flex" }}>
                    Eigenschappen
                    <Box sx={{ ml: "auto", alignSelf: "center" }}>
                        <CloseButton>
                            <FontAwesomeIcon icon={faXmark} onClick={() => props.onShowLayerProperties(null)} />
                        </CloseButton>
                    </Box>
                </Box>
            </h2>

            <ContentGroup>
                <h3>Kaartlaag</h3>
                <ContentGroupData>{layerInfo.title}</ContentGroupData>
            </ContentGroup>

            <ContentGroup>
                <h3>Opaciteit</h3>
                <ContentGroupData>
                    <AlphaPicker color={opacityColor} onChange={handleChangeComplete} width={"100%"} />
                </ContentGroupData>
            </ContentGroup>

            <ContentGroup>
                <h3>Zichtbaar bereik</h3>
                <ContentGroupData>
                    <RangeSlider ref={scaleRangeSliderRef} />
                </ContentGroupData>
            </ContentGroup>

            {layerInfo.sourceJson.layerType === "ArcGISFeatureLayer" && (
                <ActionsContainer>
                    <Button variant={"contained"} onClick={() => zoomToLayer()}>
                        Zoom naar layer...
                    </Button>
                </ActionsContainer>
            )}
        </Root>
    ) : null;
};

const mapStateToProps = ({ webMapsReducer }) => ({
    layerId: webMapsReducer.propertiesLayer,
});

const mapDispatchToProps = (dispatch) => ({
    onShowLayerProperties: (layerId) => dispatch(showLayerProperties(layerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerProperties);
