import { Box, Popover, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppContext } from "../../context/AppContext/AppContextProvider";

const Root = styled(Popover)(({ theme }) => ({
    color: theme.colors.textColor,
    userSelect: "none",
    pointerEvents: "none",
}));

const Feature = styled(Box)(({ theme }) => ({
    padding: "4px 8px",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.hover,
    },
}));

const FeaturePicker = (props) => {
    const [open, setOpen] = useState(false);
    const { state } = useContext(AppContext);

    useEffect(() => {
        if (!Boolean(props?.options?.features)) return;
        setOpen(props.options.features.length > 0);
    }, [props.options]);

    useEffect(() => {
        if (state.activePane !== props.paneId) setOpen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.activePane]);

    const handleMouseEnter = (objectId) => {
        props.setHighlightIds(objectId);
    };

    const handleMouseLeave = () => {
        props.removeHighlightIds();
    };

    const { options, callback } = props;
    const { event, features } = options;

    return (
        <Root
            anchorReference="anchorPosition"
            anchorPosition={{ top: event.y + 50, left: event.x + props.container.current.getBoundingClientRect().x }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={open}
            onClose={() => setOpen(false)}
            onMouseLeave={() => handleMouseLeave()}
            PaperProps={{
                sx: {
                    pointerEvents: "all",
                },
            }}
        >
            {features.map((feature, index) => {
                const { objectId, label, title } = feature;
                const splitTitle = title.split(" - ");
                const partialTitle = splitTitle[splitTitle.length - 1];
                const mappedName = props[objectId];

                return (
                    <Feature key={`FeaturePicker__${objectId}__${index}`} onClick={() => callback(feature)} onMouseEnter={() => handleMouseEnter(objectId)}>
                        {mappedName || `${partialTitle} - ${label}`}
                    </Feature>
                );
            })}
        </Root>
    );
};

const mapStateToProps = ({ objectNamesReducer }) => ({
    objectNames: objectNamesReducer.names,
});

export default connect(mapStateToProps)(FeaturePicker);
