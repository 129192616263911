import { useLayoutEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { ContentEntry, ContentUpdate } from "./ContentUpdate";
import ContentUpdateItem from "./ContentUpdateItem";
import CloseButton from "../../generic/CloseButton";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

export interface ContentUpdateModalProps {
    update: ContentUpdate;
    open: boolean;
    onClose: () => void;
}

const ContentUpdateModal = ({ open, onClose, update }: ContentUpdateModalProps) => {
    const [content, setContent] = useState<JSX.Element>();

    useLayoutEffect(() => {
        if (!update) return;

        const prefix = <Typography sx={{ marginBottom: 16 }}>De inhoud van de applicatie werd geüpdatet, hierbij een overzicht van de wijzigingen:</Typography>;
        setContent(
            <>
                {prefix}
                {renderUpdates(update.updates)}
            </>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    const renderUpdates = (updates: Array<ContentEntry>) => {
        return updates.map((update, index) => <ContentUpdateItem key={index} type={update.type} content={update.title} description={update.description} />);
    };

    return (
        <Dialog open={open} keepMounted aria-labelledby="release-changelog-dialog-slide-title" fullWidth={true}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <DialogTitle id="release-changelog-dialog-slide-title">
                        Inhoudsupdate
                        <Typography variant="caption" component={"span"} style={{ display: "block" }}>
                            {update.date.toLocaleDateString("nl-BE", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}
                        </Typography>
                    </DialogTitle>

                    <CloseButton aria-label="Sluit inhoudsupdate informatie" size="small" onClick={() => onClose()}>
                        <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                    </CloseButton>
                </Box>
                <DialogContent
                    dividers
                    sx={{
                        flex: 1,
                        overflow: "auto",
                    }}
                >
                    {content}
                </DialogContent>
                <DialogActions sx={{ m: "5px" }}>
                    <Button color="primary" variant="contained" onClick={() => onClose()}>
                        Doorgaan
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ContentUpdateModal;
