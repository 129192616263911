import { Component } from "react";
import { connect } from "react-redux";
import * as objectNamesConnector from "../../connectors/objectNames";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../context/SplitPaneContext/State";
import FallbackNamesCache from "../../utils/fallbackNamesCache";
import { configuration } from "../../_configuration/configuration";

class ObjectName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linkedObjectId: null,
            loadObjectNameController: null,
            fallback: null,
        };

        this.cache = FallbackNamesCache.getInstance();
    }

    componentDidMount() {
        this.getFallbackName();
        this.getObjectName();
    }

    componentDidUpdate(prevProps, prevState) {
        const objectIdHasChanged = this.props.objectId !== prevProps.objectId;
        if (objectIdHasChanged) {
            this.setState({
                linkedObjectId: null,
                loadObjectNameController: null,
                fallback: null,
            });

            this.getFallbackName();
            this.getObjectName();
        }

        const objectRelationsChanged = this.props.objectRelations !== prevProps.objectRelations;
        if (objectRelationsChanged) {
            const objectRelations = this.props.objectRelations[this.props.objectId];
            if (objectRelations && objectRelations.status === "fetched") {
                this.getObjectName();
            }
        }
    }

    componentWillUnmount() {
        const { loadObjectNameController } = this.state;
        if (loadObjectNameController) {
            loadObjectNameController.abort();
        }
    }

    isModel = (objectId) => this.props.resources.Bim360.find((x) => x.derivative === objectId) !== null;

    matchAgainstModelIds = (objectId) => {
        const model = this.props.resources.Bim360.find((x) => x.derivative === objectId);
        if (!model) return;

        this.setState({ fallback: model.name });
    };

    getFallbackName = () => {
        const { objectId, source } = this.props;
        if (this.cache.has(objectId) && !this.isModel) {
            this.setState({ fallback: this.cache.get(objectId) });
            return;
        }

        const { activePane, panesState } = this.context.state;
        const mode = panesState[activePane - 1].mode;
        let targetMode = mode === PaneMode.BIM ? "Bim360" : "arcgis";
        if (source) targetMode = source === configuration.sources.bim ? "Bim360" : "arcgis";

        const url = `${configuration.api}api/ObjectName/${targetMode}/${objectId}`;
        const options = {
            method: "GET",
            cache: "force-cache",
        };

        fetch(url, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((name) => {
                if (!name) {
                    this.matchAgainstModelIds(objectId);
                    return;
                }
                this.cache.set(objectId, name);
                this.setState({ fallback: name });
            });
    };

    getObjectName = () => {
        const { objectId, objectNames, objectRelations, shouldGetObjectName, shouldCheckLinkedObject } = this.props;

        if (shouldGetObjectName) {
            const { linkedObjectId } = this.state;

            if (!linkedObjectId && shouldCheckLinkedObject) {
                const { OutgoingRelations: outgoingRelations = [] } = (objectRelations[objectId] || {}).relations || {};

                const linkedPhysicalObject = outgoingRelations.find(
                    ({ Name }) =>
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT
                );
                const linkedLogicalObject = outgoingRelations.find(
                    ({ Name }) =>
                        Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_LOGISCH_OBJECT ||
                        Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_LOGISCH_OBJECT
                );

                if (linkedPhysicalObject) {
                    this.setState({ linkedObjectId: linkedPhysicalObject.OtherObjectId });
                    objectNamesConnector.loadObjectName(linkedPhysicalObject.OtherObjectId);
                } else if (linkedLogicalObject) {
                    this.setState({ linkedObjectId: linkedLogicalObject.OtherObjectId });
                    objectNamesConnector.loadObjectName(linkedLogicalObject.OtherObjectId);
                }
            }

            const { status } = objectNames[objectId] || {};

            if (!status) {
                const loadObjectNameController = objectNamesConnector.loadObjectName(objectId);
                this.setState({ loadObjectNameController });
            }
        }
    };

    render() {
        const { objectId, objectNames } = this.props;
        const { linkedObjectId, fallback } = this.state;
        const { name } = objectNames[linkedObjectId || objectId] || {};

        return name || fallback || objectId || null;
    }
}

ObjectName.contextType = AppContext;

const mapStateToProps = ({ objectNamesReducer, objectRelationsReducer, projectReducer }) => ({
    objectNames: objectNamesReducer.objects,
    objectRelations: objectRelationsReducer.objects,
    resources: projectReducer.resources,
});

export default connect(mapStateToProps)(ObjectName);
