import { createRoot } from "react-dom/client";
import { Panel } from "./Panel";
import QueryFilterExtensionPanel from "./QueryFilterExtensionPanel";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import styles from "./QueryFilterExtension.module.css";

const Autodesk = window.Autodesk;

const extensionTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#8dd9c8",
        },
        secondary: {
            main: "#0d273e",
        },
    },
});

class QueryFilterExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;
        this.options = options;
        this.panel = null;
        this.panelVisible = false;
    }

    load = () => {
        this.panel = new Panel(this.viewer.container, "query-filter-extension-panel");
        this.panel.addVisibilityListener(this.updateToolbarState);

        const root = createRoot(this.panel.content);
        root.render(
            <ThemeProvider theme={extensionTheme}>
                <QueryFilterExtensionPanel
                    viewer={this.viewer}
                    onFilter={this.options.onFilter}
                    onReset={this.options.onReset}
                    setVisible={(visible) => {
                        if (!visible) {
                            this.panel.setVisible(false);
                        }
                    }}
                />
            </ThemeProvider>
        );

        return true;
    };

    unload = () => true;

    onToolbarCreated = (toolbar) => {
        const that = this;

        this.btnToggleSearchPanel = new Autodesk.Viewing.UI.Button("toggle-search-panel-button");
        this.btnToggleSearchPanel.onClick = function (e) {
            let visible = !that.panel.isVisible();
            that.panel.setVisible(visible);

            if (visible) {
                that.btnToggleSearchPanel.removeClass("inactive");
                that.btnToggleSearchPanel.addClass("active");
                that.btnToggleSearchPanel.addClass(styles.active);
            } else {
                that.btnToggleSearchPanel.addClass("inactive");
                that.btnToggleSearchPanel.removeClass("active");
                that.btnToggleSearchPanel.removeClass(styles.active);
            }
        };

        this.btnToggleSearchPanel.icon.classList.add(styles.toolbarButton);
        this.btnToggleSearchPanel.addClass("inactive");
        this.btnToggleSearchPanel.addClass(styles.hoverEffect);
        this.btnToggleSearchPanel.setToolTip("Query Model");

        let subToolbar = new Autodesk.Viewing.UI.ControlGroup("lantis-custom-functions-toolbar");
        subToolbar.addControl(this.btnToggleSearchPanel);

        toolbar.addControl(subToolbar);
    };

    updateToolbarState = (visible) => {
        if (visible) {
            this.btnToggleSearchPanel.removeClass("inactive");
            this.btnToggleSearchPanel.addClass("active");
            this.btnToggleSearchPanel.addClass(styles.active);
        } else {
            this.btnToggleSearchPanel.addClass("inactive");
            this.btnToggleSearchPanel.removeClass("active");
            this.btnToggleSearchPanel.removeClass(styles.active);
        }
    };
}

export default QueryFilterExtension;
