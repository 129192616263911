import { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import GenericRelations from "./GenericRelations";
import MapsIcon from "../../../../../svg/selectionTabs/MapsIcon";
import { configuration } from "../../../../../../_configuration/configuration";

class Maps extends Component {
    render() {
        const { gisRelations, classes, ...restProps } = this.props;
        return Boolean(gisRelations) ? (
            <GenericRelations
                relations={gisRelations}
                noRelationsText="Geen relaties tot kaarten gevonden."
                source={configuration.sources.gis}
                icon={MapsIcon}
                {...restProps}
            />
        ) : (
            <CircularProgress
                sx={{
                    position: "relative",
                    top: "calc(50% - 20px)",
                    left: "calc(50% - 20px)",
                }}
            />
        );
    }
}

const mapStateToProps = ({ objectRelationsReducer }) => ({
    gisRelations: objectRelationsReducer.filteredRelations.gisRelations,
});

export default connect(mapStateToProps)(Maps);
