import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    TextField,
    useTheme,
} from "@mui/material";
import { faCheck, faPencil, faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import { fetchResources, modifyResource } from "../../../connectors/admin/resourcesConfig";
import InputButton from "../InputButton";

export interface AdminDialogContentGISAccountProps {
    resources: any;
    onClose: () => void;
}

const RESOURCE_KEY = "ArcGis";

const AdminDialogContentGISAccount = ({ resources, onClose }: AdminDialogContentGISAccountProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [owner, setOwner] = useState<any>();
    const [editOwner, setEditOwner] = useState<string>("");

    useEffect(() => {
        fetchResources();
    }, []);

    useEffect(() => {
        const data = resources.filter((x: any) => x.resource === RESOURCE_KEY);
        setData(data);
        setOwner({ ...data.find((x: any) => x.key === "Owner") });
        setIsLoading(false);
    }, [resources]);

    const onSave = () => {
        setIsLoading(true);

        modifyResource(owner);
    };

    const onReset = () => {
        setOwner({ ...data.find((x: any) => x.key === "Owner") });
    };

    const hasChanges = (): boolean => {
        const originalOwner = data.find((x: any) => x.key === "Owner");
        if (!originalOwner) return false;

        if (originalOwner.value !== owner.value) return true;

        return false;
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>GIS</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ width: 260 }}>
                                            <Typography variant="button">Eigenaar WebMaps</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {Boolean(editOwner) ? (
                                                <TextField
                                                    value={editOwner}
                                                    onChange={(e) => setEditOwner(e.currentTarget.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setOwner({ ...owner, value: editOwner });
                                                                        setEditOwner("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                                </InputButton>

                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setEditOwner("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                                </InputButton>
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {owner.value}
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setEditOwner(owner.value);
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    resources: appReducer.resources,
});

export default connect(mapStateToProps)(AdminDialogContentGISAccount);

const TableToolbar = () => {
    const theme = useTheme();

    return (
        <Toolbar
            sx={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Typography
                sx={{
                    flex: "1 1 100%",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Account Configuratie
            </Typography>
        </Toolbar>
    );
};
