const defaultState = {
    documents: [],
    fetched: false,
    bookmarkState: null,
    bookmarkStatePaneId: null,
};

const pdfReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "RECEIVE_PDF_DOCUMENTS": {
            newState.documents = action.documents;
            newState.fetched = true;
            return newState;
        }

        case "SET_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = action.paneId;
            newState.bookmarkState = action.state;
            return newState;
        }

        case "CLEAR_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = null;
            newState.bookmarkState = null;
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default pdfReducer;
