import { Box, useTheme } from "@mui/material";

const Empty = ({ title }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.colors.white,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <h2>{title}</h2>
            <Box
                sx={{
                    flex: 1,
                    position: "relative",
                    display: "flex",
                    margin: "auto",
                }}
            >
                <Box
                    component={"span"}
                    sx={{
                        backgroundColor: theme.colors.white,
                        alignItems: "center",
                        pointerEvents: "none",
                        fontFamily: theme.fonts.openSans.fontFamily,
                        fontSize: "14px",
                        color: theme.colors.placeholderText,
                        userSelect: "none",
                        display: "flex",
                    }}
                >
                    Gelieve een modus te selecteren in het actieve venster.
                </Box>
            </Box>
        </Box>
    );
};

export default Empty;
