import store from "../redux/store";
import { receiveObjectProperties, requestObjectProperties } from "../redux/properties/actions";
import { receiveObjectRelations, requestObjectRelations, requestPhysicalObjectTree, receivePhysicalObjectTree } from "../redux/relations/actions";
import { setKnownObjectIds, setKnownObjectIdsError } from "../redux/knownObjectIds/actions";
import { configuration } from "../_configuration/configuration";

export const loadKnownObjectIds = () => {
    const url = `${configuration.api}api/ObjectData`;

    fetch(url, {})
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((knownObjectIds) => {
            store.dispatch(setKnownObjectIds(knownObjectIds));
        })
        .catch((error) => {
            store.dispatch(setKnownObjectIdsError(error));
        });
};

export const loadObjectProperties = (objectId) => {
    store.dispatch(requestObjectProperties(objectId));

    const url = `${configuration.api}api/ObjectData/${objectId}`;

    fetch(url, {})
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((objectProperties) => {
            store.dispatch(receiveObjectProperties(objectId, objectProperties));
        })
        .catch((error) => {
            // console.error('connectors/objectProperties - loadObjectProperties error: ', error)
            store.dispatch(receiveObjectProperties(objectId, null, error));
        });
};

export const loadObjectRelations = (objectId) => {
    store.dispatch(requestObjectRelations(objectId));
    const projectId = store.getState().projectReducer.activeProject.id;

    let url = `${configuration.api}api/ObjectRelations/${objectId}`;
    const options = {
        // cache: 'force-cache',
    };

    const objectRelationsPromise = fetch(url, options).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });

    url = `${configuration.api}api/Projects/${projectId}/Objects/${objectId}/Pdfs`;
    const objectDocumentsPromise = fetch(url, options).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    });

    Promise.all([objectRelationsPromise, objectDocumentsPromise])
        .then(([objectProperties, documentProperties]) => {
            if (documentProperties && documentProperties.length > 0) {
                for (let i = 0; i < documentProperties.length; i++) {
                    if (!objectProperties.IncomingRelations) objectProperties.IncomingRelations = [];
                    objectProperties.IncomingRelations.push({
                        Name: "PDF_IS_GELINKT_AAN_FYSIEK_OBJECT",
                        OtherObjectId: documentProperties[i].derivative,
                    });
                }
            }

            store.dispatch(receiveObjectRelations(objectId, objectProperties));
        })
        .catch((error) => {
            // console.error("connectors/objectRelations - loadObjectRelations error: ", error);
            store.dispatch(receiveObjectRelations(objectId, null, error));
        });
};

export const loadPhysicalObjectTree = (objectId, isPhysicalObject = false) => {
    if (!objectId) return;

    const url = `${configuration.api}api/ObjectRelations/${objectId}`;
    const options = {
        cache: "force-cache",
    };

    const physicalObjectTree = {
        children: [],
        parents: [],
        linkedPhysicalObjectId: null,
    };

    store.dispatch(requestPhysicalObjectTree(objectId));

    fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((objectRelations) => {
            const { OutgoingRelations: outgoingRelations = [] } = objectRelations;

            const linkedPhysicalObject = isPhysicalObject
                ? {
                      OtherObjectId: objectId,
                  }
                : outgoingRelations.find(({ Name }) => {
                      return (
                          Name === configuration.relationTypes.BIM_IS_GELINKT_AAN_FYSIEK_OBJECT ||
                          Name === configuration.relationTypes.GIS_IS_GELINKT_AAN_FYSIEK_OBJECT
                      );
                  });

            if (linkedPhysicalObject) {
                const objectId = linkedPhysicalObject.OtherObjectId;
                physicalObjectTree.linkedPhysicalObjectId = objectId;

                const createPromise = (objectId, getChildren = false) => {
                    return new Promise((resolve, reject) => {
                        const url = `${configuration.api}api/ObjectRelations/${objectId}`;

                        fetch(url, options)
                            .then((response) => {
                                if (response.ok) {
                                    response
                                        .json()
                                        .then((objectRelations) => {
                                            const { IncomingRelations: incomingRelations = [], OutgoingRelations: outgoingRelations = [] } = objectRelations;

                                            if (getChildren) {
                                                const children = incomingRelations
                                                    .filter(({ Name }) => Name === configuration.relationTypes.HEEFT_BOVENLIGGEND_FYSIEK_OBJECT)
                                                    .map(({ OtherObjectId }) => OtherObjectId);
                                                physicalObjectTree.children.push(...children);
                                            }

                                            const parentPhysicalObject = outgoingRelations.find(
                                                ({ Name }) => Name === configuration.relationTypes.HEEFT_BOVENLIGGEND_FYSIEK_OBJECT
                                            );
                                            if (parentPhysicalObject) {
                                                physicalObjectTree.parents.unshift(parentPhysicalObject.OtherObjectId);
                                                resolve(createPromise(parentPhysicalObject.OtherObjectId));
                                            } else {
                                                resolve(physicalObjectTree);
                                            }
                                        })
                                        .catch(reject);
                                } else {
                                    reject(response);
                                }
                            })
                            .catch(reject);
                    });
                };
                return createPromise(objectId, true);
            }
        })
        .then((physicalObjectTree) => {
            // console.info('loadPhysicalObjectTree - physicalObjectTree: ', physicalObjectTree)
            store.dispatch(receivePhysicalObjectTree(objectId, physicalObjectTree));
        })
        .catch((error) => {
            // console.error("connectors/objectData - loadPhysicalObjectTree error: ", error);
            store.dispatch(receivePhysicalObjectTree(objectId, null, error));
        });
};
