import store from "../redux/store";
import { setState } from "../redux/account/actions";
import { configuration } from "../_configuration/configuration";

export const fetchUserState = () => {
    const url = `${configuration.api}api/UserState`;
    const options = {
        method: "GET",
    };

    fetch(`${url}`, options)
        .then((response) => {
            if (response.status === 204) return [];
            return response.json();
        })
        .then((userState) => {
            store.dispatch(setState(userState));
        })
        .catch((error) => {
            // console.error("connectors/userState - fetchUserState error: ", error);
        });
};

export const addViewedChangelog = (version) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    if (!projectState.changelogs) {
        projectState.changelogs = [];
    }

    let changelogs = [...projectState.changelogs];
    if (!changelogs) {
        changelogs = [];
    }

    const existingChangelog = changelogs.findIndex((x) => x === version);
    if (existingChangelog >= 0) return;
    else changelogs.push(version);
    return saveUserState(changelogs, "changelogs");
};

export const addOrUpdateBookmark = (bookmark) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    if (!projectState.bookmarks) {
        projectState.bookmarks = [];
    }

    let bookmarks = [...projectState.bookmarks];
    if (!bookmarks) {
        bookmarks = [];
    }

    const existingBookmark = bookmarks.findIndex((x) => x.id === bookmark.id);
    if (existingBookmark >= 0) bookmarks[existingBookmark] = bookmark;
    else bookmarks.push({ ...bookmark });
    return saveUserState(bookmarks, "bookmarks");
};

export const renameBookmark = (id, name) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    const bookmarks = [...projectState.bookmarks];
    const bookmark = bookmarks.find((x) => x.id === id);
    if (bookmark) {
        bookmark.name = name;
    }

    return saveUserState(bookmarks, "bookmarks");
};

export const removeBookmark = (uuid) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    const url = `${configuration.api}api/UserState/Bookmarks/${uuid}`;
    const options = {
        method: "DELETE",
    };

    return new Promise((resolve) =>
        fetch(url, options).then(() => {
            const bookmarks = projectState.bookmarks.filter((x) => x.id !== uuid);
            resolve(saveUserState([...bookmarks], "bookmarks"));
        })
    );
};

export const uploadBookmarkScreenshot = async (uuid, screenshot) => {
    const url = `${configuration.api}api/UserState/Bookmarks/${uuid}`;
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({ data: screenshot.split(",")[1] }),
    };

    return fetch(url, options).then((res) => res.json());
};

const saveUserState = (data, type) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = [...store.getState().accountReducer.state];
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    projectState[type] = data;

    const url = `${configuration.api}api/UserState`;
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify(userState),
    };

    return fetch(url, options)
        .then(() => {
            store.dispatch(setState(userState));
        })
        .catch((error) => {
            // console.error("connectors/userState - saveUserState error: ", error);
        });
};

export const addViewedTip = (tipId) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    if (!projectState.tips) {
        projectState.tips = [];
    }

    let tips = [...projectState.tips];
    if (!tips) {
        tips = [];
    }

    const existingTip = tips.findIndex((x) => x === tipId);
    if (existingTip >= 0) return;
    else tips.push(tipId);
    return saveUserState(tips, "tips");
};

export const addViewedUpdate = (updateId) => {
    const activeProject = store.getState().projectReducer.activeProject;
    const userState = store.getState().accountReducer.state;
    let projectState = userState.find((x) => x.projectId === activeProject.name);
    if (!projectState) {
        projectState = { projectId: activeProject.name, gis: null, bim: null, bookmarks: null };
        userState.push(projectState);
    }

    if (!projectState.contentUpdates) {
        projectState.contentUpdates = [];
    }

    let contentUpdates = [...projectState.contentUpdates];
    if (!contentUpdates) {
        contentUpdates = [];
    }

    const existingContentUpdate = contentUpdates.findIndex((x) => x === updateId);
    if (existingContentUpdate >= 0) return;
    else contentUpdates.push(updateId);
    return saveUserState(contentUpdates, "contentUpdates");
};

export const clearUserState = () => {
    const url = `${configuration.api}api/UserState`;
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify([]),
    };

    return fetch(url, options)
        .then(() => {
            store.dispatch(setState([]));
        })
        .catch((error) => {
            // console.error("connectors/userState - saveUserState error: ", error);
        });
};
