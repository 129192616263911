import { useState } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { faCog, faToolbox } from "@fortawesome/pro-solid-svg-icons";

import SettingsDialog from "../settings/SettingsDialog";
import AdminDialog from "../admin/AdminDialog";
import { useAccount } from "@azure/msal-react";

import { setSuppressNotifications } from "../../redux/app/actions";
import StyledTooltip from "../generic/StyledTooltip";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { configuration } from "../../_configuration/configuration";

const Root = styled(Box)(() => ({
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flex: 0,
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(0, 2),
    cursor: "pointer",
    color: "#499C85",
    "&:hover": {
        color: "#327668",
    },
}));

const OptionsBar = (props) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);
    const account = useAccount();

    const isAdmin = account.idTokenClaims.groups.some((id) => configuration.authentication.adminGroup === id);

    const openAdminPanel = () => {
        if (!isAdmin) return;
        setShowAdmin(true);
        props.onSuppressNotifications(true);
    };

    const closeAdminPanel = () => {
        if (!isAdmin) return;
        setShowAdmin(false);
        props.onSuppressNotifications(false);
    };

    return (
        <Root>
            <StyledTooltip title={"Instellingen..."}>
                <IconContainer>
                    <FontAwesomeSvgIcon icon={faCog} onClick={() => setShowOptions(true)} sx={{ maxHeight: 20 }} />
                </IconContainer>
            </StyledTooltip>

            <SettingsDialog isOpen={showOptions} onClose={() => setShowOptions(false)} />

            {isAdmin && (
                <>
                    <StyledTooltip title={"Administratie paneel..."}>
                        <IconContainer>
                            <FontAwesomeSvgIcon icon={faToolbox} onClick={() => openAdminPanel()} sx={{ maxHeight: 20 }} />
                        </IconContainer>
                    </StyledTooltip>

                    <AdminDialog isOpen={showAdmin} onClose={() => closeAdminPanel()} />
                </>
            )}
        </Root>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onSuppressNotifications: (suppressed) => dispatch(setSuppressNotifications(suppressed)),
});

export default connect(null, mapDispatchToProps)(OptionsBar);
