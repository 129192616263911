import { EnvConfiguration } from "./AppConfiguration";

export const production: EnvConfiguration = {
    api: "https://dashboard-gateway-staging.azurewebsites.net/",
    assets: "https://dashboardcontainers.blob.core.windows.net/assets/",
    authentication: {
        appId: "4d789d67-e748-42d3-80be-b794f03a43f1",
        tenant: "5aa016da-26bf-4f4b-b497-651dcd12a62a",
        scopes: ["api://dashboard-gateway-staging/Dashboard.Access"],
        adminGroup: "befff72f-082b-433d-9706-63a642675b32",
    },
    sentry: {
        dsn: "https://da2fa4297ece4c90b4a84c77d14da2da@o399431.ingest.sentry.io/4505148256747520",
        env: "production",
        sampleRate: 0.2,
    },
    gtm: {
        id: "GTM-MK5WWBL",
    },
};
