export const requestObjectRelations = (objectId) => ({
  type: 'REQUEST_OBJECT_RELATIONS',
  objectId,
})

export const receiveObjectRelations = (objectId, relations, error) => ({
  type: 'RECEIVE_OBJECT_RELATIONS',
  objectId,
  relations,
  error,
})

export const setFilteredRelations = (filteredRelations) => ({
  type: 'SET_FILTERED_RELATIONS',
  filteredRelations,
})

export const requestPhysicalObjectTree = (objectId) => ({
  type: 'REQUEST_PHYSICAL_OBJECT_TREE',
  objectId,
})

export const receivePhysicalObjectTree = (objectId, physicalObjectTree, error) => ({
  type: 'RECEIVE_PHYSICAL_OBJECT_TREE',
  objectId,
  physicalObjectTree,
  error,
})

export const setLinkedPhysicalAndLogicalObjectIds = (physicalObjectId, logicalObjectId) => ({
  type: 'SET_LINKED_PHYSICAL_AND_LOGICAL_OBJECT_IDS',
  physicalObjectId,
  logicalObjectId,
})