import { Component } from "react";
import { Box, styled } from "@mui/material";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import StyledTooltip from "../generic/StyledTooltip";

const Root = styled(Box)(() => ({
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flex: 0,
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(0, 4, 0, 2),
    cursor: "pointer",
    color: "#499C85",
    "&:hover": {
        color: "#327668",
    },
}));

class HelpBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Root>
                <StyledTooltip title={"Help..."}>
                    <IconContainer>
                        <FontAwesomeSvgIcon
                            icon={faQuestion}
                            sx={{ maxHeight: 20 }}
                            onClick={() => {
                                window.open("https://bamnv.sharepoint.com/sites/Wiki/SitePages/BIM-Dashboard.aspx", "_blank");
                            }}
                        />
                    </IconContainer>
                </StyledTooltip>
            </Root>
        );
    }
}

export default HelpBar;
