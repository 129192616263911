import { Component } from "react";
import { connect } from "react-redux";
import FavoriteIcon from "../svg/selection/FavoriteIcon";
import { toggleFavoriteObject } from "../../redux/favorites/actions";
import StyledTooltip from "./StyledTooltip";

class FavoriteToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    toggleHovering = (isHovering) => {
        this.setState({ isHovering });
    };

    handleMouseEnter = (event) => {
        this.toggleHovering(true);
    };

    handleMouseLeave = () => {
        this.toggleHovering(false);
    };

    toggleFavoriteObject = (event) => {
        event.stopPropagation();
        const { objectId, source, isPhysicalObject } = this.props;
        this.props.onToggleFavoriteObject(objectId, source, isPhysicalObject);
    };

    render() {
        const { favoriteObjects, objectId, isHidden = false, isAlt = false } = this.props;

        const { isHovering } = this.state;

        const isFavorite = favoriteObjects.some((favoriteObject) => objectId === favoriteObject.objectId);

        if (!isFavorite && isHidden) {
            return null;
        }

        const toolTipValue = Boolean(isFavorite) ? "Verwijder uit favorieten" : "Voeg toe aan favorieten";

        return (
            <StyledTooltip title={toolTipValue}>
                <div
                    onClick={this.toggleFavoriteObject}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    style={{ cursor: "pointer" }}
                >
                    <FavoriteIcon selected={isHovering || isFavorite} isAlt={isAlt} />
                </div>
            </StyledTooltip>
        );
    }
}

const mapStateToProps = ({ favoritesReducer }) => ({
    favoriteObjects: favoritesReducer.favoriteObjects,
});

const mapDispatchToProps = (dispatch) => ({
    onToggleFavoriteObject: (objectId, source, isPhysicalObject) => dispatch(toggleFavoriteObject(objectId, source, isPhysicalObject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteToggle);
