import { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { addViewedTip } from "../../../connectors/userState";
import TipsModal from "./TipsModal";

export interface TipsManagerProps {
    activeProject: any;
    suppressedNotifications: boolean;
    tips: any;
    userState: any;
}

export class Tip {
    id: string;
    date: Date;
    description: string;

    constructor(id: string, date: string, description: string) {
        this.id = id;
        this.date = new Date(date);
        this.description = description;
    }

    static fromJson = (json: string) => {
        const parsed = JSON.parse(json);
        const result = new Array<Tip>();

        parsed.forEach((tip: any) => {
            result.push(new Tip(tip.id, tip.datum, tip.omschrijving));
        });

        return result;
    };
}

const TipsManager = ({ suppressedNotifications, userState, activeProject, tips }: TipsManagerProps) => {
    const [shouldShow, setShouldShow] = useState(false);
    const [activeTips, setActiveTips] = useState<Array<Tip>>([]);
    const [tip, setTip] = useState<Tip>();

    useLayoutEffect(() => {
        setActiveTips(Tip.fromJson(JSON.stringify(tips)));
    }, [tips]);

    useLayoutEffect(() => {
        if (activeTips.length > 0) {
            const lastTip = activeTips[activeTips.length - 1] as Tip;
            setTip(lastTip);
        }
    }, [activeTips]);

    useLayoutEffect(() => {
        if (!tip || !userState || suppressedNotifications) return;

        const state = userState.find((x: any) => x.projectId === activeProject.name);
        if (!state || !state.tips) {
            setShouldShow(true);
            return;
        }

        setShouldShow(!state.tips.some((x: string) => x === tip.id));
    }, [userState, activeProject, suppressedNotifications, tip]);

    const onConfirm = () => {
        if (!tip) return;

        addViewedTip(tip.id);
        setShouldShow(false);
    };

    return tip ? <TipsModal open={shouldShow} message={tip.description} onClose={onConfirm} /> : null;
};

const mapStateToProps = ({ accountReducer, appReducer, projectReducer }: any) => ({
    activeProject: projectReducer.activeProject,
    suppressedNotifications: appReducer.suppressedNotifications,
    tips: appReducer.tips,
    userState: accountReducer.state,
});

export default connect(mapStateToProps)(TipsManager);
