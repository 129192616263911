import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import Favorite from "./Favorite";

const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    "& h2": {
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

class Favorites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    renderFavorites = () => {
        const { favoriteObjects } = this.props;

        return favoriteObjects.map(({ objectId, source, isPhysicalObject }, index) => {
            return <Favorite key={index} objectId={objectId} source={source} isPhysicalObject={isPhysicalObject} />;
        });
    };

    render() {
        const { title } = this.props;

        return (
            <Root>
                <h2>{title}</h2>
                {this.renderFavorites()}
            </Root>
        );
    }
}

const mapStateToProps = ({ favoritesReducer }) => ({
    favoriteObjects: favoritesReducer.favoriteObjects,
});

export default connect(mapStateToProps)(Favorites);
