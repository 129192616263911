const SplitScreenOneDefaultIcon = ({ className, style }) => {
    return (
        <svg
            version="1.1"
            id="split-screen-1-default"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={style}
        >
            <path
                style={{ fill: "currentcolor" }}
                d="M464,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V80A48,48,0,0,0,464,32Zm16,383c0,18.2-6.7,33-15,33H47.2C38.8,448,32,433,32,414.5V95.7H480Z"
            />
        </svg>
    );
};

export default SplitScreenOneDefaultIcon;
