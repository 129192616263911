import { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import DocumentRelations from "./DocumentRelations";
import ModelsIcon from "../../../../../svg/selectionTabs/ModelsIcon";
import { configuration } from "../../../../../../_configuration/configuration";

class Models extends Component {
    render() {
        const { pdfRelations, classes, ...restProps } = this.props;

        return Boolean(pdfRelations) ? (
            <DocumentRelations
                relations={pdfRelations}
                noRelationsText="Geen relaties tot documenten gevonden."
                source={configuration.sources.pdf}
                icon={ModelsIcon}
                {...restProps}
            />
        ) : (
            <CircularProgress
                sx={{
                    position: "relative",
                    top: "calc(50% - 20px)",
                    left: "calc(50% - 20px)",
                }}
            />
        );
    }
}

const mapStateToProps = ({ objectRelationsReducer }) => ({
    pdfRelations: objectRelationsReducer.filteredRelations.pdfRelations,
});

export default connect(mapStateToProps)(Models);
