import { Box, useTheme } from "@mui/material";
import Relations from "./tabComponents/selection/relations/Relations";

const RelationsOverlay = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                zIndex: 1,
                pointerEvents: "all",
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    maxHeight: "100%",
                    display: "flex",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: `${theme.colors.secondarySelection}BF`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "stretch",
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            marginLeft: "188px",
                            marginRight: "188px",
                            marginTop: "42px",
                            marginBottom: "42px",
                        }}
                    >
                        <Relations isExpanded />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default RelationsOverlay;
