import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import SelectObject from "../SelectObject";
import FavoriteToggle from "../../../../../generic/FavoriteToggle";

const Root = styled(Box)(({ theme }) => ({
    fontFamily: theme.fonts.openSans.fontFamily,
}));

const TitleContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hovered",
})(({ hovered, theme }) => ({
    padding: "11px 13px",
    display: "flex",
    backgroundColor: hovered ? theme.colors.secondarySelectionHover : "unset",
}));

const ContentContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hovered",
})(({ hovered, theme }) => ({
    backgroundColor: hovered ? `${theme.colors.secondarySelectionHover}96` : "unset",
}));

const CountContainer = styled(Box)(({ theme }) => ({
    fontSize: "12px",
    color: theme.colors.placeholderText,
    marginLeft: "10px",
}));

class RelationsGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    handleMouseEnter = (event) => {
        this.setState({ isHovering: true });
    };

    handleMouseLeave = (event) => {
        this.setState({ isHovering: false });
    };

    render() {
        const { isHovering } = this.state;

        const {
            classes,
            uid,
            ignoreOnScroll,
            title,
            content,
            objectId,
            source,
            isPhysicalObject,
            depth = 0,
            count,
            favoriteObjects,
            highlightIds,
        } = this.props;

        const isFavorite = favoriteObjects.some((favoriteObject) => objectId === favoriteObject.objectId);

        const rootClassNames = [classes.root];
        if (isHovering) {
            rootClassNames.push(classes.rootHovered);
        }

        return (
            <Root className={rootClassNames.join(" ")} data-container-type="relationsGroup" data-ignore-on-scroll={ignoreOnScroll} data-uid={uid}>
                <TitleContainer hovered={isHovering} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <Box sx={{ flex: 1, paddingLeft: `calc(${depth} * 24px)` }}>{title}</Box>
                    <Box sx={{ display: "flex" }}>
                        {Boolean(isHovering) && (
                            <SelectObject objectId={objectId} source={source} isPhysicalObject={isPhysicalObject} highlightIds={highlightIds} />
                        )}

                        {Boolean(isHovering || isFavorite) && (
                            <Box sx={{ ml: "10px" }}>
                                <FavoriteToggle objectId={objectId} source={source} isPhysicalObject={isPhysicalObject} />
                            </Box>
                        )}
                        <CountContainer>{count}</CountContainer>
                    </Box>
                </TitleContainer>
                <ContentContainer hovered={isHovering}>{content}</ContentContainer>
            </Root>
        );
    }
}

const mapStateToProps = ({ favoritesReducer }) => ({
    favoriteObjects: favoritesReducer.favoriteObjects,
});

export default connect(mapStateToProps)(RelationsGroup);
