const defaultState = {
    projects: [],
    projectsFetched: false,
    activeProject: null,
    resources: {},
    forgeFilters: null,
    whitelist: null,
    isFetching: false,
};

const projectsReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "REQUEST_PROJECT_INFO": {
            newState.isFetching = true;

            return newState;
        }

        case "RECEIVE_PROJECT_LIST": {
            newState.projects = action.projects;
            newState.projectsFetched = true;

            return newState;
        }

        case "RECEIVE_PROJECT": {
            newState.activeProject = action.project;
            newState.resources = {};

            return newState;
        }

        case "RECEIVE_PROJECT_RESOURCE_ITEMS": {
            newState.resources[action.source] = action.resourceItems;

            return newState;
        }

        case "RECEIVED_PROJECT_INFO": {
            newState.isFetching = false;

            return newState;
        }

        case "RECEIVE_PROJECT_FILTERS": {
            newState.forgeFilters = action.filters;

            return newState;
        }

        case "RECEIVE_PROJECT_WHITELIST": {
            newState.whitelist = action.whitelist;

            return newState;
        }

        default: {
            return state;
        }
    }
};

export default projectsReducer;
