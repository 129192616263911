import { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import { AppContext } from "../../context/AppContext/AppContextProvider";
import { PanesOrientation } from "../../context/AppContext/State";

import TwoHorizontalIcon from "../svg/splitscreen/TwoHorizontalIcon";
import TwoVerticalIcon from "../svg/splitscreen/TwoVerticalIcon";
import ThreeHorizontalIcon from "../svg/splitscreen/ThreeHorizontalIcon";
import ThreeVerticalIcon from "../svg/splitscreen/ThreeVerticalIcon";
import ThreeMixedIcon from "../svg/splitscreen/ThreeMixedIcon";
import FourDefaultIcon from "../svg/splitscreen/FourDefaultIcon";
import { setPanes } from "../../context/AppContext/Reducer";
import CloseButton from "../generic/CloseButton";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";

export interface SettingsDialogContentSplitScreenProps {
    onClose: () => void;
}

interface SplitScreenOptions {
    [key: number]: Array<SplitScreenOption> | null;
}

interface SplitScreenOption {
    id: string;
    orientation: PanesOrientation;
    icon: any;
}

const splitscreenOptions: SplitScreenOptions = {
    1: null,
    2: [
        {
            id: "two-horizontal",
            orientation: PanesOrientation.HORIZONTAL,
            icon: TwoHorizontalIcon,
        },
        {
            id: "two-vertical",
            orientation: PanesOrientation.VERTICAL,
            icon: TwoVerticalIcon,
        },
    ],
    3: [
        {
            id: "three-horizontal",
            orientation: PanesOrientation.HORIZONTAL,
            icon: ThreeHorizontalIcon,
        },
        {
            id: "three-vertical",
            orientation: PanesOrientation.VERTICAL,
            icon: ThreeVerticalIcon,
        },
        {
            id: "three-mixed",
            orientation: PanesOrientation.MIXED,
            icon: ThreeMixedIcon,
        },
    ],
    4: [
        {
            id: "four-default",
            orientation: PanesOrientation.HORIZONTAL,
            icon: FourDefaultIcon,
        },
    ],
};

const SettingsDialogContentSplitScreen = ({ onClose }: SettingsDialogContentSplitScreenProps) => {
    const { state, dispatch } = useContext(AppContext);
    const [panesCount, setPanesCount] = useState(state.panesCount ?? 1);
    const [layout, setLayout] = useState<string | null>();
    const [quickToggle, setQuickToggle] = useState(false);
    const [options, setOptions] = useState<Array<SplitScreenOption>>([]);

    useEffect(() => {
        const { panesCount, panesOrientation } = state;
        const selectedLayout = splitscreenOptions[panesCount]?.find((x: any) => x.orientation === panesOrientation)?.id;
        const quickToggle = Boolean(JSON.parse(localStorage.getItem("split-screen-quick-toggle") ?? "1"));
        setPanesCount(panesCount);
        setLayout(selectedLayout);
        setQuickToggle(quickToggle);
    }, [state]);

    useEffect(() => {
        const options = splitscreenOptions[panesCount] ?? [];
        if (options.length === 0) setLayout(null);
        else setLayout(options[0].id);
        setOptions(options);
    }, [panesCount]);

    const onSave = () => {
        const selectedOrientation = splitscreenOptions[panesCount]?.find((x: any) => x.id === layout)?.orientation;
        dispatch(setPanes(panesCount, selectedOrientation ?? PanesOrientation.HORIZONTAL));
        localStorage.setItem("split-screen-quick-toggle", `${quickToggle}`);
        onClose();
    };

    const renderOptions = () => {
        if (panesCount === 1) return null;

        return (
            <TableRow>
                <TableCell style={{ border: "none" }}>
                    <Typography variant="body2" component="p">
                        Gewenste layout voor de verschillende vensters
                    </Typography>
                </TableCell>
                <TableCell style={{ width: 300, border: "none" }}>
                    <ToggleButtonGroup value={layout} exclusive onChange={(_, value) => Boolean(value) && setLayout(value)}>
                        {options.map((option, index) => {
                            const Icon = option.icon;

                            return (
                                <ToggleButton key={index} value={option.id}>
                                    <Icon
                                        style={{
                                            width: 60,
                                            height: 60,
                                            margin: 10,
                                        }}
                                    />
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </TableCell>
            </TableRow>
        );
    };

    const updatePanesCount = (value: number | number[]) => {
        if (Array.isArray(value)) {
            console.warn("Got array for new panes value:", value);
            return;
        }

        setPanesCount(value);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>Split screen</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>

            <DialogContent dividers>
                <Table
                    sx={{
                        marginBottom: 3,
                    }}
                    size="small"
                >
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant="button" component="p">
                                    Algemeen
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "none" }}>
                                <Typography variant="body2" component="p">
                                    Quick Toggle
                                </Typography>
                                <Typography variant="caption" component="p">
                                    Voeg een extra actiebalk toe in het primaire scherm om sneller tussen split screen layouts te wisselen.
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: 80, border: "none", textAlign: "center" }}>
                                <Switch color="primary" checked={quickToggle} onChange={() => setQuickToggle((x) => !x)} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table sx={{ marginBottom: 20 }} size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant="button" component="p">
                                    Vensters
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "none" }}>
                                <Typography variant="body2" component="p">
                                    Aantal vensters dat u wenst te gebruiken
                                </Typography>
                            </TableCell>
                            <TableCell style={{ width: 320, border: "none" }}>
                                <Slider
                                    aria-label="Aantal vensters"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks
                                    min={1}
                                    max={4}
                                    value={panesCount}
                                    onChange={(_, value) => updatePanesCount(value)}
                                    onChangeCommitted={(_, value) => updatePanesCount(value)}
                                />
                            </TableCell>
                        </TableRow>
                        {renderOptions()}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions sx={{ m: "5px" }}>
                <Button onClick={() => onSave()} color="primary" variant="contained">
                    Opslaan
                </Button>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
            </DialogActions>
        </>
    );
};

export default SettingsDialogContentSplitScreen;
