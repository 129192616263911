import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

import { ContentUpdate } from "../managers/ContentUpdateManager/ContentUpdate";
import ContentUpdateItem from "../managers/ContentUpdateManager/ContentUpdateItem";
import CloseButton from "../generic/CloseButton";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";

export interface SettingsDialogContentUpdateHistoryProps {
    updates: any;
    onClose: () => void;
}

const SettingsDialogContentUpdateHistory = ({ updates, onClose }: SettingsDialogContentUpdateHistoryProps) => {
    const [contentUpdates, setContentUpdates] = useState<Array<ContentUpdate>>([]);

    useEffect(() => {
        setContentUpdates(ContentUpdate.fromJson(JSON.stringify(updates)));
    }, [updates]);

    const renderUpdates = () => {
        return orderBy(contentUpdates, (o) => o.date, "desc").map((contentUpdate: ContentUpdate) => (
            <Card key={contentUpdate.id} style={{ marginBottom: "16px" }}>
                <CardContent>
                    <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                        {contentUpdate.date.toLocaleDateString("nl-BE", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </Typography>
                    {contentUpdate.updates.map((update, index) => (
                        <ContentUpdateItem key={index} type={update.type} content={update.title} description={update.description} />
                    ))}
                </CardContent>
            </Card>
        ));
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>Inhoud Updates Geschiedenis</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            <DialogContent dividers>{renderUpdates()}</DialogContent>
            <DialogActions sx={{ m: "5px" }}>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Sluiten
                </Button>
            </DialogActions>
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    updates: appReducer.updates,
});

export default connect(mapStateToProps)(SettingsDialogContentUpdateHistory);
