import { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import { resetForceRelationsSelection, setShouldRevealSelectedRelation } from "../../../../../../redux/app/actions";
import { clearQueuedSelectedObjectResource, setQueuedHighlights } from "../../../../../../redux/selection/actions";
import ReactResizeDetector from "react-resize-detector";
import Expander from "../../../../../generic/Expander";
import ObjectName from "../../../../../generic/ObjectName";
import RelationsGroup from "./RelationsGroup";
import RevealSelectedRelation from "../RevealSelectedRelation";
import SelectObject from "../SelectObject";
import { Box, styled } from "@mui/material";
import { stringToCharCodes } from "../../../../../../utils/stringFunctions";
import theme from "../../../../../../themes/default";

const Root = styled(Box)(() => ({
    height: "100%",
    width: "100%",
    overflowY: "auto",
}));

const Inner = styled(Box)(() => ({
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    alignItems: "stretch",
}));

const Left = styled(Box)(({ theme }) => ({
    width: "240px",
    maxWidth: "240px",
    overflowY: "auto",
    backgroundColor: theme.colors.inputBackground,
}));

const Right = styled(Box)(() => ({
    flex: 1,
    overflowY: "auto",
}));

const IndexContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontFamily: theme.fonts.openSans.fontFamily,
    color: theme.colors.textColor,
    padding: "12px",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
        backgroundColor: theme.colors.secondarySelectionHover,
    },
}));

const IndexName = styled(Box)(({ theme }) => ({
    flex: 1,
    wordBreak: "break-all",
    fontSize: "14px",
    fontWeight: theme.fonts.openSans.semiBold,
}));

const IndexCounter = styled(Box)(() => ({
    fontSize: "12px",
    marginLeft: "16px",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
    height: "100%",
    display: "flex",
}));

class GenericRelations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            selectedIndex: null,
            parsedData: null,
            selectionIsInvisible: false,
        };
        this.rootRef = createRef();
        this.revealSelectedRelationInterval = null;
    }

    componentDidMount() {
        this.parseData();

        if (this.props.forceSelection) {
            const { relations, resetForceRelationsSelection, source, onSetQueuedHighlights, onClearQueuedSelectedObjectResource, highlightResource } = this.props;
            const highlightIds = [...relations.physicalIncomingRelations, ...relations.physicalOutgoingRelations].map(({ OtherObjectId }) => OtherObjectId);

            if (highlightIds.length > 0) {
                if (source === "forge") {
                    const urns = new Set(highlightIds.map((id) => id.split(".")[0]));
                    let urn = urns[0];
                    if (highlightResource) urn = highlightResource;

                    const firstUrnIds = highlightIds.filter((id) => id.split(".")[0] === urn);
                    onSetQueuedHighlights(source, firstUrnIds, true);
                    onClearQueuedSelectedObjectResource();
                } else {
                    onSetQueuedHighlights(source, [highlightIds[0]], true);
                }
                
                resetForceRelationsSelection();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const relationsDataHasChanged = this.props.relations !== prevProps.relations;
        const objectNamesHasChanged = this.props.objectNames !== prevProps.objectNames;
        const physicalObjectIdHasChanged = this.props.physicalObjectId !== prevProps.physicalObjectId;
        const logicalObjectIdHasChanged = this.props.logicalObjectId !== prevProps.logicalObjectId;
        if (relationsDataHasChanged || objectNamesHasChanged || physicalObjectIdHasChanged || logicalObjectIdHasChanged) {
            this.parseData();
        }

        if (relationsDataHasChanged && this.props.forceSelection) {
            const { relations, resetForceRelationsSelection, source, onSetQueuedHighlights } = this.props;
            const highlightIds = [...relations.physicalIncomingRelations, ...relations.physicalOutgoingRelations].map(({ OtherObjectId }) => OtherObjectId);

            if (highlightIds.length > 0) {
                console.log("ComponentDidUpdate - Set queued highights", highlightIds)
                onSetQueuedHighlights(source, [highlightIds[0]], true);
                resetForceRelationsSelection();
            }
        }

        const shouldRevealSelectedRelationHasChanged = this.props.shouldRevealSelectedRelation !== prevProps.shouldRevealSelectedRelation;
        if (shouldRevealSelectedRelationHasChanged) {
            this.revealSelectedRelation();
        }

        this.checkSecondarySelectionVisibility();
    }

    componentWillUnmount() {
        if (this.revealSelectedRelationInterval) {
            clearInterval(this.revealSelectedRelationInterval);
        }
    }

    revealSelectedRelation = () => {
        const { shouldRevealSelectedRelation, setShouldRevealSelectedRelation } = this.props;

        if (shouldRevealSelectedRelation) {
            const secondarySelectionNode = document.querySelector("#secondarySelectionNode");
            this.revealSelectedRelationInterval = setInterval(() => {
                if (secondarySelectionNode) {
                    const selectionIsInvisible = this.getSecondarySelectionVisibility();
                    if (selectionIsInvisible) {
                        secondarySelectionNode.scrollIntoView();
                    } else {
                        setShouldRevealSelectedRelation(false);
                    }
                }
            }, 0);
        } else {
            if (this.revealSelectedRelationInterval) {
                clearInterval(this.revealSelectedRelationInterval);
            }
        }
    };

    checkSecondarySelectionVisibility = () => {
        const selectionIsInvisible = this.getSecondarySelectionVisibility();
        if (this.state.selectionIsInvisible !== selectionIsInvisible) {
            this.setState((state) => ({
                selectionIsInvisible,
            }));
        }
    };

    getSecondarySelectionVisibility = () => {
        const secondarySelectionNode = document.querySelector("#secondarySelectionNode");
        if (secondarySelectionNode && this.rootRef && this.rootRef.current) {
            const { bottom: selectedBottom, top: selectedTop, height } = secondarySelectionNode.getBoundingClientRect();
            const { bottom: rootBottom, top: rootTop } = this.rootRef.current.getBoundingClientRect();

            const selectionIsBeyondTop = selectedBottom < rootTop;
            const selectionIsBeyondBottom = selectedTop > rootBottom;
            const selectionIsHidden = !height;
            return selectionIsBeyondTop || selectionIsBeyondBottom || selectionIsHidden;
        }

        return false;
    };

    parseData = () => {
        const { relations } = this.props;

        if (!relations) {
            return;
        }

        const groupedRelations = Object.keys(relations).reduce((groupedRelations, key) => {
            groupedRelations[key] = relations[key].reduce((groups, relation) => {
                const groupId = relation.OtherObjectId.split(".")[0];
                groups[groupId] = groups[groupId] || [];
                groups[groupId].push(relation);
                return groups;
            }, {});

            return groupedRelations;
        }, {});

        const physicalRelationsCount =
            this.countRelations(groupedRelations.physicalIncomingRelations) + this.countRelations(groupedRelations.physicalOutgoingRelations);

        const logicalRelationsCount =
            this.countRelations(groupedRelations.logicalIncomingRelations) + this.countRelations(groupedRelations.logicalOutgoingRelations);

        const parsedData = {
            physicalRelationsCount,
            logicalRelationsCount,
            groupedRelations,
        };

        this.setState({ parsedData });
    };

    createGroupIndices = (parentObjectId, groups) => {
        const { selectedIndex } = this.state;

        return Object.keys(groups).map((groupName, index) => {
            const group = groups[groupName];
            const counter = group.length;
            const uid = stringToCharCodes(`${parentObjectId}_${groupName}`);
            const isSelected = Boolean(uid === selectedIndex);
            const title = <ObjectName objectId={groupName} shouldGetObjectName />;

            let style = {};
            if (isSelected) {
                style = { backgroundColor: theme.colors.secondarySelectionHover };
            }

            return (
                <IndexContainer sx={style} onClick={() => this.scrollToIndex(uid)}>
                    <IndexName sx={{ paddingLeft: "24px" }}>{title}</IndexName>
                    <IndexCounter>{counter}</IndexCounter>
                </IndexContainer>
            );
        });
    };

    countRelations = (groups) => {
        return Object.values(groups).reduce((relationsCount, { length }) => {
            return relationsCount + length;
        }, 0);
    };

    scrollToIndex = (uid) => {
        if (!this.rightContainer || !uid) return;

        const element = this.rightContainer.querySelector(`div[data-container-type="relationsGroup"][data-uid="${uid}"]`);
        if (!element) return;

        element.scrollIntoView();
    };

    handleScroll = (event) => {
        if (!this.rightContainer) return;

        const selectedIndex = (
            (
                Array.prototype.slice
                    .call(this.rightContainer.querySelectorAll('div[data-container-type="relationsGroup"]:not([data-ignore-on-scroll])'))
                    .find((element) => {
                        const { top } = this.rightContainer.getBoundingClientRect();
                        const { bottom } = element.getBoundingClientRect();
                        return bottom - top > 0;
                    }) || {}
            ).dataset || {}
        ).uid;

        if (selectedIndex !== this.state.selectedIndex) {
            this.setState({ selectedIndex });
        }
    };

    handleResize = () => {
        try {
            if (this.rootRef && this.rootRef.current) {
                this.rootRef.current.style.visibility = "hidden";
                setTimeout(() => {
                    this.rootRef.current.style.visibility = "visible";
                }, 0);
            }
        } catch (error) {
            //console.log('GenericRelations handleResize error: ', error)
        }
    };

    renderRelationCategories = (categoryTitle, objectId, count, incomingRelations, outgoingRelations, hasMultipleCategories) => {
        const { isExpanded, source } = this.props;

        const highlightIds = [...incomingRelations, ...outgoingRelations].map(({ OtherObjectId }) => OtherObjectId);

        if (isExpanded) {
            const title = <ObjectName objectId={categoryTitle} shouldGetObjectName />;
            const uid = stringToCharCodes(objectId);
            return (
                <RelationsGroup
                    title={title}
                    uid={uid}
                    objectId={objectId}
                    source={source}
                    count={count}
                    highlightIds={highlightIds}
                    content={
                        <Fragment>
                            {this.renderRelationGroups(objectId, incomingRelations, "incoming")}
                            {this.renderRelationGroups(objectId, outgoingRelations, "outgoing")}
                        </Fragment>
                    }
                    isPhysicalObject
                    ignoreOnScroll
                />
            );
        } else {
            return (
                <div>
                    <ReactResizeDetector handleHeight onResize={this.checkSecondarySelectionVisibility} />
                    <Expander title={categoryTitle} startExpanded hideExpander={!hasMultipleCategories}>
                        {this.renderSimpleRelationGroups(objectId, incomingRelations, "incoming", hasMultipleCategories ? 1 : 0)}
                        {this.renderSimpleRelationGroups(objectId, outgoingRelations, "outgoing", hasMultipleCategories ? 1 : 0)}
                    </Expander>
                </div>
            );
        }
    };

    renderRelationGroups = (parentObjectId, relationGroups, direction) => {
        const { source } = this.props;

        const groups = relationGroups.reduce((groups, relation) => {
            const groupId = relation.OtherObjectId.split(".")[0];
            groups[groupId] = groups[groupId] || [];
            groups[groupId].push(relation);
            return groups;
        }, {});

        return Object.keys(groups).map((groupId, index) => {
            const uid = stringToCharCodes(`${parentObjectId}_${groupId}`);
            const groupRelations = groups[groupId];
            const highlightIds = groupRelations.map(({ OtherObjectId }) => OtherObjectId);
            const depth = 1;
            const title = <ObjectName objectId={groupId} shouldGetObjectName />;
            return (
                <RelationsGroup
                    key={index}
                    uid={uid}
                    highlightIds={highlightIds}
                    title={title}
                    content={this.renderRelations(groupRelations, direction, depth + 1)}
                    objectId={groupId}
                    source={source}
                    isPhysicalObject
                    depth={depth}
                    count={groupRelations.length}
                />
            );
        });
    };

    renderSimpleRelationGroups = (parentObjectId, relationGroups, direction, depth = 1) => {
        const { source, onSetQueuedHighlights } = this.props;

        const groups = relationGroups.reduce((groups, relation) => {
            const groupId = relation.OtherObjectId.split(".")[0];
            groups[groupId] = groups[groupId] || [];
            groups[groupId].push(relation);
            return groups;
        }, {});

        return Object.keys(groups).map((groupId, index) => {
            const uid = stringToCharCodes(`${parentObjectId}_${groupId}`);
            const groupRelations = groups[groupId];
            const highlightIds = groupRelations.map(({ OtherObjectId }) => OtherObjectId);
            const title = (
                <>
                    <ObjectName objectId={groupId} shouldGetObjectName /> <span style={{ fontWeight: 300 }}>({highlightIds.length})</span>
                </>
            );

            return (
                <Expander
                    key={uid}
                    title={title}
                    depth={depth}
                    actions={[<SelectObject onSelect={() => onSetQueuedHighlights(source, highlightIds, true)} highlightIds={highlightIds} />]}
                >
                    {source !== "forge" && this.renderRelations(groupRelations, direction, depth + 1)}
                </Expander>
            );
        });
    };

    renderRelations = (relations, direction, depth = 1) => {
        const { objectNames, source, onSetQueuedHighlights } = this.props;

        const duplicates = relations.reduce((duplicates, relation) => {
            const { name } = objectNames[relation.OtherObjectId] || {};

            const uid = name || relation.OtherObjectId;

            duplicates[uid] = duplicates[uid] || [];
            duplicates[uid].push(relation);

            return duplicates;
        }, {});

        return Object.keys(duplicates).map((uid) => {
            const highlightIds = duplicates[uid].map(({ OtherObjectId }) => OtherObjectId);
            return (
                <Expander
                    key={uid}
                    title={uid}
                    depth={depth}
                    actions={[<SelectObject onSelect={() => onSetQueuedHighlights(source, highlightIds, true)} highlightIds={highlightIds} />]}
                />
            );
        });
    };

    render() {
        const { noRelationsText, isExpanded, physicalObjectId, logicalObjectId, relations } = this.props;

        const { parsedData, selectionIsInvisible } = this.state;

        const noRelations = (
            <Root>
                <MessageContainer>{noRelationsText}</MessageContainer>
            </Root>
        );

        if (!parsedData || !relations) {
            return noRelations;
        }

        const { physicalRelationsCount, logicalRelationsCount, groupedRelations } = parsedData;

        const hasPhysicalRelations = physicalRelationsCount > 0;
        const hasLogicalRelations = logicalRelationsCount > 0;
        const hasRelations = hasPhysicalRelations || hasLogicalRelations;

        const { physicalIncomingRelations, physicalOutgoingRelations, logicalIncomingRelations, logicalOutgoingRelations } = relations;

        let rightStyle = {};
        if (selectionIsInvisible) {
            rightStyle = { paddingBottom: "50px" };
        }

        return Boolean(hasRelations) ? (
            <Root onScroll={this.checkSecondarySelectionVisibility} ref={this.rootRef}>
                <ReactResizeDetector handleHeight onResize={this.handleResize} />
                {Boolean(selectionIsInvisible) && <RevealSelectedRelation />}
                <Inner>
                    {Boolean(isExpanded) && (
                        <Left>
                            {hasPhysicalRelations && (
                                <Fragment>
                                    {hasLogicalRelations && (
                                        <IndexContainer onClick={() => this.scrollToIndex(stringToCharCodes(physicalObjectId))}>
                                            <IndexName>Fysiek object</IndexName>
                                            <IndexCounter>{physicalRelationsCount}</IndexCounter>
                                        </IndexContainer>
                                    )}
                                    {this.createGroupIndices(physicalObjectId, groupedRelations.physicalIncomingRelations)}
                                    {this.createGroupIndices(physicalObjectId, groupedRelations.physicalOutgoingRelations)}
                                </Fragment>
                            )}
                            {hasLogicalRelations && (
                                <Fragment>
                                    {hasPhysicalRelations && (
                                        <IndexContainer onClick={() => this.scrollToIndex(stringToCharCodes(logicalObjectId))}>
                                            <IndexName>Logisch object</IndexName>
                                            <IndexCounter>{logicalRelationsCount}</IndexCounter>
                                        </IndexContainer>
                                    )}
                                    {this.createGroupIndices(logicalObjectId, groupedRelations.logicalIncomingRelations)}
                                    {this.createGroupIndices(logicalObjectId, groupedRelations.logicalOutgoingRelations)}
                                </Fragment>
                            )}
                        </Left>
                    )}
                    <Right sx={rightStyle} ref={(node) => (this.rightContainer = node)} onScroll={this.handleScroll}>
                        {hasPhysicalRelations &&
                            this.renderRelationCategories(
                                "Fysiek object",
                                physicalObjectId,
                                physicalRelationsCount,
                                physicalIncomingRelations,
                                physicalOutgoingRelations,
                                hasLogicalRelations
                            )}
                        {hasLogicalRelations &&
                            this.renderRelationCategories(
                                "Logisch object",
                                logicalObjectId,
                                logicalRelationsCount,
                                logicalIncomingRelations,
                                logicalOutgoingRelations,
                                hasPhysicalRelations
                            )}
                    </Right>
                </Inner>
            </Root>
        ) : (
            noRelations
        );
    }
}

const mapStateToProps = ({ appReducer, objectRelationsReducer, objectNamesReducer, selectionReducer }) => ({
    shouldRevealSelectedRelation: appReducer.shouldRevealSelectedRelation,
    physicalObjectId: objectRelationsReducer.physicalObjectId,
    logicalObjectId: objectRelationsReducer.logicalObjectId,
    objectNames: objectNamesReducer.objects,
    forceSelection: appReducer.forceRelationsSelection,
    highlightResource: selectionReducer.highlightResource,
});

const mapDispatchToProps = (dispatch) => ({
    setShouldRevealSelectedRelation: (shouldRevealSelectedRelation) => dispatch(setShouldRevealSelectedRelation(shouldRevealSelectedRelation)),
    onSetQueuedHighlights: (source, highlightIds, keepVisible = false) => dispatch(setQueuedHighlights(source, highlightIds, keepVisible)),
    onClearQueuedSelectedObjectResource: () => dispatch(clearQueuedSelectedObjectResource()),
    resetForceRelationsSelection: () => dispatch(resetForceRelationsSelection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericRelations);
