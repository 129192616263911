import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setPropertiesOverlayOpen } from "../../redux/app/actions";
import PropertiesExpanded from "./tabComponents/selection/properties/PropertiesExpanded";

const Root = styled(Box)(({ theme }) => ({
    zIndex: 0,
    pointerEvents: "all",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: -1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
}));

const Content = styled(Box)(() => ({
    height: "100%",
    maxHeight: "100%",
    display: "flex",
}));

const Properties = styled(Box)(() => ({
    flex: 1,
}));

class PropertiesOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    closeSelection = () => {
        this.props.onSetPropertiesOverlayOpen(false);
    };

    render() {
        return (
            <Root>
                <Content>
                    <Properties>
                        <PropertiesExpanded />
                    </Properties>
                </Content>
            </Root>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onSetPropertiesOverlayOpen: (propertiesOverlayOpen) => dispatch(setPropertiesOverlayOpen(propertiesOverlayOpen)),
});

export default connect(null, mapDispatchToProps)(PropertiesOverlay);
