export const requestObjectProperties = (objectId) => ({
    type: "REQUEST_OBJECT_PROPERTIES",
    objectId: objectId
})

export const receiveObjectProperties = (objectId, properties, error) => ({
    type: "RECEIVE_OBJECT_PROPERTIES",
    objectId: objectId,
    properties,
    error,
})

export const setViewerProperties = (viewerProperties, objectId, source) => ({
    type: "SET_VIEWER_PROPERTIES",
    viewerProperties,
    objectId,
    source,
})