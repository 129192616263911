import { Fragment, useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCubes, faFilePdf, faMap, faMegaphone, faUsers } from "@fortawesome/pro-light-svg-icons";

import CurrentVersion from "../managers/ChangeLogManager/CurrentVersion";
import AdminDialogContentTips from "./Notifications/AdminDialogContentTips";
import AdminDialogContentUpdates from "./Notifications/AdminDialogContentUpdates";
import AdminDialogContentBIMAccount from "./BIM/AdminDialogContentBIMAccount";
import AdminDialogContentGISAccount from "./GIS/AdminDialogContentGISAccount";
import AdminDialogContentGISWebMaps from "./GIS/AdminDialogContentGISWebMaps";
import AdminDialogContentPDFAccount from "./PDF/AdminDialogContentPDFAccount";
import AdminDialogContentGISProperties from "./GIS/AdminDialogContentGISProperties";
import AdminDialogContentBIMProperties from "./BIM/AdminDialogContentBIMProperties";
import AdminDialogContentBIMModels from "./BIM/AdminDialogContentBIMModels";
import AdminDialogContentGISLayers from "./GIS/AdminDialogContentGISLayers";
import AdminDialogContentBIMFilters from "./BIM/AdminDialogContentBIMFilters";
import AdminDialogContentUsersRoles from "./Users/AdminDialogContentUsersRoles";
import AdminDialogContentUsersAccess from "./Users/AdminDialogContentUsersAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AdminDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

export interface AdminDialogContentProps {
    onClose: () => void;
}

interface MenuGroup {
    title: string;
    icon: IconDefinition;
    items: MenuItem[];
}

interface MenuItem {
    id: number;
    title: string;
    component: React.ComponentType<any>;
}

const menuOptions: MenuGroup[] = [
    {
        title: "Gebruikers",
        icon: faUsers,
        items: [
            {
                id: 20,
                title: "Rollen",
                component: AdminDialogContentUsersRoles,
            },
            {
                id: 21,
                title: "Toegang",
                component: AdminDialogContentUsersAccess,
            },
        ],
    },
    {
        title: "BIM",
        icon: faCubes,
        items: [
            {
                id: 30,
                title: "Account",
                component: AdminDialogContentBIMAccount,
            },
            {
                id: 31,
                title: "Eigenschappen",
                component: AdminDialogContentBIMProperties,
            },
            {
                id: 32,
                title: "Filters",
                component: AdminDialogContentBIMFilters,
            },
            {
                id: 33,
                title: "Modellen",
                component: AdminDialogContentBIMModels,
            },
        ],
    },
    {
        title: "GIS",
        icon: faMap,
        items: [
            {
                id: 40,
                title: "Account",
                component: AdminDialogContentGISAccount,
            },
            {
                id: 41,
                title: "Eigenschappen",
                component: AdminDialogContentGISProperties,
            },
            {
                id: 42,
                title: "Webmaps",
                component: AdminDialogContentGISWebMaps,
            },
            {
                id: 43,
                title: "Webmaps Lagen",
                component: AdminDialogContentGISLayers,
            },
        ],
    },
    {
        title: "PDF",
        icon: faFilePdf,
        items: [
            {
                id: 50,
                title: "Account",
                component: AdminDialogContentPDFAccount,
            },
        ],
    },
    {
        title: "Notificaties",
        icon: faMegaphone,
        items: [
            {
                id: 60,
                title: "Inhoudsupdates",
                component: AdminDialogContentUpdates,
            },
            {
                id: 61,
                title: "Tips",
                component: AdminDialogContentTips,
            },
        ],
    },
];

const AdminDialog = ({ isOpen, onClose }: AdminDialogProps) => {
    const [activeTab, setActiveTab] = useState<number>();

    useEffect(() => setActiveTab(menuOptions[0].items[0].id), [isOpen]);

    const renderMenuOptions = () => {
        return menuOptions.map((group, index) => (
            <Fragment key={index}>
                <Typography variant="button" sx={{ margin: "10px" }}>
                    <FontAwesomeIcon icon={group.icon} style={{ marginRight: 10 }} />
                    {group.title}
                </Typography>

                <ToggleButtonGroup
                    sx={{ mb: "20px" }}
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value)}
                    exclusive
                    orientation="vertical"
                    fullWidth
                >
                    {group.items.map((item) => (
                        <ToggleButton
                            sx={{
                                justifyContent: "left",
                                border: "none",
                                margin: "5px 0",
                                padding: "7px 11px",
                                width: "100%",
                            }}
                            value={item.id}
                            selected={activeTab === item.id}
                            key={item.id}
                            onClick={() => setActiveTab(item.id)}
                        >
                            <Typography variant="body1">{item.title}</Typography>
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Fragment>
        ));
    };

    const renderContent = () => {
        const items: MenuItem[] = menuOptions.reduce((a: MenuItem[], b: MenuGroup) => a.concat(b.items), []);
        const activeItem = items.find((x) => x.id === activeTab);
        if (activeItem) {
            const Component = activeItem.component;
            return <Component onClose={onClose} />;
        }

        return null;
    };

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-labelledby="settings-dialog-slide-title"
            aria-describedby="settings-dialog-slide-description"
            maxWidth="xl"
            fullWidth
        >
            <Box
                sx={{
                    display: "flex",
                    height: "80vh",
                }}
            >
                <Box
                    sx={{
                        color: "#828282",
                        width: 300,
                        minWidth: 300,
                        backgroundColor: "#f2f4f6",
                        borderRight: "1px solid #e4eaf6",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <DialogTitle id="settings-dialog-slide-title">Administratie</DialogTitle>
                    <DialogContent
                        dividers
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflow: "auto",
                        }}
                    >
                        {renderMenuOptions()}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            height: "52px",
                            margin: "5px",
                            fontSize: 11,
                            alignSelf: "center",
                        }}
                    >
                        {CurrentVersion.toString()}
                    </DialogActions>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>{renderContent()}</Box>
            </Box>
        </Dialog>
    );
};

export default AdminDialog;
