import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
    TableContainer,
    lighten,
    Checkbox,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Theme,
    SxProps,
    useTheme,
} from "@mui/material";
import { faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import { createUpdate, fetchUpdates, removeUpdate } from "../../../connectors/admin/updates";
import ContentUpdateItem from "../../managers/ContentUpdateManager/ContentUpdateItem";
import { ContentUpdate, ContentEntry } from "../../managers/ContentUpdateManager/ContentUpdate";
import DeleteButton from "../DeleteButton";
import StyledTooltip from "../../generic/StyledTooltip";
import { generateUUID } from "../../../utils/guidFunctions";

export interface AdminDialogContentUpdatesProps {
    updates: any;
    onClose: () => void;
}

interface FormContentEntry {
    id: string;
    type: string;
    title: string;
    description: string;
}

const AdminDialogContentUpdates = ({ updates, onClose }: AdminDialogContentUpdatesProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<ContentUpdate[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [createNewUpdate, setCreateNewUpdate] = useState<boolean>(false);
    const [newUpdates, setNewUpdates] = useState<FormContentEntry[]>([]);
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    useEffect(() => {
        fetchUpdates();
    }, []);

    useEffect(() => {
        const data = ContentUpdate.fromJson(JSON.stringify(updates));
        setData(data);
        setIsLoading(false);
    }, [updates]);

    useEffect(() => {
        if (!createNewUpdate) return;
        setNewUpdates([
            {
                id: generateUUID(),
                type: "",
                title: "",
                description: "",
            },
        ]);
    }, [createNewUpdate]);

    const onSave = () => {
        const updates = newUpdates.map((update) => ({
            type: update.type.toLowerCase(),
            titel: update.title,
            omschrijving: update.description,
        }));

        setIsLoading(true);
        createUpdate(updates).then(() => {
            setCreateNewUpdate(false);
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);

        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            const id = selected[i];
            promises.push(removeUpdate(id));
        }

        Promise.all(promises).then(() => {
            setSelected([]);
            setShouldDelete(false);
            setIsLoading(false);
        });
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const addNewEntry = () => {
        setNewUpdates([
            ...newUpdates,
            {
                id: generateUUID(),
                type: "",
                title: "",
                description: "",
            },
        ]);
    };

    const removeEntry = (id: string) => {
        setNewUpdates(newUpdates.filter((x) => x.id !== id));
    };

    const updateEntry = (id: string, type: string, title: string, description: string) => {
        const updates = [...newUpdates];
        const entry = updates.find((x) => x.id === id)!;
        entry.type = type;
        entry.title = title;
        entry.description = description;

        setNewUpdates(updates);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>Notificaties</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setCreateNewUpdate(true)} onDelete={onDeleteEntry} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableBody>
                                    {data.map((entry: ContentUpdate) => {
                                        const isItemSelected = isSelected(entry.id);

                                        return (
                                            <TableRow key={entry.id} hover onClick={() => handleClick(entry.id)}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell style={{ width: 260 }}>
                                                    {entry.date.toLocaleDateString("nl-BE", {
                                                        weekday: "long",
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    })}
                                                </TableCell>
                                                <TableCell>
                                                    {entry.updates.map((update: ContentEntry, index: number) => (
                                                        <ContentUpdateItem
                                                            key={index}
                                                            type={update.type}
                                                            content={update.title}
                                                            description={update.description}
                                                        />
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={createNewUpdate} onClose={() => setCreateNewUpdate(false)} aria-labelledby="form-dialog-title" maxWidth="lg">
                        <DialogTitle id="form-dialog-title">Nieuwe inhoudsupdate toevoegen</DialogTitle>
                        <DialogContent style={{ minWidth: 600, maxWidth: 600, maxHeight: 800 }}>
                            {newUpdates.map((update, index) => (
                                <UpdateEntry
                                    key={update.id}
                                    index={index}
                                    onAdd={() => addNewEntry()}
                                    onDelete={() => removeEntry(update.id)}
                                    onChange={(type: string, title: string, description: string) => updateEntry(update.id, type, title, description)}
                                    count={newUpdates.length}
                                />
                            ))}
                        </DialogContent>
                        <DialogActions sx={{ m: 1 }}>
                            <Button onClick={() => setCreateNewUpdate(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button onClick={() => onSave()} color="primary" variant="contained" disabled={false}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={shouldDelete} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Inhoudsupdate(s) verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>
                                U staat op het punt de geselecteerde inhoudsupdate(s) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

interface UpdateEntryProps {
    index: number;
    count: number;
    onChange: (type: string, title: string, description: string) => void;
    onAdd: () => void;
    onDelete: () => void;
}

const types = ["Nieuw", "Update", "Correctie"];
const UpdateEntry = ({ count, index, onChange, onAdd, onDelete }: UpdateEntryProps) => {
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        onChange(type, title, description);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, title, description]);

    return (
        <div style={{ display: "flex" }}>
            <div style={{ margin: 5 }}>
                <FormControl>
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        label="Type"
                        id="type-select"
                        value={type}
                        onChange={(e) => setType(e.target.value as string)}
                        style={{ width: 150 }}
                    >
                        {types.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "column", margin: 5 }}>
                <TextField label="Titel" value={title} onChange={(e) => setTitle(e.target.value)} />
                <TextField
                    multiline
                    label="Omschrijving"
                    minRows={6}
                    maxRows={6}
                    style={{ marginTop: 10 }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div style={{ margin: 5, marginTop: 15, width: 110 }}>
                <IconButton onClick={onDelete}>
                    <FontAwesomeSvgIcon icon={faTrash} />
                </IconButton>
                {index + 1 === count && (
                    <IconButton style={{ marginLeft: 10 }} onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    updates: appReducer.updates,
});

export default connect(mapStateToProps)(AdminDialogContentUpdates);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={style}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Inhoudsupdates
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde inhoudsupdates verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe inhoudsupdate toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
