import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";

const ImportBookmarkModal = ({ open, onClose, onImport, initialName, data }) => {
    const [name, setName] = useState(initialName);

    useEffect(() => {
        if (Boolean(open)) setName(initialName);
    }, [open, initialName]);

    const renderInfo = () => {
        if (!data) return null;

        const count = data.active.filter((x) => x.visible)?.length;
        switch (data.type) {
            case "forge":
                return (
                    <Typography variant="caption" component="span">
                        Deze bookmark bevat {count} ingeschakeld{count > 1 && "e"} {count === 1 ? "model" : "modellen"}. Het is nadien
                        mogelijk deze bookmark te beheren en delen met andere gebruikers via het zijpaneel.
                    </Typography>
                );
            case "arcgis":
                return (
                    <Typography variant="caption" component="span">
                        Deze bookmark bevat {count} inschakelde {count === 1 ? "laag" : "lagen"}. Het is nadien mogelijk deze bookmark te
                        beheren en delen met andere gebruikers via het zijpaneel.
                    </Typography>
                );
            default:
                throw new Error("Invalid type supplied:", data.type);
        }
    };

    return (
        <Dialog open={Boolean(open)} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bookmark importeren</DialogTitle>
            <DialogContent>
                <DialogContentText>Wijzig eventueel de naam voor de geïmporteerde bookmark:</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Bookmark Naam"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                {renderInfo()}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
                <Button onClick={() => onImport(name)} color="primary" variant="contained">
                    Importeren
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportBookmarkModal;
