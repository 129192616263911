import { PaneMode } from "../SplitPaneContext/State";

export interface AppState {
    sidebarWidth?: number;
    panesCount: number;
    panesOrientation: PanesOrientation;
    activePane: number;
    panesState: Array<PaneState>;
}

export enum PanesOrientation {
    HORIZONTAL,
    VERTICAL,
    MIXED,
}

export interface PaneState {
    id: number;
    mode: PaneMode;
    appObject: any;
    data?: BIMData | GISData;
}

export interface BIMData {
    type: "BIM";
    documents: Array<any>;
    legend?: any;
    tools?: BIMTools;
    loaded: Array<string>;
}

export interface BIMTools {
    legend: boolean;
}

export interface GISData {
    type: "GIS";
    basemapId: string;
    scale?: number;
    themes: Array<any>;
    tools?: GISTools;
    loaded: Array<string>;
}

export interface GISTools {
    measureTool: boolean;
    surfaceTool: boolean;
    legend: boolean;
}

export const initialAppState: AppState = {
    panesCount: 1,
    panesOrientation: PanesOrientation.HORIZONTAL,
    activePane: 1,
    panesState: [],
};
