import { Box, useTheme } from "@mui/material";

const CommentIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="17" viewBox="0 0 16.999 17">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                }}
                d="M14.874,0H2.125A2.127,2.127,0,0,0,0,2.125v9.562a2.127,2.127,0,0,0,2.125,2.125H5.312V16.6a.4.4,0,0,0,.634.322l4.147-3.111h4.781A2.127,2.127,0,0,0,17,11.687V2.125A2.127,2.127,0,0,0,14.874,0ZM9.562,8.765a.266.266,0,0,1-.266.266H4.515a.266.266,0,0,1-.266-.266V8.234a.266.266,0,0,1,.266-.266H9.3a.266.266,0,0,1,.266.266Zm3.187-3.187a.266.266,0,0,1-.266.266H4.515a.266.266,0,0,1-.266-.266V5.047a.266.266,0,0,1,.266-.266h7.968a.266.266,0,0,1,.266.266Z"
                transform="translate(0 0)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.999" height="17" viewBox="0 0 16.999 17">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.placeholderText,
                }}
                d="M14.874,0H2.125A2.127,2.127,0,0,0,0,2.125v9.562a2.127,2.127,0,0,0,2.125,2.125H5.312V16.6a.4.4,0,0,0,.634.322l4.147-3.111h4.781A2.127,2.127,0,0,0,17,11.687V2.125A2.127,2.127,0,0,0,14.874,0ZM9.562,8.765a.266.266,0,0,1-.266.266H4.515a.266.266,0,0,1-.266-.266V8.234a.266.266,0,0,1,.266-.266H9.3a.266.266,0,0,1,.266.266Zm3.187-3.187a.266.266,0,0,1-.266.266H4.515a.266.266,0,0,1-.266-.266V5.047a.266.266,0,0,1,.266-.266h7.968a.266.266,0,0,1,.266.266Z"
                transform="translate(0 0)"
            />
        </svg>
    );
};

export default CommentIcon;
