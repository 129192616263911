import { Button, styled } from "@mui/material";
import { red } from "@mui/material/colors";

const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
        backgroundColor: red[700],
    },
}));

export default DeleteButton;
