import { Component } from "react";
import { connect } from "react-redux";
import { loadModules } from "esri-loader";
import { Box, CircularProgress, Grid } from "@mui/material";
import SideBar from "./sidebar/SideBar";
import SplitPaneContainer from "./appControls/SplitPane/SplitPaneContainer";
import ObjectDataManager from "./managers/ObjectDataManager";
import SelectionManager from "./managers/SelectionManager";
import ChangelogManager from "./managers/ChangeLogManager/ChangelogManager";
import { fetchProject, fetchProjectResources, fetchProjects } from "../connectors/projects";
import { fetchUserState } from "../connectors/userState";
import { fetchActiveByDefault } from "../connectors/arcgis";
import ContentUpdateManager from "./managers/ContentUpdateManager/ContentUpdateManager";
import TipsManager from "./managers/TipsManager/TipsManager";
import { loadObjectNames } from "../connectors/objectNames";
import { fetchTips } from "../connectors/admin/tips";
import { fetchUpdates } from "../connectors/admin/updates";

class PrivateApp extends Component {
    componentDidMount() {
        fetchProjects();
        fetchUserState();
        fetchTips();
        fetchUpdates();
        loadObjectNames();

        loadModules(["esri/intl"]).then(([intl]) => {
            intl.setLocale("nl-BE");
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projects !== prevProps.projects) {
            this.updateProjects();
        }

        const { activeProject } = this.props;
        if (activeProject !== prevProps.activeProject) {
            this.updateActiveProject(activeProject);
        }
    }

    updateProjects() {
        const { projects } = this.props;

        if (projects.length === 1) {
            fetchProject(projects[0].id);
        }
    }

    updateActiveProject(activeProject) {
        fetchActiveByDefault();
        fetchProjectResources(activeProject);
    }

    renderProgress = () => {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <CircularProgress
                            sx={{
                                position: "relative",
                                top: "calc(50% - 20px)",
                                left: "calc(50% - 20px)",
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
        );
    };

    renderContent() {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    flexWrap: "nowrap",
                }}
            >
                <SplitPaneContainer />
                <SideBar />

                <ObjectDataManager />
                <SelectionManager />
                <TipsManager />
                <ContentUpdateManager />
                <ChangelogManager />
            </Grid>
        );
    }

    renderMessage = (message) => {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        margin: "auto",
                    }}
                >
                    {message}
                </Box>
            </Grid>
        );
    };

    render() {
        const { projects, projectFetching, activeProject, projectsFetched } = this.props;

        if (!projectFetching && activeProject) {
            return this.renderContent();
        }

        if (!projectFetching && !activeProject && projects.length > 1) {
            return this.renderMessage("Gelieve eerst een project te selecteren.");
        }

        if (projectsFetched && projects.length === 0) {
            return this.renderMessage("Sorry, u heeft geen toegang tot projecten.");
        }

        return this.renderProgress();
    }
}

const mapStateToProps = ({ projectReducer }) => ({
    projects: projectReducer.projects,
    activeProject: projectReducer.activeProject,
    projectResources: projectReducer.resources,
    projectFetching: projectReducer.isFetching,
    projectsFetched: projectReducer.projectsFetched,
});

export default connect(mapStateToProps)(PrivateApp);
