import { Box, useTheme } from "@mui/material";

const RequirementsIcon = ({ selected, disabled }) => {
    const theme = useTheme();

    const defaultStyle = {
        fill: theme.colors.placeholderText,
    };

    const selectedStyle = {
        fill: theme.colors.secondarySelection,
    };

    const disabledStyle = {
        pointerEvents: "none",
        fill: theme.colors.disabled,
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
            <Box
                component={"path"}
                sx={style}
                d="M7,4.25V0H.75A.748.748,0,0,0,0,.75v14.5A.748.748,0,0,0,.75,16h10.5a.748.748,0,0,0,.75-.75V5H7.75A.752.752,0,0,1,7,4.25Zm2,7.375A.376.376,0,0,1,8.625,12H3.375A.376.376,0,0,1,3,11.625v-.25A.376.376,0,0,1,3.375,11h5.25A.376.376,0,0,1,9,11.375Zm0-2A.376.376,0,0,1,8.625,10H3.375A.376.376,0,0,1,3,9.625v-.25A.376.376,0,0,1,3.375,9h5.25A.376.376,0,0,1,9,9.375Zm0-2.25v.25A.376.376,0,0,1,8.625,8H3.375A.376.376,0,0,1,3,7.625v-.25A.376.376,0,0,1,3.375,7h5.25A.376.376,0,0,1,9,7.375Zm3-3.566V4H8V0h.191a.749.749,0,0,1,.531.219l3.059,3.062A.748.748,0,0,1,12,3.809Z"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.998" height="16" viewBox="0 0 11.998 16">
            <Box
                component={"path"}
                sx={style}
                d="M11.557,2.962,8.936.341A1.5,1.5,0,0,0,7.877-.1H1.5A1.5,1.5,0,0,0,0,1.4v13a1.5,1.5,0,0,0,1.5,1.5h9A1.5,1.5,0,0,0,12,14.4V4.024a1.507,1.507,0,0,0-.441-1.062Zm-.706.709a.492.492,0,0,1,.131.231H8V.919a.492.492,0,0,1,.231.131ZM10.5,14.9h-9a.5.5,0,0,1-.5-.5V1.4A.5.5,0,0,1,1.5.9H7V4.152a.748.748,0,0,0,.75.75H11v9.5A.5.5,0,0,1,10.5,14.9ZM9,7.277v.25a.376.376,0,0,1-.375.375H3.374A.376.376,0,0,1,3,7.527v-.25A.376.376,0,0,1,3.374,6.9H8.623A.376.376,0,0,1,9,7.277Zm0,2v.25a.376.376,0,0,1-.375.375H3.374A.376.376,0,0,1,3,9.526v-.25A.376.376,0,0,1,3.374,8.9H8.623A.376.376,0,0,1,9,9.276Zm0,2v.25a.376.376,0,0,1-.375.375H3.374A.376.376,0,0,1,3,11.526v-.25a.376.376,0,0,1,.375-.375H8.623A.376.376,0,0,1,9,11.276Z"
                transform="translate(0 0.1)"
            />
        </svg>
    );
};

export default RequirementsIcon;
