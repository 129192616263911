import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Badge } from "@mui/material";
import { faBringForward } from "@fortawesome/pro-solid-svg-icons";
import { showModelViews } from "../../../redux/forge/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const countViewpoints = (root) => {
    if (!root) return 0;
    return root.reduce((acc, item) => acc + item.viewpoints.length + countViewpoints(item.children), 0);
};

const ViewsPropertiesButton = ({ item, isVisible, views, onShowModelViews }) => {
    const [count, setCount] = useState(1);

    useEffect(() => {
        const id = item.relationships?.derivatives?.data?.id;
        if (id && views[id]) {
            const count = countViewpoints([views[id]]);
            setCount(count);
        }
    }, [item, views, isVisible]);

    return isVisible && count > 1 ? (
        <Box sx={{ cursor: "pointer" }} onClick={(e) => e.stopPropagation()}>
            <Badge
                badgeContent={count}
                overlap="rectangular"
                color="primary"
                onClick={() => onShowModelViews(item.relationships.derivatives.data.id)}
                max={200}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <FontAwesomeIcon icon={faBringForward} />
            </Badge>
        </Box>
    ) : null;
};

const mapStateToProps = ({ forgeReducer }) => ({
    views: forgeReducer.views,
});

const mapDispatchToProps = (dispatch) => ({
    onShowModelViews: (modelId) => dispatch(showModelViews(modelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewsPropertiesButton);
