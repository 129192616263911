export const setAuthentication = (authentication) => ({
    type: "SET_AUTHENTICATION",
    authentication,
});

export const removeAuthentication = () => ({
    type: "REMOVE_AUTHENTICATION",
});

export const setAccount = (account) => ({
    type: "SET_ACCOUNT",
    account,
});

export const setState = (state) => ({
    type: "SET_STATE",
    state,
});
