export const getFlatModelsArray = (items, models = []) => {
    return items.reduce((models, item) => {
        const itemLayers = item.items.filter(({ type }) => type === "versions");
        const childLayers = getFlatModelsArray(item.items.filter((x) => x.folder));
        return [...models, ...itemLayers, ...childLayers];
    }, models);
};

export const getTreeIndexString = (treeIndex) => {
    return treeIndex.map((index) => `[${index}]`).join("-");
};

export const countVisibleModels = (item, visibleModels = 0) => {
    const { folder, visible, items = [] } = item;
    if (folder) {
        return items.reduce((visibleModels, item) => {
            return visibleModels + countVisibleModels(item);
        }, visibleModels);
    } else {
        return visible ? 1 : 0;
    }
};

export const countTotalModels = (item, totalModels = 0) => {
    const { folder, items = [] } = item;
    if (folder) {
        return items.reduce((totalModels, item) => {
            return totalModels + countTotalModels(item);
        }, totalModels);
    } else {
        return 1;
    }
};

export const countTotalModelsFiltered = (item, filter, totalModels = 0) => {
    const { folder, items = [] } = item;
    if (folder) {
        return items.reduce((totalModels, item) => {
            return totalModels + countTotalModelsFiltered(item, filter);
        }, totalModels);
    } else {
        return isModelFiltered(item, filter);
    }
};

export const isModelFiltered = (item, filter) => {
    const { attributes } = item;

    return (attributes.description?.toLowerCase().search(filter.toLowerCase()) ?? -1) > -1 ||
        attributes.displayName.toLowerCase().search(filter.toLowerCase()) > -1
        ? 1
        : 0;
};
