import { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { setHighlights, setSelectedObject } from "../../redux/selection/actions";
import { setRelationsOverlayOpen } from "../../redux/app/actions";

import { AppContext } from "../../context/AppContext/AppContextProvider";
import { determineTargetData, setPaneDetails } from "../../context/AppContext/Reducer";
import { PaneMode } from "../../context/SplitPaneContext/State";
import { configuration } from "../../_configuration/configuration";

const SelectionManager = (props) => {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const { queuedSelectedObject, onSetSelectedObject, onSetRelationsOverlayOpen } = props;
        if (queuedSelectedObject) {
            const { objectId, source, isPhysicalObject, highlightIds } = queuedSelectedObject;
            const targetPaneId = getValidTarget(source);

            onSetSelectedObject(objectId, source, targetPaneId, isPhysicalObject, highlightIds);
            onSetRelationsOverlayOpen(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.queuedSelectedObject]);

    useEffect(() => {
        const { queuedHighlights, setHighlights } = props;
        if (queuedHighlights) {
            const { source, highlightIds } = queuedHighlights;
            const targetPaneId = getValidTarget(source);
            setHighlights(source, highlightIds, targetPaneId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.queuedHighlights]);

    const getValidTarget = (type) => {
        const { activePane, panesCount, panesState } = state;
        let mode;
        switch (type) {
            case configuration.sources.gis:
                mode = PaneMode.GIS;
                break;
            case configuration.sources.bim:
                mode = PaneMode.BIM;
                break;
            case configuration.sources.pdf:
                mode = PaneMode.DOCUMENT;
                break;
            default:
                throw new Error("Invalid pane mode.");
        }

        if (panesCount === 1) {
            if (panesState[0].mode !== mode) changePaneMode(1, mode);
            return 1;
        }

        const matchingActivePane = panesState.find((x) => x.mode === mode && x.id === activePane);
        if (matchingActivePane) return matchingActivePane.id;

        const matchingPane = panesState.find((x) => x.mode === mode && x.id !== activePane);
        if (matchingPane) return matchingPane.id;

        const firstNoneModePane = panesState.find((x) => x.mode === PaneMode.NONE);
        if (firstNoneModePane) {
            changePaneMode(firstNoneModePane.id, mode);
            return firstNoneModePane.id;
        }

        const firstInactivePane = panesState.find((x) => x.id !== activePane);
        if (firstInactivePane) {
            changePaneMode(firstInactivePane.id, mode);
            return firstInactivePane.id;
        }
    };

    const changePaneMode = (paneId, mode) => {
        const data = determineTargetData(mode);
        dispatch(setPaneDetails(paneId, { id: paneId, mode, data, appObject: undefined }));
    };

    return null;
};

const mapStateToProps = ({ selectionReducer }) => ({
    queuedSelectedObject: selectionReducer.queuedSelectedObject,
    queuedHighlights: selectionReducer.queuedHighlights,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedObject: (objectId, source, paneId, isPhysicalObject, highlightIds) =>
        dispatch(setSelectedObject(objectId, source, paneId, isPhysicalObject, highlightIds)),
    onSetRelationsOverlayOpen: (relationsOverlayOpen) => dispatch(setRelationsOverlayOpen(relationsOverlayOpen)),
    setHighlights: (source, highlightIds, paneId) => dispatch(setHighlights(source, highlightIds, paneId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionManager);
