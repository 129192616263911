import { useContext } from "react";
import { Box } from "@mui/material";
import { SplitPaneContext } from "../../../context/SplitPaneContext/SplitPaneContextProvider";
import ToggleMode from "./ToggleMode";
import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../../context/SplitPaneContext/State";
import ForgeTools from "./ForgeTools";
import ArcgisTools from "./ArcgisTools";
import SplitScreenQuickToggle from "./SplitScreenQuickToggle";

const Toolbar = () => {
    const { state: appState } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);

    const renderAppToolbar = () => {
        const { panesState } = appState;
        const activeMode = panesState[paneState.id - 1].mode;
        switch (activeMode) {
            case PaneMode.BIM:
                return <ForgeTools />;
            case PaneMode.GIS:
                return <ArcgisTools />;
            default:
                return null;
        }
    };

    return (
        <Box
            id="bim-dashboard-application-switcher"
            sx={{
                position: "absolute",
                zIndex: 900,
                padding: "16px 24px 24px 24px",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "&>div>div": {
                    boxShadow: "0 6px 18px rgba(0, 0, 0, 0.16)",
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "-5px -10px",
                    "&>*": {
                        margin: "5px 10px",
                    },
                }}
            >
                <ToggleMode />
                <SplitScreenQuickToggle />
                {renderAppToolbar()}
            </Box>
        </Box>
    );
};

export default Toolbar;
