import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import Relations from "./relations/Relations";
import Properties from "./properties/Properties";
import { AppContext } from "../../../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../../../context/SplitPaneContext/State";

const Root = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "& h2": {
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const Container = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
}));

class Selection extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderNoSelection = () => {
        const { activePane, panesState } = this.context.state;
        const mode = panesState[activePane - 1].mode;

        let text = "Selecteer een object.";
        if (mode === PaneMode.GIS) {
            text = "Selecteer een object op de kaart.";
        } else if (mode === PaneMode.BIM) {
            text = "Selecteer een object op een 3D model.";
        }

        return <MessageContainer>{text}</MessageContainer>;
    };

    render() {
        const { title, selectedObject, hideProperties } = this.props;

        return (
            <Root>
                <h2>{title}</h2>
                <Container>
                    {Boolean(selectedObject && selectedObject.objectId) ? (
                        <Fragment>
                            <Relations />
                            {Boolean(!hideProperties) && <Properties />}
                        </Fragment>
                    ) : (
                        this.renderNoSelection()
                    )}
                </Container>
            </Root>
        );
    }
}

Selection.contextType = AppContext;

const mapStateToProps = ({ selectionReducer }) => ({
    selectedObject: selectionReducer.selectedObject,
});

export default connect(mapStateToProps)(Selection);
