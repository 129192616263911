import { Component } from "react";
import { Box } from "@mui/material";
import HistoryControls from "./HistoryControls";

class SelectionTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <span>Selectie</span>
                <HistoryControls />
            </Box>
        );
    }
}

export default SelectionTitle;
