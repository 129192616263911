const defaultState = {
    themes: [],
    defaultOrder: [],
    activeByDefault: [],
    basemapOptions: [
        // Available options through the api:
        // "satellite", "hybrid", "terrain", "oceans", "osm", "dark-gray-vector", "gray-vector", "streets-vector", "topo-vector", "streets-night-vector", "streets-relief-vector", "streets-navigation-vector"
        {
            id: "gray-vector",
            name: "Grijs - Vector",
        },
        {
            id: "dark-gray-vector",
            name: "Donkergrijs - Vector",
        },
        {
            id: "oceans",
            name: "Oceanen en zeeën",
        },
        {
            id: "osm",
            name: "OpenStreetMap",
        },
        {
            id: "arcgis-imagery",
            name: "Sateliet",
        },
        {
            id: "streets-vector",
            name: "Straten - Vector",
        },
        {
            id: "streets-night-vector",
            name: "Straten (nacht) - Vector",
        },
        {
            id: "streets-navigation-vector",
            name: "Straten (navigatie) - Vector",
        },
        {
            id: "streets-relief-vector",
            name: "Straten (relief) - Vector",
        },
        {
            id: "terrain",
            name: "Terrein",
        },
        {
            id: "topo-vector",
            name: "Topografisch - Vector",
        },
    ],
    propertiesLayer: null,
    bookmarkState: null,
    bookmarkStatePaneId: null,
};

const reducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "SET_THEMES": {
            newState.themes = action.themes;
            return newState;
        }

        case "SET_DEFAULT_ORDER": {
            newState.defaultOrder = action.order;
            return newState;
        }

        case "SET_ACTIVE_BY_DEFAULT": {
            newState.activeByDefault = action.layers;
            return newState;
        }

        case "SHOW_LAYER_PROPERTIES": {
            newState.propertiesLayer = newState.propertiesLayer !== action.layerId ? action.layerId : null;
            return newState;
        }

        case "SET_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = action.paneId;
            newState.bookmarkState = action.state;
            return newState;
        }

        case "CLEAR_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = null;
            newState.bookmarkState = null;
            return newState;
        }

        default: {
            return newState;
        }
    }
};

export default reducer;
