import { connect } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { faSlidersV } from "@fortawesome/pro-solid-svg-icons";
import { showLayerProperties } from "../../../redux/webMaps/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LayerPropertiesButton = ({ item, isVisible, onShowLayerProperties }) => {
    const theme = useTheme();

    return (
        <Box sx={{ cursor: "pointer" }} onClick={(e) => e.stopPropagation()}>
            <FontAwesomeIcon
                icon={faSlidersV}
                sx={
                    isVisible
                        ? {}
                        : {
                              color: theme.colors.disabled,
                              pointerEvents: "none",
                          }
                }
                onClick={() => onShowLayerProperties(item.id)}
            />
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onShowLayerProperties: (layerId) => dispatch(showLayerProperties(layerId)),
});

export default connect(null, mapDispatchToProps)(LayerPropertiesButton);
