import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 4, 0),
    date: new Date(2023, 1, 6),
    content: (
        <>
            <ChangelogItem
                content="BIM Documenten"
                type={ChangelogItemType.New}
                description="Er werd een extra tab voorzien om BIM Documenten (pdfs) te bekijken."
            />
            <ChangelogItem
                content="GIS onderleggers verbergen"
                type={ChangelogItemType.New}
                description="De functionaliteit om een GIS onderlegger te verbergen werd toegevoegd."
            />
        </>
    ),
};

export default changelog;
