import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 3, 0),
    date: new Date(2022, 9, 12),
    content: (
        <>
            <ChangelogItem
                content="Aanpassing ArcGIS lagen"
                type={ChangelogItemType.Update}
                description="De hoofdgroepen van de verschillende lagen werden aangepast zodat deze als volledige groep niet meer kunnen ingeschakeld worden."
            />
            <ChangelogItem
                content="Standaard zoom-level ArcGIS"
                type={ChangelogItemType.Update}
                description="Het standaard zoom-level van de ArcGIS viewer werd aangepast."
            />
            <ChangelogItem
                content="Standaard onderlegger ArcGIS"
                type={ChangelogItemType.Update}
                description="De standaard onderlegger van de ArcGIS viewer werd aangepast."
            />
            <ChangelogItem
                content="Informatie bij hover ArcGIS"
                type={ChangelogItemType.New}
                description="Bij het hoveren over elementen van een ArcGIS laag zullen de namen hiervan automatisch getoond worden."
            />
            <ChangelogItem
                content="Aanpassing BIM modellen"
                type={ChangelogItemType.Update}
                description="De hoofdgroepen van de verschillende modellen werden aangepast zodat deze als volledige groep niet meer kunnen ingeschakeld worden."
            />
            <ChangelogItem
                content="Aanpassing BIM model namen"
                type={ChangelogItemType.New}
                description="De namen van BIM modellen zijn verduidelijkt wanneer meer informatie beschikbaar is in BIM360."
            />
            <ChangelogItem
                content="Selectie in BIM model via gerelateerde modellen"
                type={ChangelogItemType.Bugfix}
                description="Probleem werd verholpen waarbij er geen selectie zichtbaar gemaakt werd wanneer een model werd ingeladen via de gerelateerde modellen tab van een selectie."
            />
        </>
    ),
};

export default changelog;
