export interface SplitPaneState {
    id: number;
}

export enum PaneMode {
    NONE,
    BIM,
    GIS,
    DOCUMENT,
}

export const initialSplitPaneState: SplitPaneState = {
    id: -1,
};
