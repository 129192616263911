import { Component, forwardRef } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Table, TableBody, TableCell, TableRow } from "@mui/material";
import isURL from "validator/lib/isURL";

const Transition = forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

class ObjectViewModal extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {};
        this.renderPropertyValue = this.renderPropertyValue.bind(this);
    }

    renderPropertyValue = (v) => {
        // check if the string is valid
        if (!v) {
            return null;
        }

        // check if it is an url
        if (typeof v == "string") {
            let url = v;
            if (url.startsWith("www.")) {
                url = `http://${url}`;
            }

            if (isURL(url, { require_protocol: true })) {
                // check if we start with "www"
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        link
                    </a>
                );
            }

            return v;
        }

        if (v.forEach) {
            let result = (
                <div>
                    {v.map((part) => {
                        return <Chip key={part} label={this.renderPropertyValue(part)} />;
                    })}
                </div>
            );
            return result;
        }

        // just return
        return v;
    };

    render() {
        const { open, handleClose, title, actions } = this.props;
        let object = this.props.object || {};
        let keys = this.props.keys || Object.keys(object);
        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                {keys.map((x) => {
                                    return (
                                        <TableRow key={x.label}>
                                            <TableCell>{x.label}</TableCell>
                                            <TableCell>{this.renderPropertyValue(object[x.key])}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        {actions.map((action) => {
                            return (
                                <Button key={action.title} onClick={action.method} color="secondary">
                                    {action.title}
                                </Button>
                            );
                        })}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ObjectViewModal;
