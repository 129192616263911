import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setSelectedLinkedId } from "../../../../../redux/selection/actions";
import SelectionNameMapsIcon from "../../../../svg/selection/SelectionNameMapsIcon";
import SelectionNameModelsIcon from "../../../../svg/selection/SelectionNameModelsIcon";
import SelectionTabsMapsIcon from "../../../../svg/selectionTabs/MapsIcon";
import Maps from "./tabComponents/Maps";
import ModelsIcon from "../../../../svg/selectionTabs/ModelsIcon";
import Models from "./tabComponents/Models";
import RequirementsIcon from "../../../../svg/selectionTabs/RequirementsIcon";
import Requirements from "./tabComponents/Requirements";
import Tabs from "./Tabs";
import ObjectName from "../../../../generic/ObjectName";
import PhysicalObjectTree from "./tabComponents/PhysicalObjectTree";
import PhysicalObjectTreeIcon from "../../../../svg/selectionTabs/PhysicalObjectTreeIcon";
import FavoriteToggle from "../../../../generic/FavoriteToggle";
import { setRelationsOverlayOpen } from "../../../../../redux/app/actions";
import Expand from "../../../../svg/appControls/Expand";
import Collapse from "../../../../svg/appControls/Collapse";
import DocumentsIcon from "../../../../svg/selectionTabs/DocumentsIcon";
import Documents from "./tabComponents/Documents";
import { configuration } from "../../../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    minHeight: "220px",
}));

const TabContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    position: "relative",
}));

const TabInner = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}));

const ObjectNameContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.inputBackground,
    padding: "20px 18px",
    display: "flex",
    justifyContent: "space-between",
    border: `1px solid ${theme.colors.primaryText}`,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    fontSize: "15px",
    color: theme.colors.primaryText,
}));

const ObjectNameContainerNotSelected = styled(ObjectNameContainer)(({ theme }) => ({
    backgroundColor: theme.colors.inputBackground,
    color: theme.colors.textColor,
    fontSize: "16px",
    border: "none",
    "&:hover": {
        backgroundColor: theme.colors.btnHover,
    },
}));

const ObjectNameValue = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    "&>span": {
        marginLeft: "18px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

const OverlayControl = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
}));

class Relations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    id: "physicalObjectTree",
                    tooltip: "Fysieke boomstructuur",
                    icon: PhysicalObjectTreeIcon,
                    component: PhysicalObjectTree,
                    counterCalculator: this.countPhysicalObjectTree,
                    counter: null,
                },
                {
                    id: "maps",
                    tooltip: "Gerelateerde kaarten",
                    icon: SelectionTabsMapsIcon,
                    component: Maps,
                    counterCalculator: this.countRelations("gisRelations"),
                    counter: null,
                },
                {
                    id: "models",
                    tooltip: "Gerelateerde 3D elementen",
                    icon: ModelsIcon,
                    component: Models,
                    counterCalculator: this.countRelations("bimRelations"),
                    counter: null,
                },
                {
                    id: "requirements",
                    tooltip: "Gerelateerde eisen",
                    icon: RequirementsIcon,
                    component: Requirements,
                    counterCalculator: this.countRelations("requirementRelations"),
                    counter: null,
                },
                {
                    id: "documents",
                    tooltip: "Gerelateerde documenten",
                    icon: DocumentsIcon,
                    component: Documents,
                    counterCalculator: this.countRelations("pdfRelations"),
                    counter: null,
                },
                // {
                //     id: "planning",
                //     tooltip: "Gerelateerde planning",
                //     icon: PlanningIcon,
                //     component: null,
                //     disabled: true,
                // },
                // {
                //     id: "costs",
                //     tooltip: "Kosten",
                //     icon: CostsIcon,
                //     component: null,
                //     disabled: true,
                // },
            ],
        };
    }

    countPhysicalObjectTree = () => {
        const { selectedObject, physicalObjectTrees } = this.props;

        if (!selectedObject) {
            return null;
        }

        const { objectId } = selectedObject;

        const { status, physicalObjectTree } = physicalObjectTrees[objectId] || {};

        if (status !== "fetched" || !physicalObjectTree) {
            return null;
        }

        const { parents = [], children = [] } = physicalObjectTree;

        return [...parents, ...children].length;
    };

    countRelations = (relationsKey) => () => {
        const { filteredRelations } = this.props;
        const relations = filteredRelations[relationsKey];
        if (!relations) {
            return null;
        }
        const {
            logicalIncomingRelations = [],
            logicalOutgoingRelations = [],
            physicalIncomingRelations = [],
            physicalOutgoingRelations = [],
        } = relations || {};
        return [...logicalIncomingRelations, ...logicalOutgoingRelations, ...physicalIncomingRelations, ...physicalOutgoingRelations].length;
    };

    componentDidMount() {
        this.setTabCounters();
        window.addEventListener("keyup", this.handleEscapeKey);
    }

    componentDidUpdate(prevProps, prevState) {
        this.setTabCounters();
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleEscapeKey);
    }

    handleEscapeKey = (event) => {
        const { isExpanded } = this.props;
        if (isExpanded && event.key === "Escape") {
            this.toggleRelationsOverlay();
        }
    };

    setTabCounters = () => {
        const { tabs } = this.state;

        let tabCountersHaveChanged = false;

        const updatedTabs = tabs.map((tab) => {
            const { counter, counterCalculator } = tab;
            if (typeof counterCalculator !== "function") {
                return tab;
            }

            const updatedTab = Object.assign({}, tab);
            updatedTab.counter = counterCalculator();
            if (updatedTab.counter !== counter) {
                tabCountersHaveChanged = true;
            }
            return updatedTab;
        });

        if (tabCountersHaveChanged) {
            this.setState({ tabs: updatedTabs });
        }
    };

    toggleRelationsOverlay = (event) => {
        if (event) {
            event.stopPropagation();
        }
        const { isExpanded, onSetRelationsOverlayOpen } = this.props;
        onSetRelationsOverlayOpen(!isExpanded);
    };

    renderTab = () => {
        const { tabs } = this.state;

        const { selectedRelationsTabId, isExpanded } = this.props;

        const selectedTab = tabs.find(({ id }) => id === selectedRelationsTabId);
        if (selectedTab) {
            const Component = selectedTab.component;
            return <Component isExpanded={isExpanded} />;
        }
    };

    renderObjectName = () => {
        const { selectedObject, selectedLinkedId, onSetSelectedLinkedId, isExpanded } = this.props;

        const hasSelectedLinkedId = Boolean(selectedLinkedId);

        let Container = ObjectNameContainer;
        if (hasSelectedLinkedId) {
            Container = ObjectNameContainerNotSelected;
        }

        let Icon = null;
        switch (selectedObject.source) {
            case configuration.sources.gis: {
                Icon = SelectionNameMapsIcon;
                break;
            }

            case configuration.sources.bim: {
                Icon = SelectionNameModelsIcon;
                break;
            }

            default: {
                break;
            }
        }

        const overlayControlIcon = Boolean(isExpanded) ? <Collapse isAlt /> : <Expand isAlt />;

        return (
            <Container onClick={() => onSetSelectedLinkedId(null, null)}>
                <ObjectNameValue>
                    {Boolean(Icon) && <Icon selected={!hasSelectedLinkedId} />}
                    <span>
                        <ObjectName objectId={selectedObject.objectId} shouldGetObjectName shouldCheckLinkedObject />
                    </span>
                </ObjectNameValue>
                <ActionsContainer>
                    <FavoriteToggle objectId={selectedObject.objectId} source={selectedObject.source} isAlt={hasSelectedLinkedId} />
                    <OverlayControl onClick={this.toggleRelationsOverlay} sx={{ cursor: "pointer" }}>
                        {overlayControlIcon}
                    </OverlayControl>
                </ActionsContainer>
            </Container>
        );
    };

    render() {
        const { tabs } = this.state;

        return (
            <Root>
                {this.renderObjectName()}
                <Tabs tabs={tabs} />
                <TabContainer>
                    <TabInner>{this.renderTab()}</TabInner>
                </TabContainer>
            </Root>
        );
    }
}

const mapStateToProps = ({ appReducer, selectionReducer, objectRelationsReducer }) => ({
    selectedRelationsTabId: appReducer.selectedRelationsTabId,
    selectedObject: selectionReducer.selectedObject,
    selectedLinkedId: selectionReducer.selectedLinkedId,
    physicalObjectTrees: objectRelationsReducer.physicalObjectTrees,
    filteredRelations: objectRelationsReducer.filteredRelations,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedLinkedId: (selectedLinkedId, source) => dispatch(setSelectedLinkedId(selectedLinkedId, source)),
    onSetRelationsOverlayOpen: (relationsOverlayOpen) => dispatch(setRelationsOverlayOpen(relationsOverlayOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Relations);
