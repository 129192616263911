import { useTheme } from "@mui/material";

const Expand = ({ isAlt }) => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
                style={{ fill: isAlt ? theme.colors.primaryText : theme.colors.white }}
                d="M29.531,18H36v6.469l-2.149-1.935-3.108,3.11L28.359,23.26l3.107-3.111Zm-7,15.851,3.11-3.108L23.26,28.359l-3.111,3.107L18,29.531V36h6.469Zm.723-8.21,2.384-2.384-3.107-3.108L24.469,18H18v6.469l2.149-1.935Zm7.487,2.711L28.36,30.736l3.106,3.115L29.531,36H36V29.531l-2.149,1.935Z"
                transform="translate(-18 -18)"
            />
        </svg>
    );
};

export default Expand;
