const Autodesk = window.Autodesk;

class SectionBoxExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;
        this.sections = null;
        this.options = options;
        this.active = false;
        this.lastHitPosition = null;

        this.actions = [
            {
                id: "x-plane-button",
                icon: "adsk-icon-plane-x",
                desc: "X-as sectie aanmaken",
                action: () => this.createSectionPlane(new window.THREE.Vector3(1, 0, 0)),
            },
            {
                id: "y-plane-button",
                icon: "adsk-icon-plane-y",
                desc: "Y-as sectie aanmaken",
                action: () => this.createSectionPlane(new window.THREE.Vector3(0, 0, 1)),
            },
            {
                id: "z-plane-button",
                icon: "adsk-icon-plane-z",
                desc: "Z-as sectie aanmaken",
                action: () => this.createSectionPlane(new window.THREE.Vector3(0, -1, 0)),
            },
            {
                id: "face-plane-button",
                icon: "adsk-icon-section-analysis",
                desc: "Sectie aanmaken",
                action: () => this.createFaceSectionPlane(),
            },
            // {
            //     id: "box-button",
            //     icon: "adsk-icon-box",
            //     desc: "Sectiebox aanmaken",
            //     action: this.createSectionBox,
            // },
        ];
    }

    load = () => {
        this.viewer.addEventListener(Autodesk.Viewing["SELECTION_CHANGED_EVENT"], this.updateToolbarStatus);
        this.viewer.addEventListener(Autodesk.Viewing["MODEL_UNLOADED_EVENT"], this.modelUnloaded);
        this.viewer.addEventListener(Autodesk.Viewing["MODEL_REMOVED_EVENT"], this.modelUnloaded);

        this.viewer.canvas.addEventListener("click", this.saveHitPosition);

        return true;
    };

    unload = () => {
        this.viewer.removeEventListener(Autodesk.Viewing["SELECTION_CHANGED_EVENT"], this.updateToolbarStatus);
        this.viewer.removeEventListener(Autodesk.Viewing["MODEL_UNLOADED_EVENT"], this.modelUnloaded);
        this.viewer.removeEventListener(Autodesk.Viewing["MODEL_REMOVED_EVENT"], this.modelUnloaded);

        this.viewer.canvas.removeEventListener("click", this.saveHitPosition);

        return true;
    };

    saveHitPosition = (e) => {
        const coords = this.relMouseCoords(e);
        this.lastHitPosition = this.viewer.impl.hitTest(coords.x, coords.y, false);
    };

    relMouseCoords = (e) => {
        let totalOffsetX = 0;
        let totalOffsetY = 0;
        let canvasX = 0;
        let canvasY = 0;
        let currentElement = this.viewer.canvas;

        do {
            totalOffsetX += currentElement.offsetLeft - currentElement.scrollLeft;
            totalOffsetY += currentElement.offsetTop - currentElement.scrollTop;
        } while ((currentElement = currentElement.offsetParent));

        canvasX = e.pageX - totalOffsetX;
        canvasY = e.pageY - totalOffsetY;

        return { x: canvasX, y: canvasY };
    };

    updateToolbarStatus = () => {
        if (this.active) {
            this.groupButton.addClass("active");
            this.groupButton.removeClass("inactive");
            this.groupButton.removeClass("disabled");

            return;
        }

        const hasSelection = this.viewer.getSelectionCount();
        this.groupButton.addClass("inactive");
        this.groupButton.removeClass("active");

        if (hasSelection) this.groupButton.removeClass("disabled");
        else this.groupButton.addClass("disabled");
    };

    modelUnloaded = () => {
        const models = this.viewer.getVisibleModels();
        if (models.length > 0) return;
        if (this.sections) this.sections.deactivate();
    };

    onToolbarCreated = (toolbar) => {
        const sbeToolbar = new Autodesk.Viewing.UI.RadioButtonGroup("sbe-toolbar");

        this.groupButton = new Autodesk.Viewing.UI.ComboButton(this.actions[this.actions.length - 1].id);
        this.groupButton.setToolTip(this.actions[this.actions.length - 1].desc);
        this.groupButton.setIcon(this.actions[this.actions.length - 1].icon);
        this.groupButton.addClass("disabled");
        this.groupButton.onClick = this.actions[this.actions.length - 1].action;

        for (let i = 0, len = this.actions.length; i < len; i++) {
            const button = this.actions[i];

            const actionButton = new Autodesk.Viewing.UI.Button(`sbe-button-${i}`);
            actionButton.setToolTip(button.desc);
            actionButton.setIcon(button.icon);
            actionButton.onClick = () => {
                button.action();
                this.groupButton.setToolTip(button.desc);
                this.groupButton.setIcon(button.icon);
                this.groupButton.onClick = button.action;
            };

            this.groupButton.addControl(actionButton);
        }

        sbeToolbar.addControl(this.groupButton);
        toolbar.addControl(sbeToolbar);
    };

    createSectionBox = () => {
        if (!this.sections) this.sections = this.viewer.loadedExtensions["Autodesk.Section"];

        if (!this.active) {
            if (this.viewer.getSelectionCount() === 0) return;

            this.active = true;
            this.sections.setSectionBox(this.viewer.utilities.getBoundingBox());
        } else {
            this.active = false;
            this.sections.deactivate();
        }

        this.updateToolbarStatus();
    };

    createSectionPlane = (normal) => {
        if (!this.sections) this.sections = this.viewer.loadedExtensions["Autodesk.Section"];

        if (!this.active) {
            if (this.viewer.getSelectionCount() === 0) return;

            this.active = true;
            const point = this.viewer.utilities.getBoundingBox().center();
            this.sections.setSectionPlane(normal, point, true);
        } else {
            this.active = false;
            this.sections.deactivate();
        }

        this.updateToolbarStatus();
    };

    createFaceSectionPlane = () => {
        if (!this.sections) this.sections = this.viewer.loadedExtensions["Autodesk.Section"];

        if (!this.active) {
            if (this.viewer.getSelectionCount() === 0 || !this.lastHitPosition) return;

            this.active = true;
            this.sections.setSectionPlane(
                this.lastHitPosition.face.normal,
                this.lastHitPosition.point ?? new window.THREE.Vector3(0, 0, 1),
                true
            );
        } else {
            this.active = false;
            this.sections.deactivate();
        }

        this.updateToolbarStatus();
    };
}

export default SectionBoxExtension;
