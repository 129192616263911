import { configuration } from "../../_configuration/configuration";
import { addResourceItem, addRole, deleteResourceItem, deleteRole, editResourceItem, editRole, setResourceItems, setRoles } from "../../redux/app/actions";
import store from "../../redux/store";

export const fetchRoles = () => {
    const url = `${configuration.api}api/Roles`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((roles) => {
            store.dispatch(setRoles(roles));
        });
};

export const createRole = (role) => {
    const url = `${configuration.api}api/Roles`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(role),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((roles) => {
            store.dispatch(addRole(roles));
        });
};

export const modifyRole = (role) => {
    const url = `${configuration.api}api/Roles/${role.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(role),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((roles) => {
            store.dispatch(editRole(roles));
        });
};

export const removeRole = (id) => {
    const url = `${configuration.api}api/Roles/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteRole(id));
    });
};

export const fetchResourceItems = () => {
    const url = `${configuration.api}api/ResourceItems`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((resourceItems) => {
            store.dispatch(setResourceItems(resourceItems));
        });
};

export const createResourceItem = (resourceItem) => {
    const url = `${configuration.api}api/ResourceItems`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resourceItem),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((resourceItem) => {
            store.dispatch(addResourceItem(resourceItem));
        });
};

export const modifyResourceItem = (resourceItem) => {
    const url = `${configuration.api}api/ResourceItems/${resourceItem.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resourceItem),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((resourceItem) => {
            store.dispatch(editResourceItem(resourceItem));
        });
};

export const removeResourceItem = (id) => {
    const url = `${configuration.api}api/ResourceItems/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteResourceItem(id));
    });
};
