import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 0, 0),
    date: new Date(2022, 0, 14),
    content: (
        <>
            <ChangelogItem
                content="Implementatie Bookmarks"
                type={ChangelogItemType.New}
                description="Bookmarks laten het toe de staat van de ingeschakelde lagen of modellen op te slaan zodat deze op een later tijdstip opnieuw kunnen worden ingeladen."
            />
            <ChangelogItem
                type={ChangelogItemType.New}
                content="Implementatie Split Screen"
                description="Split Screen laat toe het venster in 2, 3 of 4 verschillende vensters te verdelen. Het is mogelijk om per venster te kiezen tussen BIM of GIS functionaliteit en lagen of modellen in te laden."
            />
            <ChangelogItem
                type={ChangelogItemType.New}
                content="Implementatie Google Analytics"
                description="Google Analytics laat ons toe het gebruiksgedrag te analyseren en de applicatie te verbeteren. Hiervoor werd ook een cookie melding aan de applicatie toegevoegd die éénmalig wordt weergegeven."
            />
            <ChangelogItem
                type={ChangelogItemType.Update}
                content="Tooltips"
                description="Ontbrekende tooltips werden toegevoegd in de hoofding."
            />
            <ChangelogItem
                type={ChangelogItemType.Update}
                content="Performantie"
                description="Er werden achterliggend enkele wijzigingen doorgevoerd om de performantie te verbeteren."
            />
            <ChangelogItem
                type={ChangelogItemType.Bugfix}
                content="Object namen"
                description="Er werd een bug verholpen waardoor de object namen soms niet correct weergegeven konden worden."
            />
            <ChangelogItem
                type={ChangelogItemType.Bugfix}
                content="Fysieke Boomstructuur"
                description="Er werd een bug verholpen waardoor het aanklikken van de `Fysieke Boomstructuur` de applicatie deed crashen."
            />
            <ChangelogItem
                type={ChangelogItemType.Bugfix}
                content="Selectie van objecten"
                description="Er werd een bug verholpen waardoor de selectie van objecten soms niet zichtbaar was wanneer meerdere modellen of lagen gelijktijdig werden ingeladen."
            />
        </>
    ),
};

export default changelog;
