import { Box, useTheme } from "@mui/material";

const EmptyIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    stroke: "rgba(0,0,0,0)",
                }}
                d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.8" height="21" viewBox="0 0 512 512">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.placeholderText,
                }}
                d="M464 32H48C21.5 32 0 53.5 0 80v64c0 8.8 7.2 16 16 16h16v272c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V160h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-16 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V160h384v272zm32-304H32V80c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16v48zM204 256h104c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12z"
            />
        </svg>
    );
};

export default EmptyIcon;
