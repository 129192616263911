import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
    Toolbar,
    Typography,
    lighten,
    useTheme,
} from "@mui/material";
import { faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { removeTip, fetchTips, createTip } from "../../../connectors/admin/tips";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import { draftToMarkdown } from "markdown-draft-js";
import CloseButton from "../../generic/CloseButton";
import DeleteButton from "../DeleteButton";
import StyledTooltip from "../../generic/StyledTooltip";

export interface AdminDialogContentTipsProps {
    tips: any;
    onClose: () => void;
}

const toolbarOptions = {
    options: ["inline", "blockType", "list", "link", "remove", "history"],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ["bold", "italic", "underline", "strikethrough", "monospace"],
    },
    blockType: {
        inDropdown: true,
        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    list: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ["unordered", "ordered"],
    },
    link: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        dropdownClassName: undefined,
        showOpenOptionOnHover: true,
        defaultTargetOption: "_blank",
        options: ["link", "unlink"],
        link: { className: undefined },
        unlink: { className: undefined },
        linkCallback: undefined,
    },
    history: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ["undo", "redo"],
        undo: { className: undefined },
        redo: { className: undefined },
    },
};

const AdminDialogContentTips = ({ tips, onClose }: AdminDialogContentTipsProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState<string[]>([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [createNewTip, setCreateNewTip] = useState<boolean>(false);
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    useEffect(() => {
        fetchTips().then(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (createNewTip) return;
        setEditorState(EditorState.createEmpty());
    }, [createNewTip]);

    const onSave = () => {
        setIsLoading(true);
        const tip = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
        createTip(tip).then(() => {
            setCreateNewTip(false);
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);

        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            const id = selected[i];
            promises.push(removeTip(id));
        }

        Promise.all(promises).then(() => {
            setSelected([]);
            setShouldDelete(false);
            setIsLoading(false);
        });
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onEditorStateChange = (editorState: EditorState) => setEditorState(editorState);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>Notificaties</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setCreateNewTip(true)} onDelete={onDeleteEntry} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableBody>
                                    {tips.map((tip: any) => {
                                        const isItemSelected = isSelected(tip.id);

                                        return (
                                            <TableRow key={tip.id} hover onClick={() => handleClick(tip.id)}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>
                                                    <ReactMarkdown linkTarget={"_blank"}>{tip.omschrijving}</ReactMarkdown>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={createNewTip} onClose={() => setCreateNewTip(false)} aria-labelledby="form-dialog-title" maxWidth="lg">
                        <DialogTitle id="form-dialog-title">Nieuwe tip toevoegen</DialogTitle>
                        <DialogContent>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="tips-editor-wrapper"
                                editorClassName="tips-editor"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={toolbarOptions}
                            />
                        </DialogContent>
                        <DialogActions sx={{ m: 1 }}>
                            <Button onClick={() => setCreateNewTip(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button
                                onClick={() => onSave()}
                                color="primary"
                                variant="contained"
                                disabled={draftToMarkdown(convertToRaw(editorState.getCurrentContent())).length === 0}
                            >
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={shouldDelete} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Tip(s) verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>U staat op het punt de geselecteerde tip(s) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    tips: appReducer.tips,
});

export default connect(mapStateToProps)(AdminDialogContentTips);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={style}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Tips
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde tip(s) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe tip toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
