import compareObjectsBilaterally from "./compareObjectsBilaterally";

export const comparePropsAndState = (props, nextProps, state, nextState) => {
    const propsAreDifferent =
        (!props && nextProps) || (props && !nextProps) || (Boolean(props && nextProps) ? compareObjectsBilaterally(props, nextProps) : false);
    const stateIsDifferent =
        (!state && nextState) || (state && !nextState) || (Boolean(state && nextState) ? compareObjectsBilaterally(state, nextState) : false);

    return propsAreDifferent || stateIsDifferent;
};

export default comparePropsAndState;
