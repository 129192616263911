import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DeleteButton from "../../../admin/DeleteButton";

const DeleteBookmarkConfirmationModal = ({ open, bookmark, onClose, onConfirm }) => {
    return (
        <Dialog open={Boolean(open)} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bookmark verwijderen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    U staat op het punt de bookmark <strong>{bookmark.name}</strong> te verwijderen. Deze actie kan niet ongedaan worden gemaakt.
                </DialogContentText>
                <DialogContentText>Weet u zeker dat u dit wenst te doen?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
                <DeleteButton onClick={() => onConfirm()} color="primary" variant="contained">
                    Verwijderen
                </DeleteButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteBookmarkConfirmationModal;
