import { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import GenericRelations from "./GenericRelations";
import ModelsIcon from "../../../../../svg/selectionTabs/ModelsIcon";
import { configuration } from "../../../../../../_configuration/configuration";

class Models extends Component {
    render() {
        const { bimRelations, classes, ...restProps } = this.props;
        return Boolean(bimRelations) ? (
            <GenericRelations
                relations={bimRelations}
                noRelationsText="Geen relaties tot 3d modellen gevonden."
                source={configuration.sources.bim}
                icon={ModelsIcon}
                {...restProps}
            />
        ) : (
            <CircularProgress
                sx={{
                    position: "relative",
                    top: "calc(50% - 20px)",
                    left: "calc(50% - 20px)",
                }}
            />
        );
    }
}

const mapStateToProps = ({ objectRelationsReducer }) => ({
    bimRelations: objectRelationsReducer.filteredRelations.bimRelations,
});

export default connect(mapStateToProps)(Models);
