const defaultState = {
    documents: [],
    fetched: false,
    externalIdMapping: {},
    externalDbIdMapping: {},
    bookmarkState: null,
    bookmarkStatePaneId: null,
    views: {},
    propertiesViews: null,
};

const forgeReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "RECEIVE_FORGE_DOCUMENTS": {
            newState.documents = action.documents;
            newState.fetched = true;
            return newState;
        }

        case "SET_EXTERNAL_ID_MAPPING": {
            newState.externalIdMapping[action.modelId] = action.mapping;
            return newState;
        }

        case "SET_EXTERNAL_DBID_MAPPING": {
            newState.externalDbIdMapping[action.modelId] = action.mapping;
            return newState;
        }

        case "SET_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = action.paneId;
            newState.bookmarkState = action.state;
            return newState;
        }

        case "CLEAR_STATE_TO_RESTORE": {
            newState.bookmarkStatePaneId = null;
            newState.bookmarkState = null;
            return newState;
        }

        case "SET_MODEL_VIEWS": {
            newState.views = { ...state.views, [action.modelId]: action.views };
            return newState;
        }

        case "SHOW_MODEL_VIEWS": {
            newState.propertiesViews = newState.propertiesViews !== action.modelId ? action.modelId : null;
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default forgeReducer;
