const defaultState = {
    knownObjectIds: [],
    knownObjectIdsError: null,
};

const knownObjectIdsReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "SET_KNOWN_OBJECT_IDS": {
            newState.knownObjectIds = action.knownObjectIds;
            return newState;
        }

        case "SET_KNOWN_OBJECT_IDS_ERROR": {
            newState.knownObjectIdsError = action.knownObjectIdsError;
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default knownObjectIdsReducer;
