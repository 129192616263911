import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 5, 0),
    date: new Date(2023, 4, 3),
    content: (
        <>
            <ChangelogItem
                content="Update geschiedenis"
                type={ChangelogItemType.New}
                description="In het instellingen scherm werd een nieuwe knop voorzien om de geschiedenis van de inhoud updates te kunnen raadplegen."
            />
            <ChangelogItem
                content="Tips geschiedenis"
                type={ChangelogItemType.New}
                description="In het instellingen scherm werd een nieuwe knop voorzien om de geschiedenis van de tips te kunnen raadplegen."
            />
            <ChangelogItem
                content="Optimalisaties"
                type={ChangelogItemType.Update}
                description="Er werden achterliggend enkele optimalisaties uitgevoerd om de werking van het BIM Dashboard te verbeteren."
            />
            <ChangelogItem
                content="Element labels"
                type={ChangelogItemType.Bugfix}
                description="Het probleem waarbij de labels van de verschillende elementen bij klikken in GIS lagen niet correct afgebeeld werden werd verholpen."
            />
            <ChangelogItem
                content="Tooltips"
                type={ChangelogItemType.Bugfix}
                description="Het probleem waarbij een wit scherm zichtbaar werd veroorzaakt door de tooltips op kleinere monitors werd verholpen."
            />
        </>
    ),
};

export default changelog;
