import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import JsonTable from "ts-react-json-table";

import { AppContext } from "../../context/AppContext/AppContextProvider";
import { SplitPaneContext } from "../../context/SplitPaneContext/SplitPaneContextProvider";

const ArcgisTooltip = (props) => {
    const { state: appState } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);
    const [tableFields, setTableFields] = useState([]);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!props.whitelist) return;
        
        setTableFields(props.whitelist.filter((x) => x.dataType?.toLowerCase() === "table").map((x) => x.name));
    }, [props.whitelist]);

    useEffect(() => {
        if (!props.selectedObject?.objectId || props.selectedObject.paneId !== paneState.id) setData(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedObject]);

    useEffect(() => {
        if (!props.viewerProperties?.properties) return;
        if (paneState.id !== appState.activePane) return;

        const properties = Object.keys(props.viewerProperties.properties);
        const tableField = properties.filter((x) => tableFields.some((y) => y?.toLowerCase() === x?.toLocaleLowerCase()));
        if (tableField.length === 0) {
            setData(null);
            return;
        }

        if (tableField.length > 1) {
            console.warn("Multiple table fields detected, only using the first one!", tableField);
        }

        setData(props.viewerProperties.properties[tableField[0]]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.viewerProperties]);

    useEffect(() => setVisible(data !== null), [data]);

    return visible && !props.propertiesOverlayOpen && !props.relationsOverlayOpen ? (
        <Box
            sx={{
                position: "absolute",
                bottom: "30px",
                left: "30px",
                zIndex: "999",
                minWidth: "400px",
                maxHeight: "40%",
                maxWidth: "70%",
                overflow: "auto",
                background: "#ffffff",
                boxShadow: "0 1px 2px rgb(0 0 0 / 30%)",
                "&>div": {
                    width: "100%",
                },
                "&>div>div": {
                    overflow: "unset !important",
                },
                "& table": {
                    margin: ".5em 1em",
                    width: "calc(100% - 2em)",
                },
            }}
            className={"esri-component esri-widget esri-ui-corner"}
        >
            <JsonTable style rows={JSON.parse(data)} />
        </Box>
    ) : null;
};

const mapStateToProps = ({ appReducer, projectReducer, selectionReducer, objectPropertiesReducer }) => ({
    propertiesOverlayOpen: appReducer.propertiesOverlayOpen,
    relationsOverlayOpen: appReducer.relationsOverlayOpen,
    whitelist: projectReducer.whitelist,
    selectedObject: selectionReducer.selectedObject,
    viewerProperties: objectPropertiesReducer.viewerProperties,
});

export default connect(mapStateToProps)(ArcgisTooltip);
