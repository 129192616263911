import { forwardRef } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
import { IconDefinition } from "@fortawesome/sharp-solid-svg-icons";

type FontAwesomeSvgIconProps = {
    icon: IconDefinition;
} & SvgIconProps;

const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>((props, ref) => {
    const { icon, ...rest } = props;

    const {
        icon: [width, height, , , svgPathData],
    } = icon;

    return (
        <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} {...rest}>
            {typeof svgPathData === "string" ? (
                <path d={svgPathData} />
            ) : (
                /**
                 * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
                 * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
                 * of a duotone icon. 40% is the default opacity.
                 *
                 * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
                 */
                svgPathData.map((path: string, index: number) => <path key={index} style={{ opacity: index === 0 ? 0.4 : 1 }} d={path} />)
            )}
        </SvgIcon>
    );
});

export default FontAwesomeSvgIcon;
