export const setSelectedObject = (objectId, source, pane, isPhysicalObject = false, highlightIds = []) => ({
    type: "SET_SELECTED_OBJECT",
    objectId,
    source,
    pane,
    isPhysicalObject,
    highlightIds,
});

export const setQueuedSelectedObject = (objectId, source, isPhysicalObject = false, highlightIds = []) => ({
    type: "SET_QUEUED_SELECTED_OBJECT",
    objectId,
    source,
    isPhysicalObject,
    highlightIds,
});

export const setQueuedSelectedObjectResource = (resource) => ({
    type: "SET_QUEUED_SELECTED_OBJECT_RESOURCE",
    resource,
});

export const clearQueuedSelectedObjectResource = () => ({
    type: "CLEAR_QUEUED_SELECTED_OBJECT_RESOURCE",
});

export const setQueuedHighlights = (source, highlightIds, keepVisibleContainersVisible = false) => ({
    type: "SET_QUEUED_HIGHLIGHTS",
    source,
    highlightIds,
    keepVisibleContainersVisible,
});

export const setHighlights = (source, highlightIds, paneId) => ({
    type: "SET_HIGHLIGHTS",
    source,
    highlightIds,
    paneId,
});

export const cancelHighlights = () => ({
    type: "CANCEL_HIGHLIGHTS",
});

export const setSelectedLinkedId = (selectedLinkedId, selectedLinkedSource) => ({
    type: "SET_SELECTED_LINKED_ID",
    selectedLinkedId,
    selectedLinkedSource,
});

export const setHistoryIndex = (historyIndex) => ({
    type: "SET_HISTORY_INDEX",
    historyIndex,
});

export const clearSelectionAction = () => ({
    type: "CLEAR",
});
