import { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faColumns, faHistory } from "@fortawesome/pro-light-svg-icons";

import CurrentVersion from "../managers/ChangeLogManager/CurrentVersion";
import SettingsDialogContentSplitScreen from "./SettingsDialogContentSplitScreen";
import SettingsDialogContentVersionHistory from "./SettingsDialogContentVersionHistory";
import SettingsDialogContentUpdateHistory from "./SettingsDialogContentUpdateHistory";
import SettingsDialogContentTipsHistory from "./SettingsDialogContentTipsHistory";

export interface SettingsDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

interface MenuItem {
    id: number;
    title: string;
    icon: IconDefinition;
    component: JSX.Element;
}

const SettingsDialog = ({ isOpen, onClose }: SettingsDialogProps) => {
    const menuOptions: Array<MenuItem> = [
        {
            id: 1,
            title: "Split Screen",
            icon: faColumns,
            component: <SettingsDialogContentSplitScreen onClose={onClose} />,
        },
        {
            id: 4,
            title: "Tips Geschiedenis",
            icon: faHistory,
            component: <SettingsDialogContentTipsHistory onClose={onClose} />,
        },
        {
            id: 3,
            title: "Update Geschiedenis",
            icon: faHistory,
            component: <SettingsDialogContentUpdateHistory onClose={onClose} />,
        },
        {
            id: 2,
            title: "Versie Geschiedenis",
            icon: faHistory,
            component: <SettingsDialogContentVersionHistory onClose={onClose} />,
        },
    ];

    const [activeTab, setActiveTab] = useState(menuOptions[0].id);

    const renderMenuOptions = () => {
        return (
            <ToggleButtonGroup value={activeTab} exclusive onChange={(_, value) => setActiveTab(value)} orientation="vertical">
                {menuOptions.map((item) => (
                    <ToggleButton
                        sx={{
                            justifyContent: "left",
                            border: "none",
                            margin: "5px 0",
                            padding: "7px 11px",
                            width: "100%",
                        }}
                        value={item.id}
                        selected={activeTab === item.id}
                        key={item.id}
                    >
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={item.icon} style={{ marginRight: "10px" }} />
                            {item.title}
                        </Typography>
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    };

    const renderContent = () => {
        return menuOptions.find((x) => x.id === activeTab)?.component ?? null;
    };

    return (
        <Dialog
            open={isOpen}
            keepMounted
            aria-labelledby="settings-dialog-slide-title"
            aria-describedby="settings-dialog-slide-description"
            maxWidth="lg"
            fullWidth={true}
        >
            <Box
                sx={{
                    display: "flex",
                    height: "80vh",
                }}
            >
                <Box
                    sx={{
                        color: "#828282",
                        width: 300,
                        minWidth: 300,
                        backgroundColor: "#f2f4f6",
                        borderRight: "1px solid #e4eaf6",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <DialogTitle id="settings-dialog-slide-title">Instellingen</DialogTitle>
                    <DialogContent
                        dividers
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflow: "auto",
                        }}
                    >
                        {renderMenuOptions()}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            height: "52px",
                            margin: "5px",
                            fontSize: 11,
                            alignSelf: "center",
                        }}
                    >
                        {CurrentVersion.toString()}
                    </DialogActions>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                    }}
                >
                    {renderContent()}
                </Box>
            </Box>
        </Dialog>
    );
};

export default SettingsDialog;
