import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 1, 1),
    date: new Date(2022, 2, 21),
    content: (
        <>
            <ChangelogItem
                content="Aanpasing Relatics links"
                type={ChangelogItemType.Update}
                description="Links naar Relatics werden aangepast zodat deze nu de nieuwe portal gebruiken."
            />
        </>
    ),
};

export default changelog;
