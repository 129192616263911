export const setSidebarOpen = (sidebarOpen) => ({
    type: "SET_SIDEBAR_OPEN",
    sidebarOpen,
});

export const setPropertiesOverlayOpen = (propertiesOverlayOpen) => ({
    type: "SET_PROPERTIES_OVERLAY_OPEN",
    propertiesOverlayOpen,
});

export const setRelationsOverlayOpen = (relationsOverlayOpen) => ({
    type: "SET_RELATIONS_OVERLAY_OPEN",
    relationsOverlayOpen,
});

export const setSelectedSidebarTabId = (selectedSidebarTabId) => ({
    type: "SET_SELECTED_SIDEBAR_TAB_ID",
    selectedSidebarTabId,
});

export const setSelectedRelationsTabId = (selectedRelationsTabId, forceRelationsSelection = false) => ({
    type: "SET_SELECTED_RELATIONS_TAB_ID",
    selectedRelationsTabId,
    forceRelationsSelection,
});

export const resetForceRelationsSelection = () => ({
    type: "RESET_FORCE_RELATIONS_SELECTION",
});

export const setToolTip = (value, x, y, hideToolTip = false) => ({
    type: "SET_TOOL_TIP",
    value,
    x,
    y,
    hideToolTip,
});

export const setShouldRevealSelectedRelation = (shouldRevealSelectedRelation) => ({
    type: "SET_SHOULD_REVEAL_SELECTED_RELATION",
    shouldRevealSelectedRelation,
});

export const setSuppressNotifications = (suppressed) => ({
    type: "SET_SUPPRESSED_NOTIFICATIONS",
    suppressed,
});

export const setLayersSearch = (query) => ({
    type: "SET_LAYERS_SEARCH",
    query,
});

export const setModelsSearch = (query) => ({
    type: "SET_MODELS_SEARCH",
    query,
});

export const setDocumentsSearch = (query) => ({
    type: "SET_DOCUMENTS_SEARCH",
    query,
});

export const setTips = (tips) => ({
    type: "SET_TIPS",
    tips,
});

export const addTip = (tip) => ({
    type: "ADD_TIP",
    tip,
});

export const deleteTip = (id) => ({
    type: "DELETE_TIP",
    id,
});

export const setUpdates = (updates) => ({
    type: "SET_CONTENT_UPDATES",
    updates,
});

export const addUpdate = (update) => ({
    type: "ADD_CONTENT_UPDATE",
    update,
});

export const deleteUpdate = (id) => ({
    type: "DELETE_CONTENT_UPDATE",
    id,
});

export const setResources = (resources) => ({
    type: "SET_RESOURCE_CONFIGS",
    resources,
});

export const addResource = (resource) => ({
    type: "ADD_RESOURCE_CONFIG",
    resource,
});

export const editResource = (resource) => ({
    type: "UPDATE_RESOURCE_CONFIG",
    resource,
});

export const deleteResource = (id) => ({
    type: "DELETE_RESOURCE_CONFIG",
    id,
});

export const setProperties = (properties) => ({
    type: "SET_PROPERTIES",
    properties,
});

export const addProperty = (property) => ({
    type: "ADD_PROPERTY",
    property,
});

export const editProperty = (property) => ({
    type: "UPDATE_PROPERTY",
    property,
});

export const deleteProperty = (id) => ({
    type: "DELETE_PROPERTY",
    id,
});

export const setBIMResources = (resources) => ({
    type: "SET_BIM_RESOURCES",
    resources,
});

export const setGISResources = (resources) => ({
    type: "SET_GIS_RESOURCES",
    resources,
});

export const setActivatableResourceGroups = (resourceGroups) => ({
    type: "SET_ACTIVATABLE_RESOURCE_GROUPS",
    resourceGroups,
});

export const addActivatableResourceGroup = (resourceGroup) => ({
    type: "ADD_ACTIVATABLE_RESOURCE_GROUP",
    resourceGroup,
});

export const deleteActivatableResourceGroup = (id) => ({
    type: "DELETE_ACTIVATABLE_RESOURCE_GROUP",
    id,
});

export const setDefaultActiveResources = (resources) => ({
    type: "SET_DEFAULT_ACTIVE_RESOURCES",
    resources,
});

export const addDefaultActiveResource = (resource) => ({
    type: "ADD_DEFAULT_ACTIVE_RESOURCE",
    resource,
});

export const deleteDefaultActiveResource = (id) => ({
    type: "DELETE_DEFAULT_ACTIVE_RESOURCE",
    id,
});

export const setFilters = (filters) => ({
    type: "SET_FILTERS",
    filters,
});

export const addFilter = (filter) => ({
    type: "ADD_FILTER",
    filter,
});

export const editFilter = (filter) => ({
    type: "UPDATE_FILTER",
    filter,
});

export const deleteFilter = (id) => ({
    type: "DELETE_FILTER",
    id,
});

export const addGroup = (group) => ({
    type: "ADD_GROUP",
    group,
});

export const editGroup = (group) => ({
    type: "UPDATE_GROUP",
    group,
});

export const deleteGroup = (id) => ({
    type: "DELETE_GROUP",
    id,
});

export const setRoles = (roles) => ({
    type: "SET_ROLES",
    roles,
});

export const addRole = (role) => ({
    type: "ADD_ROLE",
    role,
});

export const editRole = (role) => ({
    type: "UPDATE_ROLE",
    role,
});

export const deleteRole = (id) => ({
    type: "DELETE_ROLE",
    id,
});

export const setResourceItems = (items) => ({
    type: "SET_RESOURCE_ITEMS",
    items,
});

export const addResourceItem = (item) => ({
    type: "ADD_RESOURCE_ITEM",
    item,
});

export const editResourceItem = (item) => ({
    type: "UPDATE_RESOURCE_ITEM",
    item,
});

export const deleteResourceItem = (id) => ({
    type: "DELETE_RESOURCE_ITEM",
    id,
});
