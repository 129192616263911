import { configuration } from "../../_configuration/configuration";
import { addFilter, addGroup, deleteFilter, deleteGroup, editFilter, editGroup, setFilters } from "../../redux/app/actions";
import store from "../../redux/store";

export const fetchFilters = () => {
    const url = `${configuration.api}api/Filters`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((filters) => {
            store.dispatch(setFilters(filters));
        });
};

export const createFilter = (filter) => {
    const url = `${configuration.api}api/Filters`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((filter) => {
            store.dispatch(addFilter(filter));
        });
};

export const modifyFilter = (filter) => {
    const url = `${configuration.api}api/Filters/${filter.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((filter) => {
            store.dispatch(editFilter(filter));
        });
};

export const removeFilter = (id) => {
    const url = `${configuration.api}api/Filters/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteFilter(id));
    });
};

export const createGroup = (group) => {
    const url = `${configuration.api}api/Groups`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(group),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((group) => {
            store.dispatch(addGroup(group));
        });
};

export const modifyGroup = (group) => {
    const url = `${configuration.api}api/Groups/${group.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(group),
    };

    return fetch(url, options)
        .then((response) => response.json())
        .then((group) => {
            store.dispatch(editGroup(group));
        });
};

export const removeGroup = (id) => {
    const url = `${configuration.api}api/Groups/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteGroup(id));
    });
};
