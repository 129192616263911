import Header from "./components/Header";
import PrivateApp from "./components/PrivateApp";
import PublicApp from "./components/PublicApp";
import QueryParameterManager from "./components/managers/QueryParameterManager";
import CookieConsent from "./components/cookieConsent/CookieConsent";

import { AlertTitle, Box, Typography, styled } from "@mui/material";
import { Alert } from "@mui/lab";

const Container = styled(Box)(() => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflow: "hidden",
}));

const App = ({ error, isAuthenticated, login, logout, user }) => (
    <Container>
        <Header isAuthenticated={isAuthenticated} authButtonMethod={isAuthenticated ? logout : login} user={user} />
        {error && (
            <Alert severity="error">
                <AlertTitle>Authenticatie gefaald!</AlertTitle>
                <Typography>{error.message}</Typography>
                {error.debug && (
                    <Typography
                        sx={{
                            padding: "0.5rem !important",
                            border: "1px solid #dee2e6 !important",
                            backgroundColor: "#f8f9fa !important",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {error.debug}
                    </Typography>
                )}
            </Alert>
        )}
        {isAuthenticated ? <PrivateApp /> : <PublicApp />}
        <QueryParameterManager />
        <CookieConsent />
    </Container>
);

export default App;
