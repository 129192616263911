import { ActionType, SetMode, SplitPaneAction } from "./Actions";
import { PaneMode, SplitPaneState } from "./State";

export const splitPaneReducer = (state: SplitPaneState, action: SplitPaneAction) => {
    switch (action.type) {
        case ActionType.SetMode:
            return { ...state, mode: action.payload.mode };

        default:
            return state;
    }
};

export const setMode = (mode: PaneMode): SetMode => ({
    type: ActionType.SetMode,
    payload: { mode },
});
