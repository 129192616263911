import { Box, Grid } from "@mui/material";

const PublicApp = () => {
    return (
        <Grid
            container
            spacing={0}
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    width: "50%",
                    margin: "auto",
                }}
            >
                <h1>Lantis BIM Dashboard</h1>
                <p>
                    Het BIM dashboard is een door Lantis ontwikkeld online informatieplatform dat door de Opdrachtgever wordt gefaciliteerd om
                    projectmedewerkers en stakeholders inzage te geven in de projectinformatie. Het BIM dashboard beschikt over een BIM omgeving en een GIS
                    omgeving (ArcGIS Online) dewelke de projectinformatie via de fysieke objecten linkt aan de objectinformatie in de Relatics omgeving.
                </p>

                <p>In de GIS afdeling van het BIM dashboard vind je naast allerlei GIS informatie ook de objecten tekeningen terug. </p>
            </Box>
        </Grid>
    );
};

export default PublicApp;
