import { Component } from "react";
import ObjectName from "../../../generic/ObjectName";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { faMap, faCube } from "@fortawesome/pro-solid-svg-icons";
import FavoriteToggle from "../../../generic/FavoriteToggle";
import Icon from "../../../svg/selectionTabs/Icon";
import { setQueuedSelectedObject } from "../../../../redux/selection/actions";
import { setSelectedSidebarTabId } from "../../../../redux/app/actions";
import { configuration } from "../../../../_configuration/configuration";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    userSelect: "none",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.colors.secondarySelectionHover,
    },
}));

const NameContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const NameValue = styled(Box)(({ theme }) => ({
    marginLeft: "12px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
    color: theme.colors.textColor,
    wordBreak: "break-word",
}));

const FavoriteIconContainer = styled(Box)(() => ({
    marginLeft: "12px",
}));

class Favorite extends Component {
    openFavorite = (objectId, source, isPhysicalObject) => {
        this.props.onSetQueuedSelectedObject(objectId, source, isPhysicalObject);
        this.props.onSetSelectedSidebarTabId("selection");
    };

    renderIcon = (source) => {
        switch (source) {
            case configuration.sources.gis: {
                return <Icon icon={faMap} />;
            }

            case configuration.sources.bim: {
                return <Icon icon={faCube} />;
            }

            default: {
                return null;
            }
        }
    };

    render() {
        const { objectId, source, isPhysicalObject } = this.props;

        return (
            <Root onClick={() => this.openFavorite(objectId, source, isPhysicalObject)}>
                <NameContainer>
                    {this.renderIcon(source)}
                    <NameValue>
                        <ObjectName objectId={objectId} shouldGetObjectName shouldCheckLinkedObject source />
                    </NameValue>
                </NameContainer>
                <FavoriteIconContainer onClick={this.handleRemoveFavorite}>
                    <FavoriteToggle objectId={objectId} source={source} />
                </FavoriteIconContainer>
            </Root>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onSetQueuedSelectedObject: (objectId, source, isPhysicalObject) => dispatch(setQueuedSelectedObject(objectId, source, isPhysicalObject)),
    onSetSelectedSidebarTabId: (selectedSidebarTabId) => dispatch(setSelectedSidebarTabId(selectedSidebarTabId)),
});

export default connect(null, mapDispatchToProps)(Favorite);
