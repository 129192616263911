const SplitScreenTwoVerticalIcon = ({ className, style }) => {
    return (
        <svg
            version="1.1"
            id="split-screen-2-vertical"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={style}
        >
            <path
                style={{ fill: "currentcolor" }}
                d="M464,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V80A48,48,0,0,0,464,32Zm16,401a15,15,0,0,1-15,15H47.2A15.19,15.19,0,0,1,32,432.8V288H480Zm0-177.3H32V95.7H480Z"
            />
        </svg>
    );
};

export default SplitScreenTwoVerticalIcon;
