import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    SxProps,
    TableContainer,
    Toolbar,
    TextField,
    Theme,
    lighten,
    useTheme,
} from "@mui/material";
import { faCheck, faPencil, faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import DeleteButton from "../DeleteButton";
import { createProperty, fetchProperties, modifyProperty, removeProperty } from "../../../connectors/admin/properties";
import InputButton from "../InputButton";
import StyledTooltip from "../../generic/StyledTooltip";

export interface AdminDialogContentGISPropertiesProps {
    properties: any;
    onClose: () => void;
}

const RESOURCE_KEY = "ArcGis";
const DATA_TYPES = ["Text", "Date", "Table"];

const AdminDialogContentGISProperties = ({ properties, onClose }: AdminDialogContentGISPropertiesProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [gisProperties, setGisProperties] = useState<any[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [addNewProperty, setAddNewProperty] = useState<boolean>(false);
    const [newProperty, setNewProperty] = useState<any>({
        project: "OWV - Lantis", // TODO: Replace??
        type: RESOURCE_KEY,
        category: "",
        name: "",
        dataType: "Text",
        whiteList: false,
        blackList: false,
        priority: 1,
    });
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);
    const [editProperty, setEditProperty] = useState<any>();

    useEffect(() => {
        fetchProperties();
    }, []);

    useEffect(() => {
        const data = properties.filter((x: any) => x.type === RESOURCE_KEY);
        setData(data);
        setGisProperties(data.sort((a: any, b: any) => a.category.localeCompare(b.category) || a.name.localeCompare(b.name)).map((x: any) => ({ ...x })));
        setIsLoading(false);
    }, [properties]);

    const onSave = () => {
        setIsLoading(true);
        const promises = [];
        for (let i = 0; i < gisProperties.length; i++) {
            const property = gisProperties[i];
            const originalProperty = data.find((x) => x.id === property.id);
            if (
                originalProperty.category !== property.category ||
                originalProperty.name !== property.name ||
                originalProperty.dataType !== property.dataType ||
                originalProperty.whiteList !== property.whiteList ||
                originalProperty.blackList !== property.blackList ||
                originalProperty.priority !== property.priority
            )
                promises.push(modifyProperty(property));
        }
        Promise.all(promises);
    };

    const onReset = () => {
        setGisProperties(data.map((x: any) => ({ ...x })));
    };

    const hasChanges = (): boolean => {
        const originalProperties = data;
        if (originalProperties.length !== gisProperties.length) return true;

        for (let i = 0; i < gisProperties.length; i++) {
            const originalProperty = originalProperties[i];
            const property = gisProperties[i];

            if (
                originalProperty.category !== property.category ||
                originalProperty.name !== property.name ||
                originalProperty.dataType !== property.dataType ||
                originalProperty.whiteList !== property.whiteList ||
                originalProperty.blackList !== property.blackList ||
                originalProperty.priority !== property.priority
            )
                return true;
        }

        return false;
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const editEntry = () => {
        const data = [...gisProperties];
        const entry = data.find((x) => x.id === editProperty.id);
        const fields = Object.keys(entry);
        for (let i = 0; i < fields.length; i++) {
            entry[fields[i]] = editProperty[fields[i]];
        }

        setGisProperties(data);
    };

    const onAddEntry = () => {
        setIsLoading(true);
        createProperty(newProperty).then(() => {
            setAddNewProperty(false);
            setNewProperty({
                project: "OWV - Lantis", // TODO: Replace??
                type: RESOURCE_KEY,
                category: "",
                name: "",
                dataType: "Text",
                whiteList: false,
                blackList: false,
                priority: 1,
            });
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);
        setShouldDelete(false);
        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            promises.push(removeProperty(selected[i]));
        }

        Promise.all(promises).then((_) => {
            setSelected([]);
            setIsLoading(false);
        });
    };

    const renderBool = (value: boolean): string => {
        if (value) return "Ja";
        return "Neen";
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>GIS</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setAddNewProperty(true)} onDelete={() => onDeleteEntry()} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"></TableCell>
                                        <TableCell>Categorie</TableCell>
                                        <TableCell>Veld</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Whitelisted</TableCell>
                                        <TableCell>Blacklisted</TableCell>
                                        <TableCell>Prioriteit</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gisProperties.map((property, index) => {
                                        const isItemSelected = isSelected(property.id);
                                        const isEditting = editProperty?.id === property.id;

                                        return isEditting ? (
                                            <TableRow hover key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editProperty.category}
                                                        onChange={(e) => setEditProperty({ ...editProperty, category: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={editProperty.name}
                                                        onChange={(e) => setEditProperty({ ...editProperty, name: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={editProperty.dataType}
                                                        onChange={(e) => setEditProperty({ ...editProperty, dataType: e.target.value })}
                                                    >
                                                        {DATA_TYPES.map((type, index) => (
                                                            <MenuItem key={index} value={type}>
                                                                {type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={editProperty.whiteList}
                                                        onChange={(e) => setEditProperty({ ...editProperty, whiteList: e.target.checked })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={editProperty.blackList}
                                                        onChange={(e) => setEditProperty({ ...editProperty, blackList: e.target.checked })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={editProperty.priority}
                                                        onChange={(e) => setEditProperty({ ...editProperty, priority: e.currentTarget.value })}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            editEntry();
                                                            setEditProperty(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                    </InputButton>

                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditProperty(undefined);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow hover onClick={() => handleClick(property.id)} key={index}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} />
                                                </TableCell>
                                                <TableCell>{property.category}</TableCell>
                                                <TableCell>{property.name}</TableCell>
                                                <TableCell>{property.dataType}</TableCell>
                                                <TableCell>{renderBool(property.whiteList)}</TableCell>
                                                <TableCell>{renderBool(property.blackList)}</TableCell>
                                                <TableCell>{property.priority}</TableCell>
                                                <TableCell>
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setEditProperty({ ...property });
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={addNewProperty} onClose={() => setAddNewProperty(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                        <DialogTitle id="form-dialog-title">Nieuwe eigenschap toevoegen</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField
                                        label="Categorie"
                                        value={newProperty.category}
                                        onChange={(e) => setNewProperty({ ...newProperty, category: e.currentTarget.value })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField
                                        label="Veld"
                                        value={newProperty.name}
                                        onChange={(e) => setNewProperty({ ...newProperty, name: e.currentTarget.value })}
                                    />
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <InputLabel id="demo-simple-select-label">Data type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newProperty.dataType}
                                        onChange={(e) => setNewProperty({ ...newProperty, dataType: e.target.value })}
                                    >
                                        {DATA_TYPES.map((type, index) => (
                                            <MenuItem key={index} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={newProperty.whiteList}
                                                    onChange={(e) => setNewProperty({ ...newProperty, whiteList: e.target.checked })}
                                                />
                                            }
                                            label="Whitelisted"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={newProperty.blackList}
                                                    onChange={(e) => setNewProperty({ ...newProperty, blackList: e.target.checked })}
                                                />
                                            }
                                            label="Blacklisted"
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl
                                    sx={{
                                        margin: "10px 0",
                                        minWidth: 120,
                                    }}
                                >
                                    <TextField
                                        label="Prioriteit"
                                        type="number"
                                        value={newProperty.priority}
                                        onChange={(e) => setNewProperty({ ...newProperty, priority: Number(e.currentTarget.value) })}
                                    />
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddNewProperty(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button onClick={() => onAddEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={Boolean(shouldDelete)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Eigenschap(pen) verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>
                                U staat op het punt de geselecteerde eigenschap(pen) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    properties: appReducer.properties,
});

export default connect(mapStateToProps)(AdminDialogContentGISProperties);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={{ style }}>
            <Typography
                sx={{
                    flex: "1 1 100%",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Eigenschappen
            </Typography>
            {selected.length > 0 && (
                <Typography
                    sx={{
                        whiteSpace: "nowrap",
                        marginRight: "20px",
                        fontWeight: 500,
                    }}
                    color="inherit"
                    variant="button"
                    component="div"
                >
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde eigenschap(pen) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe eigenschap toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
