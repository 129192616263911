import { useContext, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";

import { AppContext } from "../../../context/AppContext/AppContextProvider";
import { determineTargetData, setPaneDetails } from "../../../context/AppContext/Reducer";
import { SplitPaneContext } from "../../../context/SplitPaneContext/SplitPaneContextProvider";
import { PaneMode } from "../../../context/SplitPaneContext/State";

import MapsIcon from "../../svg/appControls/MapsIcon";
import ModelsIcon from "../../svg/appControls/ModelsIcon";
import DocumentsIcon from "../../svg/appControls/DocumentsIcon";
import StyledTooltip from "../../generic/StyledTooltip";

const toggles = [
    {
        mode: PaneMode.GIS,
        icon: MapsIcon,
        tooltip: "GIS",
    },
    {
        mode: PaneMode.BIM,
        icon: ModelsIcon,
        tooltip: "BIM",
    },
    {
        mode: PaneMode.DOCUMENT,
        icon: DocumentsIcon,
        tooltip: "BIM Documents",
    },
];

const ToggleMode = () => {
    const { state: appState, dispatch: appDispatch } = useContext(AppContext);
    const { state: paneState } = useContext(SplitPaneContext);
    const [hovered, setHovered] = useState(PaneMode.NONE);

    const renderToggles = () => {
        const { panesState } = appState;
        const activeMode = panesState[paneState.id - 1].mode;

        return toggles.map(({ mode, icon: Icon, tooltip }, index) => {
            let style: SxProps<Theme> = {
                boxSizing: "border-box",
                height: 32,
                width: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "32px",
                maxHeight: "32px",
                padding: "8px",
                cursor: "pointer",
                "&.active": {
                    backgroundColor: "#1F293C",
                },
                "&:hover": {
                    backgroundColor: "#1F293C",
                },
                "&.disabled": {
                    backgroundColor: "#F8F8F8",
                    pointerEvents: "none",
                },
                "&.disabled svg > *": {
                    fill: "#BABABA",
                },
            };

            const isSelected = mode === activeMode;
            const isHovered = hovered === mode;

            if (isSelected) style = { ...style, backgroundColor: "#1F293C" };

            return (
                <StyledTooltip key={index} title={tooltip}>
                    <Box
                        sx={style}
                        onClick={() => selectMode(mode)}
                        onMouseEnter={() => handleMouseEnter(mode)}
                        onMouseLeave={() => handleMouseLeave()}
                        data-tooltip={tooltip}
                    >
                        <Icon selected={isSelected || isHovered} />
                    </Box>
                </StyledTooltip>
            );
        });
    };

    const selectMode = (mode: PaneMode) => {
        const { panesState } = appState;
        const activeMode = panesState[paneState.id - 1].mode;
        if (mode === activeMode) return;

        const data = determineTargetData(mode);
        appDispatch(setPaneDetails(paneState.id, { id: paneState.id, mode, data, appObject: undefined }));
    };

    const handleMouseEnter = (mode: PaneMode) => {
        setHovered(mode);
    };

    const handleMouseLeave = () => {
        setHovered(PaneMode.NONE);
    };

    return (
        <Box
            sx={{
                pointerEvents: "all",
                backgroundColor: "#FFFFFF",
                display: "flex",
                boxShadow: "0 0 2px #D2DDE5",
                userSelect: "none",
            }}
        >
            {renderToggles()}
        </Box>
    );
};

export default ToggleMode;
