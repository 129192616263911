import { useTheme } from "@mui/material";
import { faFileAlt } from "@fortawesome/pro-light-svg-icons";
import { faFileAlt as faFileAltSolid } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

const DocumentsIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <FontAwesomeSvgIcon
            icon={faFileAltSolid}
            sx={{
                width: "22px!important",
                height: "22px!important",
                color: theme.colors.secondaryDark,
            }}
        />
    ) : (
        <FontAwesomeSvgIcon
            icon={faFileAlt}
            sx={{
                width: "22px!important",
                height: "22px!important",
                color: theme.colors.placeholderText,
            }}
        />
    );
};

export default DocumentsIcon;
