export const getFlatLayersArray = (items, layers = []) => {
    return items.reduce((layers, item) => {
        const itemLayers = item.children.filter(({ type }) => type !== "group");
        const childLayers = getFlatLayersArray(item.children.filter(({ type }) => type === "group"));
        return [...layers, ...itemLayers, ...childLayers];
    }, layers);
};

export const groupLayers = (data, parent = null) => {
    const unpackTitle = (fullTitle) => {
        const [title, ...titleParts] = fullTitle.split(" - ");
        const path = Boolean(parent) ? `${parent.id} - ${title}` : title;
        return {
            title,
            titleParts,
            path,
        };
    };

    const findNextAvailableIdentifier = (group, increment = 0) => {
        let identifier = group.identifier * 100 + group.data.length + increment;
        if (group.data.find((x) => x.identifier === identifier)) return findNextAvailableIdentifier(group, increment + 1);
        else return identifier;
    };

    const verifyIdentifiers = (item) => {
        if (!item.children || !item.children.length) return;

        for (let i = 0, len = item.children.length; i < len; i++) {
            item.children[i].identifier = item.identifier * 100 + i;
        }
    };

    let numericId = Boolean(parent) ? parent.identifier * 100 : 1;
    const groups = [];
    data.forEach((item) => {
        const unpackedItem = unpackTitle(item.title);
        let group = groups.find(({ type, title }) => title === unpackedItem.title && type !== "layer");
        if (!group) {
            if (unpackedItem.titleParts.length) {
                groups.push({
                    id: unpackedItem.path,
                    title: unpackedItem.title,
                    path: unpackedItem.title,
                    type: "group",
                    children: [],
                    data: [],
                    identifier: numericId + groups.length,
                });
            } else {
                groups.push({
                    title: unpackedItem.title,
                    type: "layer",
                    id: item.id,
                    sourceJson: item.sourceJson,
                    layer: item.layer ?? null,
                    identifier: item.identifier,
                    visible: item.visible ?? false,
                });

                return;
            }

            group = groups[groups.length - 1];
        }

        group.data.push({
            id: item.id,
            title: unpackedItem.titleParts.join(" - "),
            sourceJson: item.sourceJson ?? item,
            identifier: findNextAvailableIdentifier(group),
            visible: item.visible ?? false,
            layer: item.layer ?? null,
        });
    });

    groups.forEach((item) => {
        if (item.type === "group") {
            item.children.push(...groupLayers(item.data, item));
            verifyIdentifiers(item);
        }
        delete item.data;
    });

    return groups;
};

export const setFirstLayerVisible = (item) => {
    if (item.type === "group") {
        return setFirstLayerVisible(item.children[0]);
    } else {
        item.visible = true;
    }
};

export const getTreeIndexString = (treeIndex) => {
    return treeIndex.map((index) => `[${index}]`).join("-");
};

export const countVisibleLayers = (item, visibleLayers = 0) => {
    const { type, visible, children = [] } = item;
    if (type === "group") {
        return children.reduce((visibleLayers, item) => {
            return visibleLayers + countVisibleLayers(item);
        }, visibleLayers);
    } else {
        return visible ? 1 : 0;
    }
};

export const countTotalLayers = (item, totalLayers = 0) => {
    const { type, children = [] } = item;
    if (type === "group") {
        return children.reduce((totalLayers, item) => {
            return totalLayers + countTotalLayers(item);
        }, totalLayers);
    } else {
        return 1;
    }
};

export const countTotalLayersFiltered = (item, filter, totalLayers = 0) => {
    const { type, children = [] } = item;
    if (type === "group") {
        return children.reduce((totalLayers, item) => {
            return totalLayers + countTotalLayersFiltered(item, filter);
        }, totalLayers);
    } else {
        return item.title.toLowerCase().search(filter.toLowerCase()) > -1 ? 1 : 0;
    }
};
