export const receiveForgeDocuments = (documents) => ({
    type: "RECEIVE_PDF_DOCUMENTS",
    documents,
});

// TODO: Check if this is something we still need for PDFs

export const setBookmarkState = (paneId, state) => ({
    type: "SET_STATE_TO_RESTORE",
    paneId,
    state,
});

export const clearBookmarkState = () => ({
    type: "CLEAR_STATE_TO_RESTORE",
});
