import { createTheme } from "@mui/material/styles";
import { nlNL as coreNlNL } from "@mui/material/locale";
import { nlNL } from "@mui/x-data-grid-pro";

import "@fontsource/open-sans/latin-300.css";
import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import "@fontsource/open-sans/latin-800.css";

import "@fontsource/raleway/latin-300.css";
import "@fontsource/raleway/latin-400.css";
import "@fontsource/raleway/latin-600.css";
import "@fontsource/raleway/latin-700.css";
import "@fontsource/raleway/latin-800.css";

declare module "@mui/material/styles" {
    interface Theme {
        colors: {
            white: string;
            black: string;
            secondarySelection: string;
            secondarySelectionHover: string;
            placeholderText: string;
            secondaryDark: string;
            inputBackground: string;
            inputBorder: string;
            disabled: string;
            textColor: string;
            btnHover: string;
            btnActive: string;
            dropdownActive: string;
            error: string;
            success: string;
            primaryText: string;
            primaryBtnActive: string;
            hover: string;
            active: string;
        };
        fonts: {
            openSans: {
                fontFamily: string;
                light: number;
                regular: number;
                semiBold: number;
                bold: number;
                bolder: number;
            };
            raleway: {
                fontFamily: string;
                light: number;
                regular: number;
                semiBold: number;
                bold: number;
                bolder: number;
            };
        };
    }

    interface ThemeOptions {
        colors?: {
            white?: string;
            black?: string;
            secondarySelection?: string;
            secondarySelectionHover?: string;
            placeholderText?: string;
            secondaryDark?: string;
            inputBackground?: string;
            inputBorder?: string;
            disabled?: string;
            textColor?: string;
            btnHover?: string;
            btnActive?: string;
            dropdownActive?: string;
            error?: string;
            success?: string;
            primaryText?: string;
            primaryBtnActive?: string;
            hover?: string;
            active?: string;
        };
        fonts: {
            openSans: {
                fontFamily: string;
                light: number;
                regular: number;
                semiBold: number;
                bold: number;
                bolder: number;
            };
            raleway: {
                fontFamily: string;
                light: number;
                regular: number;
                semiBold: number;
                bold: number;
                bolder: number;
            };
        };
    }
}

export default createTheme(
    {
        palette: {
            primary: {
                main: "#8dd9c8",
            },
            secondary: {
                main: "#0d273e",
            },
            mode: "light",
        },

        typography: {
            fontFamily: '"Open Sans", sans-serif',
        },

        colors: {
            white: "#FFFFFF",
            black: "#000000",
            secondarySelection: "#0D273E",
            secondarySelectionHover: "#DCE3EA",
            placeholderText: "#707070",
            secondaryDark: "#499C85",
            inputBackground: "#F8F8F8",
            inputBorder: "#E6E6E6",
            disabled: "#BABABA",
            textColor: "#1F293C",
            btnHover: "#FFF3F0",
            btnActive: "#FFD9D0",
            dropdownActive: "#FFF3F0",
            error: "#ED2828",
            success: "#25BA64",
            primaryText: "#E02C16",
            primaryBtnActive: "#B22310",
            hover: "#DAF7ED",
            active: "#BAE5D6",
        },

        fonts: {
            openSans: {
                fontFamily: "Open Sans, sans-serif",
                light: 300,
                regular: 400,
                semiBold: 600,
                bold: 700,
                bolder: 800,
            },
            raleway: {
                fontFamily: "Raleway, sans-serif",
                light: 300,
                regular: 400,
                semiBold: 600,
                bold: 700,
                bolder: 800,
            },
        },

        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "standard",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "standard",
                },
            },
        },
    },
    // x-data-grid-pro translations, spread original values to be able to add missing locale texts for NL
    {
        components: {
            MuiDataGrid: {
                defaultProps: {
                    localeText: {
                        ...nlNL.components.MuiDataGrid.defaultProps.localeText,
                        filterPanelRemoveAll: "Alles leegmaken",
                        columnMenuManageColumns: "Beheer kolommen",
                    },
                },
            },
        },
    },
    coreNlNL // core translations
);
