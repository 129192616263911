export const requestProjectInfo = () => ({
    type: "REQUEST_PROJECT_INFO",
});

export const receiveProjects = (projects) => ({
    type: "RECEIVE_PROJECT_LIST",
    projects,
});

export const receiveProjectInfo = (project) => ({
    type: "RECEIVE_PROJECT",
    project,
});

export const receiveProjectResources = (source, resourceItems) => ({
    type: "RECEIVE_PROJECT_RESOURCE_ITEMS",
    source,
    resourceItems,
});

export const receiveProjectFilters = (filters) => ({
    type: "RECEIVE_PROJECT_FILTERS",
    filters,
});

export const receivedProjectInfo = () => ({
    type: "RECEIVED_PROJECT_INFO",
});

export const receiveProjectWhitelist = (whitelist) => ({
    type: "RECEIVE_PROJECT_WHITELIST",
    whitelist,
});
