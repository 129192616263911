import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const RenameBookmarkModal = ({ open, bookmark, onClose, onRename }) => {
    const [name, setName] = useState("");

    useEffect(() => {
        if (Boolean(open) && bookmark) setName(bookmark.name ?? "");
    }, [open, bookmark]);

    return (
        <Dialog open={Boolean(open)} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bookmark hernoemen</DialogTitle>
            <DialogContent>
                <DialogContentText>Voer een nieuwe naam in voor deze bookmark:</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Bookmark Naam"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
                <Button onClick={() => onRename(name)} color="primary" variant="contained">
                    Hernoemen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenameBookmarkModal;
