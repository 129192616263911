import store from "../redux/store";
import {
    requestProjectInfo,
    receiveProjects,
    receiveProjectInfo,
    receiveProjectResources,
    receivedProjectInfo,
    receiveProjectFilters,
    receiveProjectWhitelist,
} from "../redux/projects/actions";
import { configuration } from "../_configuration/configuration";

export const fetchProjects = () => {
    store.dispatch(requestProjectInfo());

    const url = `${configuration.api}api/Projects`;
    const options = {
        method: "GET",
    };

    fetch(`${url}`, options)
        .then((response) => {
            return response.json();
        })
        .then((projects) => {
            store.dispatch(receiveProjects(projects));
        })
        .catch((error) => {
            // console.error("connectors/projects - fetchProjects error: ", error);
        });
};

export const fetchProject = (id) => {
    store.dispatch(requestProjectInfo());

    const url = `${configuration.api}api/Projects`;
    const options = {
        method: "GET",
    };

    fetch(`${url}/${id}`, options)
        .then((response) => {
            return response.json();
        })
        .then((project) => {
            store.dispatch(receiveProjectInfo(project));
        })
        .catch((error) => {
            // console.error("connectors/projects - fetchProject error: ", error);
        });
};

export const fetchProjectResources = (project) => {
    store.dispatch(requestProjectInfo());

    let url = `${configuration.api}api/Projects`;
    const options = {
        method: "GET",
    };

    const resources = [];
    for (let i = 0; i < project.resources.length; i++) {
        const resource = project.resources[i].Name;
        const promise = fetch(`${url}/${project.id}/Resources/${resource}/Items`, options)
            .then((response) => {
                return response.json();
            })
            .then((items) => {
                store.dispatch(receiveProjectResources(resource, items));
            })
            .catch((error) => {
                // console.error("connectors/projects - fetchProjectResources error: ", error);
            });

        resources.push(promise);
    }

    url = `${configuration.api}api/Filters`;
    const filters = fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((filters) => {
            store.dispatch(receiveProjectFilters(filters));
        })
        .catch((error) => {
            // console.error("connectors/projects - fetchProjectResources error: ", error);
        });

    resources.push(filters);

    url = `${configuration.api}api/Properties`;
    const whitelist = fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((whitelist) => {
            store.dispatch(receiveProjectWhitelist(whitelist));
        })
        .catch((error) => {
            // console.error("connectors/projects - fetchProjectResources error: ", error);
        });

    resources.push(whitelist);

    Promise.all(resources).then(() => store.dispatch(receivedProjectInfo()));
};
