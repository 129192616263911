import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import Tabs from "./Tabs";
import SideBarLayers from "./tabComponents/Layers";
import SideBarDocuments from "./tabComponents/Documents";
import SideBarSelection from "./tabComponents/selection/Selection";
import SideBarHistory from "./tabComponents/History";
import SidebarBookmarks from "./tabComponents/Bookmarks";
import SideBarFavorites from "./tabComponents/favorites/Favorites";
import LayersIcon from "../svg/sidebarTabs/LayersIcon";
import SelectionIcon from "../svg/sidebarTabs/SelectionIcon";
import HistoryIcon from "../svg/sidebarTabs/HistoryIcon";
import FavoritesIcon from "../svg/sidebarTabs/FavoritesIcon";
import ModelsIcon from "../svg/sidebarTabs/ModelsIcon";
import SideBarModels from "./tabComponents/Models";
import SelectionTitle from "./tabComponents/selection/SelectionTitle";
import { setSidebarOpen } from "../../redux/app/actions";
import { faChevronDoubleLeft } from "@fortawesome/pro-solid-svg-icons";
import BookmarksIcon from "../svg/sidebarTabs/BookmarksIcon";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../context/SplitPaneContext/State";
import Empty from "./tabComponents/Empty";
import EmptyIcon from "../svg/sidebarTabs/EmptyIcon";
import DocumentsIcon from "../svg/sidebarTabs/DocumentsIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Root = styled(Box)(({ theme }) => ({
    zIndex: 600,
    minHeight: "100%",
    maxHeight: "100%",
    borderLeft: `1px solid ${theme.colors.inputBorder}`,
    width: "420px",
    minWidth: "300px",
    maxWidth: "50%",
    display: "flex",
    flexDirection: "row",
    transition: "margin-right 0.2s ease-in",
    position: "relative",
}));

const SidebarContent = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "calc(100% - 20px)",
}));

const DragHandle = styled(Box)(({ theme }) => ({
    cursor: "ew-resize",
    width: "20px",
    borderRight: `1px solid ${theme.colors.inputBorder}`,
    backgroundImage:
        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='50'><path d='M2 0 v50 M5 0 v50 M8 0 v50' fill='none' stroke='black'/></svg>\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "white",
}));

const Expand = styled(Box)(({ theme }) => ({
    display: "flex",
    cursor: "pointer",
    minWidth: "20px",
    placeContent: "center",
    alignItems: "center",
    color: theme.colors.placeholderText,
    "&:hover": {
        backgroundColor: "#F8F8F8",
        color: "#499C85",
    },
}));

const TabContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "&>div": {
        flex: 1,
    },
}));

const tabs = [
    {
        id: "layers",
        title: "Lagen kiezen",
        icon: LayersIcon,
        component: SideBarLayers,
        isVisible: (activeMode) => activeMode === PaneMode.GIS,
    },
    {
        id: "models",
        title: "3D modellen kiezen",
        icon: ModelsIcon,
        component: SideBarModels,
        isVisible: (activeMode) => activeMode === PaneMode.BIM,
    },
    {
        id: "documents",
        title: "Documenten selecteren",
        icon: DocumentsIcon,
        component: SideBarDocuments,
        isVisible: (activeMode) => activeMode === PaneMode.DOCUMENT,
    },
    {
        id: "empty",
        title: "Modus selecteren",
        icon: EmptyIcon,
        component: Empty,
        isVisible: (activeMode) => activeMode === PaneMode.NONE,
    },
    {
        id: "selection",
        title: <SelectionTitle />,
        tooltip: "Selectie",
        icon: SelectionIcon,
        component: SideBarSelection,
        isVisible: (activeMode) => true,
    },
    {
        id: "bookmarks",
        title: "Bookmarks",
        icon: BookmarksIcon,
        component: SidebarBookmarks,
        isVisible: (activeMode) => true,
    },
    {
        id: "history",
        title: "Geschiedenis",
        icon: HistoryIcon,
        component: SideBarHistory,
        isVisible: (activeMode) => true,
    },
    {
        id: "favorites",
        title: "Favorieten",
        icon: FavoritesIcon,
        component: SideBarFavorites,
        isVisible: (activeMode) => true,
    },
];

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
            width: localStorage.getItem("sidebar-width") ?? null,
        };
    }

    componentDidMount() {
        window.addEventListener("mousemove", this.drag);
        window.addEventListener("mouseup", this.dragStop);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.drag);
        window.removeEventListener("mouseup", this.dragStop);
    }

    dragStart = (event) => {
        event.preventDefault();
        if (!this.state.dragging) {
            this.setState({ dragging: true });
        }
    };

    drag = (event) => {
        const { dragging, width } = this.state;

        if (this.container && dragging) {
            event.preventDefault();
            const boundingClientRect = this.container.getBoundingClientRect();
            const widthDifference = boundingClientRect.x - event.clientX;
            let newWidth = Math.max(boundingClientRect.width + widthDifference, 0);
            if (width !== newWidth) {
                if (newWidth > document.body.clientWidth / 2) newWidth = document.body.clientWidth / 2;
                if (newWidth < 300) newWidth = 300;
                this.setState({ width: newWidth });
                localStorage.setItem("sidebar-width", newWidth);
            }
        }
    };

    dragStop = () => {
        if (this.state.dragging) {
            this.setState({ dragging: false });
        }
    };

    renderTab = () => {
        let selectedTab = tabs.find(({ id }) => id === this.props.selectedSidebarTabId);
        if (selectedTab) {
            const hideProperties = selectedTab.id === "selection" ? this.props.propertiesOverlayOpen : false;
            const Component = selectedTab.component;

            return <Component title={selectedTab.title} hideProperties={hideProperties} />;
        }
    };

    render() {
        const { width } = this.state;
        const { sidebarOpen, onSetSidebarOpen } = this.props;

        const containerStyles = {};
        if (!isNaN(Number(width))) {
            containerStyles.width = `${width}px`;
            containerStyles.minWidth = `${width}px`;
        }
        if (!sidebarOpen) containerStyles.marginRight = `-${Number(width ?? 420) - 20}px`;

        return (
            <Root ref={(ref) => (this.container = ref)} sx={containerStyles}>
                {!!!sidebarOpen ? (
                    <Expand onClick={() => onSetSidebarOpen(true)}>
                        <FontAwesomeIcon icon={faChevronDoubleLeft} />
                    </Expand>
                ) : (
                    <DragHandle onMouseDown={this.dragStart} />
                )}
                <SidebarContent>
                    <Tabs tabs={tabs} />
                    <TabContainer>{this.renderTab()}</TabContainer>
                </SidebarContent>
            </Root>
        );
    }
}

SideBar.contextType = AppContext;

const mapStateToProps = ({ appReducer }) => ({
    sidebarOpen: appReducer.sidebarOpen,
    selectedSidebarTabId: appReducer.selectedSidebarTabId,
    propertiesOverlayOpen: appReducer.propertiesOverlayOpen,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSidebarOpen: (sidebarOpen) => dispatch(setSidebarOpen(sidebarOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
