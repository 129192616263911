import { Box, useTheme } from "@mui/material";

const SelectionIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.008" height="23.299" viewBox="0 0 18.008 23.299">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.secondaryDark,
                    stroke: theme.colors.secondaryDark,
                }}
                d="M23.9,15.7v21l7.057-6.948,9.1.153Z"
                transform="translate(-23.398 -14.594)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.672" height="23" viewBox="0 0 16.672 23">
            <Box
                component={"path"}
                sx={{
                    fill: "none",
                    stroke: theme.colors.placeholderText,
                    strokeLinejoin: "round",
                    strokeWidth: "2px",
                }}
                d="M23.9,15.7v21l6.408-6.948,8.264.153Z"
                transform="translate(-22.898 -14.699)"
            />
        </svg>
    );
};

export default SelectionIcon;
