import { Mutex } from "wait-your-turn";

export class AutodeskForge {
    private static forge: AutodeskForge;

    private initialized: boolean = false;
    private mutex: Mutex;

    constructor() {
        this.mutex = new Mutex();
    }

    static get Instance(): AutodeskForge {
        if (!this.forge) this.forge = new AutodeskForge();

        return this.forge;
    }

    public initialize = (options: Autodesk.Viewing.InitializerOptions, callback?: () => void) => {
        this.mutex.acquire().then((release) => {
            if (this.initialized) {
                if (callback) callback();
                release();
                return;
            }

            Autodesk.Viewing.Initializer(options, () => {
                if (callback) callback();
                this.initialized = true;

                // Disable stats being printed to the console
                (Autodesk.Viewing.Private as any).GeometryList.prototype.printStats = function () {};

                release();
            });
        });
    };
}
