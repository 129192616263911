import getFavoriteObjectsFromLocalStorage from '../../utils/getFavoriteObjectsFromLocalStorage'

const defaultState = {
  favoriteObjects: getFavoriteObjectsFromLocalStorage(),
}

const reducer = (state = defaultState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'TOGGLE_FAVORITE_OBJECT':
      const isFavorite = state.favoriteObjects.some((favoriteObject) => {
        const isSameId = favoriteObject.objectId === action.objectId
        const isSameSource = favoriteObject.source === action.source
        return isSameId && isSameSource
      })
      if(isFavorite) {
        newState.favoriteObjects = state.favoriteObjects.filter((favoriteObject) => {
          const isDifferentId = favoriteObject.objectId !== action.objectId
          const isDifferentSource = favoriteObject.source !== action.source
          return isDifferentId || isDifferentSource
        })
      } else {
        const favoriteObject = {
          objectId: action.objectId,
          source: action.source,
          isPhysicalObject: action.isPhysicalObject
        }
        newState.favoriteObjects = [...state.favoriteObjects, favoriteObject]
      }
      localStorage.setItem('favoriteObjects', JSON.stringify(newState.favoriteObjects))
      return newState

    default:
      return newState
  }
}

export default reducer