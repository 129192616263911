import { createContext, Dispatch, useReducer } from "react";
import { SplitPaneAction } from "./Actions";
import { splitPaneReducer } from "./Reducer";
import { initialSplitPaneState, SplitPaneState } from "./State";

interface SplitPaneContextProps {
    id: number;
    children: JSX.Element;
}

const SplitPaneContext = createContext<{
    state: SplitPaneState;
    dispatch: Dispatch<SplitPaneAction>;
}>({
    state: initialSplitPaneState,
    dispatch: () => undefined,
});

const SplitPaneContextProvider = ({ id, children }: SplitPaneContextProps) => {
    const [state, dispatch] = useReducer(splitPaneReducer, { ...initialSplitPaneState, id });

    return <SplitPaneContext.Provider value={{ state, dispatch }}>{children}</SplitPaneContext.Provider>;
};

export { SplitPaneContextProvider, SplitPaneContext };
