const Autodesk = window.Autodesk;

export class Panel extends Autodesk.Viewing.UI.DockingPanel {
    constructor(container, id) {
        super(container, id, "Query Model", { shadow: true, addFooter: true });
    }

    initialize() {
        this.content = document.createElement("div");

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.closer = this.createCloseButton();
        this.footer = this.createFooter();

        this.container.appendChild(this.title);
        this.container.appendChild(this.content);
        this.container.appendChild(this.closer);
        this.container.appendChild(this.footer);

        this.container.style.height = "500px";
        this.container.style.minHeight = "300px";
        this.container.style.minWidth = "400px";
        this.container.style.left = "24px";
        this.container.style.top = "60px";
        this.container.style.resize = "auto";
        this.content.style.display = "flex";
        this.content.style.height = "calc(100% - 70px)";

        this.initializeMoveHandlers(this.title);
        this.initializeCloseHandler(this.closer);
    }
}
