import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    SxProps,
    TableContainer,
    TextField,
    Theme,
    Toolbar,
    lighten,
    useTheme,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faCheck, faGripLines, faPencil, faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import { createResource, fetchResources, modifyResource, removeResource } from "../../../connectors/admin/resourcesConfig";
import DeleteButton from "../DeleteButton";
import InputButton from "../InputButton";
import StyledTooltip from "../../generic/StyledTooltip";

export interface AdminDialogContentGISWebMapsProps {
    resources: any;
    onClose: () => void;
}

const RESOURCE_KEY = "ArcGis";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const AdminDialogContentGISWebMaps = ({ resources, onClose }: AdminDialogContentGISWebMapsProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [webMaps, setWebMaps] = useState<any[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [editValue, setEditValue] = useState<string>("");
    const [editing, setEditing] = useState<string>();
    const [addNewWebMap, setAddNewWebMap] = useState<boolean>(false);
    const [newWebMap, setNewWebMap] = useState<string>("");
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    useEffect(() => {
        fetchResources();
    }, []);

    useEffect(() => {
        const data = resources.filter((x: any) => x.resource === RESOURCE_KEY);
        setData(data);
        setWebMaps(
            data
                .filter((x: any) => x.key === "WebMap")
                .sort((a: any, b: any) => a.number - b.number)
                .map((x: any) => ({ ...x }))
        );
        setIsLoading(false);
    }, [resources]);

    const onSave = () => {
        setIsSaving(true);

        const promises = [];
        for (let i = 0; i < webMaps.length; i++) {
            const webMap = webMaps[i];
            const originalWebMap = data.find((x) => x.key === "WebMap" && x.id === webMap.id);

            if (originalWebMap.number !== webMap.number || originalWebMap.value !== webMap.value) {
                promises.push(modifyResource(webMap));
            }
        }

        Promise.all(promises).then(() => setIsSaving(false));
    };

    const onReset = () => {
        setWebMaps(
            data
                .filter((x: any) => x.key === "WebMap")
                .sort((a: any, b: any) => a.number - b.number)
                .map((x: any) => ({ ...x }))
        );
    };

    const hasChanges = (): boolean => {
        const originalWebMaps = data.filter((x: any) => x.key === "WebMap").sort((a: any, b: any) => a.number - b.number);
        if (originalWebMaps.length !== webMaps.length) return true;

        for (let i = 0; i < webMaps.length; i++) {
            const originalWebMap = originalWebMaps[i];
            const webMap = webMaps[i];

            if (originalWebMap.value !== webMap.value) return true;
        }

        return false;
    };

    const handleClick = (id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const isEditorActive = (id: string) => editing === id;

    const onDragEnd = (result: any): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const data = reorder(webMaps, result.source.index, result.destination.index);
        for (let i = 0; i < data.length; i++) {
            data[i].number = i + 1;
        }

        setWebMaps(data);
    };

    const editEntry = (id: string, value: string) => {
        const data = [...webMaps];
        const entry = data.find((x) => x.id === id);
        entry.value = value;

        setWebMaps(data);
    };

    const onAddEntry = () => {
        setIsLoading(true);
        createResource({
            project: "OWV - Lantis", // TODO: Replace??
            resource: RESOURCE_KEY,
            key: "WebMap",
            value: newWebMap,
            number: webMaps.length + 1,
        }).then(() => {
            setAddNewWebMap(false);
            setNewWebMap("");
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);
        const promises = [];
        for (let i = 0; i < selected.length; i++) {
            promises.push(removeResource(selected[i]));
        }

        Promise.all(promises).then((_) => {
            setSelected([]);
            setShouldDelete(false);
            setIsLoading(false);
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>GIS</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>

            {isLoading || isSaving ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selected} onAdd={() => setAddNewWebMap(true)} onDelete={() => onDeleteEntry()} />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle" style={{ display: "flex" }}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <TableBody
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{ display: "flex", flexDirection: "column", flex: 1 }}
                                            >
                                                {webMaps.map((webMap, index) => (
                                                    <Draggable key={webMap.id} draggableId={webMap.id} index={index}>
                                                        {(provided, snapshot) => {
                                                            const isItemSelected = isSelected(webMap.id);
                                                            const isItemEditorActive = isEditorActive(webMap.id);

                                                            return (
                                                                <TableRow
                                                                    sx={{
                                                                        display: "flex",
                                                                        flex: 1,
                                                                    }}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    hover
                                                                    onClick={() => handleClick(webMap.id)}
                                                                >
                                                                    <TableCell
                                                                        padding="checkbox"
                                                                        sx={{
                                                                            display: "flex",
                                                                            placeItems: "center",
                                                                        }}
                                                                    >
                                                                        <Checkbox checked={isItemSelected} />
                                                                    </TableCell>
                                                                    <TableCell style={{ flexBasis: 0, flexGrow: 1 }}>
                                                                        {isItemEditorActive ? (
                                                                            <div style={{ display: "flex" }}>
                                                                                <TextField
                                                                                    value={editValue}
                                                                                    onChange={(e) => setEditValue(e.currentTarget.value)}
                                                                                    fullWidth
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <>
                                                                                                <InputButton
                                                                                                    size="small"
                                                                                                    onClick={(e) => {
                                                                                                        editEntry(webMap.id, editValue);
                                                                                                        setEditing(undefined);
                                                                                                        e.stopPropagation();
                                                                                                    }}
                                                                                                >
                                                                                                    <FontAwesomeSvgIcon
                                                                                                        icon={faCheck}
                                                                                                        style={{ fontSize: "1rem" }}
                                                                                                    />
                                                                                                </InputButton>

                                                                                                <InputButton
                                                                                                    size="small"
                                                                                                    onClick={(e) => {
                                                                                                        setEditing(undefined);
                                                                                                        e.stopPropagation();
                                                                                                    }}
                                                                                                >
                                                                                                    <FontAwesomeSvgIcon
                                                                                                        icon={faTimes}
                                                                                                        style={{ fontSize: "1rem" }}
                                                                                                    />
                                                                                                </InputButton>
                                                                                            </>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <Typography>
                                                                                {webMap.value}
                                                                                <InputButton
                                                                                    size="small"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setEditValue(webMap.value);
                                                                                        setEditing(webMap.id);
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                                                </InputButton>
                                                                            </Typography>
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            display: "flex",
                                                                            placeItems: "center",
                                                                            padding: "0 15px 0 0",
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                marginLeft: "15px",
                                                                                fontSize: "16px",
                                                                                color: "#707070",
                                                                                "&:hover": {
                                                                                    color: "#000000",
                                                                                },
                                                                            }}
                                                                            {...provided.dragHandleProps}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        >
                                                                            <FontAwesomeSvgIcon icon={faGripLines} />
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    <Dialog open={addNewWebMap} onClose={() => setAddNewWebMap(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                        <DialogTitle id="form-dialog-title">Nieuwe WebMap toevoegen</DialogTitle>
                        <DialogContent>
                            <TextField value={newWebMap} onChange={(e) => setNewWebMap(e.currentTarget.value)} label="Naam van de WebMap" fullWidth />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setAddNewWebMap(false)} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <Button onClick={() => onAddEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                Toevoegen
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={Boolean(shouldDelete)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">WebMap(s) verwijderen</DialogTitle>
                        <DialogContent>
                            <Typography>U staat op het punt de geselecteerde WebMap(s) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                Annuleren
                            </Button>
                            <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                Verwijderen
                            </DeleteButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    resources: appReducer.resources,
});

export default connect(mapStateToProps)(AdminDialogContentGISWebMaps);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}
const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={{ style }}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                WebMaps
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde WebMap(s) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe WebMap toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};
