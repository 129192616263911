import { useLayoutEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Changelog } from "./Changelog";
import CloseButton from "../../generic/CloseButton";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

export interface ChangelogModalProps {
    changelog: Changelog;
    open: boolean;
    onClose: () => void;
}

const ChangelogModal = ({ open, onClose, changelog }: ChangelogModalProps) => {
    const [content, setContent] = useState<JSX.Element>();

    useLayoutEffect(() => {
        if (!changelog) return;

        const changelogPrefix = (
            <Typography
                sx={{
                    marginBottom: 2,
                }}
            >
                De applicatie werd geüpdate naar {changelog.version.toString()}, hierbij een overzicht van de wijzigingen dat werden doorgevoerd met deze
                update.
            </Typography>
        );
        setContent(
            <>
                {changelogPrefix}
                {changelog.content}
            </>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changelog]);

    return (
        <Dialog open={open} keepMounted aria-labelledby="release-changelog-dialog-slide-title" fullWidth={true}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "60vh",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <DialogTitle id="release-changelog-dialog-slide-title">
                        Nieuwe Release - {changelog.version.toString()}
                        <Typography variant="caption" component={"span"} style={{ display: "block" }}>
                            {changelog.date.toLocaleDateString("nl-BE", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}
                        </Typography>
                    </DialogTitle>

                    <CloseButton aria-label="Sluit release changelog" size="small" onClick={() => onClose()}>
                        <FontAwesomeSvgIcon
                            icon={faTimes}
                            sx={{
                                width: "12px !important",
                            }}
                        />
                    </CloseButton>
                </Box>
                <DialogContent
                    dividers
                    sx={{
                        flex: 1,
                        overflow: "auto",
                    }}
                >
                    {content}
                </DialogContent>
                <DialogActions sx={{ m: "5px" }}>
                    <Button color="primary" variant="contained" onClick={() => onClose()}>
                        Doorgaan
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ChangelogModal;
