import { useTheme } from "@mui/material";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

const Icon = ({ selected, disabled, icon }) => {
    const theme = useTheme();

    const defaultStyle = {
        height: "16px",
        color: theme.colors.placeholderText,
    };

    const selectedStyle = {
        height: "16px",
        color: theme.colors.textColor,
    };

    const disabledStyle = {
        height: "16px",
        color: theme.colors.disabled,
        pointerEvents: "none",
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return <FontAwesomeSvgIcon icon={icon} sx={style} />;
};

export default Icon;
