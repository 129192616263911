import store from "../redux/store";
import {
    requestObjectName,
    receiveObjectName,
    cancelObjectName,
    requestObjectNames,
    receiveObjectNames,
    requestObjectNamesList,
    receiveObjectNamesList,
} from "../redux/objectNames/actions";
import { configuration } from "../_configuration/configuration";

export const loadObjectNames = () => {
    store.dispatch(requestObjectNamesList());

    const url = `${configuration.api}api/ObjectName`;
    const options = {
        method: "GET",
    };

    fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((names) => {
            store.dispatch(receiveObjectNamesList(names));
        });
};

export const loadObjectName = (objectId) => {
    store.dispatch(requestObjectName(objectId));

    const controller = new AbortController();
    const signal = controller.signal;

    const url = `${configuration.api}api/ObjectPropertyValue/name/${objectId}`;
    const options = {
        signal,
        cache: "force-cache",
    };

    fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((name) => {
            store.dispatch(receiveObjectName(objectId, name));
        })
        .catch((error) => {
            // console.error("connectors/objectNames - loadObjectName error: ", error, "for objectId: ", objectId);
            if (signal.aborted) {
                store.dispatch(cancelObjectName(objectId));
            } else {
                store.dispatch(receiveObjectName(objectId, null, error));
            }
        });

    return controller;
};

export const loadObjectNamesBatch = (objectIds) => {
    store.dispatch(requestObjectNames(objectIds));

    const url = `${configuration.api}api/ObjectIndex/Name/reverse/values`;
    const options = {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(objectIds),
        cache: "force-cache",
    };

    fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then((names) => {
            store.dispatch(receiveObjectNames(objectIds, names));
        })
        .catch((error) => {
            // console.error("connectors/objectNames - loadObjectNamesBatch error: ", error, "for objectIds: ", objectIds);
            store.dispatch(receiveObjectNames(objectIds, null, error));
        });
};
