import { createContext, Dispatch, useReducer } from "react";
import { AppState, initialAppState } from "./State";
import { AppAction } from "./Actions";
import { appReducer } from "./Reducer";

interface AppContextProps {
    children: JSX.Element;
}

const AppContext = createContext<{
    state: AppState;
    dispatch: Dispatch<AppAction>;
}>({
    state: initialAppState,
    dispatch: () => undefined,
});

const AppContextProvider = ({ children }: AppContextProps) => {
    const [state, dispatch] = useReducer(appReducer, initialAppState);

    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppContextProvider, AppContext };
