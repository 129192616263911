import { Component } from "react";
import { connect } from "react-redux";
import { setHistoryIndex } from "../../../../redux/selection/actions";
import { Box, styled } from "@mui/material";
import BackIcon from "../../../svg/historyControls/BackIcon";
import ForwardIcon from "../../../svg/historyControls/ForwardIcon";

const ControllerContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled, theme }) => ({
    display: "flex",
    userSelect: "none",
    pointerEvents: disabled ? "none" : "all",
    width: 55,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderLeft: "1px solid #E6E6E6",
}));

class HistoryControls extends Component {
    constructor(props) {
        super(props);
        this.historyControls = [
            {
                id: "historyControlsBack",
                icon: BackIcon,
                action: () => this.handleChangeHistory(+1),
            },
            {
                id: "historyControlsForward",
                icon: ForwardIcon,
                action: () => this.handleChangeHistory(-1),
            },
        ];
    }

    handleChangeHistory = (offset) => {
        const historyIndex = this.props.historyIndex + offset;
        this.props.onSetHistoryIndex(historyIndex);
    };

    render() {
        const { history, historyIndex } = this.props;

        return (
            <Box
                sx={{
                    display: "flex",
                    margin: "-16px -18px",
                }}
            >
                {this.historyControls.map(({ id, icon: Icon, action }) => {
                    let hasHistory = true;
                    if (id === "historyControlsBack" && (!history.length || historyIndex === history.length - 1)) {
                        hasHistory = false;
                    }
                    if (id === "historyControlsForward" && !(history.length && historyIndex !== -1)) {
                        hasHistory = false;
                    }
                    return (
                        <ControllerContainer key={id} id={id} onClick={action} disabled={!hasHistory}>
                            <Icon selected={hasHistory} />
                        </ControllerContainer>
                    );
                })}
            </Box>
        );
    }
}

const mapStateToProps = ({ selectionReducer }) => ({
    history: selectionReducer.history,
    historyIndex: selectionReducer.historyIndex,
});

const mapDispatchToProps = (dispatch) => ({
    onSetHistoryIndex: (historyIndex) => dispatch(setHistoryIndex(historyIndex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryControls);
