import { Component, createRef, Fragment } from "react";
import { Box, styled } from "@mui/material";
import { connect } from "react-redux";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";

const TitleContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "8px 17px",
    userSelect: "none",
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
}));

const TitleInner = styled(Box)(() => ({
    display: "flex",
    flex: 1,
}));

const Title = styled(Box)(({ theme }) => ({
    flex: 1,
    wordBreak: "break-word",
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
}));

const IconContainer = styled(Box)(() => ({
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
    width: 16,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.colors.inputBorder,
}));

class Expander extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: Boolean(props.startExpanded),
        };
        this.rootRef = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const shouldRevealSelectedRelationHasChanged = this.props.shouldRevealSelectedRelation !== prevProps.shouldRevealSelectedRelation;
        if (shouldRevealSelectedRelationHasChanged) {
            this.expandIfNecessary();
        }
    }

    expandIfNecessary = () => {
        const { shouldRevealSelectedRelation } = this.props;
        if (shouldRevealSelectedRelation && this.rootRef && this.rootRef.current) {
            const secondarySelectionNode = this.rootRef.current.querySelector("#secondarySelectionNode");
            if (secondarySelectionNode) {
                this.setState({ expanded: true });
            }
        }
    };

    toggleExpanded = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    render() {
        const { title, children, withMargin = false, depth = 0, actions, hideExpander } = this.props;

        const { expanded } = this.state;

        const childrenIsArray = Array.isArray(children);
        const content = childrenIsArray ? children.map((child, index) => <Fragment key={index}>{child}</Fragment>) : children;

        const indentStyle = {
            paddingLeft: `calc(${depth} * 24px)`,
        };

        return Boolean(hideExpander) ? (
            <>{content}</>
        ) : (
            <div ref={this.rootRef}>
                <TitleContainer style={{ cursor: Boolean(content) ? "pointer" : "default" }} onClick={this.toggleExpanded}>
                    <TitleInner style={indentStyle}>
                        <IconContainer>
                            {Boolean(content) && <FontAwesomeSvgIcon icon={expanded ? faChevronDown : faChevronRight} style={{ height: "0.7em" }} />}
                        </IconContainer>
                        <Title>{title}</Title>
                        {Boolean(Array.isArray(actions)) &&
                            actions.map((action, index) => {
                                return <Fragment key={`Expander__action__${index}`}>{action}</Fragment>;
                            })}
                    </TitleInner>
                </TitleContainer>

                {Boolean(content) && (
                    <ContentContainer sx={{ display: expanded ? "block" : "none" }}>
                        {Boolean(withMargin) ? <Box sx={{ ml: 37 }}>{content}</Box> : content}
                    </ContentContainer>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ appReducer }) => ({
    shouldRevealSelectedRelation: appReducer.shouldRevealSelectedRelation,
});

export default connect(mapStateToProps)(Expander);
