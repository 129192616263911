import store from "../../redux/store";
import { addResource, deleteResource, editResource, setResources } from "../../redux/app/actions";
import { configuration } from "../../_configuration/configuration";

export const fetchResources = () => {
    const url = `${configuration.api}api/ResourceConfigs`;
    const options = {
        method: "GET",
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status === 204) return {};
            return response.json();
        })
        .then((resources) => {
            store.dispatch(setResources(resources));
        });
};

export const createResource = (resource) => {
    const url = `${configuration.api}api/ResourceConfigs`;
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resource),
    };

    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((resource) => {
            store.dispatch(addResource(resource));
        });
};

export const modifyResource = (resource) => {
    const url = `${configuration.api}api/ResourceConfigs/${resource.id}`;
    const options = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(resource),
    };

    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((resource) => {
            store.dispatch(editResource(resource));
        });
};

export const removeResource = (id) => {
    const url = `${configuration.api}api/ResourceConfigs/${id}`;
    const options = {
        method: "DELETE",
    };

    return fetch(url, options).then((response) => {
        if (response.status === 204) store.dispatch(deleteResource(id));
    });
};
