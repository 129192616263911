import { cloneElement, useLayoutEffect, useRef, useState } from "react";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import TagManager from "react-gtm-module";
import store from "../redux/store";
import { removeAuthentication, setAuthentication, setAccount } from "../redux/account/actions";
import { configuration } from "../_configuration/configuration";
import axiosInstance from "../connectors/axiosConfig";

type IdTokenClaims = {
    sub: string;
    display_name: string;
    name: string;
    email: string;
};

export interface BBRIAuthenticationHandlerProps {
    children: JSX.Element;
}

export interface AuthenticationState {
    error?: ErrorMessage;
    isAuthenticated: boolean;
    user?: UserInfo;
}

export interface ErrorMessage {
    message: string;
    debug: string;
}

export interface UserInfo {
    displayName: string;
    email: string;
}

const AuthenticationHandler = ({ children }: BBRIAuthenticationHandlerProps) => {
    const [state, setState] = useState<AuthenticationState>({ isAuthenticated: false });
    const interceptorRef = useRef<number | null>(null);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const originalFetch = useRef(window.fetch);

    useLayoutEffect(() => {
        if (!isAuthenticated) {
            window.fetch = originalFetch.current;

            if (!interceptorRef.current) return;
            axiosInstance.interceptors.request.eject(interceptorRef.current);

            return;
        }

        window.fetch = async function (input: RequestInfo | URL, info?: RequestInit) {
            if (!input.toString().startsWith(configuration.api)) return originalFetch.current.apply(this, [input, info]);

            const token = await getToken();
            if (info) info.headers = { ...info.headers, Authorization: `Bearer ${token}` };

            return originalFetch.current.apply(this, [input, info]);
        };

        interceptorRef.current = axiosInstance.interceptors.request.use(
            async (config) => {
                const accessToken = await getToken();
                config.headers["Authorization"] = `Bearer ${accessToken}`;

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useLayoutEffect(() => {
        if (!isAuthenticated) return;
        instance.acquireTokenSilent({ scopes: configuration.authentication.scopes }).then(parseToken);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const parseToken = (response: AuthenticationResult) => {
        const idTokenClaims = response.idTokenClaims as IdTokenClaims;
        const user = {
            displayName: idTokenClaims.display_name ?? idTokenClaims.name,
            email: idTokenClaims.email,
        };

        if (configuration.gtm) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "login",
                    userId: user.email,
                },
            });
        }

        setState({ isAuthenticated: true, user, error: undefined });
        store.dispatch(setAuthentication(response));
        store.dispatch(setAccount(user));
    };

    const getToken = async (): Promise<string | null | void> => {
        try {
            const token = await instance.acquireTokenSilent({
                scopes: configuration.authentication.scopes,
            });
            return token.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                return instance
                    .acquireTokenPopup({
                        scopes: configuration.authentication.scopes,
                    })
                    .then((resp) => resp.accessToken)
                    .catch((err) => {
                        console.error("Authentication failed:", err);
                        return null;
                    });
            } else {
                console.error("Failed to obtain token:", error);
            }
        }
    };

    const login = async () => {
        try {
            await instance.loginPopup({
                scopes: configuration.authentication.scopes,
                prompt: "select_account",
            });
        } catch (err) {
            if (err instanceof Error || typeof err === "string")
                setState({
                    isAuthenticated: false,
                    error: normalizeError(err),
                });
        }
    };

    const logout = () => {
        store.dispatch(removeAuthentication());

        if (configuration.gtm) {
            TagManager.dataLayer({
                dataLayer: {
                    event: "logout",
                    userId: "",
                },
            });
        }

        instance.logout();
    };

    const normalizeError = (error: string | Error): any => {
        var normalizedError = {};
        if (typeof error === "string") {
            var errParts = error.split("|");
            normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
        } else {
            normalizedError = {
                message: error.message,
                debug: JSON.stringify(error),
            };
        }
        return normalizedError;
    };

    return cloneElement(children, { login, logout, ...state });
};

export default AuthenticationHandler;
