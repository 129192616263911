import { useTheme } from "@mui/material";
import { faFileExclamation } from "@fortawesome/pro-light-svg-icons";
import { faFileExclamation as faFileExclamationSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RequirementsIcon = ({ selected, disabled }) => {
    const theme = useTheme();

    const defaultStyle = {
        fill: theme.colors.placeholderText,
    };

    const selectedStyle = {
        fill: theme.colors.secondarySelection,
    };

    const disabledStyle = {
        pointerEvents: "none",
        fill: theme.colors.disabled,
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return selected ? <FontAwesomeIcon icon={faFileExclamationSolid} style={style} /> : <FontAwesomeIcon icon={faFileExclamation} style={style} />;
};

export default RequirementsIcon;
