import React from "react";
import { Box, useTheme } from "@mui/material";

const CrossIcon = () => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64.003" viewBox="0 0 64 64.003">
            <Box
                component={"path"}
                sx={{
                    fill: theme.colors.error,
                    fillRule: "evenodd",
                }}
                d="M52.549,44.526l24-24-8-8-24,24-24-24-8,8,24,24-24,24,8,8,24-24,24,24,8-8-24-24"
                transform="translate(-12.552 -12.524)"
            />
        </svg>
    );
};

export default CrossIcon;
