import { Box, useTheme } from "@mui/material";

const MapsIcon = ({ selected, disabled }) => {
    const theme = useTheme();

    const defaultStyle = {
        fill: theme.colors.placeholderText,
    };

    const selectedStyle = {
        fill: theme.colors.secondarySelection,
    };

    const disabledStyle = {
        pointerEvents: "none",
        fill: theme.colors.disabled,
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.597" height="13.687" viewBox="0 0 17.597 13.687">
            <Box
                component={"path"}
                sx={style}
                d="M0,34.617V45.2a.489.489,0,0,0,.67.454l4.218-1.92V32L.615,33.709A.978.978,0,0,0,0,34.617Zm5.866,9.114,5.866,1.955V33.955L5.866,32Zm11.061-11.7-4.218,1.92V45.687l4.273-1.709a.978.978,0,0,0,.615-.908V32.489A.489.489,0,0,0,16.927,32.035Z"
                transform="translate(0 -32)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.6" height="13.689" viewBox="0 0 17.6 13.689">
            <Box
                component={"path"}
                sx={style}
                d="M17.112,32a.491.491,0,0,0-.182.035l-5.2,1.92h0L6.478,32.1a1.958,1.958,0,0,0-1.224,0L.615,33.71A.978.978,0,0,0,0,34.617V45.2a.488.488,0,0,0,.671.454l5.2-1.92,5.256,1.855a1.955,1.955,0,0,0,1.224,0l4.639-1.614a.978.978,0,0,0,.615-.908V32.489A.489.489,0,0,0,17.112,32ZM.936,34.633l4.442-1.545v9.784L.975,44.5.936,34.633Zm5.42,8.236V33.094l4.889,1.726v9.775ZM12.222,44.6V34.817l4.4-1.627.039,9.865Z"
                transform="translate(0 -32)"
            />
        </svg>
    );
};

export default MapsIcon;
