import { configuration } from "../_configuration/configuration";

export const submitFeedback = (category, message) => {
    return new Promise((resolve, reject) => {
        const url = `${configuration.api}api/UserFeedback`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ category, message }),
        };

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                resolve();
            })
            .catch(reject);
    });
};
