import { Box, useTheme } from "@mui/material";

const PhysicalObjectTreeIcon = ({ selected, disabled }) => {
    const theme = useTheme();

    const defaultStyle = {
        fill: theme.colors.placeholderText,
    };

    const selectedStyle = {
        fill: theme.colors.secondarySelection,
    };

    const disabledStyle = {
        pointerEvents: "none",
        fill: theme.colors.disabled,
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.824" height="15.922" viewBox="0 0 14.824 15.922">
            <Box
                component={"path"}
                sx={style}
                d="M227.721,100.461H226.6V98.652a.5.5,0,0,0-.5-.5h-3.8V96.977h1.117a1.493,1.493,0,0,0,1.492-1.492V92.25a1.494,1.494,0,0,0-1.492-1.492h-3.234a1.494,1.494,0,0,0-1.492,1.492v3.235a1.493,1.493,0,0,0,1.492,1.492H221.3v1.175h-3.8a.5.5,0,0,0-.5.5v1.809h-1.117a1.493,1.493,0,0,0-1.492,1.492v3.235a1.494,1.494,0,0,0,1.492,1.492h3.234a1.494,1.494,0,0,0,1.492-1.492v-3.235a1.493,1.493,0,0,0-1.492-1.492H218V99.152H225.6v1.309h-1.117a1.493,1.493,0,0,0-1.492,1.492v3.235a1.494,1.494,0,0,0,1.492,1.492h3.234a1.494,1.494,0,0,0,1.492-1.492v-3.235A1.493,1.493,0,0,0,227.721,100.461Z"
                transform="translate(-214.389 -90.758)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.825" height="15.922" viewBox="0 0 14.825 15.922">
            <Box
                component={"path"}
                sx={style}
                d="M206.357,100.461H205.24V98.652a.5.5,0,0,0-.5-.5h-3.8V96.977h1.118a1.494,1.494,0,0,0,1.492-1.492V92.25a1.5,1.5,0,0,0-1.492-1.492H198.82a1.5,1.5,0,0,0-1.492,1.492v3.235a1.494,1.494,0,0,0,1.492,1.492h1.117v1.175h-3.8a.5.5,0,0,0-.5.5v1.809h-1.117a1.493,1.493,0,0,0-1.492,1.492v3.235a1.494,1.494,0,0,0,1.492,1.492h3.234a1.5,1.5,0,0,0,1.493-1.492v-3.235a1.494,1.494,0,0,0-1.493-1.492h-1.117V99.152h7.606v1.309h-1.117a1.493,1.493,0,0,0-1.492,1.492v3.235a1.494,1.494,0,0,0,1.492,1.492h3.234a1.5,1.5,0,0,0,1.493-1.492v-3.235A1.494,1.494,0,0,0,206.357,100.461Zm-8.029-4.976V92.25a.493.493,0,0,1,.492-.492h3.235a.493.493,0,0,1,.492.492v3.235a.493.493,0,0,1-.492.492H198.82A.493.493,0,0,1,198.328,95.485Zm-.084,6.468v3.235a.493.493,0,0,1-.493.492h-3.234a.493.493,0,0,1-.492-.492v-3.235a.493.493,0,0,1,.492-.492h3.234A.493.493,0,0,1,198.244,101.953Zm8.606,3.235a.493.493,0,0,1-.493.492h-3.234a.493.493,0,0,1-.492-.492v-3.235a.493.493,0,0,1,.492-.492h3.234a.493.493,0,0,1,.493.492Z"
                transform="translate(-193.025 -90.758)"
            />
        </svg>
    );
};

export default PhysicalObjectTreeIcon;
