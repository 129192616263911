const defaultState = {
    sidebarOpen: true,

    relationsOverlayOpen: false,
    propertiesOverlayOpen: false,
    selectedSidebarTabId: null,
    selectedRelationsTabId: "maps",
    forceRelationsSelection: false,
    toolTip: null,
    shouldRevealSelectedRelation: false,
    suppressedNotifications: false,

    layersSearchQuery: "",
    modelsSearchQuery: "",
    documentsSearchQuery: "",

    // Admin section related
    tips: [],
    updates: [],
    resources: [],
    properties: [],
    filters: [],
    models: [],
    layers: [],
    activatableResourceGroups: [],
    defaultActiveResources: [],
    roles: [],
    resourceItems: [],
};

const appReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "SET_SIDEBAR_OPEN": {
            newState.sidebarOpen = action.sidebarOpen;
            return newState;
        }

        case "SET_SUPPRESSED_NOTIFICATIONS": {
            newState.suppressedNotifications = action.suppressed;
            return newState;
        }

        case "SET_LAYERS_SEARCH": {
            newState.layersSearchQuery = action.query;
            return newState;
        }

        case "SET_MODELS_SEARCH": {
            newState.modelsSearchQuery = action.query;
            return newState;
        }

        case "SET_DOCUMENTS_SEARCH": {
            newState.documentsSearchQuery = action.query;
            return newState;
        }

        case "SET_TIPS": {
            newState.tips = action.tips;
            return newState;
        }

        case "ADD_TIP": {
            newState.tips = [...state.tips];
            newState.tips.push(action.tip);
            return newState;
        }

        case "DELETE_TIP": {
            newState.tips = state.tips.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_CONTENT_UPDATES": {
            newState.updates = action.updates;
            return newState;
        }

        case "ADD_CONTENT_UPDATE": {
            newState.updates = [...state.updates];
            newState.updates.push(action.update);
            return newState;
        }

        case "DELETE_CONTENT_UPDATE": {
            newState.updates = state.updates.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_RESOURCE_CONFIGS": {
            newState.resources = action.resources;
            return newState;
        }

        case "ADD_RESOURCE_CONFIG": {
            newState.resources = [...state.resources];
            newState.resources.push(action.resource);
            return newState;
        }

        case "UPDATE_RESOURCE_CONFIG": {
            newState.resources = state.resources.filter((x) => x.id !== action.resource.id);
            newState.resources.push(action.resource);
            return newState;
        }

        case "DELETE_RESOURCE_CONFIG": {
            newState.resources = state.resources.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_PROPERTIES": {
            newState.properties = action.properties;
            return newState;
        }

        case "ADD_PROPERTY": {
            newState.properties = [...state.properties];
            newState.properties.push(action.property);
            return newState;
        }

        case "UPDATE_PROPERTY": {
            newState.properties = state.properties.filter((x) => x.id !== action.property.id);
            newState.properties.push(action.property);
            return newState;
        }

        case "DELETE_PROPERTY": {
            newState.properties = state.properties.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_BIM_RESOURCES": {
            newState.models = action.resources;
            return newState;
        }

        case "SET_GIS_RESOURCES": {
            newState.layers = action.resources;
            return newState;
        }

        case "SET_ACTIVATABLE_RESOURCE_GROUPS": {
            newState.activatableResourceGroups = action.resourceGroups;
            return newState;
        }

        case "ADD_ACTIVATABLE_RESOURCE_GROUP": {
            newState.activatableResourceGroups = [...state.activatableResourceGroups];
            newState.activatableResourceGroups.push(action.resourceGroup);
            return newState;
        }

        case "DELETE_ACTIVATABLE_RESOURCE_GROUP": {
            newState.activatableResourceGroups = state.activatableResourceGroups.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_DEFAULT_ACTIVE_RESOURCES": {
            newState.defaultActiveResources = action.resources;
            return newState;
        }

        case "ADD_DEFAULT_ACTIVE_RESOURCE": {
            newState.defaultActiveResources = [...state.defaultActiveResources];
            newState.defaultActiveResources.push(action.resource);
            return newState;
        }

        case "DELETE_DEFAULT_ACTIVE_RESOURCE": {
            newState.defaultActiveResources = state.defaultActiveResources.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_FILTERS": {
            newState.filters = action.filters;
            return newState;
        }

        case "ADD_FILTER": {
            newState.filters = [...state.filters];
            const group = newState.filters.find((x) => x.id === action.filter.groupId);
            group.filters.push(action.filter);

            return newState;
        }

        case "UPDATE_FILTER": {
            newState.filters = [...state.filters];
            const group = newState.filters.find((x) => x.id === action.filter.groupId);
            group.filters = group.filters.filter((x) => x.id !== action.filter.id);
            group.filters.push(action.filter);

            return newState;
        }

        case "DELETE_FILTER": {
            newState.filters = [...state.filters];
            const group = newState.filters.find((x) => x.filters.some((y) => y.id === action.id));
            group.filters = group.filters.filter((x) => x.id !== action.id);

            return newState;
        }

        case "ADD_GROUP": {
            newState.filters = [...state.filters];
            newState.filters.push(action.group);
            return newState;
        }

        case "UPDATE_GROUP": {
            const filters = [...state.filters];
            const group = filters.find((x) => x.id === action.group.id);
            group.name = action.group.name;
            group.description = action.group.description;
            group.icon = action.group.icon;

            newState.filters = filters;
            return newState;
        }

        case "DELETE_GROUP": {
            newState.filters = state.filters.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_ROLES": {
            newState.roles = action.roles;
            return newState;
        }

        case "ADD_ROLE": {
            newState.roles = [...state.roles];
            newState.roles.push(action.role);
            return newState;
        }

        case "UPDATE_ROLE": {
            newState.roles = state.roles.filter((x) => x.id !== action.role.id);
            newState.roles.push(action.role);
            return newState;
        }

        case "DELETE_ROLE": {
            newState.roles = state.roles.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_RESOURCE_ITEMS": {
            newState.resourceItems = action.items;
            return newState;
        }

        case "ADD_RESOURCE_ITEM": {
            newState.resourceItems = [...state.resourceItems];
            newState.resourceItems.push(action.item);
            return newState;
        }

        case "UPDATE_RESOURCE_ITEM": {
            newState.resourceItems = state.resourceItems.filter((x) => x.id !== action.item.id);
            newState.resourceItems.push(action.item);
            return newState;
        }

        case "DELETE_RESOURCE_ITEM": {
            newState.resourceItems = state.resourceItems.filter((x) => x.id !== action.id);
            return newState;
        }

        case "SET_RELATIONS_OVERLAY_OPEN": {
            newState.relationsOverlayOpen = action.relationsOverlayOpen;
            return newState;
        }

        case "SET_PROPERTIES_OVERLAY_OPEN": {
            newState.propertiesOverlayOpen = action.propertiesOverlayOpen;
            return newState;
        }

        case "SET_SELECTED_SIDEBAR_TAB_ID": {
            newState.selectedSidebarTabId = action.selectedSidebarTabId;
            return newState;
        }

        case "SET_SELECTED_RELATIONS_TAB_ID": {
            newState.selectedRelationsTabId = action.selectedRelationsTabId;
            newState.forceRelationsSelection = action.forceRelationsSelection;
            return newState;
        }

        case "RESET_FORCE_RELATIONS_SELECTION": {
            newState.forceRelationsSelection = false;
            return newState;
        }

        case "SET_TOOL_TIP": {
            if (action.hideToolTip) {
                newState.toolTip = null;
            } else {
                newState.toolTip = {
                    value: action.value,
                    x: action.x,
                    y: action.y,
                };
            }
            return newState;
        }

        case "SET_SHOULD_REVEAL_SELECTED_RELATION": {
            newState.shouldRevealSelectedRelation = action.shouldRevealSelectedRelation;
            return newState;
        }

        default:
            return newState;
    }
};

export default appReducer;
