const createInitialState = () => {
    const state = {
        selectedObject: null,
        queuedSelectedObject: null,
        selectedLinkedId: null,
        selectedLinkedSource: null,
        history: [],
        historyIndex: -1,
        fullHistory: [],
        queuedHighlights: null,
        highlights: null,
        keepVisibleContainersVisible: false,
        highlightResource: null,
    };

    return state;
};

class HistoryItem {
    constructor(object) {
        this.objectId = object.objectId;
        this.source = object.source;
        const date = new Date();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        this.timestamp = `${hours}:${minutes}`;
    }
}

const reducer = (state = createInitialState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "SET_SELECTED_OBJECT": {
            const isSameSelection =
                state.selectedObject && state.selectedObject.objectId === action.objectId && state.selectedObject.source === action.source;
            if (isSameSelection) {
                return state;
            }
            newState.selectedObject = {
                objectId: action.objectId,
                source: action.source,
                paneId: action.pane,
                isPhysicalObject: action.isPhysicalObject,
                highlightIds: action.highlightIds,
            };
            newState.queuedSelectedObject = null;
            newState.selectedLinkedId = null;
            if (action.objectId) {
                if (state.selectedObject) {
                    if (state.historyIndex === -1) {
                        newState.history = [state.selectedObject, ...state.history];
                    } else {
                        newState.history = [...state.history].splice(state.historyIndex);
                        newState.historyIndex = -1;
                    }
                }
                const historyItem = new HistoryItem(newState.selectedObject);
                newState.fullHistory = [historyItem, ...state.fullHistory];
            }
            return newState;
        }

        case "SET_QUEUED_SELECTED_OBJECT": {
            newState.queuedSelectedObject = {
                objectId: action.objectId,
                source: action.source,
                isPhysicalObject: action.isPhysicalObject,
                highlightIds: action.highlightIds,
            };

            return newState;
        }

        case "SET_QUEUED_SELECTED_OBJECT_RESOURCE": {
            newState.highlightResource = action.resource;
            return newState;
        }

        case "CLEAR_QUEUED_SELECTED_OBJECT_RESOURCE": {
            newState.highlightResource = null;
            return newState;
        }

        case "SET_QUEUED_HIGHLIGHTS": {
            newState.queuedHighlights = {
                source: action.source,
                highlightIds: action.highlightIds,
            };
            newState.keepVisibleContainersVisible = action.keepVisibleContainersVisible;
            return newState;
        }

        case "SET_HIGHLIGHTS": {
            newState.queuedHighlights = null;
            newState.highlights = {
                source: action.source,
                highlightIds: action.highlightIds,
                paneId: action.paneId,
            };
            return newState;
        }

        case "CANCEL_HIGHLIGHTS": {
            newState.queuedHighlights = null;
            newState.highlights = null;
            return newState;
        }

        case "SET_HISTORY_INDEX": {
            newState.historyIndex = action.historyIndex;
            newState.selectedObject = state.history[action.historyIndex];
            newState.selectedLinkedId = null;
            if (action.historyIndex === 0) {
                if (state.historyIndex === -1) {
                    newState.history = [state.selectedObject, ...state.history];
                    newState.historyIndex = 1;
                } else if (state.historyIndex === 1) {
                    newState.history = [...state.history].splice(1);
                    newState.historyIndex = -1;
                }
            }
            const historyItem = new HistoryItem(state.selectedObject);
            newState.fullHistory = [historyItem, ...state.fullHistory];
            return newState;
        }

        case "SET_SELECTED_LINKED_ID": {
            newState.selectedLinkedId = action.selectedLinkedId;
            newState.selectedLinkedSource = action.selectedLinkedSource;
            return newState;
        }

        case "CLEAR":
            return createInitialState();

        default:
            return state;
    }
};

export default reducer;
