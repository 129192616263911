import { Component, createRef } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import RequirementsIcon from "../../../../../svg/selectionTabs/RequirementsIcon";
import Relation from "../Relation";
import Expander from "../../../../../generic/Expander";
import ReactResizeDetector from "react-resize-detector";
import RevealSelectedRelation from "../RevealSelectedRelation";
import { setShouldRevealSelectedRelation } from "../../../../../../redux/app/actions";

const Root = styled(Box)(() => ({
    height: "100%",
    width: "100%",
    overflowY: "auto",
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
    height: "100%",
    display: "flex",
}));

class Requirements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionIsInvisible: false,
        };
        this.rootRef = createRef();
        this.revealSelectedRelationInterval = null;
    }

    componentDidUpdate(prevProps, prevState) {
        const shouldRevealSelectedRelationHasChanged = this.props.shouldRevealSelectedRelation !== prevProps.shouldRevealSelectedRelation;
        if (shouldRevealSelectedRelationHasChanged) {
            this.revealSelectedRelation();
        }

        this.checkSecondarySelectionVisibility();
    }

    componentWillUnmount() {
        if (this.revealSelectedRelationInterval) {
            clearInterval(this.revealSelectedRelationInterval);
        }
    }

    revealSelectedRelation = () => {
        const { shouldRevealSelectedRelation, setShouldRevealSelectedRelation } = this.props;

        if (shouldRevealSelectedRelation) {
            const secondarySelectionNode = document.querySelector("#secondarySelectionNode");
            this.revealSelectedRelationInterval = setInterval(() => {
                if (secondarySelectionNode) {
                    const selectionIsInvisible = this.getSecondarySelectionVisibility();
                    if (selectionIsInvisible) {
                        secondarySelectionNode.scrollIntoView();
                    } else {
                        setShouldRevealSelectedRelation(false);
                    }
                }
            }, 0);
        } else {
            if (this.revealSelectedRelationInterval) {
                clearInterval(this.revealSelectedRelationInterval);
            }
        }
    };

    checkSecondarySelectionVisibility = () => {
        const selectionIsInvisible = this.getSecondarySelectionVisibility();
        if (this.state.selectionIsInvisible !== selectionIsInvisible) {
            this.setState((state) => ({
                selectionIsInvisible,
            }));
        }
    };

    getSecondarySelectionVisibility = () => {
        const secondarySelectionNode = document.querySelector("#secondarySelectionNode");
        if (secondarySelectionNode && this.rootRef && this.rootRef.current) {
            const { bottom: selectedBottom, top: selectedTop, height } = secondarySelectionNode.getBoundingClientRect();
            const { bottom: rootBottom, top: rootTop } = this.rootRef.current.getBoundingClientRect();

            const selectionIsBeyondTop = selectedBottom < rootTop;
            const selectionIsBeyondBottom = selectedTop > rootBottom;
            const selectionIsHidden = !height;
            return selectionIsBeyondTop || selectionIsBeyondBottom || selectionIsHidden;
        }

        return false;
    };

    renderMapRelations = (relations, direction) => {
        return relations.map(({ Name, OtherObjectId }, index) => {
            return <Relation key={`${index}_${OtherObjectId}`} objectId={OtherObjectId} icon={RequirementsIcon} direction={direction} noSelect />;
        });
    };

    render() {
        const { requirementRelations } = this.props;
        const { selectionIsInvisible } = this.state;
        const {
            physicalIncomingRelations = [],
            physicalOutgoingRelations = [],
            logicalIncomingRelations = [],
            logicalOutgoingRelations = [],
        } = requirementRelations || {};
        const hasPhysicalRelations = Boolean(physicalIncomingRelations.length || physicalOutgoingRelations.length);
        const hasLogicalRelations = Boolean(logicalIncomingRelations.length || logicalOutgoingRelations.length);
        const hasRelations = Boolean(hasPhysicalRelations || hasLogicalRelations);

        return hasRelations ? (
            <Root ref={this.rootRef} onScroll={this.checkSecondarySelectionVisibility}>
                {Boolean(selectionIsInvisible) && <RevealSelectedRelation />}
                {hasPhysicalRelations && (
                    <div>
                        <ReactResizeDetector handleHeight onResize={this.checkSecondarySelectionVisibility} />
                        <Expander title="Fysiek object" startExpanded>
                            {this.renderMapRelations(physicalIncomingRelations, "incoming")}
                            {this.renderMapRelations(physicalOutgoingRelations, "outgoing")}
                        </Expander>
                    </div>
                )}
                {hasLogicalRelations && (
                    <div>
                        <ReactResizeDetector handleHeight onResize={this.checkSecondarySelectionVisibility} />
                        <Expander title="Logisch object" startExpanded>
                            {this.renderMapRelations(logicalIncomingRelations, "incoming")}
                            {this.renderMapRelations(logicalOutgoingRelations, "outgoing")}
                        </Expander>
                    </div>
                )}
            </Root>
        ) : (
            <Root>
                <MessageContainer>Geen relaties tot eisen gevonden.</MessageContainer>
            </Root>
        );
    }
}

const mapStateToProps = ({ objectRelationsReducer, selectionReducer, appReducer }) => ({
    requirementRelations: objectRelationsReducer.filteredRelations.requirementRelations,
    selectedLinkedId: selectionReducer.selectedLinkedId,
    shouldRevealSelectedRelation: appReducer.shouldRevealSelectedRelation,
});

const mapDispatchToProps = (dispatch) => ({
    setShouldRevealSelectedRelation: (shouldRevealSelectedRelation) => dispatch(setShouldRevealSelectedRelation(shouldRevealSelectedRelation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Requirements);
