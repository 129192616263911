import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import { PaneMode } from "../../../../context/SplitPaneContext/State";

const CreatebookmarkModal = ({ open, mode, onClose, onSave }) => {
    const [name, setName] = useState("");

    const renderInfo = () => {
        switch (mode) {
            case PaneMode.BIM:
                return (
                    <Typography variant="caption" component="span">
                        Deze bookmark zal de camera positie, selectie, sectie en ingeschakelde modellen van het actieve scherm bewaren. Het is nadien mogelijk
                        deze bookmark te beheren en delen met andere gebruikers via het zijpaneel.
                    </Typography>
                );
            case PaneMode.GIS:
                return (
                    <Typography variant="caption" component="span">
                        Deze bookmark zal de camera positie, volgorde van de lagen en de inschakelde lagen van het actieve scherm bewaren. Het is nadien
                        mogelijk deze bookmark te beheren en delen met andere gebruikers via het zijpaneel.
                    </Typography>
                );
            case PaneMode.DOCUMENT:
            default:
                throw new Error("Invalid PaneMode supplied:", mode);
        }
    };

    return (
        <Dialog open={open} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bookmark toevoegen</DialogTitle>
            <DialogContent>
                <DialogContentText>Voer een naam in voor de nieuwe bookmark:</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Bookmark Naam"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                {renderInfo()}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
                <Button onClick={() => onSave(name)} color="primary" variant="contained">
                    Toevoegen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreatebookmarkModal;
