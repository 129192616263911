import { useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Portal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    TableContainer,
    Toolbar,
    TextField,
    lighten,
    styled,
    useTheme,
    SxProps,
    Theme,
} from "@mui/material";
import { faChevronDown, faPencil, faPlus, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import { SketchPicker } from "react-color";

import CloseButton from "../../generic/CloseButton";
import DeleteButton from "../DeleteButton";
import InputButton from "../InputButton";
import { createFilter, createGroup, fetchFilters, modifyFilter, modifyGroup, removeFilter, removeGroup } from "../../../connectors/admin/filters";
import StyledTooltip from "../../generic/StyledTooltip";
import { generateUUID } from "../../../utils/guidFunctions";
import { configuration } from "../../../_configuration/configuration";

const AccordionContainer = styled("div")(() => ({
    overflowY: "auto",
    maxHeight: "calc(100% - 75px - 40px)",
    padding: "20px",
    maxWidth: "calc(100% + 20px)",
    margin: "0 -20px",
}));

export interface AdminDialogContentBIMFiltersProps {
    filters: any;
    onClose: () => void;
}

const DATA_TYPES = ["Tekst", "Ja/Neen", "Leeg"];

const AdminDialogContentBIMFilters = ({ filters, onClose }: AdminDialogContentBIMFiltersProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fetched, setFetched] = useState(false);

    const [bimFilters, setBimFilters] = useState<any[]>([]);

    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const [addNewGroup, setAddNewGroup] = useState<boolean>(false);
    const [newGroup, setNewGroup] = useState<any>({
        name: "",
        description: "",
        icon: "",
    });
    const [editGroup, setEditGroup] = useState<any>();

    const [addNewFilter, setAddNewFilter] = useState<boolean>(false);
    const [newFilter, setNewFilter] = useState<any>({
        groupId: "parent-id",
        parameters: [{ id: generateUUID(), value: "" }], // ["", ""]
        title: "",
        icon: "",
        isDefault: false,
        options: [
            {
                id: generateUUID(),
                valueType: DATA_TYPES[0],
                value: "",
                description: "",
                color: { r: 120, g: 120, b: 120, a: 1 },
            },
        ],
    });
    const [editFilter, setEditFilter] = useState<any>();
    const [shouldDelete, setShouldDelete] = useState<boolean>(false);

    useEffect(() => {
        fetchFilters().then(() => setFetched(true));
    }, []);

    useEffect(() => {
        if (!fetched) return;
        setBimFilters(filters.sort((a: any, b: any) => a.description.localeCompare(b.description)).map((x: any) => ({ ...x })));
        setIsLoading(false);
    }, [filters, fetched]);

    const handleGroupClick = (group: any) => {
        const selectedIndex = selectedGroups.indexOf(group.id);
        const selectedByProxy = areAllFiltersInGroupSelected(group);
        let newSelected: string[] = [];
        let isRemove = true;

        if (selectedIndex === -1 && !selectedByProxy) {
            newSelected = newSelected.concat(selectedGroups, group.id);
            isRemove = false;
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedGroups.slice(1));
        } else if (selectedIndex === selectedGroups.length - 1) {
            newSelected = newSelected.concat(selectedGroups.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedGroups.slice(0, selectedIndex), selectedGroups.slice(selectedIndex + 1));
        }

        handleGroupFilters(group, isRemove);
        setSelectedGroups(newSelected);
    };

    const handleGroupFilters = (group: any, isRemove: boolean) => {
        const filterIds = group.filters.map((x: any) => x.id);
        let newSelected = [...selectedFilters];

        for (let i = 0; i < filterIds.length; i++) {
            const filterId = filterIds[i];
            const notSelected = selectedFilters.indexOf(filterId) === -1;

            if (!notSelected && isRemove) {
                newSelected = newSelected.filter((x) => x !== filterId);
            } else if (notSelected && !isRemove) {
                newSelected.push(filterId);
            }
        }

        setSelectedFilters(newSelected);
    };

    const handleFilterClick = (filter: any) => {
        const selectedIndex = selectedFilters.indexOf(filter.id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedFilters, filter.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedFilters.slice(1));
        } else if (selectedIndex === selectedFilters.length - 1) {
            newSelected = newSelected.concat(selectedFilters.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedFilters.slice(0, selectedIndex), selectedFilters.slice(selectedIndex + 1));
        }

        handleFilterGroup(filter, newSelected);
        setSelectedFilters(newSelected);
    };

    const handleFilterGroup = (filter: any, newSelection: any[]) => {
        const group = bimFilters.find((x: any) => x.id === filter.groupId);
        const hasSelectedFilters = areSomeFiltersSelectedInGroup(group, newSelection) || areAllFiltersSelectedInGroup(group, newSelection);
        const selected = selectedGroups.indexOf(group.id) !== -1;

        if (selected === hasSelectedFilters) return;

        let newSelected = [...selectedGroups];

        if (selected && !hasSelectedFilters) {
            newSelected = newSelected.filter((x) => x !== group.id);
        } else {
            newSelected.push(group.id);
        }

        setSelectedGroups(newSelected);
    };

    const isGroupSelected = (id: string) => selectedGroups.indexOf(id) !== -1;

    const isFilterSelected = (id: string) => selectedFilters.indexOf(id) !== -1;

    const areAllFiltersInGroupSelected = (group: any) => {
        if (!group.filters) return false;

        for (let i = 0; i < group.filters.length; i++) {
            const filter = group.filters[i];
            if (selectedFilters.indexOf(filter.id) === -1) return false;
        }

        return group.filters.length > 0;
    };

    const areSomeFiltersInGroupSelected = (group: any) => {
        if (!group.filters) return false;

        let selected = 0;

        for (let i = 0; i < group.filters.length; i++) {
            const filter = group.filters[i];
            if (selectedFilters.indexOf(filter.id) === -1) continue;
            selected++;
        }

        return selected > 0 && selected < group.filters.length;
    };

    const areSomeFiltersSelectedInGroup = (group: any, selection: any[]) => {
        let selected = 0;

        for (let i = 0; i < group.filters.length; i++) {
            const filter = group.filters[i];
            if (selection.indexOf(filter.id) === -1) continue;
            selected++;
        }

        return selected > 0 && selected < group.filters.length;
    };

    const areAllFiltersSelectedInGroup = (group: any, selection: any[]) => {
        for (let i = 0; i < group.filters.length; i++) {
            const filter = group.filters[i];
            if (selection.indexOf(filter.id) === -1) return false;
        }

        return true;
    };

    const onAddGroupEntry = () => {
        setIsLoading(true);
        createGroup(newGroup).then(() => {
            setAddNewGroup(false);
            setNewGroup({
                name: "",
                description: "",
                icon: "",
            });
            setIsLoading(false);
        });
    };

    const onEditGroupEntry = () => {
        setIsLoading(true);
        modifyGroup(editGroup).then(() => {
            setEditGroup(null);
            setIsLoading(false);
        });
    };

    const onAddFilterEntry = () => {
        setIsLoading(true);
        const filter = { ...newFilter };
        filter.parameters = filter.parameters.map((x: any) => x.value);
        filter.options = filter.options.map((x: any) => {
            const option: any = {
                description: x.description,
                color: [x.color.r / 255, x.color.g / 255, x.color.b / 255, x.color.a],
            };

            if (x.valueType === "Tekst") {
                option.parameterStringValue = x.value;
            } else if (x.valueType === "Ja/Neen") {
                option.parameterBoolValue = x.value === "Ja" ? true : false;
            }

            return option;
        });

        createFilter(filter).then(() => {
            setAddNewFilter(false);
            setNewFilter({
                groupId: "parent-id",
                parameters: [{ id: generateUUID(), value: "" }], // ["", ""]
                title: "",
                icon: "",
                isDefault: false,
                options: [
                    {
                        id: generateUUID(),
                        valueType: DATA_TYPES[0],
                        value: "",
                        description: "",
                        color: { r: 120, g: 120, b: 120, a: 1 },
                    },
                ],
            });
            setIsLoading(false);
        });
    };

    const onEditFilterEntry = () => {
        setIsLoading(true);
        const filter = { ...editFilter };
        filter.parameters = filter.parameters.map((x: any) => x.value);
        filter.options = filter.options.map((x: any) => {
            const option: any = {
                description: x.description,
                color: [x.color.r / 255, x.color.g / 255, x.color.b / 255, x.color.a],
            };

            if (x.valueType === "Tekst") {
                option.parameterStringValue = x.value;
            } else if (x.valueType === "Ja/Neen") {
                option.parameterBoolValue = x.value === "Ja" ? true : false;
            }

            return option;
        });

        modifyFilter(filter).then(() => {
            setEditFilter(null);
            setIsLoading(false);
        });
    };

    const onDeleteEntry = () => {
        setShouldDelete(true);
    };

    const onCancelDeleteEntry = () => {
        setShouldDelete(false);
    };

    const onPerformDeleteEntry = () => {
        setIsLoading(true);
        setShouldDelete(false);

        removeFilters()
            .then(removeGroups)
            .then(() => {
                setSelectedFilters([]);
                setSelectedGroups([]);
                setIsLoading(false);
            });
    };

    const removeFilters = () => {
        const promises = [];
        for (let i = 0; i < selectedFilters.length; i++) {
            promises.push(removeFilter(selectedFilters[i]));
        }

        return Promise.all(promises);
    };

    const removeGroups = () => {
        const promises: Promise<void>[] = [];
        for (let i = 0; i < selectedGroups.length; i++) {
            const group = bimFilters.find((x) => x.id === selectedGroups[i]);
            const shouldDeleteGroup = areAllFiltersInGroupSelected(group);
            if (!group.filters || group.filters.length === 0 || shouldDeleteGroup) promises.push(removeGroup(selectedGroups[i]));
        }

        return Promise.all(promises);
    };

    const renderIcon = (icon: string) => {
        return (
            <Box
                sx={{
                    height: 20,
                    margin: 0.5,
                    position: "relative",
                    width: 20,
                    alignSelf: "center",
                }}
            >
                <Box
                    component={"img"}
                    src={`${configuration.assets}fa/light/${icon}.svg`}
                    alt="Filter icon"
                    sx={{
                        display: "inline-block",
                        fontSize: 24,
                        height: "100%",
                        lineHeight: 1,
                        position: "relative",
                        width: "100%",
                    }}
                />
            </Box>
        );
    };

    const addNewFilterParameter = () => {
        const updatedNewFilter = { ...newFilter };
        updatedNewFilter.parameters = [...updatedNewFilter.parameters, { id: generateUUID(), value: "" }];

        setNewFilter(updatedNewFilter);
    };

    const removeNewFilterParameter = (id: any) => {
        const updatedNewFilter = { ...newFilter };
        updatedNewFilter.parameters = updatedNewFilter.parameters.filter((x: any) => x.id !== id);

        if (updatedNewFilter.parameters.length === 0) {
            updatedNewFilter.parameters.push({ id: generateUUID(), value: "" });
        }

        setNewFilter(updatedNewFilter);
    };

    const updateNewFilterParameterEntry = (id: any, value: any) => {
        const updatedNewFilter = { ...newFilter };
        const option = updatedNewFilter.parameters.find((x: any) => x.id === id);
        option.value = value;

        setNewFilter(updatedNewFilter);
    };

    const addNewFilterOption = () => {
        const updatedNewFilter = { ...newFilter };
        updatedNewFilter.options = [
            ...updatedNewFilter.options,
            {
                id: generateUUID(),
                valueType: DATA_TYPES[0],
                value: "",
                description: "",
                color: { r: 120, g: 120, b: 120, a: 1 },
            },
        ];

        setNewFilter(updatedNewFilter);
    };

    const removeNewFilterOption = (id: any) => {
        const updatedNewFilter = { ...newFilter };
        updatedNewFilter.options = updatedNewFilter.options.filter((x: any) => x.id !== id);

        if (updatedNewFilter.options.length === 0) {
            updatedNewFilter.options.push({
                id: generateUUID(),
                valueType: DATA_TYPES[0],
                value: "",
                description: "",
                color: { r: 120, g: 120, b: 120, a: 1 },
            });
        }

        setNewFilter(updatedNewFilter);
    };

    const updateNewFilterOptionEntry = (id: any, value: any) => {
        const updatedNewFilter = { ...newFilter };
        const option = updatedNewFilter.options.find((x: any) => x.id === id);
        const keys = Object.keys(option);
        for (let i = 0; i < keys.length; i++) {
            option[keys[i]] = value[keys[i]];
        }

        setNewFilter(updatedNewFilter);
    };

    const addEditFilterParameter = () => {
        const updatedFilter = { ...editFilter };
        updatedFilter.parameters = [...updatedFilter.parameters, { id: generateUUID(), value: "" }];

        setEditFilter(updatedFilter);
    };

    const removeEditFilterParameter = (id: any) => {
        const updatedFilter = { ...editFilter };
        updatedFilter.parameters = updatedFilter.parameters.filter((x: any) => x.id !== id);

        if (updatedFilter.parameters.length === 0) {
            updatedFilter.parameters.push({ id: generateUUID(), value: "" });
        }

        setEditFilter(updatedFilter);
    };

    const updateEditFilterParameterEntry = (id: any, value: any) => {
        const updatedFilter = { ...editFilter };
        const option = updatedFilter.parameters.find((x: any) => x.id === id);
        option.value = value;

        setEditFilter(updatedFilter);
    };

    const addEditFilterOption = () => {
        const updatedFilter = { ...editFilter };
        updatedFilter.options = [
            ...updatedFilter.options,
            {
                id: generateUUID(),
                valueType: DATA_TYPES[0],
                value: "",
                description: "",
                color: { r: 120, g: 120, b: 120, a: 1 },
            },
        ];

        setEditFilter(updatedFilter);
    };

    const removeEditFilterOption = (id: any) => {
        const updatedFilter = { ...editFilter };
        updatedFilter.options = updatedFilter.options.filter((x: any) => x.id !== id);

        if (updatedFilter.options.length === 0) {
            updatedFilter.options.push({
                id: generateUUID(),
                valueType: DATA_TYPES[0],
                value: "",
                description: "",
                color: { r: 120, g: 120, b: 120, a: 1 },
            });
        }

        setEditFilter(updatedFilter);
    };

    const updateEditFilterOptionEntry = (id: any, value: any) => {
        const updatedFilter = { ...editFilter };
        const option = updatedFilter.options.find((x: any) => x.id === id);
        const keys = Object.keys(option);
        for (let i = 0; i < keys.length; i++) {
            option[keys[i]] = value[keys[i]];
        }

        setEditFilter(updatedFilter);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>BIM</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar selected={selectedGroups.concat(selectedFilters)} onAdd={() => setAddNewGroup(true)} onDelete={() => onDeleteEntry()} />
                        <AccordionContainer>
                            {bimFilters.map((group) => {
                                const isItemSelected = isGroupSelected(group.id);
                                const hasAllSelectedFilters = areAllFiltersInGroupSelected(group);
                                const hasSomeSelectedFilters = areSomeFiltersInGroupSelected(group);

                                return (
                                    <Accordion key={group.id}>
                                        <AccordionSummary
                                            expandIcon={<FontAwesomeSvgIcon icon={faChevronDown} />}
                                            sx={{
                                                display: "flex",
                                                "&>div": {
                                                    alignItems: "center",
                                                },
                                            }}
                                        >
                                            <FormControlLabel
                                                aria-label="Filter Groep"
                                                onFocus={(e) => e.stopPropagation()}
                                                onMouseUp={(e) => e.stopPropagation()}
                                                control={
                                                    <Checkbox
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleGroupClick(group);
                                                        }}
                                                        checked={isItemSelected || hasAllSelectedFilters}
                                                        indeterminate={!hasAllSelectedFilters && hasSomeSelectedFilters}
                                                    />
                                                }
                                                label={
                                                    <span style={{ display: "flex", alignItems: "center" }}>
                                                        {renderIcon(group.icon)}
                                                        <span style={{ marginLeft: 10 }}>{group.description}</span>
                                                    </span>
                                                }
                                            />
                                            <div style={{ marginLeft: "auto" }}>
                                                <InputButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setNewFilter({ ...newFilter, groupId: group.id });
                                                        setAddNewFilter(true);
                                                    }}
                                                >
                                                    <FontAwesomeSvgIcon icon={faPlus} style={{ fontSize: "1rem" }} />
                                                </InputButton>
                                                <InputButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setEditGroup({ ...group });
                                                    }}
                                                    sx={{ mr: 2 }}
                                                >
                                                    <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                </InputButton>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                                            }}
                                        >
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell padding="checkbox"></TableCell>
                                                            <TableCell>Icoon</TableCell>
                                                            <TableCell width={"100%"}>Naam</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {group.filters && group.filters.length > 0 ? (
                                                        <TableBody>
                                                            {group.filters
                                                                .sort((a: any, b: any) => a.title.localeCompare(b.title))
                                                                .map((filter: any) => {
                                                                    const isItemSelected = isFilterSelected(filter.id);

                                                                    return (
                                                                        <TableRow key={filter.id} hover onClick={() => handleFilterClick(filter)}>
                                                                            <TableCell padding="checkbox">
                                                                                <Checkbox checked={isItemSelected} />
                                                                            </TableCell>
                                                                            <TableCell>{renderIcon(filter.icon)}</TableCell>
                                                                            <TableCell>{filter.title}</TableCell>
                                                                            <TableCell>
                                                                                <InputButton
                                                                                    size="small"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        const editFilter = { ...filter };
                                                                                        editFilter.parameters = editFilter.parameters.map((param: any) => ({
                                                                                            id: generateUUID(),
                                                                                            value: param,
                                                                                        }));
                                                                                        editFilter.options = editFilter.options.map((option: any) => {
                                                                                            const mapped: any = {
                                                                                                id: generateUUID(),
                                                                                                description: option.description,
                                                                                                color: {
                                                                                                    r: option.color[0] * 255,
                                                                                                    g: option.color[1] * 255,
                                                                                                    b: option.color[2] * 255,
                                                                                                    a: option.color[3],
                                                                                                },
                                                                                                valueType: "Leeg",
                                                                                            };

                                                                                            if (
                                                                                                (option.parameterStringValue === null ||
                                                                                                    option.parameterStringValue === undefined) &&
                                                                                                (option.parameterBoolValue === null ||
                                                                                                    option.parameterBoolValue === undefined)
                                                                                            ) {
                                                                                                mapped.valueType = "Leeg";
                                                                                                mapped.value = "";
                                                                                            } else {
                                                                                                if (
                                                                                                    option.parameterStringValue !== null &&
                                                                                                    option.parameterStringValue !== undefined
                                                                                                ) {
                                                                                                    mapped.valueType = "Tekst";
                                                                                                    mapped.value = option.parameterStringValue;
                                                                                                } else if (
                                                                                                    option.parameterBoolValue !== null &&
                                                                                                    option.parameterBoolValue !== undefined
                                                                                                ) {
                                                                                                    mapped.valueType = "Ja/Neen";
                                                                                                    mapped.value = option.parameterBoolValue ? "Ja" : "Neen";
                                                                                                }
                                                                                            }

                                                                                            return mapped;
                                                                                        });

                                                                                        setEditFilter(editFilter);
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                                                </InputButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                        </TableBody>
                                                    ) : (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell colSpan={4}>Deze groep bevat nog geen filters.</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </AccordionContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>

                    {addNewGroup && (
                        <Dialog open={addNewGroup} onClose={() => setAddNewGroup(false)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                            <DialogTitle id="form-dialog-title">Nieuwe groepering toevoegen</DialogTitle>
                            <DialogContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Naam"
                                            value={newGroup.description}
                                            onChange={(e) => setNewGroup({ ...newGroup, description: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Icoon"
                                            value={newGroup.icon}
                                            onChange={(e) => setNewGroup({ ...newGroup, icon: e.currentTarget.value })}
                                        />
                                    </FormControl>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setAddNewGroup(false)} color="secondary" variant="outlined">
                                    Annuleren
                                </Button>
                                <Button onClick={() => onAddGroupEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                    Toevoegen
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {Boolean(editGroup) && (
                        <Dialog open={Boolean(editGroup)} onClose={() => setEditGroup(null)} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
                            <DialogTitle id="form-dialog-title">Groep aanpassen</DialogTitle>
                            <DialogContent>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Naam"
                                            value={editGroup.description}
                                            onChange={(e) => setEditGroup({ ...editGroup, description: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Icoon"
                                            value={editGroup.icon}
                                            onChange={(e) => setEditGroup({ ...editGroup, icon: e.currentTarget.value })}
                                        />
                                    </FormControl>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setEditGroup(null)} color="secondary" variant="outlined">
                                    Annuleren
                                </Button>
                                <Button onClick={() => onEditGroupEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                    Aanpassen
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {addNewFilter && (
                        <Dialog open={addNewFilter} onClose={() => setAddNewFilter(false)} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth>
                            <DialogTitle id="form-dialog-title">Nieuwe filter toevoegen</DialogTitle>
                            <DialogContent style={{ maxHeight: "60vh" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Naam"
                                            value={newFilter.title}
                                            onChange={(e) => setNewFilter({ ...newFilter, title: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Icoon"
                                            value={newFilter.icon}
                                            onChange={(e) => setNewFilter({ ...newFilter, icon: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    {newFilter.parameters.map((parameter: any, index: number) => (
                                        <ParameterEntry
                                            key={parameter.id}
                                            index={index}
                                            value={parameter.value}
                                            onAdd={() => addNewFilterParameter()}
                                            onDelete={() => removeNewFilterParameter(parameter.id)}
                                            onChange={(value: string) => updateNewFilterParameterEntry(parameter.id, value)}
                                            count={newFilter.parameters.length}
                                        />
                                    ))}

                                    {newFilter.options.map((option: any, index: number) => (
                                        <OptionEntry
                                            key={option.id}
                                            index={index}
                                            value={option}
                                            onAdd={() => addNewFilterOption()}
                                            onDelete={() => removeNewFilterOption(option.id)}
                                            onChange={(value: any) => updateNewFilterOptionEntry(option.id, value)}
                                            count={newFilter.options.length}
                                        />
                                    ))}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setAddNewFilter(false)} color="secondary" variant="outlined">
                                    Annuleren
                                </Button>
                                <Button onClick={() => onAddFilterEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                    Toevoegen
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {Boolean(editFilter) && (
                        <Dialog open={Boolean(editFilter)} onClose={() => setEditFilter(null)} aria-labelledby="form-dialog-title" maxWidth="lg" fullWidth>
                            <DialogTitle id="form-dialog-title">Filter aanpassen</DialogTitle>
                            <DialogContent style={{ maxHeight: "60vh" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Naam"
                                            value={editFilter.title}
                                            onChange={(e) => setEditFilter({ ...editFilter, title: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    <FormControl
                                        sx={{
                                            margin: "10px 0",
                                            minWidth: 120,
                                        }}
                                    >
                                        <TextField
                                            label="Icoon"
                                            value={editFilter.icon}
                                            onChange={(e) => setEditFilter({ ...editFilter, icon: e.currentTarget.value })}
                                        />
                                    </FormControl>

                                    {editFilter.parameters.map((parameter: any, index: number) => (
                                        <ParameterEntry
                                            key={parameter.id}
                                            index={index}
                                            value={parameter.value}
                                            onAdd={() => addEditFilterParameter()}
                                            onDelete={() => removeEditFilterParameter(parameter.id)}
                                            onChange={(value: string) => updateEditFilterParameterEntry(parameter.id, value)}
                                            count={editFilter.parameters.length}
                                        />
                                    ))}

                                    {editFilter.options.map((option: any, index: number) => (
                                        <OptionEntry
                                            key={option.id}
                                            index={index}
                                            value={option}
                                            onAdd={() => addEditFilterOption()}
                                            onDelete={() => removeEditFilterOption(option.id)}
                                            onChange={(value: any) => updateEditFilterOptionEntry(option.id, value)}
                                            count={editFilter.options.length}
                                        />
                                    ))}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setEditFilter(false)} color="secondary" variant="outlined">
                                    Annuleren
                                </Button>
                                <Button onClick={() => onEditFilterEntry()} color="primary" variant="contained" disabled={"a".length === 0}>
                                    Aanpassen
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}

                    {Boolean(shouldDelete) && (
                        <Dialog open={Boolean(shouldDelete)} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Groep(pen) en/of filter(s) verwijderen</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    U staat op het punt de geselecteerde groep(pen) en/of filter(s) te verwijderen. Deze actie kan niet ongedaan gemaakt worden.
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => onCancelDeleteEntry()} color="secondary" variant="outlined">
                                    Annuleren
                                </Button>
                                <DeleteButton onClick={() => onPerformDeleteEntry()} color="primary" variant="contained">
                                    Verwijderen
                                </DeleteButton>
                            </DialogActions>
                        </Dialog>
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    filters: appReducer.filters,
});

export default connect(mapStateToProps)(AdminDialogContentBIMFilters);

interface TableToolbarProps {
    selected: string[];
    onAdd: () => void;
    onDelete: () => void;
}

const TableToolbar = ({ selected, onAdd, onDelete }: TableToolbarProps) => {
    const theme = useTheme();

    let style: SxProps<Theme> = {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };

    if (selected.length > 0) style = { ...style, color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) };

    return (
        <Toolbar sx={style}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                Filters
            </Typography>
            {selected.length > 0 && (
                <Typography sx={{ whiteSpace: "nowrap", marginRight: "20px", fontWeight: 500 }} color="inherit" variant="button" component="div">
                    {selected.length} geselecteerd
                </Typography>
            )}

            {selected.length > 0 ? (
                <StyledTooltip title="Geselecteerde groep(pen) en/of filter(s) verwijderen">
                    <IconButton aria-label="verwijderen" onClick={onDelete}>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </IconButton>
                </StyledTooltip>
            ) : (
                <StyledTooltip title="Nieuwe groepering toevoegen">
                    <IconButton aria-label="nieuw" onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                </StyledTooltip>
            )}
        </Toolbar>
    );
};

interface ParameterEntryProps {
    index: number;
    count: number;
    value: string;
    onChange: (parameter: string) => void;
    onAdd: () => void;
    onDelete: () => void;
}

const ParameterEntry = ({ count, index, value, onChange, onAdd, onDelete }: ParameterEntryProps) => {
    return (
        <div style={{ display: "flex" }}>
            <div style={{ margin: 5 }}>
                <FormControl
                    sx={{
                        margin: "10px 0",
                        minWidth: 120,
                    }}
                >
                    <TextField label="Parameter" value={value} onChange={(e) => onChange(e.currentTarget.value)} />
                </FormControl>
            </div>

            <div style={{ margin: 5, marginTop: 15, width: 110 }}>
                <IconButton onClick={onDelete}>
                    <FontAwesomeSvgIcon icon={faTrash} />
                </IconButton>
                {index + 1 === count && (
                    <IconButton style={{ marginLeft: 10 }} onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

interface OptionEntryProps {
    index: number;
    count: number;
    value: any;
    onChange: (parameter: string) => void;
    onAdd: () => void;
    onDelete: () => void;
}

const OPTIONS = ["Ja", "Neen"];

const OptionEntry = ({ count, index, value, onChange, onAdd, onDelete }: OptionEntryProps) => {
    const handleTypeChange = (type: string) => {
        if (type === "Tekst") {
            onChange({ ...value, valueType: type });
        } else if (type === "Ja/Neen") {
            onChange({ ...value, valueType: type, value: "Ja" });
        } else if (type === "Leeg") {
            onChange({ ...value, valueType: type, value: "<Empty>" });
        }
    };

    return (
        <div style={{ display: "flex" }}>
            <div style={{ margin: 5 }}>
                <FormControl>
                    <InputLabel id="type-select-label">Data Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={value.valueType}
                        onChange={(e) => handleTypeChange(e.target.value as string)}
                        style={{ width: 150 }}
                    >
                        {DATA_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {value.valueType === "Tekst" && (
                <div style={{ margin: 5 }}>
                    <FormControl>
                        <TextField label="Waarde" value={value.value} onChange={(e) => onChange({ ...value, value: e.currentTarget.value })} />
                    </FormControl>
                </div>
            )}

            {value.valueType === "Ja/Neen" && (
                <div style={{ margin: 5 }}>
                    <FormControl>
                        <InputLabel id="value-select-label">Waarde</InputLabel>
                        <Select
                            labelId="value-select-label"
                            id="value-select"
                            value={value.value}
                            onChange={(e) => onChange({ ...value, value: e.target.value as string })}
                            style={{ width: 150 }}
                        >
                            {OPTIONS.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}

            {value.valueType === "Leeg" && (
                <div style={{ margin: 5 }}>
                    <FormControl>
                        <TextField label="Waarde" value={""} disabled />
                    </FormControl>
                </div>
            )}

            <div style={{ margin: 5 }}>
                <FormControl>
                    <TextField label="Beschrijving" value={value.description} onChange={(e) => onChange({ ...value, description: e.currentTarget.value })} />
                </FormControl>
            </div>

            <div style={{ margin: 5, alignSelf: "center" }}>
                <FormControl>
                    <ColorPicker color={value.color} onChange={(color) => onChange({ ...value, color: color.rgb })} />
                </FormControl>
            </div>

            <div style={{ margin: 5, marginTop: 15, width: 110 }}>
                <IconButton onClick={onDelete}>
                    <FontAwesomeSvgIcon icon={faTrash} />
                </IconButton>
                {index + 1 === count && (
                    <IconButton style={{ marginLeft: 10 }} onClick={onAdd}>
                        <FontAwesomeSvgIcon icon={faPlus} />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

interface ColorPickerProps {
    color: any;
    onChange: (color: any) => void;
}

const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
    const background = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

    return (
        <div>
            <Box
                sx={{
                    padding: "5px",
                    background: "#fff",
                    borderRadius: "1px",
                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                    display: "inline-block",
                    cursor: "pointer",
                }}
                onClick={(e) => {
                    setVisible((x) => !x);
                    setPosition({ left: e.clientX, top: e.clientY });
                }}
            >
                <Box
                    sx={{
                        width: "36px",
                        height: "14px",
                        borderRadius: "2px",
                        background: background,
                    }}
                />
            </Box>
            {visible ? (
                <Portal>
                    <Box sx={{ position: "absolute", zIndex: 9999 }} style={{ ...position }}>
                        <Box
                            sx={{
                                position: "fixed",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                            }}
                            onClick={() => setVisible(false)}
                        />
                        <SketchPicker color={color} onChange={onChange} />
                    </Box>
                </Portal>
            ) : null}
        </div>
    );
};
