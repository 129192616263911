const defaultState = {
    objects: {},
    viewerProperties: {},
};

const propertiesReducer = (state = defaultState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "REQUEST_OBJECT_PROPERTIES": {
            newState.objects = Object.assign({}, newState.objects);
            newState.objects[action.objectId] = newState.objects[action.objectId] || {};
            newState.objects[action.objectId].status = "fetching";
            return newState;
        }

        case "RECEIVE_OBJECT_PROPERTIES": {
            newState.objects = Object.assign({}, newState.objects);
            newState.objects[action.objectId] = newState.objects[action.objectId] || {};
            newState.objects[action.objectId].status = "fetched";
            newState.objects[action.objectId].properties = action.properties || {};
            if (action.error) {
                newState.objects[action.objectId].error = action.error;
            }
            return newState;
        }

        case "SET_VIEWER_PROPERTIES": {
            newState.viewerProperties = {
                source: action.source,
                objectId: action.objectId,
                properties: action.viewerProperties,
            };
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default propertiesReducer;
