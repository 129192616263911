const createDefaultState = () => ({
    authentication: null,
    account: null,
    state: {},
    tipsAndUpdates: {},
});

const reducer = (state = createDefaultState(), action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case "SET_AUTHENTICATION": {
            newState.authentication = action.authentication;
            return newState;
        }

        case "REMOVE_AUTHENTICATION": {
            return createDefaultState();
        }

        case "SET_ACCOUNT": {
            newState.account = action.account;
            return newState;
        }

        case "SET_STATE": {
            newState.state = action.state;
            return newState;
        }

        default: {
            return state;
        }
    }
};

export default reducer;
