import store from "../redux/store";
import { receiveForgeDocuments } from "../redux/forge/actions";
import { receiveForgeDocuments as receivePdfForgeDocuments } from "../redux/pdf/actions";

export function fetchForgeDocuments() {
    const storedDocuments = store.getState().forgeReducer.documents;
    if (storedDocuments && storedDocuments.length > 0) {
        return JSON.parse(JSON.stringify(storedDocuments));
    }

    const availableFiles = store.getState().projectReducer.resources.Bim360 ?? [];
    const documents = structureAvailableFiles(availableFiles);

    store.dispatch(receiveForgeDocuments(documents));
    return JSON.parse(JSON.stringify(documents));
}

export function fetchForgePdfDocuments() {
    const storedDocuments = store.getState().pdfReducer.documents;
    if (storedDocuments && storedDocuments.length > 0) {
        return JSON.parse(JSON.stringify(storedDocuments));
    }

    const availableFiles = store.getState().projectReducer.resources.Bim360Pdf ?? [];
    const documents = structureAvailableFiles(availableFiles);

    store.dispatch(receivePdfForgeDocuments(documents));
    return JSON.parse(JSON.stringify(documents));
}

export const structureAvailableFiles = (files) => {
    const result = {
        items: [],
    };

    let folderId = 1;
    const itemsWithoutFolder = {
        folder: {
            attributes: {
                displayName: "generated-root-folder",
                fullPath: "",
            },
            id: folderId++,
            type: "folder",
        },
        items: [],
    };

    for (let i = 0, len = files.length; i < len; i++) {
        const fileInfo = files[i];
        const parts = fileInfo.name.split("/");
        const filename = parts[parts.length - 1];

        let parent = result;
        for (let j = 0, len = parts.length - 1; j < len; j++) {
            const part = parts[j];

            let folder = parent.items.find((item) => item.folder && item.folder.attributes.displayName === part);
            if (!folder) {
                folder = {
                    folder: {
                        attributes: {
                            displayName: part,
                            fullPath: Boolean(parent.folder?.attributes?.fullPath) ? [parent.folder?.attributes?.fullPath, part].join("/") : part,
                        },
                        id: folderId++,
                        type: "folder",
                    },
                    items: [],
                };
                parent.items.push(folder);
            }

            parent = folder;
        }

        if (!parent.folder) parent = itemsWithoutFolder;

        let visible = false;
        parent.items.push({
            attributes: {
                displayName: filename,
                description: fileInfo.displayName,
                modificationDate: fileInfo.modificationDate,
            },
            id: fileInfo.urn,
            folderId: parent.folder.id,
            relationships: {
                derivatives: {
                    data: {
                        id: fileInfo.derivative,
                    },
                },
            },
            type: "versions",
            visible,
        });
    }

    if (itemsWithoutFolder.items.length > 0) result.items.push(itemsWithoutFolder);

    return result.items;
};
