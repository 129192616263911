export class Version {
    private major: number;
    private minor: number;
    private revision: number;

    public get Major(): number {
        return this.major;
    }

    public get Minor(): number {
        return this.minor;
    }

    public get Revision(): number {
        return this.revision;
    }

    constructor(major: number, minor: number, revision: number) {
        this.major = major;
        this.minor = minor;
        this.revision = revision;
    }

    public toString = (): string => `versie ${this.major}.${this.minor}.${this.revision}`;

    public toShortString = (): string => `${this.major}.${this.minor}.${this.revision}`;

    public isEqual = (target: Version): boolean => {
        if (this.major !== target.Major) return false;
        if (this.minor !== target.Minor) return false;
        if (this.revision !== target.Revision) return false;
        return true;
    };
}
