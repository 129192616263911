import { configuration } from "../../_configuration/configuration";

const Autodesk = window.Autodesk;

class ColorFilterExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);

        this.viewer = viewer;
        this.options = options;
    }

    setColor = (model, dbIds, color) => {
        this.getLeafNodes(model, dbIds).then((leafIds) => {
            leafIds.forEach((dbId) => {
                model.setThemingColor(dbId, color);
            });

            this.viewer.impl.invalidate(true);
        });
    };

    getLeafNodes = (model, dbIds) =>
        new Promise((resolve, reject) => {
            try {
                const instanceTree = model.getData().instanceTree;
                const leafIds = [];

                const getLeafNodesRecursive = (id) => {
                    let childCount = 0;

                    instanceTree.enumNodeChildren(id, (childId) => {
                        getLeafNodesRecursive(childId);
                        childCount++;
                    });

                    if (childCount === 0) {
                        leafIds.push(id);
                    }
                };

                for (let i = 0; i < dbIds.length; i++) {
                    getLeafNodesRecursive(dbIds[i]);
                }

                return resolve(leafIds);
            } catch (ex) {
                return reject(ex);
            }
        });

    setupCss = () => {
        const { data, id } = this.options;
        let styleElement = document.getElementById(`p${id}color-filter-extension-styles`);

        if (styleElement) return;

        let css = "";
        for (let i = 0; i < data.length; i++) {
            const group = data[i];
            group.className = `p${id}-cfes-group-${i}`;

            css += `
.${group.className} {
    background-color: #ffffff;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url(${configuration.assets}fa/light/${group.icon}.svg);
    mask-image: url(${configuration.assets}fa/light/${group.icon}.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.active > .${group.className},
.inactive:hover > .${group.className} {
    background-color: #00bfff;
}
`;

            for (let j = 0; j < group.filters.length; j++) {
                const filter = group.filters[j];
                filter.className = `p${id}-cfes-group-${i}-filter-${j}`;

                css += `
.${filter.className} {
    background-color: #ffffff;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url(${configuration.assets}fa/light/${filter.icon}.svg);
    mask-image: url(${configuration.assets}fa/light/${filter.icon}.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.active > .${filter.className},
.inactive:hover > .${filter.className} {
    background-color: #00bfff;
}
`;
            }
        }

        styleElement = document.createElement("style");
        styleElement.id = `p${id}-color-filter-extension-styles`;
        styleElement.type = "text/css";
        styleElement.innerHTML = css;

        document.getElementsByTagName("head")[0].appendChild(styleElement);
    };

    load = () => {
        Autodesk.Viewing.Viewer3D.prototype.setColorMaterial = this.setColor;
        this.setupCss();

        return true;
    };

    unload() {
        Autodesk.Viewing.Viewer3D.prototype.setColorMaterial = null;

        const { id } = this.options;
        const styles = document.getElementById(`p${id}-color-filter-extension-styles`);
        if (styles) {
            document.getElementsByTagName("head")[0].removeChild(styles);
        }

        return true;
    }

    onToolbarCreated(toolbar) {
        const { data, callback, id } = this.options;
        const cfesToolbar = new Autodesk.Viewing.UI.RadioButtonGroup(`p${id}-cfes-toolbar`);

        this.active = null;
        this.buttons = [];

        const hasActive = data.some((x) => x.filters.some((y) => y.isActive));

        for (let i = 0; i < data.length; i++) {
            const group = data[i];
            if (!group.filters || group.filters.length === 0) continue;

            // Create main group button
            const groupButton = new Autodesk.Viewing.UI.ComboButton(group.className);
            groupButton.setToolTip(group.description ?? "Geen tooltip beschikbaar");
            groupButton.setIcon(group.className);

            // Add reference to the button on the group object
            group.button = groupButton;

            // Handle filters for group
            for (let j = 0; j < group.filters.length; j++) {
                const filter = group.filters[j];

                // Create button for filter
                const filterButton = new Autodesk.Viewing.UI.Button(filter.className);
                filterButton.setToolTip(filter.title);
                filterButton.setIcon(filter.className);

                // Add reference to the button on the filter object
                filter.button = filterButton;

                // Handle filter click event.
                filterButton.onClick = (e) => {
                    const parentGroup = data.find((x) => x.id === filter.groupId);

                    if (filter.isActive) {
                        // Reset filter button
                        filter.button.addClass("inactive");
                        filter.button.removeClass("active");
                        filter.isActive = false;

                        // Reset parent button
                        parentGroup.button.setIcon(parentGroup.className);
                        parentGroup.button.addClass("inactive");
                        parentGroup.button.removeClass("active");

                        // Trigger callback to remove coloring
                        callback();
                    } else {
                        // Find active filter
                        const activeFilter = data
                            .map((group) => group.filters)
                            .reduce((acc, filter) => acc.concat(filter), [])
                            .find((filter) => filter.isActive);

                        // Reset related active filter buttons if another filter was previously active
                        if (activeFilter) {
                            // Reset previous filter button
                            activeFilter.isActive = false;
                            activeFilter.button.addClass("inactive");
                            activeFilter.button.removeClass("active");

                            // Check if the new filter is in the same group, if not, that group icon needs to be reset too
                            if (activeFilter.group !== parentGroup.name) {
                                const activeParentGroup = data.find((x) => x.id === activeFilter.groupId);
                                activeParentGroup.button.setIcon(activeParentGroup.className);
                                activeParentGroup.button.addClass("inactive");
                                activeParentGroup.button.removeClass("active");
                            }
                        }

                        // Set current filter button active
                        filter.button.addClass("active");
                        filter.button.removeClass("inactive");

                        // Update parent button to show filter icon
                        parentGroup.button.setIcon(filter.className);
                        parentGroup.button.addClass("active");
                        parentGroup.button.removeClass("inactive");

                        // Mark filter as active
                        filter.isActive = true;

                        // Trigger callback to initiate coloring
                        callback(filter);
                    }
                };

                // Set filter active if it is set to default and if nothing is set to active
                if (!hasActive) filter.isActive = filter.isDefault;

                if (filter.isActive) {
                    // Update filter button state
                    filter.button.addClass("active");
                    filter.button.removeClass("inactive");

                    // Update filter parent button state
                    group.button.setIcon(filter.className);
                    group.button.addClass("active");
                    group.button.removeClass("inactive");
                }

                groupButton.addControl(filterButton);
            }

            // Add main group button to the viewer
            cfesToolbar.addControl(groupButton);
        }

        toolbar.addControl(cfesToolbar);
    }
}

export default ColorFilterExtension;
