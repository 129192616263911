import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-light-svg-icons";
import { faCube as faCubeSolid } from "@fortawesome/pro-solid-svg-icons";

const ModelsIcon = ({ selected }) => {
    const theme = useTheme();

    return selected ? (
        <FontAwesomeIcon
            icon={faCubeSolid}
            style={{
                color: theme.colors.white,
            }}
        />
    ) : (
        <FontAwesomeIcon
            icon={faCube}
            style={{
                color: theme.colors.black,
            }}
        />
    );
};

export default ModelsIcon;
