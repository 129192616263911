import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Box, CircularProgress, styled } from "@mui/material";
import ObjectName from "../../../../../generic/ObjectName";
import { setSelectedLinkedId } from "../../../../../../redux/selection/actions";
import PhysicalObjectTreeIcon from "../../../../../svg/selectionTabs/PhysicalObjectTreeIcon";
import ArrowUp from "../../../../../svg/selectionTabs/relationsTab/ArrowUp";
import ArrowDown from "../../../../../svg/selectionTabs/relationsTab/ArrowDown";
import SelectObject from "../SelectObject";
import FavoriteToggle from "../../../../../generic/FavoriteToggle";
import { AppContext } from "../../../../../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../../../../../context/SplitPaneContext/State";

import StyledTooltip from "../../../../../generic/StyledTooltip";

import theme from "../../../../../../themes/default";
import { configuration } from "../../../../../../_configuration/configuration";

const Root = styled(Box)(() => ({
    height: "100%",
    width: "100%",
    overflowY: "auto",
}));

const Container = styled(Box)(({ theme }) => ({
    padding: "12px",
    fontFamily: theme.fonts.openSans.fontFamily,
    color: theme.colors.textColor,
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
    minHeight: "50px",
    "&:hover": {
        backgroundColor: theme.colors.secondarySelectionHover,
    },
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingRight: "7px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.bold,
    color: theme.colors.placeholderText,
    fontSize: "10px",
}));

const NameContainer = styled(Box)(({ theme }) => ({
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "14px",
    flex: 1,
}));

const ActionsContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const FavoriteIconContainer = styled(Box)(() => ({
    marginLeft: "12px",
}));

const RelativeCounterContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const RelativeCounter = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.regular,
    fontSize: "12px",
    "&>svg": {
        marginRight: "4px",
    },
    "&>svg>.a": {
        fill: "red",
    },
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontSize: "14px",
    color: theme.colors.placeholderText,
    userSelect: "none",
    height: "100%",
    display: "flex",
}));

class PhysicalObjectTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMadeParentsHidden: false,
            areParentsHidden: false,
            hoveredIndex: 0,
        };
    }

    selectLinkedSelectedObject = (objectId) => {
        const { selectedObject, onSetSelectedLinkedId } = this.props;

        onSetSelectedLinkedId(objectId, selectedObject.source);
    };

    toggleHoveredIndex = (hoveredIndex) => {
        this.setState({ hoveredIndex });
    };

    renderBranch = (objectId, index, totalParents, totalChildren) => {
        const { selectedLinkedId } = this.props;
        const { hoveredIndex } = this.state;
        const { activePane, panesState } = this.context.state;
        const paneMode = panesState[activePane - 1]?.mode;
        const mode = paneMode === PaneMode.GIS ? configuration.sources.gis : configuration.sources.bim;

        let style = {};
        let ref;
        if (index === 0) {
            style = {
                color: theme.colors.primaryText,
                backgroundColor: theme.colors.btnActive,
                fontWeight: theme.fonts.openSans.semiBold,
                "&:hover": {
                    backgroundColor: theme.colors.btnActive,
                },
            };
            ref = (node) => (this.selfContainer = node);
        } else if (index < 0) {
            style = { paddingLeft: "27px" };
        }

        if (selectedLinkedId === objectId) {
            style = { ...style, border: `1px solid ${theme.colors.secondarySelection}` };
        }

        const isHovering = hoveredIndex === index;

        const arrowIcon = index > 0 ? <ArrowUp /> : index < 0 ? <ArrowDown /> : null;

        const toolTipValue = Boolean(index > 0)
            ? `Bovenliggend fysiek object (graad ${String(index)})`
            : Boolean(index < 0)
            ? `Onderliggend fysiek object`
            : "Huidig fysiek object";

        return (
            <Container
                sx={style}
                ref={ref}
                onClick={() => this.selectLinkedSelectedObject(index === 0 ? null : objectId)}
                onMouseEnter={() => this.toggleHoveredIndex(index)}
                onMouseLeave={() => this.toggleHoveredIndex()}
            >
                <StyledTooltip title={toolTipValue}>
                    <IconContainer>
                        <PhysicalObjectTreeIcon />
                        {arrowIcon}
                        {Boolean(index > 0) ? <Fragment>{index}</Fragment> : null}
                    </IconContainer>
                </StyledTooltip>

                <NameContainer>
                    <ObjectName objectId={objectId} shouldGetObjectName shouldCheckLinkedObject />
                </NameContainer>
                <ActionsContainer>
                    {Boolean(index === 0) && (
                        <RelativeCounterContainer>
                            <RelativeCounter>
                                <ArrowUp />
                                {totalParents}
                            </RelativeCounter>
                            <RelativeCounter>
                                <ArrowDown />
                                {totalChildren}
                            </RelativeCounter>
                        </RelativeCounterContainer>
                    )}
                    {Boolean(index !== 0 && isHovering) && <SelectObject objectId={objectId} source={mode} isPhysicalObject />}
                    {Boolean(index !== 0) && (
                        <FavoriteIconContainer>
                            <FavoriteToggle objectId={objectId} source={mode} isPhysicalObject isHidden={!isHovering} />
                        </FavoriteIconContainer>
                    )}
                </ActionsContainer>
            </Container>
        );
    };

    renderTree = () => {
        const { selectedObject, physicalObjectTrees } = this.props;

        if (!selectedObject) {
            return null;
        }

        const { objectId } = selectedObject;
        const { status, physicalObjectTree } = physicalObjectTrees[objectId] || {};

        if (!status) {
            return (
                <Root>
                    <MessageContainer>Geen fysieke boomstructuur beschikbaar.</MessageContainer>
                </Root>
            );
        }

        if (status !== "fetched") {
            return (
                <Root>
                    <MessageContainer>
                        <CircularProgress />
                    </MessageContainer>
                </Root>
            );
        }

        if (!physicalObjectTree) {
            return (
                <Root>
                    <MessageContainer>Geen fysieke boomstructuur beschikbaar.</MessageContainer>
                </Root>
            );
        }

        const totalParents = physicalObjectTree.parents.length;
        const totalChildren = physicalObjectTree.children.length;

        return (
            <Fragment>
                {physicalObjectTree.parents.map((objectId, index) => {
                    return <Fragment key={index}>{this.renderBranch(objectId, physicalObjectTree.parents.length - index)}</Fragment>;
                })}
                {this.renderBranch(objectId, 0, totalParents, totalChildren)}
                {physicalObjectTree.children.map((objectId, index) => {
                    return <Fragment key={index}>{this.renderBranch(objectId, -1 - index)}</Fragment>;
                })}
            </Fragment>
        );
    };

    render() {
        return <Root ref={(node) => (this.objectTreeContainer = node)}>{this.renderTree()}</Root>;
    }
}

PhysicalObjectTree.contextType = AppContext;

const mapStateToProps = ({ selectionReducer, objectRelationsReducer }) => ({
    selectedLinkedId: selectionReducer.selectedLinkedId,
    selectedObject: selectionReducer.selectedObject,
    physicalObjectTrees: objectRelationsReducer.physicalObjectTrees,
    objectRelations: objectRelationsReducer.objects,
});
const mapDispatchToProps = (dispatch) => ({
    onSetSelectedLinkedId: (objectId, source) => dispatch(setSelectedLinkedId(objectId, source)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalObjectTree);
