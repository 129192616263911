import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#1F293C",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#1F293C",
    },
    [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
        marginTop: "5px !important",
    },
    [`& .${tooltipClasses.tooltipPlacementRight}`]: {
        marginLeft: "5px !important",
    },
}));

export default StyledTooltip;
