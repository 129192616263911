import { Box, useTheme } from "@mui/material";

const ForwardIcon = ({ selected }) => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13.048" viewBox="0 0 8 13.048">
            <Box
                component={"path"}
                sx={{
                    fill: selected ? "#000000" : theme.colors.disabled,
                    fillRule: "evenodd",
                }}
                d="M0,6.516,6.532,0,8,1.484,2.951,6.516,8,11.564,6.532,13.048Z"
                transform="translate(8 13.048) rotate(180)"
            />
        </svg>
    );
};

export default ForwardIcon;
