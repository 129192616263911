import { Component } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { setSelectedRelationsTabId } from "../../../../../redux/app/actions";
import StyledTooltip from "../../../../generic/StyledTooltip";
import theme from "../../../../../themes/default";

const Root = styled(Box)(() => ({
    display: "flex",
    userSelect: "none",
}));

const Tab = styled(Box)(({ theme }) => ({
    flex: 1,
    position: "relative",
    minHeight: "50px",
    maxHeight: "50px",
    backgroundColor: theme.colors.inputBackground,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
}));

const IconContainer = styled(Box)(() => ({
    position: "relative",
}));

const RelationsCounter = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "-4px",
    left: "calc(100% + 4px)",
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.regular,
    fontSize: "10px",
    color: theme.colors.placeholderText,
    zIndex: 1,
}));

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredTabId: null,
        };
    }

    handleMouseEnter = (event) => {
        const hoveredTabId = event.target.id;
        this.setState({ hoveredTabId });
    };

    handleMouseLeave = () => {
        this.setState({ hoveredTabId: null });
    };

    render() {
        const { tabs, selectedRelationsTabId, onSetSelectedRelationsTabId } = this.props;
        const { hoveredTabId } = this.state;

        return (
            <Root>
                {tabs.map((tab, index) => {
                    const isSelected = tab.id === selectedRelationsTabId;
                    const isHovered = tab.id === hoveredTabId;
                    const isDisabled = Boolean(tab.disabled);
                    const { counter } = tab;

                    let tabStyle = {};
                    let relationsCounterStyle = {};
                    if (isSelected || isHovered) relationsCounterStyle = { color: theme.colors.textColor, fontWeight: theme.fonts.openSans.bold };
                    if (isSelected) tabStyle = { backgroundColor: theme.colors.white };
                    if (isDisabled) tabStyle = { pointerEvents: "none" };

                    const Icon = tab.icon;
                    return (
                        <StyledTooltip key={index} title={tab.tooltip || tab.title}>
                            <Tab
                                key={index}
                                id={tab.id}
                                sx={tabStyle}
                                onClick={() => onSetSelectedRelationsTabId(tab.id)}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <IconContainer>
                                    <Icon selected={isSelected || isHovered} disabled={isDisabled} />
                                    {Boolean(counter) && <RelationsCounter sx={relationsCounterStyle}>{counter}</RelationsCounter>}
                                </IconContainer>
                            </Tab>
                        </StyledTooltip>
                    );
                })}
            </Root>
        );
    }
}

const mapStateToProps = ({ appReducer, objectRelationsReducer }) => ({
    selectedRelationsTabId: appReducer.selectedRelationsTabId,
    filteredRelations: objectRelationsReducer.filteredRelations,
});

const mapDispatchToProps = (dispatch) => ({
    onSetSelectedRelationsTabId: (selectedRelationsTabId) => dispatch(setSelectedRelationsTabId(selectedRelationsTabId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
