import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { showModelViews } from "../../redux/forge/actions";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { PaneMode } from "../../context/SplitPaneContext/State";

import { getTreeIndexString } from "../../utils/modelFunctions";

function* flatten(array, depth) {
    if (depth === undefined) depth = 1;

    for (const item of array) {
        if (Array.isArray(item.items) && depth > 0) {
            yield* flatten(item.items, depth - 1);
        } else {
            yield item;
        }
    }
}

const Root = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "64px",
    right: 0,
    zIndex: 1000,
    minHeight: "calc(100% - 64px)",
    maxHeight: "calc(100% - 64px)",
    borderLeft: `1px solid ${theme.colors.inputBorder}`,
    width: "420px",
    display: "flex",
    flexDirection: "column",
    transition: "margin-right 0.2s ease-in",
    backgroundColor: "#FFFFFF",
    userSelect: "none",
    "& h2": {
        display: "flex",
        marginTop: 0,
        marginBottom: "0",
        padding: "16px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "17px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const CloseButton = styled("span")(() => ({
    float: "right",
    fontSize: "14px",
    lineHeight: "20px",
    verticalAlign: "middle",
    cursor: "pointer",
}));

const ContentGroup = styled(Box)(({ theme }) => ({
    color: "#1F293C",
    fontSize: "12px",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 600,
    whiteSpace: "normal",
    paddingTop: "8px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    "& h3": {
        marginTop: 0,
        marginBottom: "0",
        padding: "12px 18px",
        backgroundColor: theme.colors.inputBackground,
        fontFamily: theme.fonts.raleway.fontFamily,
        fontWeight: theme.fonts.raleway.bold,
        fontSize: "14px",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: theme.colors.inputBorder,
    },
}));

const ContentGroupData = styled(Box)(() => ({
    overflow: "auto",
}));

const ItemContainer = styled(Box)(() => ({
    userSelect: "none",
    cursor: "pointer",
}));

const TitleContainer = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.inputBorder}`,
    display: "flex",
    padding: "9px 14px 8px 16px",
    alignItems: "center",
    cursor: "pointer",
    boxSizing: "border-box",
    minHeight: 40,
    "&:hover": {
        backgroundColor: "#f8f8f8",
    },
}));

const IconContainer = styled(Box)(() => ({
    fontSize: 16,
    marginRight: 10,
    color: "#499c85",
}));

const Title = styled(Box)(({ theme }) => ({
    flex: 1,
    color: theme.colors.textColor,
    fontFamily: theme.fonts.openSans.fontFamily,
    fontWeight: theme.fonts.openSans.semiBold,
    fontSize: "12px",
    cursor: "inherit",
    display: "flex",
    alignItems: "center",
}));

const ViewpointsCount = styled("span")(({ theme }) => ({
    marginLeft: "auto",
    color: theme.colors.placeholderText,
    fontSize: "10px",
    fontWeight: theme.fonts.openSans.regular,
    display: "flex",
}));

const ViewsProperties = (props) => {
    const { state } = useContext(AppContext);
    const [expandedTreeIndices, setExpandedTreeIndices] = useState({});
    const [name, setName] = useState();
    const [visible, setVisible] = useState(false);
    const [views, setViews] = useState([]);

    const activePane = state.panesState[state.activePane - 1];

    useEffect(() => {
        if (!props.modelId) {
            setVisible(false);
            return;
        }

        const { activePane, panesState } = state;
        const { data, mode } = panesState[activePane - 1];
        if (!data || mode !== PaneMode.BIM) {
            setVisible(false);
            return;
        }

        const flatDocuments = [...flatten(data.documents, Infinity)];
        if (flatDocuments.length === 0) {
            setVisible(false);
            return;
        }

        const {
            attributes: { displayName },
            visible,
        } = flatDocuments.find(({ relationships }) => relationships.derivatives.data.id === props.modelId);

        setName(displayName);
        setVisible(visible);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.modelId, activePane, activePane.data?.documents]);

    useEffect(() => {
        if (!visible || !props.modelId) return;
        setViews([props.views[props.modelId]] ?? []);
    }, [visible, props.modelId, props.views]);

    const activateView = (view) => {
        const { activePane, panesState } = state;
        const { appObject } = panesState[activePane - 1];
        appObject.impl.setViewFromCamera(view);
    };

    const countViewpoints = (root) => {
        if (!root) return 0;
        return root.reduce((acc, item) => acc + item.viewpoints.length + countViewpoints(item.children), 0);
    };

    const toggleExpandedIndex = (index, isExpanded) => {
        const indices = JSON.parse(JSON.stringify(expandedTreeIndices || {}));
        if (isExpanded) {
            delete indices[index];
            Object.keys(indices).forEach((expandedTreeIndex) => {
                if (expandedTreeIndex.startsWith(index)) {
                    delete indices[expandedTreeIndex];
                }
            });
            const getParentIndex = (index) => index.split("-").slice(0, -1).join("-");
            let parentIndex = getParentIndex(index);
            while (parentIndex) {
                indices[parentIndex] = true;
                parentIndex = getParentIndex(parentIndex);
            }
        } else {
            indices[index] = true;
        }

        setExpandedTreeIndices(indices);
    };

    const renderViewpointsStructure = () => {
        const renderViewpoints = (viewpoints, level) => {
            return viewpoints.map((viewpoint, index) => (
                <TitleContainer
                    key={index}
                    sx={{
                        paddingLeft: `${16 * (level + 1)}px`,
                        "&:not(:last-of-type)": {
                            borderBottom: "none",
                        },
                    }}
                    onClick={() => activateView(viewpoint)}
                >
                    <Title component={"label"} htmlFor={"inputId"}>
                        {viewpoint.name}
                    </Title>
                </TitleContainer>
            ));
        };

        const renderGroup =
            (level = -1, prevTreeIndex = []) =>
            (item, index) => {
                const treeIndex = [...prevTreeIndex, index];
                const treeIndexString = getTreeIndexString(treeIndex);
                const key = `ViewsProperties__renderViewpoints__renderGroup__${treeIndexString}`;
                const isRoot = item.name === "root" && level === -1;
                const isExpanded = item.expanded === true;
                const style = {
                    paddingLeft: 16 * (level + 1),
                };
                const viewpointsCount = countViewpoints([item]);

                return (
                    <ItemContainer key={key}>
                        {!isRoot && (
                            <TitleContainer
                                onClick={() => {
                                    toggleExpandedIndex(treeIndexString, isExpanded);
                                    item.expanded = !item.expanded;
                                }}
                                style={style}
                            >
                                <IconContainer>
                                    <FontAwesomeIcon icon={faFolder} />
                                </IconContainer>
                                <Title>
                                    <span>{item.name}</span>
                                    <ViewpointsCount>{viewpointsCount}</ViewpointsCount>
                                </Title>
                            </TitleContainer>
                        )}

                        {(isRoot || isExpanded) && item.children.map(renderGroup(level + 1, treeIndex))}
                        {(isRoot || isExpanded) && renderViewpoints(item.viewpoints, level + 1)}
                    </ItemContainer>
                );
            };

        return views.map(renderGroup());
    };

    return visible ? (
        <Root>
            <h2>
                <Box sx={{ display: "flex", flex: 1 }}>
                    Viewpoints
                    <Box sx={{ ml: "auto", alignSelf: "center" }}>
                        <CloseButton>
                            <FontAwesomeIcon icon={faXmark} onClick={() => props.onShowModelViews(null)} />
                        </CloseButton>
                    </Box>
                </Box>
            </h2>

            <ContentGroup>
                <h3>{name}</h3>
                <ContentGroupData>{views.length > 0 ? renderViewpointsStructure() : "Geen viewpoints beschikbaar."}</ContentGroupData>
            </ContentGroup>
        </Root>
    ) : null;
};

const mapStateToProps = ({ forgeReducer }) => ({
    modelId: forgeReducer.propertiesViews,
    views: forgeReducer.views,
});

const mapDispatchToProps = (dispatch) => ({
    onShowModelViews: (modelId) => dispatch(showModelViews(modelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewsProperties);
