import { ChangeEvent, memo } from "react";
import { Box, CircularProgress, Switch, styled } from "@mui/material";

const CustomSwitch = styled(Switch)(() => ({
    margin: -6,
    "& .MuiSwitch-track": {
        backgroundColor: "#8dd9c8",
    },
    "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "#8dd9c8",
    },
    "& .MuiSwitch-switchBase .MuiSwitch-track": {
        backgroundColor: "#8dd9c8",
    },
    "& .MuiSwitch-switchBase..Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#8dd9c8",
    },
}));

export interface LoadingSwitchProps {
    active: boolean;
    loading: boolean;
    disabled: boolean;
    onChange: () => void | null;
}

interface IconProps {
    active: boolean;
    loading: boolean;
}

const Icon = memo(({ active, loading }: IconProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: active && !loading ? "#499C85" : "#fafafa",
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
            }}
        >
            {loading && <CircularProgress size={16} color="primary" thickness={7} />}
        </Box>
    );
});

const LoadingSwitch = ({ active, disabled, loading, onChange }: LoadingSwitchProps) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        onChange && onChange();
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <CustomSwitch
                checkedIcon={<Icon loading={loading} active={active} />}
                icon={<Icon loading={loading} active={active} />}
                disabled={disabled || loading}
                checked={active}
                onChange={(e) => handleChange(e)}
                value=""
                sx={{
                    placeSelf: "start",
                }}
            />
        </div>
    );
};

export default memo(LoadingSwitch);
