import { Box, useTheme } from "@mui/material";

const ModelsIcon = ({ selected, disabled }) => {
    const theme = useTheme();

    const defaultStyle = {
        fill: theme.colors.placeholderText,
    };

    const selectedStyle = {
        fill: theme.colors.secondarySelection,
    };

    const disabledStyle = {
        pointerEvents: "none",
        fill: theme.colors.disabled,
    };

    let style;
    if (disabled) style = disabledStyle;
    else if (selected) style = selectedStyle;
    else style = defaultStyle;

    return selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.727" viewBox="0 0 16 15.727">
            <Box
                component={"path"}
                sx={style}
                d="M7.473,3.333.972,5.771A1.5,1.5,0,0,0,0,7.178v7.036a1.5,1.5,0,0,0,.828,1.341L7.33,18.8a1.49,1.49,0,0,0,1.341,0l6.5-3.251A1.5,1.5,0,0,0,16,14.213V7.178a1.5,1.5,0,0,0-.972-1.4l-6.5-2.438A1.474,1.474,0,0,0,7.473,3.333ZM8,5.274l6,2.25v.034L8,10,2,7.559V7.525ZM9,16.4V11.747l5-2.032V13.9Z"
                transform="translate(0 -3.237)"
            />
        </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.727" viewBox="0 0 16 15.727">
            <Box
                component={"path"}
                sx={style}
                d="M7.473,3.333.972,5.771A1.5,1.5,0,0,0,0,7.178v7.036a1.5,1.5,0,0,0,.828,1.341L7.33,18.8a1.49,1.49,0,0,0,1.341,0l6.5-3.251A1.5,1.5,0,0,0,16,14.213V7.178a1.5,1.5,0,0,0-.972-1.4l-6.5-2.438A1.474,1.474,0,0,0,7.473,3.333ZM8,4.205l7,2.626V6.84L8,9.875,1,6.84V6.831L8,4.205ZM1,7.931l6.5,2.816V17.77L1,14.52ZM8.5,17.77V10.747L15,7.931v6.592L8.5,17.77Z"
                transform="translate(0 -3.237)"
            />
        </svg>
    );
};

export default ModelsIcon;
