export const setThemes = (themes) => ({
    type: "SET_THEMES",
    themes,
});

export const setDefaultThemesOrder = (order) => ({
    type: "SET_DEFAULT_ORDER",
    order,
});

export const showLayerProperties = (layerId) => ({
    type: "SHOW_LAYER_PROPERTIES",
    layerId,
});

export const setActiveByDefault = (layers) => ({
    type: "SET_ACTIVE_BY_DEFAULT",
    layers,
});

export const setBookmarkState = (paneId, state) => ({
    type: "SET_STATE_TO_RESTORE",
    paneId,
    state,
});

export const clearBookmarkState = () => ({
    type: "CLEAR_STATE_TO_RESTORE",
});