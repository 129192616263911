import { IconButton, styled } from "@mui/material";

const CloseButton = styled(IconButton)(() => ({
    padding: 0,
    width: 32,
    height: 32,
    margin: "auto 20px auto auto",
}));

export default CloseButton;
