import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TableContainer,
    Toolbar,
    TextField,
    useTheme,
} from "@mui/material";
import { faCheck, faPencil, faTimes } from "@fortawesome/pro-light-svg-icons";
import { connect } from "react-redux";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";

import CloseButton from "../../generic/CloseButton";
import InputButton from "../InputButton";

import { fetchResources, modifyResource } from "../../../connectors/admin/resourcesConfig";

export interface AdminDialogContentBIMAccountProps {
    resources: any;
    onClose: () => void;
}

const RESOURCE_KEY = "Bim360";

const AdminDialogContentBIMAccount = ({ resources, onClose }: AdminDialogContentBIMAccountProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [project, setProject] = useState<any>();
    const [editProject, setEditProject] = useState<string>("");
    const [hub, setHub] = useState<any>();
    const [editHub, setEditHub] = useState<string>("");
    const [root, setRoot] = useState<any>();
    const [editRoot, setEditRoot] = useState<string>("");

    useEffect(() => {
        fetchResources();
    }, []);

    useEffect(() => {
        const data = resources.filter((x: any) => x.resource === RESOURCE_KEY);
        setData(data);
        setProject({ ...data.find((x: any) => x.key === "Project") });
        setHub({ ...data.find((x: any) => x.key === "Hub") });
        setRoot({ ...data.find((x: any) => x.key === "RootFolderFilter") });
        setIsLoading(false);
    }, [resources]);

    const onEdit = (type: string, value: string) => {
        switch (type) {
            case "Project":
                setEditProject(value);
                setEditHub("");
                setEditRoot("");
                break;
            case "Hub":
                setEditProject("");
                setEditHub(value);
                setEditRoot("");
                break;
            case "RootFolderFilter":
                setEditProject("");
                setEditHub("");
                setEditRoot(value);
                break;
        }
    };

    const onSave = () => {
        setIsLoading(true);

        const promises = [modifyResource(project), modifyResource(hub), modifyResource(root)];
        Promise.all(promises);
    };

    const onReset = () => {
        setProject({ ...data.find((x: any) => x.key === "Project") });
        setHub({ ...data.find((x: any) => x.key === "Hub") });
        setRoot({ ...data.find((x: any) => x.key === "RootFolderFilter") });
    };

    const hasChanges = (): boolean => {
        const originalProject = data.find((x: any) => x.key === "Project");
        if (!originalProject) return false;

        if (originalProject.value !== project.value) return true;

        const originalHub = data.find((x: any) => x.key === "Hub");
        if (!originalHub) return false;

        if (originalHub.value !== hub.value) return true;

        const originalRoot = data.find((x: any) => x.key === "Root");
        if (!originalRoot) return false;

        if (originalRoot.value !== root.value) return true;

        return false;
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <DialogTitle>BIM</DialogTitle>
                <CloseButton aria-label="Sluit instellingen" size="small" onClick={() => onClose()}>
                    <FontAwesomeSvgIcon icon={faTimes} sx={{ width: "12px !important" }} />
                </CloseButton>
            </Box>
            {isLoading ? (
                <DialogContent dividers style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent dividers>
                        <TableToolbar />
                        <TableContainer style={{ overflowY: "auto", maxHeight: "calc(100% - 75px)" }}>
                            <Table aria-labelledby="tableTitle">
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ width: 260 }}>
                                            <Typography variant="button">Project</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {Boolean(editProject) ? (
                                                <TextField
                                                    value={editProject}
                                                    onChange={(e) => setEditProject(e.currentTarget.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setProject({ ...project, value: editProject });
                                                                        setEditProject("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                                </InputButton>

                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setEditProject("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                                </InputButton>
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {project.value}
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onEdit("Project", project.value);
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ width: 260 }}>
                                            <Typography variant="button">Hub</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {Boolean(editHub) ? (
                                                <TextField
                                                    value={editHub}
                                                    onChange={(e) => setEditHub(e.currentTarget.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setHub({ ...hub, value: editHub });
                                                                        setEditHub("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                                </InputButton>

                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setEditHub("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                                </InputButton>
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {hub.value}
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onEdit("Hub", hub.value);
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ width: 260 }}>
                                            <Typography variant="button">Root Folder</Typography>
                                        </TableCell>
                                        <TableCell>
                                            {Boolean(editRoot) ? (
                                                <TextField
                                                    value={editRoot}
                                                    onChange={(e) => setEditRoot(e.currentTarget.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <>
                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setRoot({ ...root, value: editRoot });
                                                                        setEditRoot("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faCheck} style={{ fontSize: "1rem" }} />
                                                                </InputButton>

                                                                <InputButton
                                                                    size="small"
                                                                    onClick={(e) => {
                                                                        setEditRoot("");
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <FontAwesomeSvgIcon icon={faTimes} style={{ fontSize: "1rem" }} />
                                                                </InputButton>
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {root.value}
                                                    <InputButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onEdit("RootFolderFilter", root.value);
                                                        }}
                                                    >
                                                        <FontAwesomeSvgIcon icon={faPencil} style={{ fontSize: "1rem" }} />
                                                    </InputButton>
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions sx={{ m: "5px" }}>
                        <Button onClick={() => onSave()} color="primary" variant="contained" disabled={!hasChanges()}>
                            Opslaan
                        </Button>
                        <Button onClick={() => onReset()} color="secondary" variant="outlined" disabled={!hasChanges()}>
                            Reset
                        </Button>
                        <Button onClick={() => onClose()} color="secondary" variant="outlined">
                            Sluiten
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ appReducer }: any) => ({
    resources: appReducer.resources,
});

export default connect(mapStateToProps)(AdminDialogContentBIMAccount);

const TableToolbar = () => {
    const theme = useTheme();

    return (
        <Toolbar
            sx={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Typography
                sx={{
                    flex: "1 1 100%",
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Account Configuratie
            </Typography>
        </Toolbar>
    );
};
