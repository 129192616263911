import React from "react";
import { Changelog } from "../Changelog";
import { Version } from "../Version";
import ChangelogItem, { ChangelogItemType } from "../ChangelogItem";

const changelog: Changelog = {
    version: new Version(2, 1, 0),
    date: new Date(2022, 2, 11),
    content: (
        <>
            <ChangelogItem
                content="Implementatie Versiegeschiedenis"
                type={ChangelogItemType.New}
                description="Versiegeschiedenis werd aan het instellingenscherm toegevoegd zodat de informatie van deze meldingen steeds geraadpleegd kan worden."
            />
            <ChangelogItem
                content="Implementatie Inhoudsupdates"
                type={ChangelogItemType.New}
                description="Functionaliteit werd toegevoegd om notificaties aan de gebruiker te tonen wanneer er wijzigingen aan de inhoud werden toegepast."
            />
            <ChangelogItem
                content="Implementatie Tips"
                type={ChangelogItemType.New}
                description="Functionaliteit werd toegevoegd om tips aan de gebruiker te tonen."
            />
            <ChangelogItem
                content="Aanpassing activatie-knop modellen en lagen"
                type={ChangelogItemType.Update}
                description="De knop waarmee modellen en lagen actief/inactief worden gezet werd aangepast, deze geeft nu een indicatie wanneer de laag of het model nog bezig is met laden. Terwijl de laag of het model aan het laden is kan deze niet uitgeschakeld worden om verdere problemen met de applicatie te voorkomen."
            />
            <ChangelogItem
                content="Uitschakelen van een groep modellen"
                type={ChangelogItemType.Bugfix}
                description="Er werd een probleem verholpen waardoor bij het uitschakelen van een groep van modellen deze niet altijd allemaal verborgen werden."
            />
        </>
    ),
};

export default changelog;
