import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const UpdateBookmarkConfirmationModal = ({ open, bookmark, onClose, onConfirm }) => {
    return (
        <Dialog open={Boolean(open)} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Bookmark aanpassen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    U staat op het punt de bookmark <strong>{bookmark.name}</strong> aan te passen. Deze actie kan niet ongedaan worden
                    gemaakt.
                </DialogContentText>
                <DialogContentText>Weet u zeker dat u dit wenst te doen?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Annuleren
                </Button>
                <Button onClick={() => onConfirm()} color="primary" variant="contained">
                    Aanpassen
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateBookmarkConfirmationModal;
